import { injectable } from 'tsyringe'
import { LoggingPubSubRequestInterface } from '@/data/payload/contracts/MixPanelRequest'
import { MixPanelRepositoryInterface } from '@/data/persistences/repositories/contracts/MixPanelRepositoryInterface'

@injectable()
export class MixPanelPresenter {
  private repository: MixPanelRepositoryInterface

  constructor(repository: MixPanelRepositoryInterface) {
    this.repository = repository
  }

  public loggingPubSub(payload: LoggingPubSubRequestInterface): Promise<boolean> {
    return this.repository.loggingPubSub(payload)
  }
}
