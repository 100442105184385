import { PayrollBonusResponse } from "@/data/responses/contracts/PayrollBonusResponse";
import { Pagination } from "@/domain/entities/Pagination";
import { Bonus, BonusPayroll, BonusPayrolls, Courier } from "@/domain/entities/PayrollBonus";
import { AxiosResponse } from "axios";
import { BaseResponseMapper } from "./BaseMapper";

export class PayrollBonusMapper extends BaseResponseMapper {
  public convertBonusPayrollFromApi(result: AxiosResponse): BonusPayrolls {
    const { data:{
      data, pagination
    } } = result

    return new BonusPayrolls(
      new Pagination(
        pagination.page,
        pagination.limit,
        pagination.total_data,
        pagination.query,
      ),
      data.map(
        (item: PayrollBonusResponse) => {
          const courier = item.courier
          return new BonusPayroll(
            new Courier(
              courier.id,
              courier.type,
              courier.status,
              courier.fullname,
              courier.phone_number
            ),
            item.id,
            item.total_amount,
            item.status,
            item.month,
            item.year
          )
        }
      )
    )
  }

  public convertPayrollBonusExportFromApi(result: AxiosResponse): string {
    const { data } = result
    return data
  }

  public convertDetailBonusFromApi(result: AxiosResponse): Bonus {
    const {
      data: { data },
    } = result

    return new Bonus(
      data.id,
      data.courier_id,
      data.courier_name,
      data.phone_number,
      data.total_delivery,
      data.total_pickup,
      data.status,
      data.total_bonus_persentage,
      data.total_bonus_captain,
      data.total_bonus,
      data.component_payable_id,
      data.manage_day_id,
      data.month,
      data.year,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by
    )
  }
}
