import ApiService from '@/app/infrastructures/services/ApiService'
import { CourierMapper } from '@/data/persistences/mappers/CourierMapper'
import { CourierRepositoryInterface } from '@/data/persistences/repositories/contracts/CourierRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { Courier } from '@/domain/entities/Courier'

export class CourierApiRepository implements CourierRepositoryInterface {
  private service: ApiService
  private mapper: CourierMapper

  constructor(service: ApiService, mapper: CourierMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAllByAgentId(agentId: string): Promise<Courier[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ACCOUNT_AGENT}/${agentId}/courier`
    )
    return this.mapper.convertCouriersFromApi(resp)
  }
}
