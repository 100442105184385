export class PhoneCodes {
  public phoneCodes?: PhoneCode[]

  constructor(phoneCodes?: PhoneCode[]) {
    this.phoneCodes = phoneCodes
  }
}

export class PhoneCode {
  public id?: number
  public country?: string
  public countryIso?: string
  public countryCode?: string

  constructor(id?: number, country?: string, countryIso?: string, countryCode?: string) {
    this.id = id
    this.country = country
    this.countryIso = countryIso
    this.countryCode = countryCode
  }
}
