import { container } from 'tsyringe'
import Vue from 'vue'
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import { MixPanelPresenter } from '@/app/ui/presenters/MixPanelPresenter'
import { LoggingPubSubApiRequest } from '@/data/payload/api/MixPanelRequest'

export interface MixPanelState {
  isLoading: boolean
}

export interface PubSub {
  logType: string
  action: string
  projectId: string
  userId: string
  username: string
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: 'mix-panel',
})
class MixPanelController extends VuexModule implements MixPanelState {
  public isLoading = false

  @Action({ rawError: true })
  public sendLoggingPubSub(payload: PubSub) {
    this.setLoading(true)
    const presenter = container.resolve(MixPanelPresenter)
    presenter
      .loggingPubSub(
        new LoggingPubSubApiRequest(
          payload.logType,
          payload.projectId,
          payload.userId,
          payload.username,
          payload.action,
        )
      )
      .then(res => res)
      .catch(error => {
        Vue.notify({
          title: 'Fetch Logging PubSub Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Mutation
  private setLoading(bool: boolean) {
    this.isLoading = bool
  }
}

export default getModule(MixPanelController)
