import { injectable } from 'tsyringe'
import { ManualAdjustmentBalanceRepositoryInterface } from '@/data/persistences/repositories/contracts/ManualAdjustmentBalanceRepositoryInterface'
import {
  ManualAdjustmentBalances,
  ManualAdjustmentUploadHistories,
  UploadBulkData
} from '@/domain/entities/ManualAdjustmentBalance'
import {
  CreateManualAdjustmentBalanceRequestInterface,
  UploadBulkyManualAdjustmentBalanceRequestInterface,
  UploadManualAdjustmentBalanceRequestInterface,
} from '@/data/payload/contracts/ManualAdjustmentBalanceRequest'

@injectable()
export class ManualAdjustmentBalancePresenter {
  private repository: ManualAdjustmentBalanceRepositoryInterface

  constructor(repository: ManualAdjustmentBalanceRepositoryInterface) {
    this.repository = repository
  }

  public getAll(
    params: Map<string, string | number>
  ): Promise<ManualAdjustmentBalances> {
    return this.repository.getAll(params)
  }

  public getUploadHistoryList(
    params: Map<string, string | number>
  ): Promise<ManualAdjustmentUploadHistories> {
    return this.repository.getUploadHistoryList(params)
  }

  public create(
    payload: CreateManualAdjustmentBalanceRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public export(params: Map<string, string | number>): Promise<string> {
    return this.repository.export(params)
  }

  public uploadImage(
    payload: UploadManualAdjustmentBalanceRequestInterface
  ): Promise<string> {
    return this.repository.uploadImage(payload)
  }

  public uploadBulky(
    payload: UploadBulkyManualAdjustmentBalanceRequestInterface
  ): Promise<string> {
    return this.repository.uploadBulky(payload)
  }

  public downloadTemplate(): Promise<string> {
    return this.repository.downloadTemplate()
  }

  public uploadBulkyNew(
    payload: UploadBulkyManualAdjustmentBalanceRequestInterface
  ): Promise<UploadBulkData> {
    return this.repository.uploadBulkyNew(payload)
  }

  public getUploadBulkData(
    params: Map<string, string | number>
  ): Promise<UploadBulkData> {
    return this.repository.getUploadBulkData(params)
  }

  public submitBulk(): Promise<boolean> {
    return this.repository.submitBulk()
  }
}
