export const MIN_LATITUDE = -10.1718
export const MAX_LATITUDE = 5.88969
export const MIN_LONGITUDE = 95.31644
export const MAX_LONGITUDE = 140.71813
export const MAX_PHONE_NUMBER_WHITE_SELLER = 22
export const MAX_NAME_WHITE_SELLER = 255
export const MAX_NOMINAL_REQUEST_TOPUP_SALDO = 2000000
export const MAX_NOMINAL_REQUEST_POIN = 10000000
export const MAX_NOTES_REQUEST_TOPUP = 255
export const MIN_NOTES_REJECT_APPROVING_TOPUP = 10
export const MAX_NOTES_REJECT_APPROVING_TOPUP = 90
export const MIN_NOMINAL_REQUEST_POIN = -10000000
