import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { BalanceWithdrawalDetail, Withdrawal, Withdrawals } from '@/domain/entities/Withdrawal'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class WithdrawalMapper extends BaseResponseMapper {
  public convertWithdrawalsFromApi(result: AxiosResponse): Withdrawals {
    const {
      data: { data, pagination },
    } = result
    return new Withdrawals(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (withdrawal: Record<string, any>) =>
          new Withdrawal(
            withdrawal.withdrawal_id,
            withdrawal.customer_name,
            withdrawal.bank_id,
            withdrawal.bank_account_owner,
            withdrawal.bank_account_number,
            withdrawal.customer_email,
            withdrawal.amount,
            withdrawal.debit,
            withdrawal.fee,
            withdrawal.note,
            withdrawal.created_at,
            withdrawal.expired_at,
            withdrawal.withdrawal_status_id,
            withdrawal.withdrawal_history_created_at,
            undefined,
            undefined,
            withdrawal.phone_number,
          )
      )
    )
  }

  public convertWithdrawalFromApi(result: AxiosResponse): Withdrawal {
    const { data } = result
    return new Withdrawal(
      data.withdrawal_id,
      data.customer_name,
      data.bank_id,
      data.bank_account_owner,
      data.bank_account_number,
      data.customer_email,
      data.amount,
      data.debit,
      data.fee,
      data.note,
      data.created_at,
      data.expired_at,
      data.withdrawal_status_id,
      data.withdrawal_history_created_at,
      Utils.toInstance(new Pagination(), data.pagination),
      data.history.map(
        (history: Record<string, any>) =>
          new BalanceWithdrawalDetail(
            history.claim_id,
            history.stt_id,
            history.description,
            history.shipment_id,
            history.timestamp,
            history.delta_amount,
            history.balance_amount,            
            history.status,
          )
      ),
      data.phone_number,
      data.updated_by,
      data.current_balance,
    )
  }
}
