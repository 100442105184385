import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'
import {
  Day,
  PickupTime,
  PickupTimeDetail,
  PickupTimes,
  Slot,
  TimeSlot,
} from '@/domain/entities/PickupTimeManagement'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import { TimeSlotPickup } from '@/data/responses/contracts/PickupTimeManagementResponseInterface'

export class PickupTimeManagementMapper extends BaseResponseMapper {
  convertPickupTimesResponseFromAPI(result: AxiosResponse): PickupTimes {
    const {
      data: { pagination, data },
    } = result

    return new PickupTimes(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: Map<string, unknown>) => {
        return Utils.toInstance(new PickupTime(), item)
      })
    )
  }

  convertPickupTimeDetailResponseFromAPI(
    result: AxiosResponse
  ): PickupTimeDetail {
    const { data: { data } } = result

    return new PickupTimeDetail(
      data.dynamic_pickup_id,
      data.route_id,
      data.city,
      data.route,
      data.periode_start,
      data.periode_end,
      data.time_slot_pickup_id,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by,
      data.time_slot_pickup.map((item: TimeSlotPickup) => {
        return new TimeSlot({
          [Object.keys(item)[0]]: new Day(
            <string>item[Object.keys(item)[0] as keyof TimeSlotPickup]?.status,
            <Slot[]>item[
              Object.keys(item)[0] as keyof TimeSlotPickup
            ]?.slot?.map(slot => {
              return new Slot(
                slot.start_time,
                slot.end_time,
                slot.pickup_time_limit,
                slot.timezone
              )
            }),
            <boolean>item[Object.keys(item)[0] as keyof TimeSlotPickup]?.is_disabled
          ),
        })
      })
    )
  }
}
