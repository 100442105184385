import { UpdateLoyaltyProgramGetPointRulesInterface } from "@/data/payload/contracts/LoyaltyProgramGetPointRulesRequest";
import { LoyaltyProgramGetPointRulesMapper } from "@/data/persistences/mappers/LoyaltyProgramGetPointRulesMapper";
import { LoyaltyProgramGetPointRulesInterface } from "@/data/persistences/repositories/contracts/LoyaltyProgramGetPointRulesInterface";
import { LoyaltyProgramGetPointRules } from "@/domain/entities/LoyaltyProgramGetPointRules";
import { Endpoints } from "../../misc";
import ApiService from "../../services/ApiService";

export class LoyaltyProgramGetPointRulesRepository implements LoyaltyProgramGetPointRulesInterface {
  private service: ApiService
  private mapper: LoyaltyProgramGetPointRulesMapper

  constructor(service: ApiService, mapper: LoyaltyProgramGetPointRulesMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(): Promise<LoyaltyProgramGetPointRules> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.LOYALTY_GET_POINT_RULES
    )
    return this.mapper.convertGetPointRules(resp)
  }

  public async update(payload: UpdateLoyaltyProgramGetPointRulesInterface): Promise<boolean> {
    await this.service.invoke(
      'put',
      Endpoints.Route.LOYALTY_GET_POINT_RULES,
      undefined,
      payload
    )
    return true
  }
}
