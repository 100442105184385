import {
  ChangePasswordApiRequest,
  LoginApiRequest,
} from '@/data/payload/api/AuthRequest'
import {
  ChangePasswordRequestInterface,
  ForgotPasswordRequestInterface,
  ForgotPasswordVerifyRequestInterface,
  LoginRequestInterface,
  ResetPasswordRequestInterface,
} from '@/data/payload/contracts/AuthRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { AuthDataMapper } from '@/data/persistences/mappers/AuthDataMapper'
import { AuthRepositoryInterface } from '@/data/persistences/repositories/contracts/AuthRepositoryInterface'
import { Account, Token } from '@/domain/entities/Account'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'

export class AuthApiRepository implements AuthRepositoryInterface {
  private service: ApiService
  private mapper: AuthDataMapper

  constructor(service: ApiService, mapper: AuthDataMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getMe(): Promise<Account> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.CMS_MY_PROFILE
    )
    return this.mapper.convertMyProfileFromApi(resp)
  }

  public async login(payload: LoginRequestInterface): Promise<Token> {
    const resp = await this.service.invoke(
      'post',
      `${Endpoints.Route.CMS_AUTH}/login`,
      undefined,
      payload as LoginApiRequest
    )
    return this.mapper.convertAuthFromApi(resp)
  }

  public async changePassword(
    payload: ChangePasswordRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.CMS_AUTH}/me/password`,
      undefined,
      payload as ChangePasswordApiRequest
    )
    return true
  }

  public async forgotPassword(
    payload: ForgotPasswordRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.CMS_AUTH}/forgot_password`,
      undefined,
      payload as ForgotPasswordRequestInterface
    )
    return true
  }

  public async forgotPasswordVerify(
    payload: ForgotPasswordVerifyRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.CMS_AUTH}/forgot_password/verify`,
      undefined,
      payload as ForgotPasswordVerifyRequestInterface
    )
    return true
  }

  public async resetPassword(
    payload: ResetPasswordRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.CMS_AUTH}/password_reset/liberta`,
      undefined,
      payload as ResetPasswordRequestInterface
    )
    return true
  }
}
