import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'

export const pickupTimeManagement: RouteConfig = {
  path: '/pickup-time-management',
  component: MainLayout,
  redirect: { name: 'PickupTimeManagementTab' },
  meta: {
    title: 'Pick Up Time Management',
    single: true,
    icon: 'MenuIconPickupTimeManagement',
  },
  children: [
    {
      path: '',
      name: 'PickupTimeManagementTab',
      redirect: {
        name: 'PickupTimeManagementTabPickupTime',
      },
      component: () =>
        import(
          /* webpackChunkName: "PickupTimeManagementTab" */ '@/app/ui/views/PickupTimeManagement/Tabbing/index.vue'
        ),
      meta: {
        isParent: true,
        slugFromBackend: BACKEND_SLUG.PICKUP_TIME_MANAGEMENT,
      },
      children: [
        {
          path: 'data',
          name: 'PickupTimeManagementTabPickupTime',
          component: () =>
            import(
              /* webpackChunkName: "PickupTimeManagementTabPickupTime" */ '@/app/ui/views/PickupTimeManagement/PickupTime/index.vue'
            ),
          meta: {
            isParent: true,
            slugFromBackend: BACKEND_SLUG.PICKUP_TIME_MANAGEMENT,
          },
        },
      ],
    },
    {
      path: 'detail/:id',
      name: 'PickupTimeManagementDetail',
      component: () =>
        import(
          /* webpackChunkName: "PickupTimeManagementDetail" */ '@/app/ui/views/PickupTimeManagement/PickupTime/Detail/index.vue'
        ),
      meta: {
        title: 'Time Slot',
        slugFromBackend: BACKEND_SLUG.PICKUP_TIME_MANAGEMENT,
      },
    },
    {
      path: 'create',
      name: 'PickupTimeManagementCreate',
      component: () =>
        import(
          /* webpackChunkName: "PickupTimeManagementCreate" */ '@/app/ui/views/PickupTimeManagement/PickupTime/Create/index.vue'
        ),
      meta: {
        title: 'Time Slot',
        slugFromBackend: BACKEND_SLUG.PICKUP_TIME_MANAGEMENT
      }
    },
    {
      path: 'edit/:id',
      name: 'PickupTimeManagementEdit',
      component: () =>
        import(
          /* WebpackChunkName: "PickupTimeManagementEdit" */ '@/app/ui/views/PickupTimeManagement/PickupTime/Edit/index.vue'
        ),
      meta: {
        title: 'Time Slot',
        slugFromBackend: BACKEND_SLUG.PICKUP_TIME_MANAGEMENT
      }
    }
  ],
}
