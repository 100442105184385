import { Courier } from '@/domain/entities/Courier'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class CourierMapper extends BaseResponseMapper {
  public convertCouriersFromApi(result: AxiosResponse<any>): Courier[] {
    const { data } = result
    return data.map(
      (courier: Record<string, any>) =>
        new Courier(
          courier.courier_id,
          courier.phone_number,
          courier.courier_status
        )
    )
  }
}
