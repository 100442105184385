import ApiService from '@/app/infrastructures/services/ApiService'
import {
  ProgrammaticVouchers,
  ProgrammaticVoucher,
  ProgrammaticVoucherHistories,
  VoucherDistributions,
} from '@/domain/entities/ProgrammaticVoucher'
import { ProgrammaticVoucherRepositoryInterface } from '@/data/persistences/repositories/contracts/ProgrammaticVoucherRepositoryInterface'
import {
  CreateProgrammaticVoucherRequestInterface,
  UpdateProgrammaticVoucherRequestInterface,
  DeleteProgrammaticVoucherRequestInterface,
  UploadProgrammaticVoucherRequestInterface,
} from '@/data/payload/contracts/ProgrammaticVoucherRequest'
import { ProgrammaticVoucherMapper } from '@/data/persistences/mappers/ProgrammaticVoucherMapper'
import { ProgramaticPointMapper } from '@/data/persistences/mappers/ProgramaticPointMapper'
import {
  CreateProgrammaticVoucherRequest,
  UpdateProgrammaticVoucherRequest,
  DeleteProgrammaticVoucherRequest,
} from '@/data/payload/api/ProgrammaticVoucherRequest'
import { Endpoints } from '@/app/infrastructures/misc'
import { Trigger } from '@/domain/entities/Program'

export class ProgrammaticVoucherApiRepository
  implements ProgrammaticVoucherRepositoryInterface {
  private service: ApiService
  private mapper: ProgrammaticVoucherMapper
  private triggerMapper: ProgramaticPointMapper

  constructor(
    service: ApiService,
    mapper: ProgrammaticVoucherMapper,
    triggerMapper: ProgramaticPointMapper
  ) {
    this.service = service
    this.mapper = mapper
    this.triggerMapper = triggerMapper
  }

  public async create(
    payload: CreateProgrammaticVoucherRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.PROGRAMATIC_VOUCHER,
      undefined,
      payload as CreateProgrammaticVoucherRequest
    )

    return true
  }

  public async getAll(params: Map<string, any>): Promise<ProgrammaticVouchers> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PROGRAMATIC_VOUCHER,
      params
    )

    return this.mapper.convertProgrammaticVouchersFromApi(resp)
  }

  public async get(voucherId: string): Promise<ProgrammaticVoucher> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/${voucherId}`
    )

    return this.mapper.convertProgrammaticVoucherFromApi(resp)
  }

  public async getTriggers(): Promise<Trigger[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/trigger/active`
    )

    return this.triggerMapper.convertTriggersFromApi(resp)
  }

  public async uploadUserFile(
    payload: UploadProgrammaticVoucherRequestInterface
  ): Promise<Record<string, unknown>> {
    const resp = await this.service.invoke(
      'post',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/file`,
      undefined,
      payload as UploadProgrammaticVoucherRequestInterface,
      new Map([
        [
          'Content-Type',
          'multipart/form-data; ------WebKitFormBoundary7MA4YWxkTrZu0gW',
        ],
      ])
    )

    return this.mapper.convertUploadedUserFileFromApi(resp)
  }

  public async update(
    voucherId: string,
    payload: UpdateProgrammaticVoucherRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/${voucherId}`,
      undefined,
      payload as UpdateProgrammaticVoucherRequest
    )

    return true
  }

  public async delete(
    voucherId: string,
    payload: DeleteProgrammaticVoucherRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/${voucherId}`,
      undefined,
      payload as DeleteProgrammaticVoucherRequest
    )

    return true
  }

  public async getVoucherDistributions(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<VoucherDistributions> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/${voucherId}/distribution`,
      params
    )

    return this.mapper.convertVoucherDistributionsFromApi(resp)
  }

  public async getVoucherHistories(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<ProgrammaticVoucherHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/${voucherId}/history`,
      params
    )

    return this.mapper.convertProgrammaticVoucherHistoriesFromApi(resp)
  }

  public async exportVoucherHistories(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER}/${voucherId}/history/export`,
      params
    )

    return resp.data.url
  }

  public async getVoucherHistoriesV2(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<ProgrammaticVoucherHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER_V2}/${voucherId}/history`,
      params
    )

    return this.mapper.convertProgrammaticVoucherHistoriesFromApi(resp)
  }

  public async exportVoucherHistoriesV2(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_VOUCHER_V2}/${voucherId}/history/export`,
      params
    )

    return resp.data.url
  }
}
