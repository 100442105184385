import { injectable } from 'tsyringe'
import {
  CreateWhiteSellerRequestInterface,
  DeleteWhiteSellerRequestInterface,
  UpdateWhiteSellerRequestInterface
} from '@/data/payload/contracts/WhiteSellerRequest'
import { WhiteSellerRepositoryInterface } from '@/data/persistences/repositories/contracts/WhiteSellerRepositoryInterface'
import { WhiteListSeller, WhiteSeller } from '@/domain/entities/WhiteSeller'

@injectable()
export class WhiteSellerPresenter {
  private repository: WhiteSellerRepositoryInterface

  constructor(repository: WhiteSellerRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, any>): Promise<WhiteListSeller> {
    return this.repository.getAll(params)
  }

  public get(id: string): Promise<WhiteSeller> {
    return this.repository.get(id)
  }

  public create(payload: CreateWhiteSellerRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(payload: UpdateWhiteSellerRequestInterface): Promise<boolean> {
    return this.repository.update(payload)
  }

  public delete(payload: DeleteWhiteSellerRequestInterface): Promise<boolean> {
    return this.repository.delete(payload)
  }

  public export(): Promise<string> {
    return this.repository.export()
  }
}
