import ApiService from '@/app/infrastructures/services/ApiService'
import { CODTrackerMapper } from '@/data/persistences/mappers/CODTrackerMapper'
import { CODTrackerRepositoryInterface } from '@/data/persistences/repositories/contracts/CODTrackerRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { CODTrackerDetail, CODTrackers } from '@/domain/entities/CODTracker'
import { OriginRoute } from '@/domain/entities/Route'

export class CODTrackerApiRepository implements CODTrackerRepositoryInterface {
  private service: ApiService
  private mapper: CODTrackerMapper

  constructor(service: ApiService, mapper: CODTrackerMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getOrigins(): Promise<OriginRoute[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ROUTE}/city`
    )
    return this.mapper.convertOriginsFromApi(resp)
  }

  public async getCODTrackers(
    params: Map<string, string | number>
  ): Promise<CODTrackers> {
    const resp = await this.service.invoke('get', Endpoints.Route.COD, params)
    return this.mapper.convertCODTrackersFromApi(resp)
  }

  public async exportCODReport(params: Map<string, string>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.COD}/export`,
      params
    )

    return resp.data.url
  }

  public async getCODDetail(packageId: string): Promise<CODTrackerDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.COD}/${packageId}`
    )

    return this.mapper.convertCODTrackerDetailFromApi(resp)
  }
}
