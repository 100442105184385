import { injectable } from 'tsyringe'
import { Validation } from '@/domain/entities/CampaignRamadhan'
import { CampaignRamadhanRepositoryInterface } from '@/data/persistences/repositories/contracts/CampaignRamadhanRepositoryInterface'
import { SubmitDonationRequestInterface } from '@/data/payload/contracts/CamapignRamadhanRequest'

@injectable()
export class CampaignRamadhanPresenter {
  private repository: CampaignRamadhanRepositoryInterface

  constructor(repository: CampaignRamadhanRepositoryInterface) {
    this.repository = repository
  }

  public getValidationStatus(phoneNumber: string): Promise<Validation> {
    return this.repository.getValidationStatus(phoneNumber)
  }

  public submitDonation(
    payload: SubmitDonationRequestInterface
  ): Promise<number> {
    return this.repository.submitDonation(payload)
  }
}
