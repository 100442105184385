import { Pagination } from './Pagination'

export class ShortHistory {
  public shortStatus?: string
  public historyTime?: string
  public statusId?: string
  public reason?: string

  constructor(
    shortStatus?: string,
    historyTime?: string,
    statusId?: string,
    reason?: string
  ) {
    this.shortStatus = shortStatus
    this.historyTime = historyTime
    this.statusId = statusId
    this.reason = reason
  }
}

export class LongHistory {
  public longStatus?: string
  public historyTime?: string

  constructor(longStatus?: string, historyTime?: string) {
    this.longStatus = longStatus
    this.historyTime = historyTime
  }
}

export class Product {
  public productName?: string
  public totalPrice?: number
  public weight?: number
  public unitPrice?: number
  public quantity?: number

  constructor(
    productName?: string,
    totalPrice?: number,
    weight?: number,
    unitPrice?: number,
    quantity?: number
  ) {
    this.productName = productName
    this.totalPrice = totalPrice
    this.weight = weight
    this.unitPrice = unitPrice
    this.quantity = quantity
  }
}

type shippingType = 'REGPACK' | 'ONEPACK'

export class OrderDetail {
  public shipmentId?: string
  public packageId?: string
  public customerId?: string
  public buyerName?: string
  public buyerEmail?: string
  public buyerPhoneNumber?: string
  public recipientName?: string
  public recipientPhoneNumber?: string
  public recipientAddress?: string
  public invoiceId?: string
  public note?: string
  public subTotal?: number
  public convenienceFee?: number
  public deliveryFee?: number
  public totalAmount?: number
  public courierName?: string
  public vehiclePlate?: string
  public picture?: string
  public paymentMethod?: string
  public merchantName?: string
  public merchantAddress?: string
  public merchantCity?: string
  public shortHistories?: ShortHistory[]
  public longHistories?: LongHistory[]
  public listProducts?: Product[]
  public shippingType?: shippingType
  public prevWeight?: number
  public currWeight?: number
  public prevAmount?: number
  public currAmount?: number
  public diffAmount?: number
  public isOverpay?: boolean
  public isUnderpay?: boolean

  constructor(
    shipmentId?: string,
    packageId?: string,
    customerId?: string,
    buyerName?: string,
    buyerEmail?: string,
    buyerPhoneNumber?: string,
    recipientName?: string,
    recipientPhoneNumber?: string,
    recipientAddress?: string,
    invoiceId?: string,
    note?: string,
    subTotal?: number,
    convenienceFee?: number,
    deliveryFee?: number,
    totalAmount?: number,
    courierName?: string,
    vehiclePlate?: string,
    picture?: string,
    paymentMethod?: string,
    merchantName?: string,
    merchantAddress?: string,
    merchantCity?: string,
    shortHistories?: ShortHistory[],
    longHistories?: LongHistory[],
    listProducts?: Product[],
    shippingType?: shippingType,
    prevWeight?: number,
    currWeight?: number,
    prevAmount?: number,
    currAmount?: number,
    diffAmount?: number,
    isOverpay?: boolean,
    isUnderpay?: boolean
  ) {
    this.shipmentId = shipmentId
    this.packageId = packageId
    this.customerId = customerId
    this.buyerName = buyerName
    this.buyerEmail = buyerEmail
    this.buyerPhoneNumber = buyerPhoneNumber
    this.recipientName = recipientName
    this.recipientPhoneNumber = recipientPhoneNumber
    this.recipientAddress = recipientAddress
    this.invoiceId = invoiceId
    this.note = note
    this.subTotal = subTotal
    this.convenienceFee = convenienceFee
    this.deliveryFee = deliveryFee
    this.totalAmount = totalAmount
    this.courierName = courierName
    this.vehiclePlate = vehiclePlate
    this.picture = picture
    this.paymentMethod = paymentMethod
    this.merchantName = merchantName
    this.merchantAddress = merchantAddress
    this.merchantCity = merchantCity
    this.shortHistories = shortHistories
    this.longHistories = longHistories
    this.listProducts = listProducts
    this.shippingType = shippingType
    this.prevWeight = prevWeight
    this.currWeight = currWeight
    this.prevAmount = prevAmount
    this.currAmount = currAmount
    this.diffAmount = diffAmount
    this.isOverpay = isOverpay
    this.isUnderpay = isUnderpay
  }
}

export class Order {
  public customerId?: number
  public buyerName?: string
  public shipmentId?: string
  public merchantId?: number
  public merchantName?: string
  public orderDate?: string
  public statusId?: string
  public shortStatus?: string
  public products?: string[]
  public courierName?: string
  public vehiclePlate?: string
  public picture?: string
  public totalAmount?: number
  public subTotal?: number
  public paymentMethod?: string

  constructor(
    customerId?: number,
    buyerName?: string,
    shipmentId?: string,
    merchantId?: number,
    merchantName?: string,
    orderDate?: string,
    statusId?: string,
    shortStatus?: string,
    products?: string[],
    courierName?: string,
    vehiclePlate?: string,
    picture?: string,
    totalAmount?: number,
    subTotal?: number,
    paymentMethod?: string
  ) {
    this.customerId = customerId
    this.buyerName = buyerName
    this.shipmentId = shipmentId
    this.merchantId = merchantId
    this.merchantName = merchantName
    this.orderDate = orderDate
    this.statusId = statusId
    this.shortStatus = shortStatus
    this.products = products
    this.courierName = courierName
    this.vehiclePlate = vehiclePlate
    this.picture = picture
    this.totalAmount = totalAmount
    this.subTotal = subTotal
    this.paymentMethod = paymentMethod
  }
}

export class Orders {
  public pagination: Pagination
  public data: Order[]

  constructor(pagination: Pagination, data: Order[]) {
    this.pagination = pagination
    this.data = data
  }
}
