import {Pagination} from '@/domain/entities/Pagination'

class SkuGroup {
  id: number;
  sku: string;
  name: string;

  constructor(id: number, sku: string, name: string) {
    this.id = id;
    this.sku = sku;
    this.name = name;
  }
}


export class VoucherSetLimit {
  id: number;
  ruleName: string;
  isActive: boolean;
  skuGroups: SkuGroup[];
  periodAllCustomer: null | string;
  maxQtyAllCustomer: null | number;
  maxAmountAllCustomer: null | number;
  target: null | string;
  period: null | string;
  maxQty: null | number;
  maxAmount: null | number;
  specificCustomers: null | number[];
  createdAt: string;
  updatedAt: null | string;

  constructor(
    id: number,
    ruleName: string,
    isActive: boolean,
    skuGroups: SkuGroup[],
    periodAllCustomer: null | string,
    maxQtyAllCustomer: null | number,
    maxAmountAllCustomer: null | number,
    target: null | string,
    period: null | string,
    maxQty: null | number,
    maxAmount: null | number,
    specificCustomers: null | number[],
    createdAt: string,
    updatedAt: null | string
  ) {
    this.id = id;
    this.ruleName = ruleName;
    this.isActive = isActive;
    this.skuGroups = skuGroups;
    this.periodAllCustomer = periodAllCustomer;
    this.maxQtyAllCustomer = maxQtyAllCustomer;
    this.maxAmountAllCustomer = maxAmountAllCustomer;
    this.target = target;
    this.period = period;
    this.maxQty = maxQty;
    this.maxAmount = maxAmount;
    this.specificCustomers = specificCustomers;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export class VoucherSetLimitList {
  public pagination: Pagination
  public data: VoucherSetLimit[]

  constructor(pagination: Pagination, data: VoucherSetLimit[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ResultUploadBulkySetLimitSpecificCustomer {
  fileName: string
  totalValid: number
  totalInvalid: number

  constructor(
    fileName: string,
    totalValid: number,
    totalInvalid: number
  ) {
    this.fileName = fileName
    this.totalValid = totalValid
    this.totalInvalid = totalInvalid
  }
}


export class DetailUploadBulkySetLimitSpecificCustomer {
  customerId: number
  status: string

  constructor(
    customerId: number,
    status: string
  ) {
    this.customerId = customerId
    this.status = status
  }
}

export class UploadBulkyVoucherSetLimitSpecificCustomer {
  public result: ResultUploadBulkySetLimitSpecificCustomer
  public data: DetailUploadBulkySetLimitSpecificCustomer[]

  constructor(
    result: ResultUploadBulkySetLimitSpecificCustomer,
    data: DetailUploadBulkySetLimitSpecificCustomer[],
  ) {
    this.result = result
    this.data = data
  }
}
