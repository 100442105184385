import { AxiosResponse } from 'axios'
import { PhoneCode, PhoneCodes } from '@/domain/entities/PhoneCode'
import { Utils } from '@/app/infrastructures/misc'
import { PhoneCodeListResponseInterface } from '@/data/responses/contracts/PhoneCodeResponseInterface'

export class PhoneCodeMapper {
  convertResponseListPhoneCodeFromApi(result: AxiosResponse): PhoneCodes {
    const { data: { data } } = result

    return new PhoneCodes(
      data.map((item: PhoneCodeListResponseInterface) => {
        return Utils.toInstance(new PhoneCode(), JSON.stringify(item))
      })
    )
  }
}
