import {
  LoyaltyProgramMembershipRepositoryInterface
} from '@/data/persistences/repositories/contracts/LoyaltyProgramMembershipInterface'
import {
  ListLoyaltyProgramMembership,
  DetailLoyaltyProgramMembership
} from '@/domain/entities/LoyaltyProgramMembership'
import ApiService from '@/app/infrastructures/services/ApiService'
import { LoyaltyProgramMembershipMapper } from '@/data/persistences/mappers/LoyaltyProgramMembershipMapper'
import { Endpoints } from '@/app/infrastructures/misc';
import {
  CreateLoyaltyProgramMembershipRequestInterface,
  UpdateLoyaltyProgramMembershipRequestInterface
} from '@/data/payload/contracts/LoyaltyProgramMembershipRequest'

export class LoyaltyProgramMembershipRepository implements LoyaltyProgramMembershipRepositoryInterface {
  private service: ApiService
  private mapper: LoyaltyProgramMembershipMapper

  constructor(service: ApiService, mapper: LoyaltyProgramMembershipMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async create(payload: CreateLoyaltyProgramMembershipRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.LOYALTY_MEMBERSHIP,
      undefined,
      <CreateLoyaltyProgramMembershipRequestInterface>payload
    )

    return true
  }

  public async getAll(params: Map<string, unknown>): Promise<ListLoyaltyProgramMembership> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.LOYALTY_MEMBERSHIP,
      params
    )

    return this.mapper.convertLoyaltyProgramMembershipAPI(resp)
  }

  public async getOne(id: number): Promise<DetailLoyaltyProgramMembership> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.LOYALTY_MEMBERSHIP}/${id}`,
    )

    return this.mapper.convertDetailLoyaltyMembershipAPI(resp)
  }

  public async update(id: number, payload: UpdateLoyaltyProgramMembershipRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.LOYALTY_MEMBERSHIP}/${id}`,
      undefined,
      <UpdateLoyaltyProgramMembershipRequestInterface>payload
    )

    return true
  }
}
