import {
  CreateDynamicPickupRequest,
  UpdateDynamicPickupRequest,
} from '@/data/payload/api/PickupTimeManagementRequest'
import { PickupTimeManagementRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupTimeManagementRepositoryInterface'
import {
  PickupTimeDetail,
  PickupTimes,
} from '@/domain/entities/PickupTimeManagement'
import { injectable } from 'tsyringe'

@injectable()
export class PickupTimeManagementPresenter
  implements PickupTimeManagementRepositoryInterface {
  private repository: PickupTimeManagementRepositoryInterface

  constructor(repository: PickupTimeManagementRepositoryInterface) {
    this.repository = repository
  }

  getPickupTimes(params: Map<string, string | number>): Promise<PickupTimes> {
    return this.repository.getPickupTimes(params)
  }

  getDetailPickupTime(id: number): Promise<PickupTimeDetail> {
    return this.repository.getDetailPickupTime(id)
  }

  createDynamicPickupTime(
    payload: CreateDynamicPickupRequest
  ): Promise<boolean> {
    return this.repository.createDynamicPickupTime(payload)
  }

  updateDynamicPickupTime(
    payload: UpdateDynamicPickupRequest
  ): Promise<boolean> {
    return this.repository.updateDynamicPickupTime(payload)
  }

  deleteDynamicPickupTime(id: number): Promise<boolean> {
    return this.repository.deleteDynamicPickupTime(id)
  }
}
