import ApiService from '@/app/infrastructures/services/ApiService'
import { RouteRepositoryInterface } from '@/data/persistences/repositories/contracts/RouteRepository'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { RouteMapper } from '@/data/persistences/mappers/RouteMapper'
import { LetterCode, OriginRoute, Route } from '@/domain/entities/Route'

export class RouteApiRepository implements RouteRepositoryInterface {
  private service: ApiService
  private mapper: RouteMapper

  constructor(service: ApiService, mapper: RouteMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getActiveOrigin(): Promise<OriginRoute[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ROUTE}/origin`
    )
    return this.mapper.convertOriginFromApi(resp)
  }

  public async getAllCity(): Promise<OriginRoute[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ROUTE}/city`
    )
    return this.mapper.convertOriginFromApi(resp)
  }

  public async getAllDistrict(city: string): Promise<OriginRoute[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ROUTE}/${city}/district-only`
    )
    return this.mapper.convertOriginFromApi(resp)
  }

  public async getAllRoute(params: Map<string, string>): Promise<Route[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ROUTE}/lists`,
      params
    )

    return this.mapper.convertRoutesFromApi(resp)
  }

  public async getLetterCOdes(): Promise<LetterCode[]> {
    const resp = await this.service.invoke('GET', `${Endpoints.Route.ROUTE}/lc`)

    return this.mapper.convertLetterCodesFromApi(resp)
  }
}
