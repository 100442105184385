import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Schedule, ScheduleOrder } from '@/domain/entities/ScheduleOrder'
import { ScheduleOrderResponse } from '@/data/responses/contracts/ScheduleOrderResponse'

export class ScheduleOrderMapper extends BaseResponseMapper {
  public convertScheduleOrderFromApi(result: AxiosResponse): ScheduleOrder[] {
    const {
      data: { data },
    } = result

    return data.map(
      (item: ScheduleOrderResponse) =>
        new ScheduleOrder(
          item.day,
          item.is_active,
          item.updated_at,
          item.updated_by,
          item.schedule.schedule.map(
            schedule =>
              new Schedule(
                schedule.start_time,
                schedule.end_time,
                schedule.offer_time
              )
          )
        )
    )
  }
}
