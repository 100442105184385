import {LocalStorage} from '@/app/infrastructures/misc'
import {LabelValue} from '@/app/ui/components/OptionBox/interfaces'
import {
  CreateProgramaticPointRequestInterface,
  UpdateProgramaticPointRequestInterface,
  DeleteProgramaticPointRequestInterface, AllProgrammaticPointApprovalInterface,
} from '@/data/payload/contracts/ProgramaticPointRequest'
import dayjs from 'dayjs'

export class CreateProgramaticPointRequest
  implements CreateProgramaticPointRequestInterface {
  public programmaticPointName?: string
  public programmaticPointTarget?: string
  public selectedUser?: string
  public programmaticPointTrigger?: string
  public routes?: number[]
  public scheduledAt?: string
  public invoiceSource?: string
  public paymentMethodUsed?: string
  public minimumTransaction?: number
  public programmaticPointPurpose?: string
  public isPercentage?: boolean
  // public amount?: number
  public percentOfTransaction?: number
  public maxAmountGiven?: number | null
  public isExactDate?: boolean
  public expiredDate?: string | null
  public expiryDay?: number
  // public limitPerDay?: number
  // public limitPerUser?: number
  public budgetSource?: string
  public startDate?: string
  public endDate?: string
  public actorName?: string
  public membershipLevel?: { label: string, value: number }[]
  public budgetAmount?: null | undefined | number
  public extraRulesActive?: boolean
  public totalAmountTransaction?: null | number | undefined
  public totalAmountTransactionTime?: LabelValue<string> | null
  public totalNumberTransaction?: null | number | undefined
  public totalNumberTransactionTime?: LabelValue<string> | null
  public logicType?: string
  public statusType?: string[]
  public shipmentType?: string[]
  public productType?: string[]
  public minimumAmount?: null | number | undefined
  public pointValue?: LabelValue<string | number> | null
  public pointValueAmountForInviter?: null | number | undefined
  public pointValueAmountForInviterDisburseTo?: LabelValue<string> | null
  public pointValueAmountForInviterApprovalType?: LabelValue<string> | null
  public pointValueAmountForInvitee?: null | number | undefined
  public pointValueAmountForInviteeDisburseTo?: LabelValue<string> | null
  public pointValueAmountForInviteeApprovalType?: LabelValue<string> | null
  public pointValueAmountForGeneral?: null | number | undefined
  public pointValueAmountForGeneralDisburseTo?: LabelValue<string> | null
  public pointValueAmountForGeneralApprovalType?: LabelValue<string> | null
  public pointValuePercentageForInviter?: null | number | undefined
  public pointValuePercentageForInviterMax?: null | number | undefined
  public pointValuePercentageForInviterDisburseTo?: LabelValue<string> | null
  public pointValuePercentageForInviterApprovalType?: LabelValue<string> | null
  public pointValuePercentageForInvitee?: null | number | undefined
  public pointValuePercentageForInviteeMax?: null | number | undefined
  public pointValuePercentageForInviteeDisburseTo?: LabelValue<string> | null
  public pointValuePercentageForInviteeApprovalType?: LabelValue<string> | null
  public pointValuePercentageForGeneral?: null | number | undefined
  public pointValuePercentageForGeneralMax?: null | number | undefined
  public pointValuePercentageForGeneralDisburseTo?: LabelValue<string> | null
  public pointValuePercentageForGeneralApprovalType?: LabelValue<string> | null
  public pointDisburseType?: LabelValue<string> | null
  public shipmentStatus?: LabelValue<string> | null
  public specificDate?: LabelValue<string | number> | null
  public pointDisburseLimitPerProgram?: LabelValue<string> | null
  public totalNumberDisbursePerProgram?: null | number | undefined
  public totalAmountDisbursePerProgram?: null | number | undefined
  public pointDisburseLimitPerUser?: LabelValue<string> | null
  public totalNumberDisbursePerUser?: null | number | undefined
  public totalAmountDisbursePerUser?: null | number | undefined
  public pointPurposeState?: { checked: boolean, value: number, label: string }[]
  public deviceIdLimitCustomer?: number | null

  constructor(
    programmaticPointName?: string,
    programmaticPointTarget?: string,
    selectedUser?: string,
    programmaticPointTrigger?: string,
    routes?: number[],
    scheduledAt?: string,
    invoiceSource?: string,
    paymentMethodUsed?: string,
    minimumTransaction?: number,
    programmaticPointPurpose?: string,
    isPercentage?: boolean,
    // amount?: number,
    percentOfTransaction?: number,
    maxAmountGiven?: number | null,
    isExactDate?: boolean,
    expiredDate?: string | null,
    expiryDay?: number,
    // limitPerDay?: number,
    // limitPerUser?: number,
    budgetSource?: string,
    startDate?: string,
    endDate?: string,
    actorName?: string,
    membershipLevel?: { label: string, value: number }[],
    budgetAmount?: null | number,
    extraRulesActive?: boolean,
    totalAmountTransaction?: null | number | undefined,
    totalAmountTransactionTime?: LabelValue<string> | null,
    totalNumberTransaction?: null | number | undefined,
    totalNumberTransactionTime?: LabelValue<string> | null,
    logicType?: string,
    statusType?: string[],
    shipmentType?: string[],
    productType?: string[],
    minimumAmount?: null | number | undefined,
    pointValue?: LabelValue<string | number> | null,
    pointValueAmountForInviter?: null | number | undefined,
    pointValueAmountForInviterDisburseTo?: LabelValue<string> | null,
    pointValueAmountForInviterApprovalType?: LabelValue<string> | null,
    pointValueAmountForInvitee?: null | number | undefined,
    pointValueAmountForInviteeDisburseTo?: LabelValue<string> | null,
    pointValueAmountForInviteeApprovalType?: LabelValue<string> | null,
    pointValueAmountForGeneral?: null | number | undefined,
    pointValueAmountForGeneralDisburseTo?: LabelValue<string> | null,
    pointValueAmountForGeneralApprovalType?: LabelValue<string> | null,
    pointValuePercentageForInviter?: null | number | undefined,
    pointValuePercentageForInviterMax?: null | number | undefined,
    pointValuePercentageForInviterDisburseTo?: LabelValue<string> | null,
    pointValuePercentageForInviterApprovalType?: LabelValue<string> | null,
    pointValuePercentageForInvitee?: null | number | undefined,
    pointValuePercentageForInviteeMax?: null | number | undefined,
    pointValuePercentageForInviteeDisburseTo?: LabelValue<string> | null,
    pointValuePercentageForInviteeApprovalType?: LabelValue<string> | null,
    pointValuePercentageForGeneral?: null | number | undefined,
    pointValuePercentageForGeneralMax?: null | number | undefined,
    pointValuePercentageForGeneralDisburseTo?: LabelValue<string> | null,
    pointValuePercentageForGeneralApprovalType?: LabelValue<string> | null,
    pointDisburseType?: LabelValue<string> | null,
    shipmentStatus?: LabelValue<string> | null,
    specificDate?: LabelValue<string | number> | null,
    pointDisburseLimitPerProgram?: LabelValue<string> | null,
    totalNumberDisbursePerProgram?: null | number | undefined,
    totalAmountDisbursePerProgram?: null | number | undefined,
    pointDisburseLimitPerUser?: LabelValue<string> | null,
    totalNumberDisbursePerUser?: null | number | undefined,
    totalAmountDisbursePerUser?: null | number | undefined,
    pointPurposeState?: { checked: boolean, value: number, label: string }[],
    deviceIdLimitCustomer?: number | null,
  ) {
    this.programmaticPointName = programmaticPointName
    this.programmaticPointTarget = programmaticPointTarget
    this.selectedUser = selectedUser
    this.programmaticPointTrigger = programmaticPointTrigger
    this.routes = routes
    this.scheduledAt = scheduledAt
    this.invoiceSource = invoiceSource
    this.paymentMethodUsed = paymentMethodUsed
    this.minimumTransaction = minimumTransaction
    this.programmaticPointPurpose = programmaticPointPurpose
    this.isPercentage = isPercentage
    // this.amount = amount
    this.percentOfTransaction = percentOfTransaction
    this.maxAmountGiven = maxAmountGiven
    this.isExactDate = isExactDate
    this.expiredDate = expiredDate
    this.expiryDay = expiryDay
    // this.limitPerDay = limitPerDay
    // this.limitPerUser = limitPerUser
    this.budgetSource = budgetSource
    this.startDate = startDate
    this.endDate = endDate
    this.actorName = actorName
    this.membershipLevel = membershipLevel
    this.budgetAmount = budgetAmount
    this.extraRulesActive = extraRulesActive
    this.totalAmountTransaction = totalAmountTransaction
    this.totalAmountTransactionTime = totalAmountTransactionTime
    this.totalNumberTransaction = totalNumberTransaction
    this.totalNumberTransactionTime = totalNumberTransactionTime
    this.logicType = logicType
    this.statusType = statusType
    this.shipmentType = shipmentType
    this.productType = productType
    this.minimumAmount = minimumAmount
    this.pointValue = pointValue
    this.pointValueAmountForInviter = pointValueAmountForInviter
    this.pointValueAmountForInviterDisburseTo = pointValueAmountForInviterDisburseTo
    this.pointValueAmountForInviterApprovalType = pointValueAmountForInviterApprovalType
    this.pointValueAmountForInvitee = pointValueAmountForInvitee
    this.pointValueAmountForInviteeDisburseTo = pointValueAmountForInviteeDisburseTo
    this.pointValueAmountForInviteeApprovalType = pointValueAmountForInviteeApprovalType
    this.pointValueAmountForGeneral = pointValueAmountForGeneral
    this.pointValueAmountForGeneralDisburseTo = pointValueAmountForGeneralDisburseTo
    this.pointValueAmountForGeneralApprovalType = pointValueAmountForGeneralApprovalType
    this.pointValuePercentageForInviter = pointValuePercentageForInviter
    this.pointValuePercentageForInviterMax = pointValuePercentageForInviterMax
    this.pointValuePercentageForInviterDisburseTo = pointValuePercentageForInviterDisburseTo
    this.pointValuePercentageForInviterApprovalType = pointValuePercentageForInviterApprovalType
    this.pointValuePercentageForInvitee = pointValuePercentageForInvitee
    this.pointValuePercentageForInviteeMax = pointValuePercentageForInviteeMax
    this.pointValuePercentageForInviteeDisburseTo = pointValuePercentageForInviteeDisburseTo
    this.pointValuePercentageForInviteeApprovalType = pointValuePercentageForInviteeApprovalType
    this.pointValuePercentageForGeneral = pointValuePercentageForGeneral
    this.pointValuePercentageForGeneralMax = pointValuePercentageForGeneralMax
    this.pointValuePercentageForGeneralDisburseTo = pointValuePercentageForGeneralDisburseTo
    this.pointValuePercentageForGeneralApprovalType = pointValuePercentageForGeneralApprovalType
    this.pointDisburseType = pointDisburseType
    this.shipmentStatus = shipmentStatus
    this.specificDate = specificDate
    this.pointDisburseLimitPerProgram = pointDisburseLimitPerProgram
    this.totalNumberDisbursePerProgram = totalNumberDisbursePerProgram
    this.totalAmountDisbursePerProgram = totalAmountDisbursePerProgram
    this.pointDisburseLimitPerUser = pointDisburseLimitPerUser
    this.totalNumberDisbursePerUser = totalNumberDisbursePerUser
    this.totalAmountDisbursePerUser = totalAmountDisbursePerUser
    this.pointPurposeState = pointPurposeState
    this.deviceIdLimitCustomer = deviceIdLimitCustomer
  }

  public toPayload(): string {
    const data = {
      programmatic_point_name: this.programmaticPointName,
      programmatic_point_target: this.programmaticPointTarget,
      selected_user: this.selectedUser,
      programmatic_point_trigger: this.programmaticPointTrigger,
      route_id: this.routes,
      scheduled_at: this.scheduledAt,
      invoice_source: this.invoiceSource,
      payment_method_used: this.paymentMethodUsed,
      minimum_transaction: this.minimumTransaction,
      programmatic_point_purpose: this.programmaticPointPurpose,
      is_percentage: this.isPercentage,
      // amount: this.amount,
      percent_of_transaction: this.percentOfTransaction,
      max_amount_given: this.maxAmountGiven,
      is_exact_date: this.isExactDate,
      expiry_day: this.expiryDay,
      expired_date: this.expiredDate,
      // limit_per_day: this.limitPerDay,
      // limit_per_user: this.limitPerUser,
      budget_source: this.budgetSource,
      start_date: this.startDate,
      end_date: this.endDate,
      actor_name: this.actorName,
      membership_level_ids: this.membershipLevel?.map(level => level.value),
      is_extra_rules: this.extraRulesActive,
      extra_rules: {
        total_amount: this.totalAmountTransaction,
        duration_total_amount: this.totalAmountTransactionTime?.value,
        total_transaction: this.totalNumberTransaction,
        duration_total_transaction: this.totalNumberTransactionTime?.value,
        type_validation: this.logicType,
      },
      eligible_rules: {
        status: this.statusType?.length ? this.statusType[0] : '',
        shipment_type: this.shipmentType,
        product_type: this.productType,
        minimum_transaction: this.minimumAmount,
      },
      amount_inviter: this.pointValueAmountForInviter,
      amount_invitee: this.pointValueAmountForInvitee,
      amount: this.pointValueAmountForGeneral,
      amount_disburse_rules: {
        invitee: this.pointValueAmountForInviteeDisburseTo?.value && this.pointValueAmountForInviteeApprovalType?.value ?
          {
            disburse_to: this.pointValueAmountForInviteeDisburseTo?.value,
            approval_type: this.pointValueAmountForInviteeApprovalType?.value
          }
          : null,
        inviter: this.pointValueAmountForInviterDisburseTo?.value && this.pointValueAmountForInviterApprovalType?.value ?
          {
            disburse_to: this.pointValueAmountForInviterDisburseTo?.value,
            approval_type: this.pointValueAmountForInviterApprovalType?.value
          }
          : null,
        general: this.pointValueAmountForGeneralDisburseTo?.value && this.pointValueAmountForGeneralApprovalType?.value ? {
            disburse_to: this.pointValueAmountForGeneralDisburseTo?.value,
            approval_type: this.pointValueAmountForGeneralApprovalType?.value
          }
          : null,
      },
      percentage_inviter: {
        percentage: this.pointValuePercentageForInviter,
        max_amount: this.pointValuePercentageForInviterMax,
      },
      percentage_invitee: {
        percentage: this.pointValuePercentageForInvitee,
        max_amount: this.pointValuePercentageForInviteeMax,
      },
      percentage_general: {
        percentage: this.pointValuePercentageForGeneral,
        max_amount: this.pointValuePercentageForGeneralMax,
      },
      disburse_type: this.pointDisburseType?.value,
      disburse_status: this.shipmentStatus?.value,
      disburse_specific_date: this.specificDate?.value,
      limit_program: {
        duration: this.pointDisburseLimitPerProgram?.value,
        total_number: this.totalNumberDisbursePerProgram,
        total_amount: this.totalAmountDisbursePerProgram,
      },
      limit_user: {
        duration: this.pointDisburseLimitPerUser?.value,
        total_number: this.totalNumberDisbursePerUser,
        total_amount: this.totalAmountDisbursePerUser,
      },
      programmatic_point_purpose_percentage: this.pointPurposeState?.reduce((acc, curr) => ({
          ...acc,
          [curr.label]: curr.value
        }), {}
      ),
      updated_by: LocalStorage.getLocalStorage(
        LocalStorage.LOGIN_IDENTITY,
        true
      ),
      device_id_limit_customer: this.deviceIdLimitCustomer,
    }

    if (this.pointValuePercentageForInviter || this.pointValuePercentageForInvitee || this.pointValuePercentageForGeneral) {
      data.amount_disburse_rules = {
        invitee: this.pointValuePercentageForInviteeDisburseTo?.value && this.pointValuePercentageForInviteeApprovalType?.value ?
          {
            disburse_to: this.pointValuePercentageForInviteeDisburseTo?.value,
            approval_type: this.pointValuePercentageForInviteeApprovalType?.value
          }
          : null,
        inviter: this.pointValuePercentageForInviterDisburseTo?.value && this.pointValuePercentageForInviterApprovalType?.value ?
          {
            disburse_to: this.pointValuePercentageForInviterDisburseTo?.value,
            approval_type: this.pointValuePercentageForInviterApprovalType?.value
          }
          : null,
        general: this.pointValuePercentageForGeneralDisburseTo?.value && this.pointValuePercentageForGeneralApprovalType?.value ? {
            disburse_to: this.pointValuePercentageForGeneralDisburseTo?.value,
            approval_type: this.pointValuePercentageForGeneralApprovalType?.value
          }
          : null,
      }
    }

    if (typeof this.budgetAmount === 'number' && this.budgetAmount > 0) {
      return JSON.stringify({budget_amount: this.budgetAmount, ...data})
    }

    return JSON.stringify(data)
  }
}

export class UpdateProgramaticPointRequest
  implements UpdateProgramaticPointRequestInterface {
  public programmaticPointName?: string
  public programmaticPointTarget?: string
  public selectedUser?: string
  public programmaticPointTrigger?: string
  public routes?: number[]
  public scheduledAt?: string
  public programmaticPointPurpose?: string
  // public amount?: number
  public expiryDay?: number
  // public limitPerDay?: number
  public startDate?: string
  public endDate?: string
  public actorName?: string
  public budgetAmount?: null | number | undefined
  public extraRulesActive?: boolean
  public totalAmountTransaction?: null | number | undefined
  public totalAmountTransactionTime?: LabelValue<string> | null
  public totalNumberTransaction?: null | number | undefined
  public totalNumberTransactionTime?: LabelValue<string> | null
  public logicType?: string
  public statusType?: string[]
  public shipmentType?: string[]
  public productType?: string[]
  public minimumAmount?: null | number | undefined
  public pointValue?: LabelValue<string | number> | null
  public pointValueAmountForInviter?: null | number | undefined
  public pointValueAmountForInviterDisburseTo?: LabelValue<string> | null
  public pointValueAmountForInviterApprovalType?: LabelValue<string> | null
  public pointValueAmountForInvitee?: null | number | undefined
  public pointValueAmountForInviteeDisburseTo?: LabelValue<string> | null
  public pointValueAmountForInviteeApprovalType?: LabelValue<string> | null
  public pointValueAmountForGeneral?: null | number | undefined
  public pointValueAmountForGeneralDisburseTo?: LabelValue<string> | null
  public pointValueAmountForGeneralApprovalType?: LabelValue<string> | null
  public pointValuePercentageForInviter?: null | number | undefined
  public pointValuePercentageForInviterMax?: null | number | undefined
  public pointValuePercentageForInviterDisburseTo?: LabelValue<string> | null
  public pointValuePercentageForInviterApprovalType?: LabelValue<string> | null
  public pointValuePercentageForInvitee?: null | number | undefined
  public pointValuePercentageForInviteeMax?: null | number | undefined
  public pointValuePercentageForInviteeDisburseTo?: LabelValue<string> | null
  public pointValuePercentageForInviteeApprovalType?: LabelValue<string> | null
  public pointValuePercentageForGeneral?: null | number | undefined
  public pointValuePercentageForGeneralMax?: null | number | undefined
  public pointValuePercentageForGeneralDisburseTo?: LabelValue<string> | null
  public pointValuePercentageForGeneralApprovalType?: LabelValue<string> | null
  public pointDisburseType?: LabelValue<string> | null
  public shipmentStatus?: LabelValue<string> | null
  public specificDate?: LabelValue<string | number> | null
  public pointDisburseLimitPerProgram?: LabelValue<string> | null
  public totalNumberDisbursePerProgram?: null | number | undefined
  public totalAmountDisbursePerProgram?: null | number | undefined
  public pointDisburseLimitPerUser?: LabelValue<string> | null
  public totalNumberDisbursePerUser?: null | number | undefined
  public totalAmountDisbursePerUser?: null | number | undefined
  public pointPurposeState?: { checked: boolean, value: number, label: string }[]
  public deviceIdLimitCustomer?: number | null

  constructor(
    programmaticPointName?: string,
    programmaticPointTarget?: string,
    selectedUser?: string,
    programmaticPointTrigger?: string,
    routes?: number[],
    scheduledAt?: string,
    programmaticPointPurpose?: string,
    // amount?: number,
    expiryDay?: number,
    // limitPerDay?: number,
    startDate?: string,
    endDate?: string,
    actorName?: string,
    budgetAmount?: null | number | undefined,
    extraRulesActive?: boolean,
    totalAmountTransaction?: null | number | undefined,
    totalAmountTransactionTime?: LabelValue<string> | null,
    totalNumberTransaction?: null | number | undefined,
    totalNumberTransactionTime?: LabelValue<string> | null,
    logicType?: string,
    statusType?: string[],
    shipmentType?: string[],
    productType?: string[],
    minimumAmount?: null | number | undefined,
    pointValue?: LabelValue<string | number> | null,
    pointValueAmountForInviter?: null | number | undefined,
    pointValueAmountForInviterDisburseTo?: LabelValue<string> | null,
    pointValueAmountForInviterApprovalType?: LabelValue<string> | null,
    pointValueAmountForInvitee?: null | number | undefined,
    pointValueAmountForInviteeDisburseTo?: LabelValue<string> | null,
    pointValueAmountForInviteeApprovalType?: LabelValue<string> | null,
    pointValueAmountForGeneral?: null | number | undefined,
    pointValueAmountForGeneralDisburseTo?: LabelValue<string> | null,
    pointValueAmountForGeneralApprovalType?: LabelValue<string> | null,
    pointValuePercentageForInviter?: null | number | undefined,
    pointValuePercentageForInviterMax?: null | number | undefined,
    pointValuePercentageForInviterDisburseTo?: LabelValue<string> | null,
    pointValuePercentageForInviterApprovalType?: LabelValue<string> | null,
    pointValuePercentageForInvitee?: null | number | undefined,
    pointValuePercentageForInviteeMax?: null | number | undefined,
    pointValuePercentageForInviteeDisburseTo?: LabelValue<string> | null,
    pointValuePercentageForInviteeApprovalType?: LabelValue<string> | null,
    pointValuePercentageForGeneral?: null | number | undefined,
    pointValuePercentageForGeneralMax?: null | number | undefined,
    pointValuePercentageForGeneralDisburseTo?: LabelValue<string> | null,
    pointValuePercentageForGeneralApprovalType?: LabelValue<string> | null,
    pointDisburseType?: LabelValue<string> | null,
    shipmentStatus?: LabelValue<string> | null,
    specificDate?: LabelValue<string | number> | null,
    pointDisburseLimitPerProgram?: LabelValue<string> | null,
    totalNumberDisbursePerProgram?: null | number | undefined,
    totalAmountDisbursePerProgram?: null | number | undefined,
    pointDisburseLimitPerUser?: LabelValue<string> | null,
    totalNumberDisbursePerUser?: null | number | undefined,
    totalAmountDisbursePerUser?: null | number | undefined,
    pointPurposeState?: { checked: boolean, value: number, label: string }[],
    deviceIdLimitCustomer?: number | null,
  ) {
    this.programmaticPointName = programmaticPointName
    this.programmaticPointTarget = programmaticPointTarget
    this.selectedUser = selectedUser
    this.programmaticPointTrigger = programmaticPointTrigger
    this.routes = routes
    this.scheduledAt = scheduledAt
    this.programmaticPointPurpose = programmaticPointPurpose
    // this.amount = amount
    this.expiryDay = expiryDay
    // this.limitPerDay = limitPerDay
    this.startDate = startDate
    this.endDate = endDate
    this.actorName = actorName
    this.budgetAmount = budgetAmount
    this.extraRulesActive = extraRulesActive
    this.totalAmountTransaction = totalAmountTransaction
    this.totalAmountTransactionTime = totalAmountTransactionTime
    this.totalNumberTransaction = totalNumberTransaction
    this.totalNumberTransactionTime = totalNumberTransactionTime
    this.logicType = logicType
    this.statusType = statusType
    this.shipmentType = shipmentType
    this.productType = productType
    this.minimumAmount = minimumAmount
    this.pointValue = pointValue
    this.pointValueAmountForInviter = pointValueAmountForInviter
    this.pointValueAmountForInviterDisburseTo = pointValueAmountForInviterDisburseTo
    this.pointValueAmountForInviterApprovalType = pointValueAmountForInviterApprovalType
    this.pointValueAmountForInvitee = pointValueAmountForInvitee
    this.pointValueAmountForInviteeDisburseTo = pointValueAmountForInviteeDisburseTo
    this.pointValueAmountForInviteeApprovalType = pointValueAmountForInviteeApprovalType
    this.pointValueAmountForGeneral = pointValueAmountForGeneral
    this.pointValueAmountForGeneralDisburseTo = pointValueAmountForGeneralDisburseTo
    this.pointValueAmountForGeneralApprovalType = pointValueAmountForGeneralApprovalType
    this.pointValuePercentageForInviter = pointValuePercentageForInviter
    this.pointValuePercentageForInviterMax = pointValuePercentageForInviterMax
    this.pointValuePercentageForInviterDisburseTo = pointValuePercentageForInviterDisburseTo
    this.pointValuePercentageForInviterApprovalType = pointValuePercentageForInviterApprovalType
    this.pointValuePercentageForInvitee = pointValuePercentageForInvitee
    this.pointValuePercentageForInviteeMax = pointValuePercentageForInviteeMax
    this.pointValuePercentageForInviteeDisburseTo = pointValuePercentageForInviteeDisburseTo
    this.pointValuePercentageForInviteeApprovalType = pointValuePercentageForInviteeApprovalType
    this.pointValuePercentageForGeneral = pointValuePercentageForGeneral
    this.pointValuePercentageForGeneralMax = pointValuePercentageForGeneralMax
    this.pointValuePercentageForGeneralDisburseTo = pointValuePercentageForGeneralDisburseTo
    this.pointValuePercentageForGeneralApprovalType = pointValuePercentageForGeneralApprovalType
    this.pointDisburseType = pointDisburseType
    this.shipmentStatus = shipmentStatus
    this.specificDate = specificDate
    this.pointDisburseLimitPerProgram = pointDisburseLimitPerProgram
    this.totalNumberDisbursePerProgram = totalNumberDisbursePerProgram
    this.totalAmountDisbursePerProgram = totalAmountDisbursePerProgram
    this.pointDisburseLimitPerUser = pointDisburseLimitPerUser
    this.totalNumberDisbursePerUser = totalNumberDisbursePerUser
    this.totalAmountDisbursePerUser = totalAmountDisbursePerUser
    this.pointPurposeState = pointPurposeState
    this.deviceIdLimitCustomer = deviceIdLimitCustomer
  }

  public toPayload() {
    const data = {
      programmatic_point_name: this.programmaticPointName,
      programmatic_point_target: this.programmaticPointTarget,
      selected_user: this.selectedUser,
      programmatic_point_trigger: this.programmaticPointTrigger,
      route_id: this.routes,
      scheduled_at: this.scheduledAt,
      programmatic_point_purpose: this.programmaticPointPurpose,
      // amount: this.amount,
      expiry_day: this.expiryDay,
      // limit_per_day: this.limitPerDay,
      start_date: this.startDate,
      end_date: this.endDate,
      actor_name: this.actorName,
      is_extra_rules: this.extraRulesActive,
      extra_rules: {
        total_amount: this.totalAmountTransaction,
        duration_total_amount: this.totalAmountTransactionTime?.value,
        total_transaction: this.totalNumberTransaction,
        duration_total_transaction: this.totalNumberTransactionTime?.value,
        type_validation: this.logicType,
      },
      eligible_rules: {
        status: this.statusType?.length ? this.statusType[0] : '',
        shipment_type: this.shipmentType,
        product_type: this.productType,
        minimum_transaction: this.minimumAmount,
      },
      amount_inviter: this.pointValueAmountForInviter,
      amount_invitee: this.pointValueAmountForInvitee,
      amount: this.pointValueAmountForGeneral,
      amount_disburse_rules: {
        invitee: this.pointValueAmountForInviteeDisburseTo?.value && this.pointValueAmountForInviteeApprovalType?.value ?
          {
            disburse_to: this.pointValueAmountForInviteeDisburseTo?.value,
            approval_type: this.pointValueAmountForInviteeApprovalType?.value
          }
          : null,
        inviter: this.pointValueAmountForInviterDisburseTo?.value && this.pointValueAmountForInviterApprovalType?.value ?
          {
            disburse_to: this.pointValueAmountForInviterDisburseTo?.value,
            approval_type: this.pointValueAmountForInviterApprovalType?.value
          }
          : null,
        general: this.pointValueAmountForGeneralDisburseTo?.value && this.pointValueAmountForGeneralApprovalType?.value ? {
            disburse_to: this.pointValueAmountForGeneralDisburseTo?.value,
            approval_type: this.pointValueAmountForGeneralApprovalType?.value
          }
          : null,
      },
      percentage_inviter: {
        percentage: this.pointValuePercentageForInviter,
        max_amount: this.pointValuePercentageForInviterMax,
      },
      percentage_invitee: {
        percentage: this.pointValuePercentageForInvitee,
        max_amount: this.pointValuePercentageForInviteeMax,
      },
      percentage_general: {
        percentage: this.pointValuePercentageForGeneral,
        max_amount: this.pointValuePercentageForGeneralMax,
      },
      disburse_type: this.pointDisburseType?.value,
      disburse_status: this.shipmentStatus?.value,
      disburse_specific_date: this.specificDate?.value,
      limit_program: {
        duration: this.pointDisburseLimitPerProgram?.value,
        total_number: this.totalNumberDisbursePerProgram,
        total_amount: this.totalAmountDisbursePerProgram,
      },
      limit_user: {
        duration: this.pointDisburseLimitPerUser?.value,
        total_number: this.totalNumberDisbursePerUser,
        total_amount: this.totalAmountDisbursePerUser,
      },
      programmatic_point_purpose_percentage: this.pointPurposeState?.reduce((acc, curr) => ({
          ...acc,
          [curr.label]: curr.value
        }), {}
      ),
      updated_by: LocalStorage.getLocalStorage(
        LocalStorage.LOGIN_IDENTITY,
        true
      ),
      device_id_limit_customer: this.deviceIdLimitCustomer,
    }

    if (this.pointValuePercentageForInviter || this.pointValuePercentageForInvitee || this.pointValuePercentageForGeneral) {
      data.amount_disburse_rules = {
        invitee: this.pointValuePercentageForInviteeDisburseTo?.value && this.pointValuePercentageForInviteeApprovalType?.value ?
          {
            disburse_to: this.pointValuePercentageForInviteeDisburseTo?.value,
            approval_type: this.pointValuePercentageForInviteeApprovalType?.value
          }
          : null,
        inviter: this.pointValuePercentageForInviterDisburseTo?.value && this.pointValuePercentageForInviterApprovalType?.value ?
          {
            disburse_to: this.pointValuePercentageForInviterDisburseTo?.value,
            approval_type: this.pointValuePercentageForInviterApprovalType?.value
          }
          : null,
        general: this.pointValuePercentageForGeneralDisburseTo?.value && this.pointValuePercentageForGeneralApprovalType?.value ? {
            disburse_to: this.pointValuePercentageForGeneralDisburseTo?.value,
            approval_type: this.pointValuePercentageForGeneralApprovalType?.value
          }
          : null,
      }
    }

    if (typeof this.budgetAmount === 'number' && this.budgetAmount > 0) {
      return JSON.stringify({budget_amount: this.budgetAmount, ...data})
    }

    return JSON.stringify(data)
  }
}

export class DeleteProgramaticPointRequest
  implements DeleteProgramaticPointRequestInterface {
  public actorName?: string

  constructor(actorName?: string) {
    this.actorName = actorName
  }

  public toPayload() {
    const data = {
      actor_name: this.actorName,
    }

    return JSON.stringify({
      ...data,
    })
  }
}

export class AllProgrammaticPointApproval
  implements AllProgrammaticPointApprovalInterface {
  public status: string
  programmaticPointHistoryIds: number[]
  programmaticEligibleUserId: number

  constructor(
    status: string,
    programmaticPointHistoryIds: number[],
    programmaticEligibleUserId: number,
  ) {
    this.status = status
    this.programmaticPointHistoryIds = programmaticPointHistoryIds
    this.programmaticEligibleUserId = programmaticEligibleUserId
  }

  public toPayload(): string {
    const data = {
      status: this.status,
      programmatic_point_history_ids: this.programmaticPointHistoryIds,
      programmatic_eligible_user_id: this.programmaticEligibleUserId,
    }

    return JSON.stringify(data)
  }
}
