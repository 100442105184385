import { AxiosResponse } from "axios";
import { HistoryStatusCustomer, ManageCustomer, ManageCustomers } from "@/domain/entities/ManageCustomer";
import { BaseResponseMapper } from "./BaseMapper";
import { Pagination } from "@/domain/entities/Pagination";
import { HistoryStatusCustomerResponse } from "@/data/responses/contracts/ManageCustomerResponse";

export class ManageCustomerMapper extends BaseResponseMapper {
  public convertManageCustomersFromApi(result: AxiosResponse): ManageCustomers {
    const { data: { pagination, customer_data } } = result

    return new ManageCustomers(
      new Pagination(
        pagination.page,
        pagination.per_page,
        pagination.total_item,
        pagination.query,
        pagination.sort_by,
        pagination.customer_status
      ),
      customer_data.map((customer: Record<string, any>) => {
        return new ManageCustomer(
          customer.id,
          customer.fullname,
          customer.phone_number,
          customer.email,
          customer.membership_level,
          customer.status
        )
      })
    )
  }

  public convertDetailCustomersFromApi(result: AxiosResponse): ManageCustomer {
    const { data } = result

    return new ManageCustomer(
      data.id,
      data.fullname,
      data.phone_number,
      data.email,
      data.membership_level,
      data.status,
      data.gender,
      data.birthdate,
      data.address,
      data.city,
      data.total_points,
      data.os,
      data.created_at,
      data.deleted_at
    )
  }

  public convertCustomerHistoryStatusFromApi(result: AxiosResponse): HistoryStatusCustomer {
    const { data } = result.data

    return data.map((history: HistoryStatusCustomerResponse) => {
      return new HistoryStatusCustomer(
        history.admin_id,
        history.admin_name,
        history.ban_history_id,
        history.is_banned,
        history.reason,
        history.created_at,
        history.customer_status
      )
    })
  }
}
