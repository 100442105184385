import { injectable } from 'tsyringe'

import { SaldoPaymentRepositoryInterface } from '@/data/persistences/repositories/contracts/SaldoPaymentRepositoryInterface'
import { SaldoPayments } from '@/domain/entities/SaldoPayment'

@injectable()
export class SaldoPaymentPresenter {
  private repository: SaldoPaymentRepositoryInterface

  constructor(repository: SaldoPaymentRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, any>): Promise<SaldoPayments> {
    return this.repository.getAll(params)
  }

  public export(params: Map<string, any>): Promise<string> {
    return this.repository.export(params)
  }
}
