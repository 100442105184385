import {BaseResponse} from '@/domain/entities/BaseResponse'
import {
  Programs,
  Program,
  ProgramHistories,
  Trigger, ProgrammaticParcelPoinAndSaldoInviteeInviterData,
} from '@/domain/entities/Program'
import {
  CreateProgramaticPointRequest,
  UpdateProgramaticPointRequest,
} from '@/data/payload/api/ProgramaticPointRequest'
import {DeleteProgramaticPointRequest} from '@/data/payload/api/ProgramaticPointRequest'
import {
  AllProgrammaticPointApprovalInterface,
  CreateProgramaticPointRequestInterface,
  DeleteProgramaticPointRequestInterface,
  UpdateProgramaticPointRequestInterface,
} from '@/data/payload/contracts/ProgramaticPointRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import {ProgramaticPointMapper} from '@/data/persistences/mappers/ProgramaticPointMapper'
import {
  ProgramaticPointRepositoryInterface
} from '@/data/persistences/repositories/contracts/ProgramaticPointRepositoryInterface'
import {Endpoints} from '@/app/infrastructures/misc'

export class ProgramaticPointApiRepository
  implements ProgramaticPointRepositoryInterface {
  private service: ApiService
  private mapper: ProgramaticPointMapper

  constructor(service: ApiService, mapper: ProgramaticPointMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getTriggers(): Promise<Trigger[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/trigger/active`
    )

    return this.mapper.convertTriggersFromApi(resp)
  }

  public async create(
    payload: CreateProgramaticPointRequestInterface
  ): Promise<BaseResponse> {
    const resp = await this.service.invoke(
      'post',
      Endpoints.Route.PROGRAMATIC_POINT_V2,
      undefined,
      payload as CreateProgramaticPointRequest
    )
    return this.mapper.convertBaseResponseFromApi(resp)
  }

  public async getHistory(
    programId: string,
    params: Map<string, unknown>
  ): Promise<ProgramHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/${programId}/history`,
      params
    )

    return this.mapper.convertProgramHistoriesFromApi(resp)
  }

  public async getHistoryParcelPoint(
    programId: string,
    params: Map<string, unknown>
  ): Promise<ProgramHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT_V2}/${programId}/history`,
      params
    )

    return this.mapper.convertProgramHistoriesFromApi(resp)
  }

  public async exportHistory(
    programId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/${programId}/history/export`,
      params
    )

    return resp.data.url
  }

  public async exportHistoryV2(
    programId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT_V2}/${programId}/history/export`,
      params
    )

    return resp.data.url
  }

  public async getAll(params: Map<string, any>): Promise<Programs> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PROGRAMATIC_POINT,
      params
    )
    return this.mapper.convertProgramsFromApi(resp)
  }

  public async get(programId: string): Promise<Program> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/${programId}`
    )

    return this.mapper.convertProgramFromApi(resp)
  }

  public async update(
    programId: string,
    payload: UpdateProgramaticPointRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PROGRAMATIC_POINT_V2}/${programId}`,
      undefined,
      payload as UpdateProgramaticPointRequest
    )

    return true
  }

  public async delete(
    programId: string,
    payload: DeleteProgramaticPointRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.PROGRAMATIC_POINT}/${programId}`,
      undefined,
      payload as DeleteProgramaticPointRequest
    )

    return true
  }

  public async getPaymentMethodList(): Promise<string[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/payment-method-used`
    )

    return resp.data.data as string[]
  }

  public async getMembershipLevelList(): Promise<{ label: string, value: number }[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.LOYALTY_MEMBERSHIP}/level/active`
    )

    return this.mapper.convertMembershipLevelFromApi(resp)
  }

  public async getAllProgrammaticPointAndSaldo(params: Map<string, unknown>): Promise<any> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/data`,
      params,
    )

    return this.mapper.convertProgrammaticPointAndSaldoFromApi(resp)
  }

  public async getAllProgrammaticPointAndSaldoInviteeInviter(params: Map<string, unknown>): Promise<ProgrammaticParcelPoinAndSaldoInviteeInviterData> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/data/detail`,
      params,
    )

    return this.mapper.convertProgrammaticPointAndSaldoInviteeInviterFromApi(resp)
  }

  public async allProgrammaticPointApproval(payload: AllProgrammaticPointApprovalInterface): Promise<boolean> {
    const resp = await this.service.invoke(
      'post',
      `${Endpoints.Route.PROGRAMATIC_POINT}/data/approval`,
      undefined,
      payload
    )
    return true
  }

  public async exportNeedApprovalAllProgrammatic(
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/data/child/export`,
      params
    )

    return resp.data.url
  }

  public async exportProgrammaticData(
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/data/export`,
      params
    )

    return resp.data.url
  }

  public async exportProgrammaticDataDetail(
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PROGRAMATIC_POINT}/data/detail/export`,
      params
    )

    return resp.data.url
  }
}
