import { Pagination } from '@/domain/entities/Pagination'

export class Product {
  public id?: number
  public merchantId?: number
  public productName?: string
  public description?: string
  public salesPrice?: number
  public basePrice?: number
  public lowestPrice?: number
  public highestPrice?: number
  public information?: string
  public weight?: number
  public length?: number
  public width?: number
  public height?: number
  public imageUrl?: string
  public imagePDP?: string[]
  public isActive?: boolean
  public stock?: number | null
  public sku?: string
  public createdAt?: string
  public hasChild?: boolean
  variantList?: VariantList[]
  variantProductList?: VariantProduct[]
  productTypeId?: number
  productTypeName?: string
  secondProductTypeId?: number
  secondProductTypeName?: string
  thirdProductTypeId?: number
  thirdProductTypeName?: string


  constructor(
    id?: number,
    merchantId?: number,
    productName?: string,
    description?: string,
    salesPrice?: number,
    basePrice?: number,
    lowestPrice?: number,
    highestPrice?: number,
    information?: string,
    weight?: number,
    length?: number,
    width?: number,
    height?: number,
    imageUrl?: string,
    imagePDP?: string[],
    isActive?: boolean,
    stock?: number | null,
    sku?: string,
    createdAt?: string,
    hasChild?: boolean,
    variantList?: VariantList[],
    variantProductList?: VariantProduct[],
    productTypeId?: number,
    productTypeName?: string,
    secondProductTypeId?: number,
    secondProductTypeName?: string,
    thirdProductTypeId?: number,
    thirdProductTypeName?: string
  ) {
    this.id = id
    this.merchantId = merchantId
    this.productName = productName
    this.description = description
    this.salesPrice = salesPrice
    this.basePrice = basePrice
    this.lowestPrice = lowestPrice
    this.highestPrice = highestPrice
    this.information = information
    this.height = height
    this.length = length
    this.width = width
    this.weight = weight
    this.imageUrl = imageUrl
    this.imagePDP = imagePDP
    this.isActive = isActive || false
    this.stock = stock
    this.sku = sku
    this.createdAt = createdAt
    this.hasChild = hasChild
    this.variantList = variantList
    this.variantProductList = variantProductList
    this.productTypeId = productTypeId
    this.productTypeName = productTypeName
    this.secondProductTypeId = secondProductTypeId
    this.secondProductTypeName = secondProductTypeName
    this.thirdProductTypeId = thirdProductTypeId
    this.thirdProductTypeName = thirdProductTypeName
  }
}

export class Products {
  public pagination?: Pagination
  public data?: Product[]

  constructor(pagination?: Pagination, data?: Product[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class VariantProducts {
  public id?: number
  public listVariant?: VariantProduct[]
  public totalActive?: number
  public isLoading?: boolean

  constructor(
    id?: number,
    listVariant?: VariantProduct[],
    totalActive?: number,
    isLoading?: boolean
  ) {
    this.id = id
    this.listVariant = listVariant
    this.totalActive = totalActive
    this.isLoading = isLoading
  }
}

export class VariantProduct {
  public variantId?: number
  public variantValue1?: string
  public variantValue2?: string
  public sku?: string
  public price?: number
  public stock?: number
  public isActive?: boolean
  public weight?: number
  public variants?: string[]

  constructor(
    variantId?: number,
    variantValue1?: string,
    variantValue2?: string,
    sku?: string,
    price?: number,
    stock?: number,
    isActive?: boolean,
    weight?: number,
    variants?: string[]
  ) {
    this.variantId = variantId
    this.variantValue1 = variantValue1
    this.variantValue2 = variantValue2
    this.sku = sku
    this.price = price
    this.stock = stock
    this.weight = weight
    this.isActive = isActive
    this.variants = variants
  }
}

export class VariantList {
  key: string
  value: string[]

  constructor(key: string, value: string[]) {
    this.key = key
    this.value = value
  }
}

export class ProductDropdown {
  public productId?: number
  public productName?: string
  public merchantId?: number
  public merchantName?: string

  constructor(
    productId?: number,
    productName?: string,
    merchantId?: number,
    merchantName?: string
  ) {
    this.productId = productId
    this.productName = productName
    this.merchantId = merchantId
    this.merchantName = merchantName
  }
}

export class HistoryLogBulkProduct {
  public id?: number
  public username?: string
  public filename?: string
  public errorTitle?: string
  public meta?: string[]
  public status?: string
  public createdAt?: string

  constructor(
    id?: number,
    username?: string,
    filename?: string,
    errorTitle?: string,
    meta?: string[],
    status?: string,
    createdAt?: string
  ) {
    this.id = id
    this.username = username
    this.filename = filename
    this.errorTitle = errorTitle
    this.meta = meta
    this.status = status
    this.createdAt = createdAt
  }
}

export class HistoryLogBulkProducts {
  public pagination?: Pagination
  public data?: HistoryLogBulkProduct[]

  constructor(pagination?: Pagination, data?: HistoryLogBulkProduct[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ProductCategory {
  id: number
  name: string

  constructor(id: number, name: string) {
    this.id = id
    this.name = name
  }
}
