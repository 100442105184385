export class Courier {
  public id?: string
  public phone?: string
  public status?: string

  constructor(id?: string, phone?: string, status?: string) {
    this.id = id
    this.phone = phone
    this.status = status
  }
}
