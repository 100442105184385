import { RequestApprovalAdjustmentInterface } from "@/data/payload/contracts/ApprovalAdjustSaldoRequest";
import { ApprovalAdjustSaldoMapper } from "@/data/persistences/mappers/ApprovalAdjustSaldoMapper";
import { ApprovalAdjustSaldoInterface } from "@/data/persistences/repositories/contracts/ApprovalAdjustSaldoRepositoryInterface";
import { ApprovalAdjustSaldoDetail, ApprovalAdjustSaldoList } from "@/domain/entities/ApprovalAdjustSaldo";
import { Endpoints } from "../../misc";
import ApiService from "../../services/ApiService";

export class ApprovalAdjustSaldoRepository implements ApprovalAdjustSaldoInterface {
  private service: ApiService
  private mapper: ApprovalAdjustSaldoMapper

  constructor(service: ApiService, mapper: ApprovalAdjustSaldoMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, string | number | Date>): Promise<ApprovalAdjustSaldoList> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/approval/list`,
      params
    )
    return this.mapper.convertApprovalAdjustSaldoListFromApi(resp)
  }

  public async get(params: Map<string, string | number>): Promise<ApprovalAdjustSaldoDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/approval/detail`,
      params
    )
    return this.mapper.convertApprovalAdjustSaldoDetailFromAPi(resp)
  }

  public async requestAdjustmentApproval(payload: RequestApprovalAdjustmentInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/approval/submit`,
      undefined,
      payload
    )
    return true
  }
}
