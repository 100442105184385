import { Pagination } from "./Pagination"

export class ManageCaptains {
  public pagination?: Pagination
  public data?: ManageCaptain[]

  constructor(
    pagination?: Pagination,
    data?: ManageCaptain[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCaptain {
  public courierCaptainId?: number
  public courierId?: number
  public skStartDate?: Date
  public skEndDate?: Date
  public status?: string
  public fullname?: string
  public phoneNumber?: string
  public letterCodes?: Array<string>

  constructor(
    courier_captain_id?: number,
    courier_id?: number,
    sk_start_date?: Date,
    sk_end_date?: Date,
    status?: string,
    fullname?: string,
    phone_number?: string,
    letter_codes?: Array<string>,
  ) {
    this.courierCaptainId = courier_captain_id
    this.courierId = courier_id
    this.skStartDate = sk_start_date
    this.skEndDate = sk_end_date
    this.status = status
    this.fullname = fullname
    this.phoneNumber = phone_number
    this.letterCodes = letter_codes
  }
}

export class ManageCaptainDetail {
  public courierCaptainId?: number
  public courierId?: number
  public skStartDate?: Date
  public skEndDate?: Date
  public status?: string
  public fullname?: string
  public phoneNumber?: string
  public letterCodes?: Array<string>
  public createdAt?: string
  public createdBy?: string
  public updatedAt?: string
  public updatedBy?: string

  constructor(
    courier_captain_id?: number,
    courier_id?: number,
    sk_start_date?: Date,
    sk_end_date?: Date,
    status?: string,
    fullname?: string,
    phone_number?: string,
    letter_codes?: Array<string>,
    created_at?: string,
    created_by?: string,
    updated_at?: string,
    updated_by?: string
  ) {
    this.courierCaptainId = courier_captain_id
    this.courierId = courier_id
    this.skStartDate = sk_start_date
    this.skEndDate = sk_end_date
    this.status = status
    this.fullname = fullname
    this.phoneNumber = phone_number
    this.letterCodes = letter_codes
    this.createdAt = created_at
    this.createdBy = created_by
    this.updatedAt = updated_at
    this.updatedBy = updated_by
  }
}
