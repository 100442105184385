import { UpdateBonusPayrollRequestInterface } from "@/data/payload/contracts/PayrollBonusRequest";
import { PayrollBonusMapper } from "@/data/persistences/mappers/PayrollBonusMapper";
import { PayrollBonusRepositoryInterface } from "@/data/persistences/repositories/contracts/PayrollBonusRepositoryInterface";
import { Bonus, BonusPayrolls } from "@/domain/entities/PayrollBonus";
import { Endpoints } from "../../misc";
import ApiService from "../../services/ApiService";

export class PayrollBonusApiRepository implements PayrollBonusRepositoryInterface {
  private service: ApiService
  private mapper: PayrollBonusMapper

  constructor(service: ApiService, mapper: PayrollBonusMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getBonus(params: Map<string, number>): Promise<BonusPayrolls> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/bonus/list`,
      params
    )
    return this.mapper.convertBonusPayrollFromApi(resp)
  }

  public async get(id: string): Promise<Bonus> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/bonus/detail/${id}`
    )

    return this.mapper.convertDetailBonusFromApi(resp)
  }

  public async export(params: Map<string, number>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/bonus/export`,
      params
    )
    return this.mapper.convertPayrollBonusExportFromApi(resp)
  }

  public async update(payload: UpdateBonusPayrollRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLL}/bonus/update-status`,
      undefined,
      payload as UpdateBonusPayrollRequestInterface
    )

    return true
  }

  public async recalculateBonus(params: Map<string, string | number>): Promise<boolean> {
    await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/bonus/calculate`,
      params
    )

    return true
  }
}
