import BACKEND_SLUG from '@/app/ui/router/constants'
import { CreateElement } from 'vue'
import { RouteConfig } from 'vue-router'
import { RouteInterface } from '../interface'

export const adjustSaldoApprovalRouteName = 'AdjustSaldoApprovalPage'
export const adjustSaldoApprovalListRouteName = 'AdjustSaldoApprovalListPage'
export const adjustSaldoApprovalDetailRouteName = 'adjustSaldoApprovalDetailPage'

export const adjustSaldoApprovalListRoute: RouteInterface = {
  path: '',
  name: adjustSaldoApprovalListRouteName,
  meta: {
    slugFromBackend: BACKEND_SLUG.ADJUST_SALDO_APPROVAL,
    accessType: 'read',
    isParent: true,
    isHidden: true,
  },
}

export const adjustSaldoApprovalDetailRoute = {
  path: 'detail/:id',
  name: adjustSaldoApprovalDetailRouteName,
  meta: {
    slugFromBackend: BACKEND_SLUG.ADJUST_SALDO_APPROVAL,
    accessType: 'read',
    isParent: true,
    isHidden: true,
  },
}

export const adjustSaldoApproval: RouteConfig = {
  path: 'adjust-saldo-approval',
  name: adjustSaldoApprovalRouteName,
  redirect: { name: adjustSaldoApprovalListRouteName },
  component: {
    render: (c: CreateElement) => c('router-view'),
  },
  meta: {
    slugFromBackend: BACKEND_SLUG.ADJUST_SALDO_APPROVAL,
    title: 'Adjust Saldo Approval',
    hasSubChildren: true,
    accessType: 'read',
  },
  children: [
    {
      ...adjustSaldoApprovalListRoute,
      component: () =>
        import(
            /* webpackChunkName: "AdjustSaldoApprovalList" */ '@/app/ui/views/Payroll/AdjustSaldoApproval/index.vue'
        ),
    },
    {
      ...adjustSaldoApprovalDetailRoute,
      component: () =>
        import(
            /* webpackChunkName: "AdjustSaldoApprovalDetail" */ '@/app/ui/views/Payroll/AdjustSaldoApproval/Detail/index.vue'
        ),
    }
  ]
}
