import { Pagination } from '@/domain/entities/Pagination'

export class Claims {
  public pagination?: Pagination
  public data?: Claim[]

  constructor(pagination?: Pagination, data?: Claim[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class Claim {
  public id?: number
  public externalClaimId?: string
  public packageId?: string
  public oldPackageId?: string
  public shipmentId?: string
  public customerName?: string
  public customerPhone?: string
  public customerWaNumber?: string
  public customerEmail?: string
  public ktpImage?: string
  public amount?: number
  public note?: string
  public type?: string
  public status?: string
  public actorName?: string
  public actorRole?: string
  public createdAt?: string
  public expiredAt?: string
  public historyCreatedAt?: string
  public claimHistories?: ClaimHistory[]
  public shipmentDetail?: ShipmentDetail
  public invoiceDetails?: InvoiceDetail[]
  public receiverName?: string
  public packageImages?: string[]
  public vehicleRegistrationImages?: string[]
  public from?: string
  public claimerStatus?: string
  public invoiceImage?: string
  public bankAccountImage?: string

  constructor(
    id?: number,
    externalClaimId?: string,
    packageId?: string,
    oldPackageId?: string,
    shipmentId?: string,
    customerName?: string,
    customerPhone?: string,
    customerWaNumber?: string,
    customerEmail?: string,
    ktpImage?: string,
    amount?: number,
    note?: string,
    type?: string,
    status?: string,
    actorName?: string,
    actorRole?: string,
    createdAt?: string,
    expiredAt?: string,
    historyCreatedAt?: string,
    from?: string,
    claimerStatus?: string,
    claimHistories?: ClaimHistory[],
    shipmentDetail?: ShipmentDetail,
    invoiceDetails?: InvoiceDetail[],
    packageImages?: string[],
    vehicleRegistrationImages?: string[],
    invoiceImage?: string,
    bankAccountImage?: string,
  ) {
    this.id = id
    this.externalClaimId = externalClaimId
    this.packageId = packageId
    this.oldPackageId = oldPackageId
    this.shipmentId = shipmentId
    this.customerName = customerName
    this.customerPhone = customerPhone
    this.customerWaNumber = customerWaNumber
    this.customerEmail = customerEmail
    this.ktpImage = ktpImage
    this.amount = amount
    this.note = note
    this.type = type
    this.status = status
    this.actorName = actorName
    this.actorRole = actorRole
    this.createdAt = createdAt
    this.expiredAt = expiredAt
    this.historyCreatedAt = historyCreatedAt
    this.claimHistories = claimHistories
    this.shipmentDetail = shipmentDetail
    this.invoiceDetails = invoiceDetails
    this.packageImages = packageImages
    this.vehicleRegistrationImages = vehicleRegistrationImages
    this.from = from
    this.claimerStatus = claimerStatus
    this.invoiceImage = invoiceImage
    this.bankAccountImage = bankAccountImage
  }
}

export class ClaimHistory {
  public statusId?: string
  public actorName?: string
  public actorRole?: string
  public createdAt?: string

  constructor(
    statusId?: string,
    actorName?: string,
    actorRole?: string,
    createdAt?: string
  ) {
    this.statusId = statusId
    this.actorName = actorName
    this.actorRole = actorRole
    this.createdAt = createdAt
  }
}

export class ShipmentDetail {
  public shipmentInfo?: ShipmentInfo
  public shipmentHistories?: TimelineHistory[]
  public packageInfo?: PackageInfo
  public packageHistories?: TimelineHistory[]

  constructor(
    shipmentInfo?: ShipmentInfo,
    shipmentHistories?: TimelineHistory[],
    packageInfo?: PackageInfo,
    packageHistories?: TimelineHistory[]
  ) {
    this.shipmentInfo = shipmentInfo
    this.shipmentHistories = shipmentHistories
    this.packageInfo = packageInfo
    this.packageHistories = packageHistories
  }
}

export class ShipmentInfo {
  public id?: string
  public createdAt?: string
  public insuranceFee?: string

  constructor(id?: string, createdAt?: string, insuranceFee?: string) {
    this.id = id
    this.createdAt = createdAt
    this.insuranceFee = insuranceFee
  }
}

export class TimelineHistory {
  public statusId?: string
  public status?: string
  public statusDescription?: string
  public createdAt?: string

  constructor(
    statusId?: string,
    status?: string,
    statusDescription?: string,
    createdAt?: string
  ) {
    this.statusId = statusId
    this.status = status
    this.statusDescription = statusDescription
    this.createdAt = createdAt
  }
}

export class PackageInfo {
  public id?: string
  public productType?: string
  public senderName?: string
  public senderOrigin?: string
  public senderAddress?: string
  public receiverName?: string
  public receiverDestination?: string
  public receiverAddress?: string
  public quantity?: number
  public chargeableWeight?: number
  public totalFee?: number
  public totalDeliveryFee?: number
  public totalUnderpay?: number
  public totalOverpay?: number

  constructor(
    id?: string,
    productType?: string,
    senderName?: string,
    senderOrigin?: string,
    senderAddress?: string,
    receiverName?: string,
    receiverDestination?: string,
    receiverAddress?: string,
    quantity?: number,
    chargeableWeight?: number,
    totalFee?: number,
    totalDeliveryFee?: number,
    totalUnderpay?: number,
    totalOverpay?: number
  ) {
    this.id = id
    this.productType = productType
    this.senderName = senderName
    this.senderOrigin = senderOrigin
    this.senderAddress = senderAddress
    this.receiverName = receiverName
    this.receiverDestination = receiverDestination
    this.receiverAddress = receiverAddress
    this.quantity = quantity
    this.chargeableWeight = chargeableWeight
    this.totalFee = totalFee
    this.totalDeliveryFee = totalDeliveryFee
    this.totalUnderpay = totalUnderpay
    this.totalOverpay = totalOverpay
  }
}

export class InvoiceDetail {
  public id?: string
  public paymentMethod?: string
  public paymentStatus?: string
  public finishedAt?: string

  constructor(
    id?: string,
    paymentMethod?: string,
    paymentStatus?: string,
    finishedAt?: string
  ) {
    this.id = id
    this.paymentMethod = paymentMethod
    this.paymentStatus = paymentStatus
    this.finishedAt = finishedAt
  }
}
