export class OriginRoute {
  public id: number
  public letterCode?: string
  public city?: string
  public route?: string
  public isOrigin?: boolean
  public isPickup?: boolean
  public isShop?: boolean

  constructor(
    id: number,
    letterCode?: string,
    city?: string,
    route?: string,
    isOrigin?: boolean,
    isPickup?: boolean,
    isShop?: boolean
  ) {
    this.id = id
    this.letterCode = letterCode
    this.city = city
    this.route = route
    this.isOrigin = isOrigin
    this.isPickup = isPickup
    this.isShop = isShop
  }
}

export class Route {
  public routeId?: number
  public routeName?: string
  public city?: string
  public routes?: Route[]
  public isExists?: boolean

  constructor(
    routeId?: number,
    routeName?: string,
    city?: string,
    routes?: Route[],
    isExists?: boolean
  ) {
    this.routeId = routeId
    this.routeName = routeName
    this.city = city
    this.routes = routes
    this.isExists = isExists
  }
}

export class LetterCode {
  public lc?: string
  public city?: string

  constructor(
    lc?: string,
    city?: string
  ) {
    this.lc = lc
    this.city = city
  }
}
