import router, { routes } from './index'
import { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { LocalStorage } from '@/app/infrastructures/misc'

// Whitelisting any path that didn't need token to access
const whiteList = [
  '/campaign-ramadhan',
  '/campaign-ramadhan/form-page',
  '/campaign-ramadhan/end-page',
]

// noAuthPaths is like whitelist, but we will not permit them when users have token credential
const noAuthPaths = ['/login', '/login/reset-password']

// Here below we can add logic for permission and user role
// Also if we want to add loading effect we can add them here
router.beforeEach(
  async (to: Route, prev: Route, next: NavigationGuardNext<Vue>) => {
    // Redirect to root if no path matched
    if (!to.matched.length) {
      next('/')
    }

    const pageTitle = (title: string | ((route: Route) => string)) => {
      if (typeof title === 'function') {
        return title(to)
      }

      return title
    }

    if (to.meta.isParent) {
      document.title = `${pageTitle(to.matched[0].meta.title) || ''} | Content Management System | Lionparcel`
    } else {
      document.title = `${pageTitle(to.meta.title) || ''} | Content Management System | Lionparcel`
    }

    // Check if token is exist
    if (
      LocalStorage.getLocalStorage(LocalStorage.TOKEN_KEY) &&
      !whiteList.includes(to.path)
    ) {
      // If path is whitelisted, go to root path
      if (noAuthPaths.includes(to.path)) {
        next({ path: '/' })
      } else {
        // If token is empty string
        if (LocalStorage.getLocalStorage(LocalStorage.TOKEN_KEY) === '') {
          try {
            next({
              replace: true,
              path: to.path,
              params: to.params,
              query: to.query,
              name: to.name as string | undefined,
              hash: to.hash,
            })
          } catch (err) {
            next(`/login?redirect=${to.path}`)
          }
        } else {
          // Following CA-781:
          // CMS_GUEST only can access menu with only have READ permission
          // If my profile didn't have access to specified menu, it will be redirected to root path
          const myRole = LocalStorage.getLocalStorage(LocalStorage.MY_ROLE)
            ? LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true)
            : undefined
          const menus = LocalStorage.getLocalStorage(LocalStorage.ACCESS_MENU)
            ? JSON.parse(
                LocalStorage.getLocalStorage(LocalStorage.ACCESS_MENU, true)
              )
            : []

          if (!myRole || !menus) {
            // FOR UPDATE COMPATIBILITY PURPOSE
            // Since the oldest version didn't have role and menus in local storage
            // We should force logout all user that still logged in with old local storage
            LocalStorage.removeAllLocalStorage()
            next(`/login?redirect=${to.path}`)
          }

          // Collecting all registered route in app
          const listedRoutes = routes.filter(
            item => item.children && item.children?.length > 0
          ) as RouteConfig[]
          const availableRoutes = [] as RouteConfig[]
          for (let i = 0; i < listedRoutes.length; i++) {
            // Collecting all route that have children
            availableRoutes.push(...(listedRoutes[i].children || []))
          }
          // nextPath contains first path that user will be redirected to
          const nextPath = availableRoutes.find(
            item => item.meta.slugFromBackend.slug === menus[0]
          )

          if (to.path === '/') {
            if (myRole === 'ADMIN') {
              next({ path: '/user-management' })
            } else {
              let nextPathName = nextPath?.name
              if (!nextPathName && nextPath?.children && nextPath.children.length > 0) {
                nextPathName = nextPath?.children[0].name
              }
              next({ name: nextPathName })
            }
          } else {
            if (
              myRole === 'ADMIN' || // role = 'ADMIN' is traditional role name that always have all access
              menus.includes(to.meta.slugFromBackend.slug)
            ) {
              if (prev.name && to.name && prev.name !== to.name) {
                const prevRoute = sessionStorage.getItem('previousRoute')
                if (prevRoute && to.fullPath === prevRoute) {
                  sessionStorage.removeItem('previousRoute')
                } else {
                  sessionStorage.setItem('previousRoute', prev.fullPath)
                }
              }


              if (to.meta.accessType === 'write' && myRole === 'CMS_GUEST') {
                next({ path: '/' })
              } else {
                next()
              }
            } else {
              if (to.meta.allUserCanAccess) {
                // Allow the path to all users
                next()
              } else if (availableRoutes && availableRoutes.length > 0) {
                const nextPath = availableRoutes.find(
                  item => item.meta.slugFromBackend.slug === menus[0]
                )
                next({ name: nextPath?.name })
              }
            }
          }
        }
      }
    } else {
      if (
        whiteList.indexOf(to.path) !== -1 ||
        noAuthPaths.indexOf(to.path) !== -1
      ) {
        next()
      } else {
        next(`/login?redirect=${to.path}`)
      }
    }
  }
)
