import { injectable } from 'tsyringe'
import {
  PublicVoucher,
  PublicVoucherCodeMultipleExternalPartner,
  PublicVoucherHistories,
  PublicVouchers,
} from '@/domain/entities/PublicVoucher'
import {
  CreatePublicVoucherRequestInterface,
  DeletePublicVoucherRequestInterface,
  UpdatePublicVoucherRequestInterface,
} from '@/data/payload/contracts/PublicVoucherRequest'
import {
  PublicVoucherRepositoryInterface
} from '@/data/persistences/repositories/contracts/PublicVoucherRepositoryInterface';

@injectable()
export class PublicVoucherPresenter {
  private repository: PublicVoucherRepositoryInterface

  constructor(repository: PublicVoucherRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, unknown>): Promise<PublicVouchers> {
    return this.repository.getAllPublicVoucher(params)
  }

  public get(publicVoucherId: string): Promise<PublicVoucher> {
    return this.repository.getPublicVoucher(publicVoucherId)
  }

  public getHistories(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<PublicVoucherHistories> {
    return this.repository.getPublicVoucherHistories(publicVoucherId, params)
  }

  public getHistoriesV2(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<PublicVoucherHistories> {
    return this.repository.getPublicVoucherHistoriesV2(publicVoucherId, params)
  }

  public exportHistories(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    return this.repository.exportPublicVoucherHistories(publicVoucherId, params)
  }

  public exportHistoriesV2(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    return this.repository.exportPublicVoucherHistoriesV2(
      publicVoucherId,
      params
    )
  }

  public create(
    payload: CreatePublicVoucherRequestInterface
  ): Promise<boolean> {
    return this.repository.createPublicVoucher(payload)
  }

  public update(
    publicVoucherId: string,
    payload: UpdatePublicVoucherRequestInterface
  ): Promise<boolean> {
    return this.repository.updatePublicVoucher(publicVoucherId, payload)
  }

  public delete(
    publicVoucherId: string,
    payload: DeletePublicVoucherRequestInterface
  ): Promise<boolean> {
    return this.repository.deletePublicVoucher(publicVoucherId, payload)
  }

  public getPublicVoucherCodePartner(): Promise<PublicVoucherCodeMultipleExternalPartner[]> {
    return this.repository.getPublicVoucherCodePartner()
  }

  public getMultipleVouchers(
    params: Map<string, unknown>
  ): Promise<PublicVoucherHistories> {
    return this.repository.getMultipleVouchers(params)
  }

  public exportPublicMultipleVoucherHistories(params: Map<string, unknown>): Promise<string> {
    return this.repository.exportPublicMultipleVoucherHistories(params)
  }
}
