import { CODRepositoryInterface } from '@/data/persistences/repositories/contracts/CODRepositoryInterface'
import ApiService from '../../services/ApiService'
import { CODMapper } from '@/data/persistences/mappers/CODMapper'
import {
  CODPaymentDetail,
  CODPaymentSetting,
  CODPaymentSettings,
  CODPayments,
} from '@/domain/entities/COD'
import { Endpoints } from '../../misc'
import {
  SubmitCODPaymentSettingRequest,
  UpdateCODPaymentStatusRequest,
} from '@/data/payload/api/CODRequest'

export class CODApiRepository implements CODRepositoryInterface {
  private service: ApiService
  private mapper: CODMapper

  constructor(service: ApiService, mapper: CODMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getAllCODPaymentSetting(
    params: Map<string, string | number>
  ): Promise<CODPaymentSettings> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/list`,
      params
    )

    return this.mapper.convertCODPaymentSettingListFromApi(resp)
  }

  async createCODPaymentSetting(
    payload: SubmitCODPaymentSettingRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      Endpoints.Route.COD_PAYMENT_SETTING,
      undefined,
      payload
    )

    return true
  }

  async getCODPaymentSetting(codFeeId: number): Promise<CODPaymentSetting> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/detail/${codFeeId}`
    )

    return this.mapper.convertCODPaymentSettingFromApi(resp)
  }

  async updateCODPaymentSetting(
    codFeeId: number,
    payload: SubmitCODPaymentSettingRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/${codFeeId}`,
      undefined,
      payload
    )

    return true
  }

  async deleteCODPaymentSetting(codFeeId: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/${codFeeId}`
    )

    return true
  }

  async getAllPayment(
    params: Map<string, string | number>
  ): Promise<CODPayments> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/payment/list`,
      params
    )

    return this.mapper.convertCODPaymentsFromApi(resp)
  }

  async getCODPayment(packageId: string): Promise<CODPaymentDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/payment/${packageId}`
    )

    return this.mapper.convertCODPaymentDetailFromApi(resp)
  }

  async updateCODPaymentStatus(
    packageId: string,
    payload: UpdateCODPaymentStatusRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/payment/${packageId}/validate`,
      undefined,
      payload
    )

    return true
  }

  async exportCODPayments(
    params: Map<string, string | number>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.COD_PAYMENT_SETTING}/payment/export`,
      params
    )

    return this.mapper.convertExportCODPaymentsFromApi(resp)
  }
}
