export class Pagination {
  public page?: number
  public perPage?: number
  public totalItem?: number
  public keyword?: string
  public filterBy?: string
  public status?: string
  public sortBy?: string

  constructor(
    page = 1,
    perPage = 20,
    totalItem = 0,
    keyword?: string,
    filterBy?: string,
    status?: string,
    sortBy?: string
  ) {
    this.page = page
    this.perPage = perPage
    this.totalItem = totalItem
    this.keyword = keyword
    this.filterBy = filterBy
    this.status = status
    this.sortBy = sortBy
  }
}
