import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import {
  ManageCaptainDetail,
  ManageCaptains,
} from '@/domain/entities/ManageCaptain'
import { ManageCaptainMapper } from '@/data/persistences/mappers/ManageCaptainMapper'
import {
  CreateManageCaptainRequestInterface,
  UpdateManageCaptainRequestInterface,
} from '@/data/payload/contracts/ManageCaptainRequest'

export class ManageCaptainApiRepository implements ManageCaptainApiRepository {
  private service: ApiService
  private mapper: ManageCaptainMapper

  constructor(service: ApiService, mapper: ManageCaptainMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, number>): Promise<ManageCaptains> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER}/captain`,
      params
    )

    return this.mapper.convertManageCaptainFromApi(resp)
  }

  public async get(id: number): Promise<ManageCaptainDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER}/captain/${id}`
    )

    return this.mapper.convertDetailManageCaptainFromApi(resp)
  }

  public async create(
    payload: CreateManageCaptainRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.MANAGE_COURIER}/captain`,
      undefined,
      <CreateManageCaptainRequestInterface>payload
    )

    return true
  }

  public async update(
    id: string,
    payload: UpdateManageCaptainRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.MANAGE_COURIER}/captain/${id}`,
      undefined,
      <UpdateManageCaptainRequestInterface>payload
    )

    return true
  }
}
