import { injectable } from 'tsyringe'

import { ProductRepositoryInterface } from '@/data/persistences/repositories/contracts/ProductRepositoryInterface'
import {
  HistoryLogBulkProducts,
  Product,
  Products,
  VariantProducts,
  ProductCategory
} from '@/domain/entities/Product'
import {
  CreateProductRequestInterface,
  UpdateProductRequestInterface,
  UpdateProductStatusRequestInterface,
  UploadFileProductBulkRequestInterface,
} from '@/data/payload/contracts/ProductRequest'

@injectable()
export class ProductPresenter {
  private repository: ProductRepositoryInterface

  constructor(repository: ProductRepositoryInterface) {
    this.repository = repository
  }

  public get(productId: string): Promise<Product> {
    return this.repository.get(productId)
  }

  public getAll(
    params: Map<string, string | number | undefined>
  ): Promise<Products> {
    return this.repository.getAll(params)
  }

  public getVariantList(productId: number): Promise<VariantProducts> {
    return this.repository.getVariantList(productId)
  }

  async getCategoryLevelOne(): Promise<ProductCategory[]> {
    return this.repository.getCategoryLevelOne()
  }

  async getCategoryLevelTwo(
    categoryTypeIdOne: number
  ): Promise<ProductCategory[]> {
    return this.repository.getCategoryLevelTwo(categoryTypeIdOne)
  }

  async getCategoryLevelThree(
    categoryTypeIdOne: number,
    categoryTypeIdTwo: number
  ): Promise<ProductCategory[]> {
    return this.repository.getCategoryLevelThree(
      categoryTypeIdOne,
      categoryTypeIdTwo
    )
  }

  public create(payload: CreateProductRequestInterface): Promise<Product> {
    return this.repository.create(payload)
  }

  public update(
    productId: number,
    payload: UpdateProductRequestInterface
  ): Promise<boolean> {
    return this.repository.update(productId, payload)
  }

  public delete(productId: number): Promise<boolean> {
    return this.repository.delete(productId)
  }

  public setStatus(
    productId: number,
    payload: UpdateProductStatusRequestInterface
  ): Promise<boolean> {
    return this.repository.setStatus(productId, payload)
  }

  public setStatusVariant(
    productId: number,
    payload: UpdateProductStatusRequestInterface
  ): Promise<boolean> {
    return this.repository.setStatusVariant(productId, payload)
  }

  public uploadProductBulk(payload: UploadFileProductBulkRequestInterface): Promise<boolean> {
    return this.repository.uploadProductBulk(payload)
  }

  public getHistoryLog(
    params: Map<string, string | number | undefined>
  ): Promise<HistoryLogBulkProducts> {
    return this.repository.getHistoryLog(params)
  }
}
