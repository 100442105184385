import { injectable } from 'tsyringe'
import { ManageDayRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageDayRepositoryInterface'
import { ManageDays, ManageDayYearList } from '@/domain/entities/ManageDay'
import { UpdateManageDayRequestInterface } from '@/data/payload/contracts/ManageDayRequest'

@injectable()
export class ManageDayPresenter {
  private repository: ManageDayRepositoryInterface

  constructor(repository: ManageDayRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number>): Promise<ManageDays> {
    return this.repository.getAll(params)
  }

  public getYearList(): Promise<ManageDayYearList> {
    return this.repository.getYearList()
  }

  public update(id: number, payload: UpdateManageDayRequestInterface): Promise<boolean> {
    return this.repository.update(id, payload)
  }
}
