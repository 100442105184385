import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import {
  Banner,
  Banners,
  BannerType,
  OrderBanner,
  PopUpBanner,
  PopUpBanners,
} from '@/domain/entities/Banner'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BannerInterface, PopUpBannerInterface } from '@/data/responses/contracts/PopUpBannerResponseInterface'

export class BannerMapper extends BaseResponseMapper {
  public convertBannersFromApi(result: AxiosResponse): Banners {
    const {
      data: { pagination, data },
    } = result
    return new Banners(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (banner: BannerInterface) =>
          new Banner(
            banner.banner_id,
            banner.image_url,
            banner.banner_name,
            banner.banner_description,
            banner.status,
            banner.start_date,
            banner.end_date,
            banner.banner_type_id,
            banner.banner_category_id,
            banner.target_url,
            banner.banner_order
          )
      )
    )
  }

  public convertBannerFromApi(result: AxiosResponse): Banner {
    const { data } = result
    return new Banner(
      data.banner_id,
      data.image_url,
      data.banner_name,
      data.banner_description,
      data.status,
      data.start_date,
      data.end_date,
      data.banner_type_id,
      data.banner_category_id,
      data.target_url,
      data.banner_order
    )
  }

  public convertBannerTypesFromApi(result: AxiosResponse): BannerType[] {
    const {
      data: { data },
    } = result
    return data.map(
      (banner: Record<string, never>) =>
        new BannerType(
          banner.banner_type_id,
          banner.banner_type_name,
          banner.banner_type_remarks
        )
    )
  }

  public convertOrderBannerFromApi(result: AxiosResponse): OrderBanner {
    const { data } = result
    return new OrderBanner(data.value)
  }

  public convertPopUpBannersFromApi(result: AxiosResponse): PopUpBanners {
    const {
      data: { pagination, data },
    } = result

    return new PopUpBanners(
      Utils.toInstance(new Pagination(), pagination),
      data?.map(
        (pop_up_banner: PopUpBannerInterface) =>
          new PopUpBanner(
            pop_up_banner?.pop_up_banner_id,
            pop_up_banner?.type,
            pop_up_banner?.title,
            pop_up_banner?.location,
            pop_up_banner?.image_url,
            pop_up_banner?.deep_link_url,
            pop_up_banner?.timer,
            pop_up_banner?.frequency,
            pop_up_banner?.start_date,
            pop_up_banner?.end_date,
            pop_up_banner?.status,
            pop_up_banner?.created_at
          )
      )
    )
  }

  public convertPopUpBannerFromApi(
    result: AxiosResponse
  ): PopUpBanner {
    const { data } = result
    return new PopUpBanner(
      data?.pop_up_banner_id,
      data?.type,
      data?.title,
      data?.location,
      data?.image_url,
      data?.deep_link_url,
      data?.timer,
      data?.frequency,
      data?.start_date,
      data?.end_date,
      data?.status,
      data?.created_at
    )
  }
}
