import { CODApiRepository } from '@/app/infrastructures/repositories/api/CODApiRepository'
import {
  SubmitCODPaymentSettingRequest,
  UpdateCODPaymentStatusRequest,
} from '@/data/payload/api/CODRequest'
import { CODRepositoryInterface } from '@/data/persistences/repositories/contracts/CODRepositoryInterface'
import {
  CODPaymentDetail,
  CODPaymentSetting,
  CODPaymentSettings,
  CODPayments,
} from '@/domain/entities/COD'
import { injectable } from 'tsyringe'

@injectable()
export class CODPresenter implements CODRepositoryInterface {
  private repository: CODApiRepository

  constructor(repository: CODApiRepository) {
    this.repository = repository
  }

  getAllCODPaymentSetting(
    params: Map<string, string | number>
  ): Promise<CODPaymentSettings> {
    return this.repository.getAllCODPaymentSetting(params)
  }

  createCODPaymentSetting(
    payload: SubmitCODPaymentSettingRequest
  ): Promise<boolean> {
    return this.repository.createCODPaymentSetting(payload)
  }

  getCODPaymentSetting(codFeeId: number): Promise<CODPaymentSetting> {
    return this.repository.getCODPaymentSetting(codFeeId)
  }

  updateCODPaymentSetting(
    codFeeId: number,
    payload: SubmitCODPaymentSettingRequest
  ): Promise<boolean> {
    return this.repository.updateCODPaymentSetting(codFeeId, payload)
  }

  deleteCODPaymentSetting(codFeeId: number): Promise<boolean> {
    return this.repository.deleteCODPaymentSetting(codFeeId)
  }

  getAllPayment(params: Map<string, string | number>): Promise<CODPayments> {
    return this.repository.getAllPayment(params)
  }

  getCODPayment(packageId: string): Promise<CODPaymentDetail> {
    return this.repository.getCODPayment(packageId)
  }

  updateCODPaymentStatus(
    packageId: string,
    payload: UpdateCODPaymentStatusRequest
  ): Promise<boolean> {
    return this.repository.updateCODPaymentStatus(packageId, payload)
  }

  exportCODPayments(params: Map<string, string | number>): Promise<string> {
    return this.repository.exportCODPayments(params)
  }
}
