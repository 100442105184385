import { injectable } from 'tsyringe'
import { AccessMenu, Accounts, Role, Account } from '@/domain/entities/Account'
import {
  AccessMenuRequestInterface,
  CreateUserRequestInterface,
  UpdateUserRequestInterface,
} from '@/data/payload/contracts/UserManagementRequest'
import { UserManagementRepositoryInterface } from '@/data/persistences/repositories/contracts/UserManagementRepositoryInterface'

@injectable()
export class UserManagementPresenter {
  private repository: UserManagementRepositoryInterface

  constructor(repository: UserManagementRepositoryInterface) {
    this.repository = repository
  }

  public getRoles(params: Map<string, any>): Promise<Role[]> {
    return this.repository.getRoles(params)
  }

  public getUsers(params: Map<string, any>): Promise<Accounts> {
    return this.repository.getUsers(params)
  }

  public getUser(accountId: string): Promise<Account> {
    return this.repository.getUser(accountId)
  }

  public getMenus(params: Map<string, any>): Promise<AccessMenu[]> {
    return this.repository.getMenus(params)
  }

  public createUser(payload: CreateUserRequestInterface): Promise<boolean> {
    return this.repository.createUser(payload)
  }

  public updateUser(
    accountId: string,
    payload: UpdateUserRequestInterface
  ): Promise<boolean> {
    return this.repository.updateUser(accountId, payload)
  }

  public deleteUser(accountId: string): Promise<boolean> {
    return this.repository.deleteUser(accountId)
  }

  public addMenu(payload: AccessMenuRequestInterface): Promise<boolean> {
    return this.repository.addMenu(payload)
  }

  public updateMenu(
    id: number,
    payload: AccessMenuRequestInterface
  ): Promise<boolean> {
    return this.repository.updateMenu(id, payload)
  }

  public deleteMenu(id: number): Promise<boolean> {
    return this.repository.deleteMenu(id)
  }
}
