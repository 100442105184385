import {AxiosResponse} from 'axios'
import {BaseResponseMapper} from '@/data/persistences/mappers/BaseMapper'
import {
  VoucherSetLimitList,
  VoucherSetLimit,
  UploadBulkyVoucherSetLimitSpecificCustomer,
  DetailUploadBulkySetLimitSpecificCustomer,
  ResultUploadBulkySetLimitSpecificCustomer,
} from "@/domain/entities/VoucherConfigSetLimit"
import {
  DetailUploadBulkyInterface,
  PreviewUploadBulkySetLimitSpecificCustomerInterface,
} from '@/data/responses/contracts/VoucherConfigResponseInterface'

export class VoucherConfigSetLimitMapper extends BaseResponseMapper {
  convertVoucherSetLimit(result: AxiosResponse): VoucherSetLimit {
    const {
      data,
    } = result
    return new VoucherSetLimit(
      data.id,
      data.rule_name,
      data.is_active,
      data.sku_groups.map((skuGroup: { id: number; sku: string; name: string }) => ({
        id: skuGroup.id,
        sku: skuGroup.sku,
        name: skuGroup.name,
      })),
      data.period_all_customer,
      data.max_qty_all_customer,
      data.max_amount_all_customer,
      data.target,
      data.period,
      data.max_qty,
      data.max_amount,
      (data.specific_customers || []),
      data.created_at,
      data.updated_at,
    );
  }

  convertVoucherSetLimitList(result: AxiosResponse): VoucherSetLimitList {
    const {
      data: {data, pagination},
    } = result
    return new VoucherSetLimitList(
      pagination,
      data.map((item: {
          id: number;
          rule_name: string;
          is_active: boolean;
          sku_groups: {
            id: number;
            sku: string;
            name: string;
          }[];
          period_all_customer: null | string;
          max_qty_all_customer: null | number;
          max_amount_all_customer: null | number;
          target: null | string;
          period: null | string;
          max_qty: null | number;
          max_amount: null | number;
          specific_customers: null | number[];
          created_at: string;
          updated_at: null | string;
        }) => new VoucherSetLimit(
          item.id,
          item.rule_name,
          item.is_active,
          item.sku_groups.map((skuGroup: { id: number; sku: string; name: string }) => ({
            id: skuGroup.id,
            sku: skuGroup.sku,
            name: skuGroup.name,
          })),
          item.period_all_customer,
          item.max_qty_all_customer,
          item.max_amount_all_customer,
          item.target,
          item.period,
          item.max_qty,
          item.max_amount,
        (item.specific_customers || []),
          item.created_at,
          item.updated_at,
        )
      ));
  }

  public convertUploadBulkyFromApi(result: AxiosResponse): UploadBulkyVoucherSetLimitSpecificCustomer {
    const {data: metadata} = result.data
    return new UploadBulkyVoucherSetLimitSpecificCustomer(
      new ResultUploadBulkySetLimitSpecificCustomer(
        metadata.file_name || '',
        Number(metadata.total_valid || 0),
        Number(metadata.total_invalid || 0),
      ),
      metadata.data.map((val: { customer_id: number, status: string }) => {
        return new DetailUploadBulkySetLimitSpecificCustomer(
          Number(val?.customer_id || 0),
          val?.status || '',
        )
      }),
    )
  }
}
