import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'
import {
  CODPayment,
  CODPaymentCourierInfo,
  CODPaymentDetail,
  CODPaymentRecipientInfo,
  CODPaymentSetting,
  CODPaymentSettings,
  CODPaymentSummary,
  CODPayments,
} from '@/domain/entities/COD'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import { ManageCourier } from '@/domain/entities/ManageCourier'
import {
  CODPaymentDetailResponse,
  CODPaymentResponse,
  CODPaymentSummaryResponse,
} from '@/data/responses/contracts/CODResponse'

export class CODMapper extends BaseResponseMapper {
  convertCODPaymentSettingListFromApi(
    result: AxiosResponse
  ): CODPaymentSettings {
    const {
      data: { data, paginate },
    } = result

    return new CODPaymentSettings(
      data.map((cod: string) => {
        return Utils.toInstance(new CODPaymentSetting(), cod)
      }),
      Utils.toInstance(new Pagination(), paginate)
    )
  }

  convertCODPaymentSettingFromApi(result: AxiosResponse): CODPaymentSetting {
    const { data } = result

    return Utils.toInstance(new CODPaymentSetting(), data)
  }

  convertCODPaymentsFromApi(result: AxiosResponse): CODPayments {
    const {
      data: { data, summary, paginate },
    } = result

    const summaryData: CODPaymentSummaryResponse = summary

    return new CODPayments(
      data.map((payment: CODPaymentResponse) => {
        return new CODPayment(
          payment.package_id,
          payment.cod_type,
          payment.pod_date,
          payment.cod_amount,
          new ManageCourier(
            payment.courier_data.courier_id,
            payment.courier_data.fullname,
            payment.courier_data.role,
            payment.courier_data.phone_number,
            payment.courier_data.vehicle_type,
            payment.courier_data.vehicle_plate,
            payment.courier_data.partner_type,
            payment.courier_data.partner_name,
            payment.courier_data.origin,
            payment.courier_data.courier_type,
            payment.courier_data.courier_status,
            payment.courier_data.courier_type_valid
          ),
          payment.payment_method,
          payment.status,
          payment.status_txt
        )
      }),
      new CODPaymentSummary(
        summaryData?.not_valid,
        summaryData?.valid,
        summaryData?.waiting
      ),
      Utils.toInstance(new Pagination(), paginate)
    )
  }

  convertCODPaymentDetailFromApi(result: AxiosResponse): CODPaymentDetail {
    const { data }: { data: CODPaymentDetailResponse } = result

    return new CODPaymentDetail(
      data.stt_number,
      data.cod_type,
      data.pod_date,
      data.cod_amount,
      data.cod_fee,
      data.payment_method,
      data.status,
      data.status_text,
      data.invalid_reason,
      data.history_id,
      data.is_valid,
      new CODPaymentCourierInfo(
        data.courier_info.courier_id,
        data.courier_info.courier_name,
        data.courier_info.courier_phone
      ),
      new CODPaymentRecipientInfo(
        data.recipient_info.recipient_name,
        data.recipient_info.recipient_phone
      ),
      data.photo_proofs || [],
      data.approval_by,
      data.approval_at
    )
  }

  convertExportCODPaymentsFromApi(result: AxiosResponse): string {
    const {
      data: { url },
    } = result

    return url
  }
}
