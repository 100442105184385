import { Pagination } from './Pagination'

export class Metadata {
  public totalDEL?: number
  public countDEL?: number
  public totalPOD?: number
  public countPOD?: number
  public totalDEX?: number
  public countDEX?: number
  public totalRejected?: number
  public countRejected?: number
  public sumTotalCOD?: number
  public countTotalCOD?: number

  constructor(
    totalDEL?: number,
    countDEL?: number,
    totalPOD?: number,
    countPOD?: number,
    totalDEX?: number,
    countDEX?: number,
    totalRejected?: number,
    countRejected?: number,
    sumTotalCOD?: number,
    countTotalCOD?: number
  ) {
    this.totalDEL = totalDEL
    this.countDEL = countDEL
    this.totalPOD = totalPOD
    this.countPOD = countPOD
    this.totalDEX = totalDEX
    this.countDEX = countDEX
    this.totalRejected = totalRejected
    this.countRejected = countRejected
    this.sumTotalCOD = sumTotalCOD
    this.countTotalCOD = countTotalCOD
  }
}

export class CODTracker {
  public packageId?: string
  public codValue?: number
  public statusId?: string
  public destination?: string
  public courierId?: number
  public courierName?: string
  public updatedAt?: string
  public origin?: string

  constructor(
    packageId?: string,
    codValue?: number,
    statusId?: string,
    destination?: string,
    courierId?: number,
    courierName?: string,
    updatedAt?: string,
    origin?: string
  ) {
    this.packageId = packageId
    this.codValue = codValue
    this.statusId = statusId
    this.destination = destination
    this.courierId = courierId
    this.courierName = courierName
    this.updatedAt = updatedAt
    this.origin = origin
  }
}

export class CODTrackers {
  public pagination?: Pagination
  public data?: CODTracker[]
  public metadata?: Metadata

  constructor(
    pagination?: Pagination,
    data?: CODTracker[],
    metadata?: Metadata
  ) {
    this.pagination = pagination
    this.data = data
    this.metadata = metadata
  }
}

export class CODMeta {
  public proofs?: string[]
  public signature?: string

  constructor(proofs?: string[], signature?: string) {
    this.proofs = proofs
    this.signature = signature
  }
}

export class ShipmentCODRejectDetail {
  public shipmentId?: string
  public driverName?: string
  public driverId?: number
  public driverPhone?: string
  public quantity?: number
  public date?: string

  constructor(
    shipmentId?: string,
    driverName?: string,
    driverId?: number,
    driverPhone?: string,
    quantity?: number,
    date?: string
  ) {
    this.shipmentId = shipmentId
    this.driverName = driverName
    this.driverId = driverId
    this.driverPhone = driverPhone
    this.quantity = quantity
    this.date = date
  }
}

export class PackageCODRejectDetail {
  public packageId?: string
  public productType?: string
  public weight?: number
  public recipientName?: string
  public recipientPhone?: string
  public recipientAddress?: string
  public statusId?: string
  public date?: string

  constructor(
    packageId?: string,
    productType?: string,
    weight?: number,
    recipientName?: string,
    recipientPhone?: string,
    recipientAddress?: string,
    statusId?: string,
    date?: string
  ) {
    this.packageId = packageId
    this.productType = productType
    this.weight = weight
    this.recipientName = recipientName
    this.recipientPhone = recipientPhone
    this.recipientAddress = recipientAddress
    this.statusId = statusId
    this.date = date
  }
}

export class DEXHistory {
  public date?: string
  public driverName?: string
  public driverId?: number
  public driverPhone?: string

  constructor(
    date?: string,
    driverName?: string,
    driverId?: number,
    driverPhone?: string
  ) {
    this.date = date
    this.driverName = driverName
    this.driverId = driverId
    this.driverPhone = driverPhone
  }
}
export class DeliveryCODRejectDetail {
  public statusId?: string
  public driverName?: string
  public driverId?: number
  public driverPhone?: string
  public packageId?: string
  public productType?: string
  public codValue?: number
  public recipientName?: string
  public recipientPhone?: string
  public recipientAddress?: string
  public receiverName?: string
  public receiverRelation?: string
  public date?: string
  public meta?: CODMeta
  public dexHistories?: DEXHistory[]

  constructor(
    statusId?: string,
    driverName?: string,
    driverId?: number,
    driverPhone?: string,
    packageId?: string,
    productType?: string,
    codValue?: number,
    recipientName?: string,
    recipientPhone?: string,
    recipientAddress?: string,
    receiverName?: string,
    receiverRelation?: string,
    date?: string,
    meta?: CODMeta,
    dexHistories?: DEXHistory[]
  ) {
    this.statusId = statusId
    this.driverName = driverName
    this.driverId = driverId
    this.driverPhone = driverPhone
    this.packageId = packageId
    this.productType = productType
    this.codValue = codValue
    this.recipientName = recipientName
    this.recipientPhone = recipientPhone
    this.recipientAddress = recipientAddress
    this.receiverName = receiverName
    this.receiverRelation = receiverRelation
    this.date = date
    this.meta = meta
    this.dexHistories = dexHistories
  }
}

export class CODTrackerDetail {
  public statusId?: number
  public driverName?: string
  public driverId?: number
  public driverPhone?: string
  public packageId?: string
  public productType?: string
  public codValue?: number
  public recipientName?: string
  public recipientPhone?: string
  public recipientAddress?: string
  public receiverName?: string
  public receiverRelation?: string
  public date?: string
  public meta?: CODMeta
  public shipmentCODRejectDetail?: ShipmentCODRejectDetail
  public packageCODRejectDetail?: PackageCODRejectDetail
  public deliveryCODRejectDetail?: DeliveryCODRejectDetail

  constructor(
    statusId?: number,
    driverName?: string,
    driverId?: number,
    driverPhone?: string,
    packageId?: string,
    productType?: string,
    codValue?: number,
    recipientName?: string,
    recipientPhone?: string,
    recipientAddress?: string,
    receiverName?: string,
    receiverRelation?: string,
    date?: string,
    meta?: CODMeta,
    shipmentCODRejectDetail?: ShipmentCODRejectDetail,
    packageCODRejectDetail?: PackageCODRejectDetail,
    deliveryCODRejectDetail?: DeliveryCODRejectDetail
  ) {
    this.statusId = statusId
    this.driverName = driverName
    this.driverId = driverId
    this.driverPhone = driverPhone
    this.packageId = packageId
    this.productType = productType
    this.codValue = codValue
    this.recipientName = recipientName
    this.recipientPhone = recipientPhone
    this.recipientAddress = recipientAddress
    this.receiverName = receiverName
    this.receiverRelation = receiverRelation
    this.date = date
    this.meta = meta
    this.shipmentCODRejectDetail = shipmentCODRejectDetail
    this.packageCODRejectDetail = packageCODRejectDetail
    this.deliveryCODRejectDetail = deliveryCODRejectDetail
  }
}
