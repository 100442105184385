import { AxiosResponse } from 'axios'
import {
  HistoryLogBulkProduct,
  HistoryLogBulkProducts,
  Product,
  Products,
  VariantList,
  VariantProduct,
  VariantProducts,
  ProductCategory,
} from '@/domain/entities/Product'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'
import {
  HistoryLogBulkProductResponseInterface,
  ProductResponseInterface,
  VariantProductResponseInterface,
  ProductCategoryInterface,
} from '@/data/responses/contracts/ProductResponseInterface'

type ObjectVariant = Record<string, number | string | string[] | boolean>

export class ProductMapper extends BaseResponseMapper {
  public convertProductFromApi(result: AxiosResponse): Product {
    const { data } = result
    return new Product(
      data.product_id,
      data.merchant_id,
      data.product_name,
      data.description,
      data.price,
      data.base_price,
      data.lowest_price,
      data.highest_price,
      data.information,
      data.weight,
      data.length,
      data.width,
      data.height,
      data.image_url,
      data.image_pdp,
      data.is_active,
      data.stock,
      data.sku,
      data.created_at,
      data.has_child,
      <VariantList[]>data.variant_list,
      this._convertProductVariantList(data.list_product_variant),
      data.product_type_id,
      data.product_type_name,
      data.product_type_2nd_id,
      data.product_type_2nd_name,
      data.product_type_3rd_id,
      data.product_type_3rd_name,
    )
  }

  private _convertProductVariantList(
    resultApi?: ObjectVariant[]
  ): VariantProduct[] {
    return <VariantProduct[]>resultApi?.map((v: ObjectVariant) => {
      return new VariantProduct(
        <number>v.product_id,
        undefined,
        undefined,
        <string>v.sku,
        <number>v.price,
        <number>v.stock,
        <boolean>v.is_active,
        <number>v.weight,
        <string[]>v.variants
      )
    })
  }

  public convertProductsFromApi(result: AxiosResponse): Products {
    const {
      data: { data, pagination },
    } = result
    return new Products(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (data: ProductResponseInterface) =>
          new Product(
            data.product_id,
            data.merchant_id,
            data.product_name,
            data.description,
            data.price,
            data.base_price,
            data.lowest_price,
            data.highest_price,
            data.information,
            data.weight,
            data.length,
            data.width,
            data.height,
            data.image_url,
            data.image_pdp,
            data.is_active,
            data.stock,
            data.sku,
            data.created_at,
            data.has_child,
            undefined,
            undefined,
          )
      )
    )
  }

  public convertVariantResponseFromApi(result: AxiosResponse): VariantProducts {
    const {
      data: { product_id, total_active, variant_list },
    } = result

    return new VariantProducts(
      product_id,
      variant_list.map((item: VariantProductResponseInterface) => {
        return new VariantProduct(
          item.variant_id,
          item.variant_value_1,
          item.variant_value_2,
          item.sku,
          item.price,
          item.stock,
          item.is_active
        )
      }),
      total_active
    )
  }

  public convertHistoryLogFromApi(
    result: AxiosResponse
  ): HistoryLogBulkProducts {
    const {
      data: { data, pagination },
    } = result
    return new HistoryLogBulkProducts(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (log: HistoryLogBulkProductResponseInterface) =>
          new HistoryLogBulkProduct(
            log.id,
            log.username,
            log.filename,
            log.error_title,
            log.meta,
            log.status,
            log.created_at
          )
      )
    )
  }

  convertCategoryResponse(result: AxiosResponse): ProductCategory[] {
    const {
      data: { data },
    } = result

    return <ProductCategory[]>(
      data.map(
        (val: ProductCategoryInterface) => new ProductCategory(val.id, val.name)
      )
    )
  }
}
