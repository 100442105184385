import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'
import { UpdateAppVersion, UpdateAppVersions } from '@/domain/entities/UpdateAppVersion'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { Pagination } from '@/domain/entities/Pagination'

export class UpdateAppVersionMapper extends BaseResponseMapper {
  public convertOriginFromApiList(result: AxiosResponse): UpdateAppVersions {
    const {
      data: {data, pagination},
    } = result
    const listData = data.map((upgradeVersion: {
      app_upgrade_id: number,
      os: string,
      status: string,
      force_version: string,
      recommend_version: string,
      version_validation: string,
      created_at: string,
      start_date: string,
      updated_at: string,
      deleted_at: string
    }) => {
      return new UpdateAppVersion(
        upgradeVersion.app_upgrade_id,
        upgradeVersion.os,
        upgradeVersion.status,
        upgradeVersion.force_version,
        upgradeVersion.recommend_version,
        upgradeVersion.version_validation,
        upgradeVersion.created_at,
        upgradeVersion.start_date,
        upgradeVersion.updated_at,
        upgradeVersion.deleted_at
      )
    })

    return new UpdateAppVersions(
      Utils.toInstance(new Pagination(), pagination),
      listData
    )
  }

  public convertOriginFromApiDetail(result: AxiosResponse): UpdateAppVersion {
    const {
      data: upgradeVersion,
    } = result
    return new UpdateAppVersion(
      upgradeVersion.app_upgrade_id,
      upgradeVersion.os,
      upgradeVersion.status,
      upgradeVersion.force_version,
      upgradeVersion.recommend_version,
      upgradeVersion.version_validation,
      upgradeVersion.created_at,
      upgradeVersion.start_date,
      upgradeVersion.updated_at,
      upgradeVersion.deleted_at
    )
  }
}
