import { injectable } from 'tsyringe'
import { RequestTopupSaldoRepositoryInterface } from '@/data/persistences/repositories/contracts/RequestTopupSaldoRepositoryInterface'
import {
  DetailTopupSaldoForm,
  HistoryUploadBulky,
  RequestTopupSaldoHistoryList,
  RequestTopupSaldoList,
  UploadBulky,
} from '@/domain/entities/RequestTopupSaldo'
import {
  ApproveRejectBulkyRequestInterface,
  ApprovingTopupRequestInterface,
  RequestTopupRequestInterface,
} from '@/data/payload/contracts/AdjustmentRequest'
import { UploadRequest } from '@/data/payload/api/UploadRequest'
import { CancelTokenSource } from 'axios'

@injectable()
export class RequestTopupSaldoPresenter {
  private repository: RequestTopupSaldoRepositoryInterface

  constructor(repository: RequestTopupSaldoRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string>): Promise<RequestTopupSaldoList> {
    return this.repository.getAll(params)
  }

  public getHistory(
    params: Map<string, string>
  ): Promise<RequestTopupSaldoHistoryList> {
    return this.repository.getHistory(params)
  }

  public postRequestTopup(
    payload: RequestTopupRequestInterface
  ): Promise<boolean> {
    return this.repository.postRequestTopup(payload)
  }

  public getDetailTopup(customerId: number): Promise<DetailTopupSaldoForm> {
    return this.repository.getDetailTopup(customerId)
  }

  public postApprovingTopup(
    payload: ApprovingTopupRequestInterface
  ): Promise<boolean> {
    return this.repository.postApprovingTopup(payload)
  }

  public postApproveRejectBulk(
    payload: ApproveRejectBulkyRequestInterface
  ): Promise<boolean> {
    return this.repository.postApproveRejectBulky(payload)
  }

  public uploadBulkyRequestTopUp(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    return this.repository.uploadBulkyRequestTopUp(file, cancelToken)
  }

  public previewUploadBulky(params: Map<string, string>): Promise<UploadBulky> {
    return this.repository.previewUploadBulky(params)
  }

  public processBulkyRequestTopUp(): Promise<boolean> {
    return this.repository.processBulkyRequestTopUp()
  }

  public getHistoryUploadBulky(
    params: Map<string, string>
  ): Promise<HistoryUploadBulky> {
    return this.repository.getHistoryUploadBulky(params)
  }
}
