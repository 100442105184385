import { AxiosResponse } from 'axios'
import { SelectedMerchant } from '@/domain/entities/SelectedMerchant'
import { BaseResponseMapper } from './BaseMapper'
import { MerchantDropdown } from '@/domain/entities/Merchant'

export class SelectedMerchantMapper extends BaseResponseMapper {
  convertSelectedMerchantFromApi(
    result: AxiosResponse<any>
  ): SelectedMerchant[] {
    const {
      data: { data },
    } = result
    if (!data || data.length === 0) {
      return []
    }
    return data.map(
      (result: Record<string, any>) =>
        new SelectedMerchant(
          result.selected_merchant_id,
          result.merchant_name,
          result.merchant_id,
          result.status,
          result.position
        )
    )
  }

  convertMerchantsDropdownFromApi(
    result: AxiosResponse<any>
  ): MerchantDropdown[] {
    const { data } = result
    if (!data || data.length === 0) {
      return []
    }
    return data.map(
      (merchant: Record<string, string | number>) =>
        new MerchantDropdown(
          merchant.merchant_id as number,
          merchant.merchant_name as string,
          merchant.status as string
        )
    )
  }
}
