import { BaseResponseMapper } from "./BaseMapper";
import { AxiosResponse } from "axios";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ManualAdjustmentBalance,
  ManualAdjustmentBalanceCourier,
  ManualAdjustmentBalances,
  ManualAdjustmentUploadHistories,
  ManualAdjustmentUploadHistory,
  UploadBulkBalanceAmount,
  UploadBulkData,
  UploadBulkDataUpload,
  UploadBulkRow,
  UploadBulkSummary
} from "@/domain/entities/ManualAdjustmentBalance";
import {
  ManualAdjustmentBalanceResponse,
  UploadBulkRowResponse
} from "@/data/responses/contracts/ManualAdjustmentBalanceResponseInterface";
import { Utils } from '@/app/infrastructures/misc'

export class ManualdjustmentBalanceMapper extends BaseResponseMapper {
  public convertManualAdjustmentBalanceFromApi(result: AxiosResponse): ManualAdjustmentBalances {
    const {
      data: { data, pagination },
    } = result

    return new ManualAdjustmentBalances(
      Utils.toInstance(new Pagination(pagination), pagination),
      data.map(
        (item: ManualAdjustmentBalanceResponse) => {
          const courier = item.courier
          return new ManualAdjustmentBalance(
            item.id,
            item.notes,
            item.insert_type,
            item.meta,
            item.amount,
            item.status,
            item.activity,
            item.type,
            item.created_by,
            item.created_at,
            item.approved_by,
            item.approved_at,
            item.filename,
            new ManualAdjustmentBalanceCourier(
              courier.id,
              courier.type,
              courier.status,
              courier.fullname,
              courier.phone_number
            )
          )
        }
      )
    )
  }

  public convertManualAdjustmentUploadHistoryFromApi(result: AxiosResponse): ManualAdjustmentUploadHistories {
    const {
      data: { data, pagination },
    } = result

    return new ManualAdjustmentUploadHistories(
      new Pagination(
        pagination.page,
        pagination.limit,
        pagination.total_data,
      ),
      data.map(
        (item: Record<string, string | number | Date>) => {
          return new ManualAdjustmentUploadHistory(
            <number>item.id,
            <string>item.filename,
            <string>item.filepath,
            <string>item.status_id,
            <string>item.created_by,
            <string>item.created_at,
            <string>item.deleted_by,
            <string>item.deleted_at,
            <string>item.updated_by,
            <string>item.updated_at,
            <string>item.reason,
            <string>item.status_approval
          )
        }
      )
    )
  }

  public convertExportManualAdjustmentBalanceFromApi(result: AxiosResponse): string {
    const {
      data: { data },
    } = result

    return data.url
  }

  public convertDownloadTemplateManualAdjustmentBalanceFromApi(result: AxiosResponse): string {
    const {
      data: { data },
    } = result

    return data.url
  }

  public convertUploadBulkResFromApi(result: AxiosResponse, isUpload: boolean): UploadBulkData {
    const {
      data: { data },
    } = result

    let summary
    let filename
    let rows
    let pagination
    let filepath

    if (isUpload) {
      const dataUpload = data.data_upload
      filename = dataUpload.filename
      summary = dataUpload.summary
      rows = dataUpload.rows
      pagination = dataUpload.pagination
      filepath = dataUpload.filepath
    } else {
      filename = data.filename
      summary = data.summary
      rows = data.rows
      pagination = data.pagination
      filepath = data.filepath
    }

    return new UploadBulkData(
      data.created_by,
      data.created_at,
      new UploadBulkDataUpload(
        filename,
        filepath,
        new UploadBulkSummary(
          summary.row_valid,
          summary.row_invalid,
          summary.courier_valid,
          summary.courier_invalid,
          new UploadBulkBalanceAmount(
            summary.amount_valid.addition,
            summary.amount_valid.reduction
          ),
          new UploadBulkBalanceAmount(
            summary.amount_invalid.addition,
            summary.amount_invalid.reduction
          )
        ),
        rows.map((row: UploadBulkRowResponse) => {
          return new UploadBulkRow(
            row.no,
            row.courier_id,
            row.amount,
            row.note,
            row.status,
            row.reason
          )
        }),
        pagination && new Pagination(
          pagination.page,
          pagination.limit,
          pagination.total_item,
        )
      )
    )
  }
}
