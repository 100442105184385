import { AxiosResponse } from 'axios'
import {
  DriverBank,
  Geolocation,
  POD,
  PODDestination,
  PODDetail,
  PODDetailData,
  PODHistory,
  PODHistoryMeta,
  PODs,
  PODTrackerDriver,
  PODTrackerDrivers,
} from '@/domain/entities/PODTracker'
import { OriginRoute } from '@/domain/entities/Route'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class PODTrackerMapper extends BaseResponseMapper {
  public convertDriversFromApi(result: AxiosResponse<any>): PODTrackerDrivers {
    const {
      data: { data, pagination },
    } = result
    return new PODTrackerDrivers(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (
          driver: Record<
            string,
            string | number | string[] | Record<string, string>
          >
        ) => {
          const bank = driver.bank as {
            bank_id: string
            bank_name: string
            bank_account: string
          }
          return new PODTrackerDriver(
            Number(driver.courier_id),
            driver.role as string,
            driver.fullname as string,
            driver.phone_number as string,
            driver.vehicle_type as string,
            driver.vehicle_plate as string,
            driver.partner_type as string,
            driver.partner_name as string,
            driver.origin as string,
            driver.picture as string,
            Number(driver.agent_id),
            Number(driver.score),
            driver.announcement_courier_type as string,
            driver.created_at as string,
            driver.courier_type as string,
            driver.courier_status as string,
            new DriverBank(bank.bank_id, bank.bank_name, bank.bank_account)
          )
        }
      )
    )
  }

  public convertOriginsFromApi(result: AxiosResponse<any>): OriginRoute[] {
    const {
      data: { data },
    } = result

    return data.map(
      (route: Record<string, string | boolean | number>) =>
        new OriginRoute(
          route.route_id as number,
          route.lc as string,
          route.city as string,
          route.route as string,
          route.is_origin as boolean,
          route.is_pickup as boolean,
          route.is_shop as boolean
        )
    )
  }

  public convertPODsFromApi(result: AxiosResponse<any>): PODs {
    const {
      data: { data, pagination, ...driver },
    } = result
    return new PODs(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (
          pod: Record<
            string,
            | string
            | number
            | string[]
            | Record<string, string | number>
            | boolean
          >
        ) => {
          const podDestination = pod.pod_destination as {
            address: string
            latitude: number
            longitude: number
          }

          const podActual = pod.pod_actual as {
            latitude: number
            longitude: number
          }

          return new POD(
            pod.package_id as string,
            Number(pod.courier_id),
            pod.courier_name as string,
            pod.pod_date as string,
            new PODDestination(
              podDestination.address,
              podDestination.latitude,
              podDestination.longitude
            ),
            new Geolocation(podActual.latitude, podActual.longitude),
            pod.is_valid_pod as boolean
          )
        }
      ),
      driver.courier_id,
      driver.courier_name,
      driver.phone_number,
      driver.vehicle_type,
      driver.vehicle_plate,
      driver.partner_type,
      driver.partner_name,
      driver.origin,
      driver.courier_type_valid
    )
  }

  public convertPODDetailFromApi(result: AxiosResponse<any>): PODDetail {
    const { data } = result

    return new PODDetail(
      data.courier_id,
      data.courier_name,
      data.phone_number,
      data.vehicle_type,
      data.vehicle_plate,
      data.partner_type,
      data.partner_name,
      data.origin,
      data.courier_type_valid,
      new PODDetailData(
        data.detail_pod.package_id,
        data.detail_pod.pod_date,
        new PODDestination(
          data.detail_pod.pod_destination.address,
          data.detail_pod.pod_destination.latitude,
          data.detail_pod.pod_destination.longitude
        ),
        new Geolocation(
          data.detail_pod.pod_actual.latitude,
          data.detail_pod.pod_actual.longitude
        ),
        data.detail_pod.is_valid_pod,
        new PODHistory(
          data.detail_pod.history.history_id,
          data.detail_pod.history.history_type,
          data.detail_pod.history.location,
          data.detail_pod.history.city,
          data.detail_pod.history.status_id,
          data.detail_pod.history.status,
          data.detail_pod.history.long_status,
          data.detail_pod.history.actor_id,
          data.detail_pod.history.actor_role,
          data.detail_pod.history.actor_name,
          data.detail_pod.history.reason_id,
          data.detail_pod.history.reason,
          data.detail_pod.history.relation_id,
          data.detail_pod.history.relation,
          data.detail_pod.history.person_name,
          data.detail_pod.history.note,
          new PODHistoryMeta(
            data.detail_pod.history.meta.proofs,
            data.detail_pod.history.meta.quantity_confirm,
            data.detail_pod.history.meta.quantity_original,
            data.detail_pod.history.meta.signature
          ),
          data.detail_pod.history.created_at
        )
      )
    )
  }
}
