import { injectable } from 'tsyringe'
import {
  CourierDedicatedList,
  GeoAddress,
  ManageCourierDedicatedDetail,
  ManageCourierDedicateds,
} from '@/domain/entities/ManageCourierDedicated'
import { ManageCourierDedicatedRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageCourierDedicatedRepositoryInterface'
import {
  CreateManageCourierDedicatedRequestInterface,
  UpdateManageCourierDedicatedRequestInterface,
} from '@/data/payload/contracts/ManageCourierDedicatedRequest'

@injectable()
export class ManageCourierDedicatedPresenter {
  private repository: ManageCourierDedicatedRepositoryInterface

  constructor(repository: ManageCourierDedicatedRepositoryInterface) {
    this.repository = repository
  }

  public getAll(
    params: Map<string, string | number>
  ): Promise<ManageCourierDedicateds> {
    return this.repository.getAll(params)
  }

  public getAllCourierDedicated(
    params: Map<string, string | number>
  ): Promise<CourierDedicatedList> {
    return this.repository.getAllCourierDedicated(params)
  }

  public getGeoAddress(
    params: Map<string, string>
  ): Promise<Array<GeoAddress>> {
    return this.repository.getGeoAddress(params)
  }

  public delete(id: number): Promise<boolean> {
    return this.repository.delete(id)
  }

  public get(id: number): Promise<ManageCourierDedicatedDetail> {
    return this.repository.get(id)
  }

  public create(
    payload: CreateManageCourierDedicatedRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public getMaxConfig(): Promise<number> {
    return this.repository.getMaxConfig()
  }

  public update(
    id: number,
    payload: UpdateManageCourierDedicatedRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }
}
