import { Pagination } from '@/domain/entities/Pagination'

export class ProgrammaticVoucher {
  public id?: number
  public voucherName?: string
  public voucherTarget?: string
  public selectedUser?: string
  public selectedUserUrl?: string
  public selectedUserFileName?: string
  public os?: string
  public osValidation?: string
  public osValidationVersion?: string
  public routeIds?: string[]
  public membershipLevel?: string
  public voucherTrigger?: string
  public scheduledAt?: string
  public membershipLevelUp?: string
  public limitPerDay?: number
  public triggerInvoiceType?: string
  public voucherPurposeParent?: string
  public voucherPurposeChild?: string
  public pointAmount?: number
  public pointExpiry?: number
  public pointPurpose?: string
  public discountAmount?: number
  public discountMinimum?: number
  public discountPercentage?: number
  public discountPercentageMinimum?: number
  public discountPercentageMaximum?: number
  public cashbackPercentage?: number
  public cashbackPercentageMinimum?: number
  public cashbackPercentageMaximum?: number
  public voucherPurposeInvoiceType?: string
  public expiryDay?: number
  public limitUsage?: number
  public startDate?: string
  public endDate?: string
  public createdAt?: string
  public updatedAt?: string
  public createdBy?: string
  public updatedBy?: string
  public deletedAt?: string
  public status?: string
  public budgetAmount?: number | null
  public budgetUsed?: number | null

  constructor(
    id?: number,
    voucherName?: string,
    voucherTarget?: string,
    selectedUser?: string,
    selectedUserUrl?: string,
    selectedUserFileName?: string,
    os?: string,
    osValidation?: string,
    osValidationVersion?: string,
    routeIds?: string[],
    membershipLevel?: string,
    voucherTrigger?: string,
    scheduledAt?: string,
    membershipLevelUp?: string,
    limitPerDay?: number,
    triggerInvoiceType?: string,
    voucherPurposeParent?: string,
    voucherPurposeChild?: string,
    pointAmount?: number,
    pointExpiry?: number,
    pointPurpose?: string,
    discountAmount?: number,
    discountMinimum?: number,
    discountPercentage?: number,
    discountPercentageMinimum?: number,
    discountPercentageMaximum?: number,
    cashbackPercentage?: number,
    cashbackPercentageMinimum?: number,
    cashbackPercentageMaximum?: number,
    voucherPurposeInvoiceType?: string,
    expiryDay?: number,
    limitUsage?: number,
    startDate?: string,
    endDate?: string,
    createdAt?: string,
    updatedAt?: string,
    createdBy?: string,
    updatedBy?: string,
    deletedAt?: string,
    status?: string,
    budgetAmount?: number | null,
    budgetUsed?: number | null
  ) {
    this.id = id
    this.voucherName = voucherName
    this.voucherTarget = voucherTarget
    this.selectedUser = selectedUser
    this.selectedUserUrl = selectedUserUrl
    this.selectedUserFileName = selectedUserFileName
    this.os = os
    this.osValidation = osValidation
    this.osValidationVersion = osValidationVersion
    this.routeIds = routeIds
    this.membershipLevel = membershipLevel
    this.voucherTrigger = voucherTrigger
    this.scheduledAt = scheduledAt
    this.membershipLevelUp = membershipLevelUp
    this.limitPerDay = limitPerDay
    this.triggerInvoiceType = triggerInvoiceType
    this.voucherPurposeParent = voucherPurposeParent
    this.voucherPurposeChild = voucherPurposeChild
    this.pointAmount = pointAmount
    this.pointExpiry = pointExpiry
    this.pointPurpose = pointPurpose
    this.discountAmount = discountAmount
    this.discountMinimum = discountMinimum
    this.discountPercentage = discountPercentage
    this.discountPercentageMinimum = discountPercentageMinimum
    this.discountPercentageMaximum = discountPercentageMaximum
    this.cashbackPercentage = cashbackPercentage
    this.cashbackPercentageMinimum = cashbackPercentageMinimum
    this.cashbackPercentageMaximum = cashbackPercentageMaximum
    this.voucherPurposeInvoiceType = voucherPurposeInvoiceType
    this.expiryDay = expiryDay
    this.limitUsage = limitUsage
    this.startDate = startDate
    this.endDate = endDate
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.deletedAt = deletedAt
    this.status = status
    this.budgetAmount = budgetAmount
    this.budgetUsed = budgetUsed
  }
}

export class ProgrammaticVouchers {
  public pagination?: Pagination
  public data?: ProgrammaticVoucher[]

  constructor(pagination?: Pagination, data?: ProgrammaticVoucher[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ProgrammaticVoucherHistory {
  public id?: number
  public name?: string
  public userType?: string
  public userTypeDetail?: string
  public customerId?: number
  public customerName?: string
  public customerEmail?: string
  public city?: string
  public voucherPurpose?: string
  public initialAmount?: number
  public voucherCreatedAt?: string
  public voucherUsedAt?: string
  public spentAmount?: number
  public voucherPercentage?: number // specific for type payment voucher
  public voucherAmount?: number // specific for type payment voucher
  public voucherUsed?: number // specific for type payment voucher
  public limitUsage?: number
  public voucherID?: number
  public voucherMaximumAmount?: number

  constructor(
    id?: number,
    name?: string,
    userType?: string,
    userTypeDetail?: string,
    customerId?: number,
    customerName?: string,
    customerEmail?: string,
    city?: string,
    voucherPurpose?: string,
    initialAmount?: number,
    voucherCreatedAt?: string,
    voucherUsedAt?: string,
    spentAmount?: number,
    voucherPercentage?: number,
    voucherAmount?: number,
    voucherUsed?: number,
    limitUsage?: number,
    voucherID?: number,
    voucherMaximumAmount?: number
  ) {
    this.id = id
    this.name = name
    this.userType = userType
    this.userTypeDetail = userTypeDetail
    this.customerId = customerId
    this.customerName = customerName
    this.customerEmail = customerEmail
    this.city = city
    this.voucherPurpose = voucherPurpose
    this.initialAmount = initialAmount
    this.voucherCreatedAt = voucherCreatedAt
    this.voucherUsedAt = voucherUsedAt
    this.spentAmount = spentAmount
    this.voucherPercentage = voucherPercentage
    this.voucherAmount = voucherAmount
    this.voucherUsed = voucherUsed
    this.limitUsage = limitUsage
    this.voucherID = voucherID
    this.voucherMaximumAmount = voucherMaximumAmount
  }
}

export class ProgrammaticVoucherHistories {
  public pagination?: Pagination
  public data?: ProgrammaticVoucherHistory[]

  constructor(pagination?: Pagination, data?: ProgrammaticVoucherHistory[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class VoucherDistribution {
  public id?: number
  public itemId?: number
  public name?: string
  public customerId?: number
  public customerName?: string
  public customerEmail?: string
  public city?: string
  public voucherId?: number
  public voucherPurpose?: string
  public voucherAmount?: number
  public voucherPercentage?: number
  public totalUsage?: number
  public limitUsage?: string

  constructor(
    id?: number,
    itemId?: number,
    name?: string,
    customerId?: number,
    customerName?: string,
    customerEmail?: string,
    city?: string,
    voucherId?: number,
    voucherPurpose?: string,
    voucherAmount?: number,
    voucherPercentage?: number,
    totalUsage?: number,
    limitUsage?: string
  ) {
    this.id = id
    this.itemId = itemId
    this.name = name
    this.customerId = customerId
    this.customerName = customerName
    this.customerEmail = customerEmail
    this.city = city
    this.voucherId = voucherId
    this.voucherPurpose = voucherPurpose
    this.voucherAmount = voucherAmount
    this.voucherPercentage = voucherPercentage
    this.totalUsage = totalUsage
    this.limitUsage = limitUsage
  }
}

export class VoucherDistributions {
  public pagination?: Pagination
  public data?: VoucherDistribution[]

  constructor(pagination?: Pagination, data?: VoucherDistribution[]) {
    this.pagination = pagination
    this.data = data
  }
}
