import {
  CreateFAQRequest,
  ReorderStarQuestionRequest,
  UpdateFAQCategoryOrderRequest,
  UpdateFAQCategoryStatusRequest,
  UpdateFAQDraftStatusRequest,
  UpdateFAQRequest,
} from '@/data/payload/api/FAQRequest'
import {
  CreateFAQRequestInterface,
  UpdateFAQRequestInterface,
  UpdateFAQCategoryOrderRequestInterface,
  UpdateFAQCategoryStatusRequestInterface,
  UpdateFAQDraftStatusRequestInterface,
  UploadFAQCategoryImageRequestInterface,
  ReorderStarQuestionRequestInterface,
} from '@/data/payload/contracts/FAQRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { FAQRepositoryInterface } from '@/data/persistences/repositories/contracts/FAQRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { FAQMapper } from '@/data/persistences/mappers/FAQMapper'
import { FAQ, FAQs, StarQuestion } from '@/domain/entities/FAQ'

export class FAQApiRepository implements FAQRepositoryInterface {
  private service: ApiService
  private mapper: FAQMapper

  constructor(service: ApiService, mapper: FAQMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(faqCategoryId: string): Promise<FAQ> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.FAQ}/${faqCategoryId}`
    )
    return this.mapper.convertFAQFromApi(resp)
  }

  public async getAll(params: Map<string, any>): Promise<FAQs> {
    const resp = await this.service.invoke('get', Endpoints.Route.FAQ, params)
    return this.mapper.convertFAQsFromApi(resp)
  }

  public async getListQuestion(): Promise<StarQuestion[]> {
    const resp = await this.service.invoke('get', Endpoints.Route.FAQ_STARRED_QUESTION)
    return this.mapper.convertListQuestionFromApi(resp)
  }

  public async create(payload: CreateFAQRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.FAQ,
      undefined,
      payload as CreateFAQRequest
    )
    return true
  }

  public async update(
    faqCategoryId: string,
    payload: UpdateFAQRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.FAQ}/${faqCategoryId}`,
      undefined,
      payload as UpdateFAQRequest
    )
    return true
  }

  public async updateCategoryOrder(
    faqCategoryId: string,
    payload: UpdateFAQCategoryOrderRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.FAQ}/${faqCategoryId}/reorder`,
      undefined,
      payload as UpdateFAQCategoryOrderRequest
    )
    return true
  }

  public async updateCategoryStatus(
    faqCategoryId: string,
    payload: UpdateFAQCategoryStatusRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.FAQ}/${faqCategoryId}/status`,
      undefined,
      payload as UpdateFAQCategoryStatusRequest
    )
    return true
  }

  public async updateFAQDraftStatus(
    faqCategoryId: string,
    payload: UpdateFAQDraftStatusRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.FAQ}/${faqCategoryId}/draft-status`,
      undefined,
      payload as UpdateFAQDraftStatusRequest
    )
    return true
  }

  public async deleteFAQ(
    faqCategoryId: string,
    params: Map<string, any>
  ): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.FAQ}/${faqCategoryId}`,
      params
    )
    return true
  }

  public async deleteSubCategory(
    faqCategoryId: number
  ): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.FAQ_SUB_CATEGORY}/${faqCategoryId}`
    )
    return true
  }

  public async postImageCategory(
    payload: UploadFAQCategoryImageRequestInterface
  ): Promise<string> {
    const res = await this.service.invoke(
      'post',
      Endpoints.Route.FAQ_IMAGE_CATEGORY,
      undefined,
      payload
    )

    return res.data.filename
  }

  public async starredQuestion(
    idQuestion: number,
    action: string
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.FAQ_STARRED_QUESTION}/${idQuestion}?action=${action}`
    )
    return true
  }

  public async reorderStarQuestion(
    payload: ReorderStarQuestionRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.FAQ_STARRED_QUESTION}/reorder`,
      undefined,
      payload as ReorderStarQuestionRequest
    )
    return true
  }
}
