import { Utils } from '@/data/infrastructures/misc/Utils'
import { Pagination } from '@/domain/entities/Pagination'
import {
  PushNotifications,
  PushNotification,
  PushNotificationProgramMembership,
  NotificationData,
  NotificationOptionProgramMembership,
} from '@/domain/entities/PN'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'
import { LoyaltyMembershipLevelInterface } from '@/data/responses/contracts/LoyaltyProgramMembership'

export class PNMapper extends BaseResponseMapper {
  public convertPushNotificationsFromApi(
    result: AxiosResponse
  ): PushNotifications {
    const {
      data: { pagination, data },
    } = result
    return new PushNotifications(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (pn: Record<string, any>) =>
          new PushNotification(
            pn.notification_id,
            pn.notification_title,
            undefined,
            undefined,
            pn.published_at,
            undefined,
            undefined,
            undefined,
            pn.notification_status,
            pn.notification_target,
            pn.city_residence ? pn.city_residence.split(',') : [],
            undefined,
            undefined,
            pn.specific_platform_os,
            pn.specific_platform_version_validation,
            pn.specific_platform_version,
            pn.created_at,
            pn.gif_url,
            pn.notification_category
          )
      )
    )
  }

  public convertPushNotificationFromApi(
    result: AxiosResponse
  ): PushNotification {
    const { data } = result

    const notificationDataValue = data.notification_data || {}
    const membershipLabel = notificationDataValue.membership_levels || []

    let notificationData: NotificationData | undefined = new NotificationData(
      membershipLabel.map(
        (val: { id: number; level_name: string; selected: boolean }) => {
          return new NotificationOptionProgramMembership(
            val.id,
            val.level_name,
            val.selected
          )
        }
      )
    )

    if (!data.notification_data) {
      notificationData = undefined
    }

    return new PushNotification(
      data.notification_id,
      data.notification_title,
      data.notification_wording,
      data.notification_content,
      data.published_at,
      data.image_url,
      data.target_url,
      data.cta_name,
      data.notification_status,
      data.notification_target,
      data.city_residence ? data.city_residence.split(',') : [],
      data.selected_user,
      data.membership_level,
      data.specific_platform_os,
      data.specific_platform_version_validation,
      data.specific_platform_version,
      data.created_at,
      data.gif_url,
      data.notification_category,
      notificationData
    )
  }

  public convertProgramActiveMembershipFromApi(
    result: AxiosResponse
  ): PushNotificationProgramMembership[] {
    const {
      data: { data },
    } = result
    return data.map((val: LoyaltyMembershipLevelInterface) => {
      return new PushNotificationProgramMembership(
        val.level_name,
        val.level_id || -1
      )
    })
  }
}
