import ApiService from '@/app/infrastructures/services/ApiService'
import { OrderRepositoryInterface } from '@/data/persistences/repositories/contracts/OrderRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { OrderMapper } from '@/data/persistences/mappers/OrderMapper'
import { Orders, OrderDetail } from '@/domain/entities/Order'
import { CancelOrderRequestInterface } from '@/data/payload/contracts/OrderRequest'

export class OrderApiRepository implements OrderRepositoryInterface {
  private service: ApiService
  private mapper: OrderMapper

  constructor(service: ApiService, mapper: OrderMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(shipmentId: string): Promise<OrderDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ORDER_MANAGEMENT}/${shipmentId}`
    )
    return this.mapper.convertOrderFromApi(resp)
  }

  public async getAll(
    params: Map<string, string | number | undefined>
  ): Promise<Orders> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ORDER_MANAGEMENT}`,
      params
    )
    return this.mapper.convertOrdersFromApi(resp)
  }

  public async cancelOrder(
    payload: CancelOrderRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.CANCEL_ORDER,
      undefined,
      payload
    )

    return true
  }

  public async exportOrder(
    params: Map<string, string | number | undefined>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ORDER_MANAGEMENT}/export`,
      params
    )

    return resp.data.url
  }
}
