import { Pagination } from '@/domain/entities/Pagination'

export class PickupRadiusValidation {
  public id?: number
  public shipmentId?: string
  public courierId?: number
  public courierName?: string
  public courierPhoneNumber?: string
  public courierTypeValid?: string
  public courierLetterCode?: string
  public createdAt?: string
  public statusPickup?: string

  constructor(
    id?: number,
    shipmentId?: string,
    courierId?: number,
    courierName?: string,
    courierPhoneNumber?: string,
    courierTypeValid?: string,
    courierLetterCode?: string,
    createdAt?: string,
    statusPickup?: string
  ) {
    this.id = id
    this.shipmentId = shipmentId
    this.courierId = courierId
    this.courierName = courierName
    this.courierPhoneNumber = courierPhoneNumber
    this.courierTypeValid = courierTypeValid
    this.courierLetterCode = courierLetterCode
    this.createdAt = createdAt
    this.statusPickup = statusPickup
  }
}

export class PickupRadiusValidationDetail {
  public courierName?: string
  public courierTypeValid?: string
  public courierPhoneNumber?: string
  public courierLetterCode?: string
  public shipmentId?: string
  public createdAt?: string
  public actualPickupLocation?: string
  public pickupLocation?: string
  public pickupRadius?: number
  public proofImages?: string[]
  public isPickupValid?: number
  public address?: string
  public description?: string

  constructor(
    courierName?: string,
    courierTypeValid?: string,
    courierPhoneNumber?: string,
    courierLetterCode?: string,
    shipmentId?: string,
    createdAt?: string,
    actualPickupLocation?: string,
    pickupLocation?: string,
    pickupRadius?: number,
    proofImages?: string[],
    isPickupValid?: number,
    address?: string,
    description?: string
  ) {
    this.courierName = courierName
    this.courierTypeValid = courierTypeValid
    this.courierPhoneNumber = courierPhoneNumber
    this.courierLetterCode = courierLetterCode
    this.shipmentId = shipmentId
    this.createdAt = createdAt
    this.actualPickupLocation = actualPickupLocation
    this.pickupLocation = pickupLocation
    this.pickupRadius = pickupRadius
    this.proofImages = proofImages
    this.isPickupValid = isPickupValid
    this.address = address
    this.description = description
  }
}

export class HistoryLog {
  public updatedBy?: string
  public updatedDate?: string

  constructor(updatedBy?: string, updatedDate?: string) {
    this.updatedBy = updatedBy
    this.updatedDate = updatedDate
  }
}

export class Summary {
  public fraud?: number
  public needValidation?: number
  public valid?: number

  constructor(fraud?: number, needValidation?: number, valid?: number) {
    this.fraud = fraud
    this.needValidation = needValidation
    this.valid = valid
  }
}

export class PickupRadiusValidations {
  public pagination: Pagination
  public data: PickupRadiusValidation[]
  public summary: Summary

  constructor(
    pagination: Pagination,
    data: PickupRadiusValidation[],
    summary: Summary
  ) {
    this.pagination = pagination
    this.data = data
    this.summary = summary
  }
}

export class HistoryLogs {
  public pagination: Pagination
  public data: HistoryLog[]

  constructor(pagination: Pagination, data: HistoryLog[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PickupRadiusDetail {
  public pickupValidationRadius: PickupRadiusValidationDetail
  public historyLogs: HistoryLogs

  constructor(
    pickupValidationRadius: PickupRadiusValidationDetail,
    historyLogs: HistoryLogs
  ) {
    this.pickupValidationRadius = pickupValidationRadius
    this.historyLogs = historyLogs
  }
}
