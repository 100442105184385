import { Pagination } from '@/domain/entities/Pagination'

export class PickupLocation {
  public routeId?: number
  public city?: string
  public subDistrict?: string
  public status?: 'active' | 'not_active'
  public updatedAt?: string
  public updatedBy?: string

  constructor(
    routeId?: number,
    city?: string,
    subDistrict?: string,
    status?: 'active' | 'not_active',
    updatedAt?: string,
    updatedBy?: string
  ) {
    this.routeId = routeId
    this.city = city
    this.subDistrict = subDistrict
    this.status = status
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
  }
}

export class PickupLocations {
  public pagination: Pagination
  public data: Array<PickupLocation>

  constructor(pagination: Pagination, data: Array<PickupLocation>) {
    this.pagination = pagination
    this.data = data
  }
}
