import { CancelValidationApiRepository } from '@/app/infrastructures/repositories/api/CancelValidationApiRepository'
import { UpdateStatusValidationRequest } from '@/data/payload/api/CancelValidationRequest'
import { CancelValidationRepositoryInterface } from '@/data/persistences/repositories/contracts/CancelValidationRepositoryInterface'
import {
  CancelDetail,
  Cancels,
  CourierShipmentCancels,
} from '@/domain/entities/CancelValidation'

export class CancelValidationPresenter
  implements CancelValidationRepositoryInterface {
  private repository: CancelValidationApiRepository

  constructor(repository: CancelValidationApiRepository) {
    this.repository = repository
  }
  getCancelList(params: Map<string, string | number>): Promise<Cancels> {
    return this.repository.getCancelList(params)
  }
  getCourierShipmentCancelList(
    courierId: string,
    params: Map<string, string | number>
  ): Promise<CourierShipmentCancels> {
    return this.repository.getCourierShipmentCancelList(courierId, params)
  }
  getCancelDetail(
    historyId: string,
    params: Map<string, string | number>
  ): Promise<CancelDetail> {
    return this.repository.getCancelDetail(historyId, params)
  }
  updateStatusValidation(
    historyId: string,
    payload: UpdateStatusValidationRequest
  ): Promise<boolean> {
    return this.repository.updateStatusValidation(historyId, payload)
  }

  exportCancelList(params: Map<string, string | number>): Promise<string> {
    return this.repository.exportCancelList(params)
  }

  exportCourierShipmentCancelList(
    courierId: number,
    params: Map<string, string | number>
  ): Promise<string> {
    return (
      this, this.repository.exportCourierShipmentCancelList(courierId, params)
    )
  }
}
