import {
  CreateCategorySectionRequest,
  UpdateCategorySectionRequest,
} from '@/data/payload/api/CategorySectionRequest'
import {
  CreateCategorySectionRequestInterface,
  RepositionCategorySectionRequestInterface,
  UpdateCategorySectionRequestInterface,
} from '@/data/payload/contracts/CategorySectionRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { CategorySectionRepositoryInterface } from '@/data/persistences/repositories/contracts/CategorySectionRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { CategorySectionMapper } from '@/data/persistences/mappers/CategorySectionMapper'
import { CategorySection } from '@/domain/entities/CategorySection'
import { ProductDropdown } from '@/domain/entities/Product'

export class CategorySectionApiRepository
  implements CategorySectionRepositoryInterface {
  private service: ApiService
  private mapper: CategorySectionMapper

  constructor(service: ApiService, mapper: CategorySectionMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(categorySectionId: number): Promise<CategorySection> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.CATEGORY_SECTION}/${categorySectionId}`
    )
    return this.mapper.convertCategorySectionFromApi(resp)
  }

  public async getAll(): Promise<CategorySection[]> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.CATEGORY_SECTION
    )
    return this.mapper.convertCategorySectionsFromApi(resp)
  }

  public async create(
    payload: CreateCategorySectionRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.CATEGORY_SECTION,
      undefined,
      payload as CreateCategorySectionRequest
    )

    return true
  }

  public async update(
    categorySectionId: number,
    payload: UpdateCategorySectionRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.CATEGORY_SECTION}/${categorySectionId}`,
      undefined,
      payload as UpdateCategorySectionRequest
    )
    return true
  }

  public async delete(categorySectionId: number): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.CATEGORY_SECTION}/${categorySectionId}`
    )
    return true
  }

  public async getProducts(
    params: Map<string, string | number>
  ): Promise<ProductDropdown[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT}/dropdown`,
      params
    )
    return this.mapper.convertProductDropdownsFromApi(resp)
  }

  public async reposition(
    payload: RepositionCategorySectionRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'patch',
      `${Endpoints.Route.CATEGORY_SECTION}/reposition`,
      undefined,
      payload
    )

    return true
  }
}
