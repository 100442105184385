import { RequestApprovalAdjustmentInterface } from "@/data/payload/contracts/ApprovalAdjustSaldoRequest";
import { ApprovalAdjustSaldoInterface } from "@/data/persistences/repositories/contracts/ApprovalAdjustSaldoRepositoryInterface";
import { ApprovalAdjustSaldoDetail, ApprovalAdjustSaldoList } from "@/domain/entities/ApprovalAdjustSaldo";
import { injectable } from "tsyringe";

@injectable()
export class ApprovalAdjustSaldoPresenter {
  private repository: ApprovalAdjustSaldoInterface

  constructor(repository: ApprovalAdjustSaldoInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number | Date>): Promise<ApprovalAdjustSaldoList> {
    return this.repository.getAll(params)
  }

  public get(params: Map<string, string | number>): Promise<ApprovalAdjustSaldoDetail> {
    return this.repository.get(params)
  }

  public requestAdjustmentApproval(payload: RequestApprovalAdjustmentInterface): Promise<boolean> {
    return this.repository.requestAdjustmentApproval(payload)
  }
}
