import { AxiosResponse } from 'axios'
import { Merchant, Merchants, MerchantDetail } from '@/domain/entities/Merchant'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'

export class MerchantMapper extends BaseResponseMapper {
  public convertMerchantFromApi(result: AxiosResponse<any>): MerchantDetail {
    const { data } = result
    return new MerchantDetail(
      data.merchant_id,
      data.merchant_name,
      { value: data.brand_id, label: data.brand_name },
      { value: data.city, label: data.city },
      { value: data.district, label: data.district },
      data.description,
      data.address,
      data.latitude,
      data.longitude,
      data.image_banner_url,
      data.bank_name,
      data.bank_account_name,
      data.bank_account_number,
      data.name,
      data.email,
      data.phone_number,
      data.created_at,
      data.status
    )
  }

  public convertMerchantsFromApi(result: AxiosResponse<any>): Merchants {
    const {
      data: { data, pagination },
    } = result
    return new Merchants(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (merchant: Record<string, string | number>) =>
          new Merchant(
            merchant.merchant_id as number,
            merchant.merchant_name as string,
            merchant.brand_name as string,
            merchant.description as string,
            merchant.city as string,
            merchant.created_at as string,
            merchant.status as string
          )
      )
    )
  }
}
