import { SelectedMerchantRepositoryInterface } from '@/data/persistences/repositories/contracts/SelectedMerchantRepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import { SelectedMerchantMapper } from '@/data/persistences/mappers/SelectedMerchantMapper'
import { SelectedMerchant } from '@/domain/entities/SelectedMerchant'
import { SaveSelectedMerchantRequestInterface } from '@/data/payload/contracts/SelectedMerchantRequest'
import { Endpoints } from '@/app/infrastructures/misc'
import { MerchantDropdown } from '@/domain/entities/Merchant'

export class SelectedMerchantApiRepository
  implements SelectedMerchantRepositoryInterface {
  private service: ApiService
  private mapper: SelectedMerchantMapper

  constructor(service: ApiService, mapper: SelectedMerchantMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getAll(): Promise<SelectedMerchant[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.SELECTED_MERCHANT}`
    )
    return this.mapper.convertSelectedMerchantFromApi(resp)
  }

  async getAllDropdown(): Promise<MerchantDropdown[]> {
    // const tempEndpoint = 'https://private-e9741-shop78.apiary-mock.com/v1/shop/merchant/drop-down'
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MERCHANT}/drop-down`,
      undefined,
      undefined
    )
    return this.mapper.convertMerchantsDropdownFromApi(resp)
  }

  async create(
    payload: SaveSelectedMerchantRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      Endpoints.Route.SELECTED_MERCHANT,
      undefined,
      payload as SaveSelectedMerchantRequestInterface
    )
    return true
  }
}
