import { ShuttleApiRepository } from '@/app/infrastructures/repositories/api/ShuttleApiRepository'
import { CreateShuttleRequest } from '@/data/payload/api/ShuttleManagementRequest'
import { ShuttleRepositoryInterface } from '@/data/persistences/repositories/contracts/ShuttleRepositoryInterface'
import { CourierShuttles, ShuttleDetail, Shuttles } from '@/domain/entities/Shuttle'
import { injectable } from 'tsyringe'

@injectable()
export class ShuttlePresenter implements ShuttleRepositoryInterface {
  private repository: ShuttleApiRepository

  constructor(repository: ShuttleApiRepository) {
    this.repository = repository
  }

  getAll(params: Map<string, string | number>): Promise<Shuttles> {
    return this.repository.getAll(params)
  }

  create(payload: CreateShuttleRequest): Promise<boolean> {
    return this.repository.create(payload)
  }

  get(shuttleId: string): Promise<ShuttleDetail> {
    return this.repository.get(shuttleId)
  }

  update(payload: CreateShuttleRequest, shuttleId: string): Promise<boolean> {
    return this.repository.update(payload, shuttleId)
  }

  delete(shuttleId: string): Promise<boolean> {
    return this.repository.delete(shuttleId)
  }

  getAllCourierShuttle(params: Map<string, string | number>): Promise<CourierShuttles> {
    return this.repository.getAllCourierShuttle(params)
  }
}
