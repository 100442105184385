import ApiService from '@/app/infrastructures/services/ApiService'
import { RequestTopupSaldoRepositoryInterface } from '@/data/persistences/repositories/contracts/RequestTopupSaldoRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { RequestTopupSaldoMapper } from '@/data/persistences/mappers/RequestTopupSaldoMapper'
import {
  RequestTopupSaldoHistoryList,
  RequestTopupSaldoList,
  DetailTopupSaldoForm,
  UploadBulky,
  HistoryUploadBulky,
} from '@/domain/entities/RequestTopupSaldo'
import { RequestTopupRequest } from '@/data/payload/api/AdjustmentRequest'
import {
  ApproveRejectBulkyRequestInterface,
  ApprovingTopupRequestInterface,
  RequestTopupRequestInterface,
} from '@/data/payload/contracts/AdjustmentRequest'
import { UploadRequest } from '@/data/payload/api/UploadRequest'
import { CancelTokenSource } from 'axios'

export class RequestTopupSaldoApiRepository
  implements RequestTopupSaldoRepositoryInterface {
  private service: ApiService
  private mapper: RequestTopupSaldoMapper

  constructor(service: ApiService, mapper: RequestTopupSaldoMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(
    params: Map<string, string>
  ): Promise<RequestTopupSaldoList> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADJUSTMENT}/list`,
      params
    )
    return this.mapper.convertRequestTopupSaldoListFromApi(resp)
  }

  public async getHistory(
    params: Map<string, string>
  ): Promise<RequestTopupSaldoHistoryList> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADJUSTMENT}/topup/list`,
      params
    )
    return this.mapper.convertRequestTopupSaldoHistoryFromApi(resp)
  }

  public async getDetailTopup(
    balanceId: number
  ): Promise<DetailTopupSaldoForm> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADJUSTMENT}/topup/${balanceId}`
    )
    return this.mapper.convertDetailTopupSaldoFormFromApi(resp)
  }

  public async postRequestTopup(
    payload: RequestTopupRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.ADJUSTMENT}/topup`,
      undefined,
      <RequestTopupRequest>payload
    )
    return true
  }

  public async postApprovingTopup(
    payload: ApprovingTopupRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.ADJUSTMENT}/topup/status`,
      undefined,
      <RequestTopupRequest>payload
    )
    return true
  }

  public async postApproveRejectBulky(payload: ApproveRejectBulkyRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.ADJUSTMENT}/bulk-topup/update-status`,
      undefined,
      <ApproveRejectBulkyRequestInterface>payload
    )

    return true
  }

  public async uploadBulkyRequestTopUp(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    const customService = new ApiService(Endpoints.baseUrl, 200000, cancelToken)

    await customService.invoke(
      'POST',
      `${Endpoints.Route.ADJUSTMENT}/bulk-topup/upload`,
      undefined,
      file
    )

    return true
  }

  public async previewUploadBulky(
    params: Map<string, string>
  ): Promise<UploadBulky> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.ADJUSTMENT}/bulk-topup/preview`,
      params
    )
    return this.mapper.convertUploadBulkyFromApi(resp)
  }

  public async processBulkyRequestTopUp(): Promise<boolean> {
    const customService = new ApiService(Endpoints.baseUrl, 300000)

    await customService.invoke(
      'POST',
      `${Endpoints.Route.ADJUSTMENT}/bulk-topup/process`
    )

    return true
  }

  public async getHistoryUploadBulky(params: Map<string, string>): Promise<HistoryUploadBulky> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.ADJUSTMENT}/bulk-topup/history`,
      params,
    )

    return this.mapper.convertHistoryUploadBulky(resp)
  }
}
