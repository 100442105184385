export class BaseResponse {
  public success: boolean

  constructor(success: boolean) {
    this.success = success
  }
}

export class ErrorResponse {
  public errorId?: string
  public message?: ErrorMessage

  constructor(errorId?: string, message?: ErrorMessage) {
    this.errorId = errorId
    this.message = message
  }
}

export class ErrorMessage {
  public en?: string
  public id?: string

  constructor(en?: string, id?: string) {
    this.en = en
    this.id = id
  }
}
