import { DependencyContainer } from 'tsyringe'
import { AuthApiRepository } from '@/app/infrastructures/repositories/api/AuthApiRepository'
import { ProgramaticPointApiRepository } from '@/app/infrastructures/repositories/api/ProgramaticPointApiRepository'
import { AuthPresenter } from '@/app/ui/presenters/AuthPresenter'
import { ProgramaticPointPresenter } from '@/app/ui/presenters/ProgramaticPointPresenter'
import { PointPurposeApiRepository } from '@/app/infrastructures/repositories/api/PointPurposeApiRepository'
import { BannerApiRepository } from '@/app/infrastructures/repositories/api/BannerApiRepository'
import { BannerPresenter } from '@/app/ui/presenters/BannerPresenter'
import { POSApiRepository } from '@/app/infrastructures/repositories/api/POSApiRepository'
import { CourierApiRepository } from '@/app/infrastructures/repositories/api/CourierApiRepository'
import { POSPresenter } from '@/app/ui/presenters/POSPresenter'
import { SaldoPaymentPresenter } from '@/app/ui/presenters/SaldoPaymentPresenter'
import { SaldoPaymentApiRepository } from '@/app/infrastructures/repositories/api/SaldoPaymentApiRepository'
import { UserManagementPresenter } from '@/app/ui/presenters/UserManagementPresenter'
import { UserManagementApiRepository } from '@/app/infrastructures/repositories/api/UserManagementApiRepository'
import { FAQPresenter } from '@/app/ui/presenters/FAQPresenter'
import { FAQApiRepository } from '@/app/infrastructures/repositories/api/FAQApiRepository'
import { PNPresenter } from '@/app/ui/presenters/PNPresenter'
import { PNApiRepository } from '@/app/infrastructures/repositories/api/PNApiRepository'
import { ClaimPresenter } from '@/app/ui/presenters/ClaimPresenter'
import { ClaimApiRepository } from '@/app/infrastructures/repositories/api/ClaimApiRepository'
import { PublicVoucherPresenter } from '@/app/ui/presenters/PublicVoucherPresenter'
import { PublicVoucherApiRepository } from '@/app/infrastructures/repositories/api/PublicVoucherApiRepository'
import { ProgrammaticVoucherPresenter } from '@/app/ui/presenters/ProgrammaticVoucherPresenter'
import { ProgrammaticVoucherApiRepository } from '@/app/infrastructures/repositories/api/ProgrammaticVoucherApiRepository'
import { BrandPresenter } from '@/app/ui/presenters/BrandPresenter'
import { BrandApiRepository } from '@/app/infrastructures/repositories/api/BrandApiRepository'
import { MerchantPresenter } from '@/app/ui/presenters/MerchantPresenter'
import { MerchantApiRepository } from '@/app/infrastructures/repositories/api/MerchantApiRepository'
import { RoutePresenter } from '@/app/ui/presenters/RoutePresenter'
import { RouteApiRepository } from '@/app/infrastructures/repositories/api/RouteApiRepository'
import { ProductPresenter } from '@/app/ui/presenters/ProductPresenter'
import { ProductApiRepository } from '@/app/infrastructures/repositories/api/ProductApiRepository'
import { OrderPresenter } from '@/app/ui/presenters/OrderPresenter'
import { OrderApiRepository } from '@/app/infrastructures/repositories/api/OrderApiRepository'
import { CampaignRamadhanPresenter } from '@/app/ui/presenters/CampaignRamadhanPresenter'
import { CampaignRamadhanApiRepository } from '@/app/infrastructures/repositories/api/CampaignRamadhanApiRepository'
import { ExportDataPresenter } from '@/app/ui/presenters/ExportDataPresenter'
import { ExportDataApiRepository } from '@/app/infrastructures/repositories/api/ExportDataApiRepository'
import { SettingPresenter } from '@/app/ui/presenters/SettingPresenter'
import { DriverScoreboardPresenter } from '@/app/ui/presenters/DriverScoreboardPresenter'
import { DriverScoreboardApiRepository } from '@/app/infrastructures/repositories/api/DriverScoreboardApiRepository'
import { PODTrackerPresenter } from '@/app/ui/presenters/PODTrackerPresenter'
import { PODTrackerApiRepository } from '@/app/infrastructures/repositories/api/PODTrackerApiRepository'
import { SelectedMerchantApiRepository } from '@/app/infrastructures/repositories/api/SelectedMerchantRepository'
import { SelectedMerchantPresenter } from '@/app/ui/presenters/SelectedMerchantPresenter'
import { CategorySectionPresenter } from '@/app/ui/presenters/CategorySectionPresenter'
import { CategorySectionApiRepository } from '@/app/infrastructures/repositories/api/CategorySectionApiRepository'
import { CODTrackerPresenter } from '@/app/ui/presenters/CODTrackerPresenter'
import { CODTrackerApiRepository } from '@/app/infrastructures/repositories/api/CODTrackerApiRepository'
import { WithdrawalPresenter } from '@/app/ui/presenters/WithdrawalPresenter'
import { WithdrawalApiRepository } from '@/app/infrastructures/repositories/api/WithdrawalApiRepository'
import { PickupPresenter } from '@/app/ui/presenters/PickupPresenter'
import { PickupApiRepository } from '@/app/infrastructures/repositories/api/PickupApiRepository'
import { HomepageBannerPresenter } from '@/app/ui/presenters/HomepageBannerPresenter'
import { HomepageBannerApiRepository } from '@/app/infrastructures/repositories/api/HomepageBannerApiRepository'
import { DriverAnnouncementPresenter } from '@/app/ui/presenters/DriverAnnouncementPresenter'
import { DriverAnnouncementApiRepository } from '@/app/infrastructures/repositories/api/DriverAnnouncementApiRepository'
import { ManageCourierPresenter } from '@/app/ui/presenters/ManageCourierPresenter'
import { ManageCourierApiRepository } from '@/app/infrastructures/repositories/api/ManageCourierApiRepository'
import { ManageCustomerPresenter } from '@/app/ui/presenters/ManageCustomerPresenter'
import { ManageCustomerApiRepository } from '@/app/infrastructures/repositories/api/ManageCustomerApiRepository'
import { MixPanelPresenter } from '@/app/ui/presenters/MixPanelPresenter'
import { MixPanelApiRepository } from '@/app/infrastructures/repositories/api/MixPanelRepository'
import { ManualPODPhotosPresenter } from '@/app/ui/presenters/ManualPODPhotosPresenter'
import { ManualPODPhotosApiRepository } from '@/app/infrastructures/repositories/api/ManualPODPhotosApiRepository'
import { WhiteSellerPresenter } from '@/app/ui/presenters/WhiteSellerPresenter'
import { WhiteSellerApiRepository } from '@/app/infrastructures/repositories/api/WhiteSellerApiRepository'
import { PayrollPresenter } from '@/app/ui/presenters/PayrollPresenter'
import { PayrollApiRepository } from '@/app/infrastructures/repositories/api/PayrollApiRepository'
import { ManualAdjustmentBalancePresenter } from '@/app/ui/presenters/ManualAdjustmentBalancePresenter'
import { ManualAdjustmentBalanceApiRepository } from '@/app/infrastructures/repositories/api/ManualAdjustmentBalanceApiRepository'
import { ManageDayPresenter } from '@/app/ui/presenters/ManageDayPresenter'
import { ManageDayApiRepository } from '@/app/infrastructures/repositories/api/ManageDayApiRespository'
import { PayrollBonusPresenter } from '@/app/ui/presenters/PayrollBonusPresenter'
import { PayrollBonusApiRepository } from '@/app/infrastructures/repositories/api/PayrollBonusApiRepository'
import { RequestTopupSaldoPresenter } from '@/app/ui/presenters/RequestTopupSaldoPresenter'
import { RequestTopupSaldoApiRepository } from '@/app/infrastructures/repositories/api/RequestTopupSaldoApiRepository'
import { ManageCaptainPresenter } from '@/app/ui/presenters/ManageCaptainPresenter'
import { ManageCaptainApiRepository } from '@/app/infrastructures/repositories/api/ManageCaptainApiRepository'
import { ManageDataPayrollPresenter } from "@/app/ui/presenters/ManageDataPayrollPresenter";
import { ManageDataPayrollApiRepository } from "@/app/infrastructures/repositories/api/ManageDataPayrollApiRepository";
import { PickupLocationPresenter } from '@/app/ui/presenters/PickupLocationPresenter'
import { PickupLocationApiRepository } from '@/app/infrastructures/repositories/api/PickupLocationApiRepository'
import { ScheduleOrderPresenter } from '@/app/ui/presenters/ScheduleOrderPresenter'
import { ScheduleOrderApiRepository } from '@/app/infrastructures/repositories/api/ScheduleOrderApiRepository'
import { ManageCourierDedicatedApiRepository } from '@/app/infrastructures/repositories/api/ManageCourierDedicatedApiRepository'
import { ManageCourierDedicatedPresenter } from '@/app/ui/presenters/ManageCourierDedicatedPresenter'
import { LoyaltyProgramGetPointRulesRepository } from '@/app/infrastructures/repositories/api/LoyaltyProgramGetPointRulesRepository'
import { LoyaltyGetPointRulesPresenter } from '@/app/ui/presenters/LoyaltyProgramGetPointRulesPresenter'
import { MembershipFormulaPresenter } from '@/app/ui/presenters/MembershipFormulaPresenter'
import { MembershipFormulaApiRepository } from '@/app/infrastructures/repositories/api/MembershipFormulaApiRepository'
import { LoyaltyProgramMembershipPresenter } from '@/app/ui/presenters/LoyaltyProgramMembershipPresenter'
import {
  LoyaltyProgramMembershipRepository
} from '@/app/infrastructures/repositories/api/LoyaltyProgramMembershipRepository'
import { AdjustmentParcelPoinPresenter } from '@/app/ui/presenters/AdjustmentParcelPoinPresenter'
import { AdjustmentParcelPoinRepository } from '@/app/infrastructures/repositories/api/AdjustmentParcelPoinRepository'
import { ReschedulePickupPresenter } from '@/app/ui/presenters/ReschedulePickupPresenter'
import { ReschedulePickupRepository } from '../repositories/api/ReschedulePickupRepository'
import { ApprovalAdjustSaldoRepository } from '@/app/infrastructures/repositories/api/ApprovalAdjustSaldoRepository'
import { ApprovalAdjustSaldoPresenter } from '@/app/ui/presenters/ApprovalAdjustSaldoPresenter'
import { PickupRadiusValidationPresenter } from '@/app/ui/presenters/PickupRadiusValidationPresenter'
import { PickupRadiusValidationApiRepository } from '@/app/infrastructures/repositories/api/PickupRadiusValidationApiRepository'
import { UpdateAppVersionPresenter } from '@/app/ui/presenters/UpdateAppVersionPresenter'
import { UpdateAppVersionApiRepository } from '@/app/infrastructures/repositories/api/UpdateAppVersionApiRepository'
import { PackagePresenter } from '@/app/ui/presenters/PackagePresenter'
import { PackageApiRepository } from '@/app/infrastructures/repositories/api/PackageApiRepository'
import { PhoneCodePresenter } from '@/app/ui/presenters/PhoneCodePresenter'
import { PhoneCodeApiRepository } from '../repositories/api/PhoneCodeApiRepository'
import { CancelValidationPresenter } from '@/app/ui/presenters/CancelValidationPresenter'
import { CancelValidationApiRepository } from '../repositories/api/CancelValidationApiRepository'
import { PickupTimeManagementPresenter } from '@/app/ui/presenters/PickupTimeManagementPresenter'
import { PickupTimeManagementApiRepository } from '../repositories/api/PickupTimeManagementApiRepository'
import { ShuttlePresenter } from '@/app/ui/presenters/ShuttlePresenter'
import { ShuttleApiRepository } from '../repositories/api/ShuttleApiRepository'
import { CODPresenter } from '@/app/ui/presenters/CODPresenter'
import { CODApiRepository } from '../repositories/api/CODApiRepository'
import { VoucherConfigSKUPresenter } from '@/app/ui/presenters/VoucherConfigSKUPresenter'
import { VoucherConfigSKURepository } from '@/app/infrastructures/repositories/api/VoucherConfigSKURepository'
import { VoucherConfigSetLimitPresenter } from '@/app/ui/presenters/VoucherConfigSetLimitPresenter'
import { VoucherConfigSetLimitRepository } from '@/app/infrastructures/repositories/api/VoucherConfigSetLimitRepository'
import { VoucherConfigHistoryBulkyPresenter } from '@/app/ui/presenters/VoucherConfigHistoryBulkyPresenter'
import { VoucherConfigHistoryBulkyRepository } from '@/app/infrastructures/repositories/api/VoucherConfigHistoryBulkyRepository'

export class PresenterModule {
  public static init(container: DependencyContainer): void {
    container.register<AuthPresenter>(AuthPresenter, {
      useFactory: d => {
        return new AuthPresenter(d.resolve(AuthApiRepository))
      },
    })
    container.register<ProgramaticPointPresenter>(ProgramaticPointPresenter, {
      useFactory: d => {
        return new ProgramaticPointPresenter(
          d.resolve(ProgramaticPointApiRepository),
          d.resolve(PointPurposeApiRepository)
        )
      },
    })
    container.register<BannerPresenter>(BannerPresenter, {
      useFactory: d => {
        return new BannerPresenter(d.resolve(BannerApiRepository))
      },
    })
    container.register<POSPresenter>(POSPresenter, {
      useFactory: d => {
        return new POSPresenter(
          d.resolve(POSApiRepository),
          d.resolve(CourierApiRepository)
        )
      },
    })
    container.register<SaldoPaymentPresenter>(SaldoPaymentPresenter, {
      useFactory: d => {
        return new SaldoPaymentPresenter(d.resolve(SaldoPaymentApiRepository))
      },
    })
    container.register<UserManagementPresenter>(UserManagementPresenter, {
      useFactory: d => {
        return new UserManagementPresenter(
          d.resolve(UserManagementApiRepository)
        )
      },
    })
    container.register<FAQPresenter>(FAQPresenter, {
      useFactory: d => {
        return new FAQPresenter(d.resolve(FAQApiRepository))
      },
    })
    container.register<PNPresenter>(PNPresenter, {
      useFactory: d => {
        return new PNPresenter(
          d.resolve(PNApiRepository),
          d.resolve(ProgramaticPointApiRepository)
        )
      },
    })
    container.register<ClaimPresenter>(ClaimPresenter, {
      useFactory: d => {
        return new ClaimPresenter(d.resolve(ClaimApiRepository))
      },
    })
    container.register<PublicVoucherPresenter>(PublicVoucherPresenter, {
      useFactory: d => {
        return new PublicVoucherPresenter(d.resolve(PublicVoucherApiRepository))
      },
    })
    container.register<ProgrammaticVoucherPresenter>(
      ProgrammaticVoucherPresenter,
      {
        useFactory: d => {
          return new ProgrammaticVoucherPresenter(
            d.resolve(ProgrammaticVoucherApiRepository),
            d.resolve(ProgramaticPointApiRepository)
          )
        },
      }
    ),
      container.register<BrandPresenter>(BrandPresenter, {
        useFactory: d => {
          return new BrandPresenter(d.resolve(BrandApiRepository))
        },
      })
    container.register<MerchantPresenter>(MerchantPresenter, {
      useFactory: d => {
        return new MerchantPresenter(d.resolve(MerchantApiRepository))
      },
    })
    container.register<RoutePresenter>(RoutePresenter, {
      useFactory: d => {
        return new RoutePresenter(d.resolve(RouteApiRepository))
      },
    })
    container.register<ProductPresenter>(ProductPresenter, {
      useFactory: d => {
        return new ProductPresenter(d.resolve(ProductApiRepository))
      },
    })
    container.register<OrderPresenter>(OrderPresenter, {
      useFactory: d => {
        return new OrderPresenter(d.resolve(OrderApiRepository))
      },
    })
    container.register<CampaignRamadhanPresenter>(CampaignRamadhanPresenter, {
      useFactory: d => {
        return new CampaignRamadhanPresenter(
          d.resolve(CampaignRamadhanApiRepository)
        )
      },
    })
    container.register<ExportDataPresenter>(ExportDataPresenter, {
      useFactory: d => {
        return new ExportDataPresenter(d.resolve(ExportDataApiRepository))
      },
    })
    container.register<SettingPresenter>(SettingPresenter, {
      useFactory: d => {
        return new SettingPresenter(d.resolve(AuthApiRepository))
      },
    })
    container.register<DriverScoreboardPresenter>(DriverScoreboardPresenter, {
      useFactory: d => {
        return new DriverScoreboardPresenter(
          d.resolve(DriverScoreboardApiRepository)
        )
      },
    })
    container.register<PODTrackerPresenter>(PODTrackerPresenter, {
      useFactory: d => {
        return new PODTrackerPresenter(d.resolve(PODTrackerApiRepository))
      },
    })
    container.register<ManualPODPhotosPresenter>(ManualPODPhotosPresenter, {
      useFactory: d => {
        return new ManualPODPhotosPresenter(d.resolve(ManualPODPhotosApiRepository))
      },
    })
    container.register<SelectedMerchantPresenter>(SelectedMerchantPresenter, {
      useFactory: d => {
        return new SelectedMerchantPresenter(
          d.resolve(SelectedMerchantApiRepository)
        )
      },
    })
    container.register<CategorySectionPresenter>(CategorySectionPresenter, {
      useFactory: d => {
        return new CategorySectionPresenter(
          d.resolve(CategorySectionApiRepository)
        )
      },
    })
    container.register<CODTrackerPresenter>(CODTrackerPresenter, {
      useFactory: d => {
        return new CODTrackerPresenter(d.resolve(CODTrackerApiRepository))
      },
    })
    container.register<WithdrawalPresenter>(WithdrawalPresenter, {
      useFactory: d => {
        return new WithdrawalPresenter(d.resolve(WithdrawalApiRepository))
      },
    })
    container.register<PickupPresenter>(PickupPresenter, {
      useFactory: d => {
        return new PickupPresenter(d.resolve(PickupApiRepository))
      },
    })
    container.register<HomepageBannerPresenter>(HomepageBannerPresenter, {
      useFactory: d => {
        return new HomepageBannerPresenter(
          d.resolve(HomepageBannerApiRepository)
        )
      },
    })
    container.register<DriverAnnouncementPresenter>(DriverAnnouncementPresenter, {
      useFactory: d => {
        return new DriverAnnouncementPresenter(
          d.resolve(DriverAnnouncementApiRepository)
        )
      },
    })
    container.register<ManageCourierPresenter>(ManageCourierPresenter, {
      useFactory: d => {
        return new ManageCourierPresenter(
          d.resolve(ManageCourierApiRepository)
        )
      },
    })
    container.register<ManageCustomerPresenter>(ManageCustomerPresenter, {
      useFactory: d => {
        return new ManageCustomerPresenter(
          d.resolve(ManageCustomerApiRepository)
        )
      },
    })
    container.register<MixPanelPresenter>(MixPanelPresenter, {
      useFactory: d => {
        return new MixPanelPresenter(d.resolve(MixPanelApiRepository))
      },
    })
    container.register<WhiteSellerPresenter>(WhiteSellerPresenter, {
      useFactory: d => {
        return new WhiteSellerPresenter(d.resolve(WhiteSellerApiRepository))
      },
    })
    container.register<PayrollPresenter>(PayrollPresenter, {
      useFactory: d => {
        return new PayrollPresenter(d.resolve(PayrollApiRepository))
      },
    })
    container.register<PayrollBonusPresenter>(PayrollBonusPresenter, {
      useFactory: d => {
        return new PayrollBonusPresenter(d.resolve(PayrollBonusApiRepository))
      }
    })
    container.register<ManualAdjustmentBalancePresenter>(ManualAdjustmentBalancePresenter, {
      useFactory: d => {
        return new ManualAdjustmentBalancePresenter(d.resolve(ManualAdjustmentBalanceApiRepository))
      },
    })
    container.register<ManageDayPresenter>(ManageDayPresenter, {
      useFactory: d => {
        return new ManageDayPresenter(d.resolve(ManageDayApiRepository))
      },
    })
    container.register<RequestTopupSaldoPresenter>(RequestTopupSaldoPresenter, {
      useFactory: d => {
        return new RequestTopupSaldoPresenter(d.resolve(RequestTopupSaldoApiRepository))
      },
    })
    container.register<ManageCaptainPresenter>(ManageCaptainPresenter, {
      useFactory: d => {
        return new ManageCaptainPresenter(d.resolve(ManageCaptainApiRepository))
      },
    })
    container.register<ManageDataPayrollPresenter>(ManageDataPayrollPresenter, {
      useFactory: d => {
        return new ManageDataPayrollPresenter(d.resolve(ManageDataPayrollApiRepository))
      },
    })
    container.register<PickupLocationPresenter>(PickupLocationPresenter, {
      useFactory: d => {
        return new PickupLocationPresenter(d.resolve(PickupLocationApiRepository))
      }
    })
    container.register<ScheduleOrderPresenter>(ScheduleOrderPresenter, {
      useFactory: d => {
        return new ScheduleOrderPresenter(d.resolve(ScheduleOrderApiRepository))
      },
    })
    container.register<ManageCourierDedicatedPresenter>(ManageCourierDedicatedPresenter, {
      useFactory: d => {
        return new ManageCourierDedicatedPresenter(d.resolve(ManageCourierDedicatedApiRepository))
      },
    })
    container.register<LoyaltyGetPointRulesPresenter>(LoyaltyGetPointRulesPresenter, {
      useFactory: d => {
        return new LoyaltyGetPointRulesPresenter(d.resolve(LoyaltyProgramGetPointRulesRepository))
      }
    })
    container.register<MembershipFormulaPresenter>(MembershipFormulaPresenter, {
      useFactory: d => {
        return new MembershipFormulaPresenter(d.resolve(MembershipFormulaApiRepository))
      },
    })
    container.register<LoyaltyProgramMembershipPresenter>(LoyaltyProgramMembershipPresenter, {
      useFactory: d => {
        return new LoyaltyProgramMembershipPresenter(d.resolve(LoyaltyProgramMembershipRepository))
      }
    })
    container.register<AdjustmentParcelPoinPresenter>(AdjustmentParcelPoinPresenter, {
      useFactory: d => {
        return new AdjustmentParcelPoinPresenter(d.resolve(AdjustmentParcelPoinRepository))
      }
    })
    container.register<ReschedulePickupPresenter>(ReschedulePickupPresenter, {
      useFactory: d => {
        return new ReschedulePickupPresenter(d.resolve(ReschedulePickupRepository))
      }
    })
    container.register<ApprovalAdjustSaldoPresenter>(ApprovalAdjustSaldoPresenter, {
      useFactory: d => {
        return new ApprovalAdjustSaldoPresenter(d.resolve(ApprovalAdjustSaldoRepository))
      }
    })
    container.register<PickupRadiusValidationPresenter>(PickupRadiusValidationPresenter, {
      useFactory: d => {
        return new PickupRadiusValidationPresenter(d.resolve(PickupRadiusValidationApiRepository))
      }
    })
    container.register<UpdateAppVersionPresenter>(UpdateAppVersionPresenter, {
      useFactory: d => {
        return new UpdateAppVersionPresenter(d.resolve(UpdateAppVersionApiRepository))
      }
    })
    container.register<PackagePresenter>(PackagePresenter, {
      useFactory: d => {
        return new PackagePresenter(d.resolve(PackageApiRepository))
      }
    })
    container.register<PhoneCodePresenter>(PhoneCodePresenter, {
      useFactory: d => {
        return new PhoneCodePresenter(d.resolve(PhoneCodeApiRepository))
      }
    })
    container.register<CancelValidationPresenter>(CancelValidationPresenter, {
      useFactory: d => {
        return new CancelValidationPresenter(d.resolve(CancelValidationApiRepository))
      }
    })
    container.register<PickupTimeManagementPresenter>(PickupTimeManagementPresenter, {
      useFactory: d => {
        return new PickupTimeManagementPresenter(d.resolve(PickupTimeManagementApiRepository))
      }
    })
    container.register<ShuttlePresenter>(ShuttlePresenter, {
      useFactory: d => {
        return new ShuttlePresenter(d.resolve(ShuttleApiRepository))
      }
    })
    container.register<CODPresenter>(CODPresenter, {
      useFactory: d => {
        return new CODPresenter(d.resolve(CODApiRepository))
      }
    })
    container.register<VoucherConfigSKUPresenter>(VoucherConfigSKUPresenter, {
      useFactory: d => {
        return new VoucherConfigSKUPresenter(d.resolve(VoucherConfigSKURepository))
      }
    })
    container.register<VoucherConfigSetLimitPresenter>(VoucherConfigSetLimitPresenter, {
      useFactory: d => {
        return new VoucherConfigSetLimitPresenter(d.resolve(VoucherConfigSetLimitRepository))
      }
    })
    container.register<VoucherConfigHistoryBulkyPresenter>(VoucherConfigHistoryBulkyPresenter, {
      useFactory: d => {
        return new VoucherConfigHistoryBulkyPresenter(d.resolve(VoucherConfigHistoryBulkyRepository))
      }
    })
  }
}
