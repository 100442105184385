import { injectable } from 'tsyringe'
import {
  PickupLocation,
  PickupLocations,
} from '@/domain/entities/PickupLocation'
import { PickupLocationActivateOrDeactivateRequestInterface } from '@/data/payload/contracts/PickupLocationRequest'
import { PickupLocationRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupLocationRepositoryInterface'

@injectable()
export class PickupLocationPresenter {
  private repository: PickupLocationRepositoryInterface

  constructor(repository: PickupLocationRepositoryInterface) {
    this.repository = repository
  }

  getPickupLocations(
    params: Map<string, string | number>
  ): Promise<PickupLocations> {
    return this.repository.getPickupLocations(params)
  }

  getPickupLocation(id: string): Promise<PickupLocation> {
    return this.repository.getPickupLocation(id)
  }

  activateOrDeactivatePickupLocation(
    payload: PickupLocationActivateOrDeactivateRequestInterface
  ): Promise<boolean> {
    return this.repository.activateOrDeactivatePickupLocation(payload)
  }

  getCity(): Promise<Array<string>> {
    return this.repository.getCity()
  }
}
