import ApiService from '@/app/infrastructures/services/ApiService'
import { Role, Accounts, Account, AccessMenu } from '@/domain/entities/Account'
import { UserManagementMapper } from '@/data/persistences/mappers/UserManagementMapper'
import { UserManagementRepositoryInterface } from '@/data/persistences/repositories/contracts/UserManagementRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import {
  AccessMenuRequestInterface,
  CreateUserRequestInterface,
  UpdateUserRequestInterface,
} from '@/data/payload/contracts/UserManagementRequest'
import {
  CreateUserRequest,
  UpdateUserRequest,
} from '@/data/payload/api/UserManagementRequest'

export class UserManagementApiRepository
  implements UserManagementRepositoryInterface {
  private service: ApiService
  private mapper: UserManagementMapper

  constructor(service: ApiService, mapper: UserManagementMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getRoles(params: Map<string, any>): Promise<Role[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADMIN}/role`,
      params
    )
    return this.mapper.convertRolesFromApi(resp)
  }

  public async getUsers(params: Map<string, any>): Promise<Accounts> {
    const resp = await this.service.invoke('get', Endpoints.Route.ADMIN, params)
    return this.mapper.convertAccountsFromApi(resp)
  }

  public async getUser(accountId: string): Promise<Account> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADMIN}/${accountId}`
    )
    return this.mapper.convertAccountFromApi(resp)
  }

  public async getMenus(params: Map<string, any>): Promise<AccessMenu[]> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.CMS_MENU,
      params
    )
    return this.mapper.convertAccessMenusFromApi(resp)
  }

  public async createUser(
    payload: CreateUserRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.ADMIN,
      undefined,
      payload as CreateUserRequest
    )

    return true
  }

  public async updateUser(
    accountId: string,
    payload: UpdateUserRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.ADMIN}/${accountId}`,
      undefined,
      payload as UpdateUserRequest
    )

    return true
  }

  public async deleteUser(accountId: string): Promise<boolean> {
    await this.service.invoke('delete', `${Endpoints.Route.ADMIN}/${accountId}`)

    return true
  }

  public async addMenu(payload: AccessMenuRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.CMS_MENU,
      undefined,
      payload
    )

    return true
  }

  public async updateMenu(
    id: number,
    payload: AccessMenuRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.CMS_MENU}/${id}`,
      undefined,
      payload
    )

    return true
  }

  public async deleteMenu(id: number): Promise<boolean> {
    await this.service.invoke('delete', `${Endpoints.Route.CMS_MENU}/${id}`)

    return true
  }
}
