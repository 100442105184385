import ApiService from "@/app/infrastructures/services/ApiService";
import { ManageCustomerRepositoryInterface } from "@/data/persistences/repositories/contracts/ManageCustomerInterface";
import { ManageCustomerMapper } from "@/data/persistences/mappers/ManageCustomerMapper";
import { ManageCustomer, ManageCustomers, HistoryStatusCustomer } from "@/domain/entities/ManageCustomer";
import { Endpoints } from "@/app/infrastructures/misc";
import {
  BlockedCustomerRequestInterface,
  ChangeStatusCustomerRequestInterface,
  UnblockedCustomerRequestInterface
} from "@/data/payload/contracts/ManageCustomer";

export class ManageCustomerApiRepository implements ManageCustomerRepositoryInterface {
  private service: ApiService
  private mapper: ManageCustomerMapper

  constructor(
    service: ApiService,
    mapper: ManageCustomerMapper
  ) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, string | number>): Promise<ManageCustomers> {
    const resp = await this.service.invoke(
      'GET',
      Endpoints.Route.CUSTOMER,
      params,
    )
    return this.mapper.convertManageCustomersFromApi(resp)
  }

  public async get(customerId: string): Promise<ManageCustomer> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CUSTOMER}/${customerId}`
    )

    return this.mapper.convertDetailCustomersFromApi(resp)
  }

  public async getHistoryStatus(customerId: string): Promise<HistoryStatusCustomer> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CUSTOMER_HISTORY_STATUS}/${customerId}`,
    )
    return this.mapper.convertCustomerHistoryStatusFromApi(resp)
  }

  public async blocked(
    payload: BlockedCustomerRequestInterface,
    customerId: string
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.CUSTOMER_BLOCKED}/${customerId}`,
      undefined,
      payload
    )
    return true
  }

  public async unblocked(
    payload: UnblockedCustomerRequestInterface,
    customerId: string
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.CUSTOMER_UNBLOCKED}/${customerId}`,
      undefined,
      payload
    )
    return true
  }

  public async changeStatus(payload: ChangeStatusCustomerRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.CUSTOMER}/change-status`,
      undefined,
      payload
    )
    return true
  }
}
