import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import {
  PublicVoucher,
  PublicVoucherData,
  PublicVoucherHistories,
  PublicVoucherHistory,
  PublicVouchers,
  PublicVoucherPurposeData, PublicVoucherCodeMultipleExternalPartner,
} from '@/domain/entities/PublicVoucher'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/data/infrastructures/misc/Utils'

export class PublicVoucherMapper extends BaseResponseMapper {
  public convertPublicVouchersFromApi(
    result: AxiosResponse
  ): PublicVouchers {
    const {
      data: { pagination, data },
    } = result
    return new PublicVouchers(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (pbvc: Record<string, never>) =>
          new PublicVoucher(
            pbvc.public_voucher_id,
            pbvc.public_voucher_name,
            pbvc.public_voucher_code,
            undefined,
            pbvc.public_voucher_purpose,
            undefined,
            undefined,
            undefined,
            pbvc.status,
            pbvc.start_date,
            pbvc.end_date,
            pbvc.created_by,
            pbvc.updated_by,
            pbvc.created_at,
            pbvc.updated_at,
            undefined,
            undefined,
            undefined,
            pbvc.multiple_voucher_count,
            pbvc.voucher_partner_code,
          )
      )
    )
  }

  public convertPublicVoucherFromApi(
    result: AxiosResponse
  ): PublicVoucher {
    const { data } = result
    return new PublicVoucher(
      data.public_voucher_id,
      data.public_voucher_name,
      data.public_voucher_code,
      data.limit_per_day,
      data.public_voucher_purpose,
      data.voucher_limit_usage,
      data.limit_usage_per_user,
      new PublicVoucherData(
        data.voucher_expiry_day,
        data.public_voucher_data.point_amount,
        data.public_voucher_data.point_purpose,
        data.public_voucher_data.point_expiry_day
      ),
      data.status,
      data.start_date,
      data.end_date,
      data.created_by,
      data.updated_by,
      data.created_at,
      data.updated_at,
      data.deleted_at,
      data.voucher_expired_at,
      new PublicVoucherPurposeData(
        data.public_voucher_purpose_data.discount_percentage,
        data.public_voucher_purpose_data.discount_percentage_minimum_transaction,
        data.public_voucher_purpose_data.discount_percentage_maximum_amount,
        data.public_voucher_purpose_data.shipment_type,
      ),
      data.multiple_voucher_count,
      data.voucher_partner_code,
    )
  }

  public convertPublicVoucherHistoriesFromApi(
    result: AxiosResponse
  ): PublicVoucherHistories {
    const {
      data: { pagination, data },
    } = result
    return new PublicVoucherHistories(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (pbvc: Record<string, never>) =>
          new PublicVoucherHistory(
            pbvc.public_voucher_item_id,
            pbvc.public_voucher_name,
            pbvc.customer_id,
            pbvc.customer_name,
            pbvc.customer_email,
            pbvc.city,
            'Public Voucher',
            pbvc.voucher_code,
            pbvc.point_initial_amount,
            pbvc.point_spent_amount,
            pbvc.voucher_created_at,
            pbvc.voucher_percentage,
            pbvc.voucher_used_at,
            pbvc.voucher_amount,
            pbvc.voucher_used,
            pbvc.voucher_purpose,
            pbvc.purchase_at,
          )
      )
    )
  }

  public convertPublicVoucherCodeExternalPartnerFromApi(
    result: AxiosResponse
  ): PublicVoucherCodeMultipleExternalPartner[] {
    const {
      data: { data },
    } = result
    return data.map(
      (pbvc: Record<string, never>) =>
    new PublicVoucherCodeMultipleExternalPartner(
          pbvc.voucher_partner_code,
          pbvc.voucher_partner_name,
        )
    )
  }
}
