import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import {RouteConfig} from 'vue-router'
import {RouteInterface} from '@/app/ui/router/interface'

export const programmaticVoucherRouteName = 'ProgrammaticVoucherPage'
export const createProgrammaticVoucherRouteName =
  'CreateProgrammaticVoucherPage'
export const programmaticVoucherUsageRouteName = 'ProgrammaticVoucherUsagePage'
export const programmaticVoucherEditRouteName = 'ProgrammaticVoucherEditPage'
export const programmaticVoucherDetailRouteName =
  'ProgrammaticVoucherDetailPage'
export const publicVoucherRouteName = 'PublicVoucherPage'
export const createPublicVoucherRouteName = 'CreatePublicVoucherPage'
export const publicVoucherUsageRouteName = 'PublicVoucherUsagePage'
export const publicVoucherEditRouteName = 'PublicVoucherEditPage'
export const publicVoucherDetailRouteName = 'PublicVoucherDetailPage'
export const publicVoucherMultipleCodeRouteName = 'PublicVoucherMultipleCodePage'
export const voucherConfigMainPageRouteName = 'VoucherConfigMainPage'
export const voucherConfigSKUCreateRouteName = 'VoucherConfigSKUCreatePage'
export const voucherConfigSKUPreviewUploadBulkyRouteName = 'VoucherConfigSKUPreviewUploadBulkyPage'
export const voucherConfigSKUEditRouteName = 'VoucherConfigSKUEditPage'
export const voucherConfigSetLimitListRouteName = 'VoucherConfigSetLimitListPage'
export const voucherConfigSetLimitCreateRouteName = 'VoucherConfigSetLimitCreatePage'
export const voucherConfigSetLimitEditRouteName = 'VoucherConfigSetLimitEditPage'
export const voucherConfigHistoryBulkyRouteName = 'voucherConfigHistoryBulkyPage'

export const programmaticVoucherRoute: RouteInterface = {
  path: 'programmatic-voucher',
  name: programmaticVoucherRouteName,
  meta: {
    title: 'Programmatic Voucher',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_VOUCHER,
    accessType: 'read',
  },
}

export const createProgrammaticVoucherRoute: RouteInterface = {
  path: 'programmatic-voucher/create',
  name: createProgrammaticVoucherRouteName,
  meta: {
    title: 'Create Programmatic Voucher',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_VOUCHER,
    accessType: 'write',
  },
}

export const programmaticVoucherUsageRoute: RouteInterface = {
  path: 'programmatic-voucher/:id/usage',
  name: programmaticVoucherUsageRouteName,
  meta: {
    title: 'Programmatic Voucher Usage',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_VOUCHER,
    accessType: 'read',
  },
}

export const programmaticVoucherEditRoute: RouteInterface = {
  path: 'programmatic-voucher/:id/edit',
  name: programmaticVoucherEditRouteName,
  meta: {
    title: 'Edit Programmatic Voucher',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_VOUCHER,
    accessType: 'write',
  },
}

export const programmaticVoucherDetailRoute: RouteInterface = {
  path: 'programmatic-voucher/:id',
  name: programmaticVoucherDetailRouteName,
  meta: {
    title: 'Programmatic Voucher Detail',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_VOUCHER,
    accessType: 'read',
  },
}

export const publicVoucherRoute: RouteInterface = {
  path: 'public-voucher',
  name: publicVoucherRouteName,
  meta: {
    title: 'Public Voucher Code',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.PUBLIC_CODE,
    accessType: 'read',
  },
}

export const createPublicVoucherRoute: RouteInterface = {
  path: 'public-voucher/create',
  name: createPublicVoucherRouteName,
  meta: {
    title: 'Create Public Voucher',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PUBLIC_CODE,
    accessType: 'write',
  },
}

export const publicVoucherUsageRoute = {
  path: 'public-voucher/:id/usage',
  name: publicVoucherUsageRouteName,
  meta: {
    title: 'Public Voucher Usage',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PUBLIC_CODE,
    accessType: 'read',
  },
}

export const publicVoucherEditRoute = {
  path: 'public-voucher/:id/edit',
  name: publicVoucherEditRouteName,
  meta: {
    title: 'Edit Public Voucher Code',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PUBLIC_CODE,
    accessType: 'write',
  },
}

export const publicVoucherDetailRoute = {
  path: 'public-voucher/:id',
  name: publicVoucherDetailRouteName,
  meta: {
    title: 'Public Voucher Detail',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PUBLIC_CODE,
    accessType: 'read',
  },
}

export const publicVoucherMultipleCodeRoute = {
  path: 'public-voucher/multiple-code/list',
  name: publicVoucherMultipleCodeRouteName,
  meta: {
    title: 'Public Multiple Voucher Code',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PUBLIC_CODE,
    accessType: 'read',
  },
}

export const voucherConfigRoute = {
  path: 'voucher-config',
  name: voucherConfigMainPageRouteName,
  meta: {
    title: 'Voucher Config',
    isHidden: false,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'read',
  },
}

export const voucherConfigSKUCreateRoute = {
  path: 'voucher-config/voucher-sku/create',
  name: voucherConfigSKUCreateRouteName,
  meta: {
    title: 'Create SKU Voucher',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'write',
  },
}

export const voucherConfigSKUPreviewUploadBulkyRoute = {
  path: 'voucher-config/voucher-sku/preview-upload-bulky',
  name: voucherConfigSKUPreviewUploadBulkyRouteName,
  meta: {
    title: 'Preview Upload Bulky SKU Voucher',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'read',
  },
}

export const voucherConfigSKUEditRoute = {
  path: 'voucher-config/voucher-sku/edit/:id',
  name: voucherConfigSKUEditRouteName,
  meta: {
    title: 'Edit SKU Voucher',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'write',
  },
}

export const voucherConfigSetLimitListRoute = {
  path: 'voucher-config/set-limit',
  name: voucherConfigSetLimitListRouteName,
  meta: {
    title: 'Set Limit',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'read',
  },
}

export const voucherConfigSetLimitCreateRoute = {
  path: 'voucher-config/set-limit/create',
  name: voucherConfigSetLimitCreateRouteName,
  meta: {
    title: 'Create Set Limit',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'write',
  },
}

export const voucherConfigSetLimitEditRoute = {
  path: 'voucher-config/set-limit/edit/:id',
  name: voucherConfigSetLimitEditRouteName,
  meta: {
    title: 'Edit Set Limit',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'write',
  },
}

export const voucherConfigHistoryBulkyRoute = {
  path: 'voucher-config/history-bulky',
  name: voucherConfigHistoryBulkyRouteName,
  meta: {
    title: 'History Bulky',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.VOUCHER_CONFIG,
    accessType: 'read',
  },
}

export const voucher: RouteConfig = {
  path: '/voucher',
  component: MainLayout,
  redirect: '/voucher/programmatic-voucher',
  meta: {
    title: 'Voucher',
    single: false,
    icon: 'MenuIconVoucher',
  },
  children: [
    {
      ...programmaticVoucherRoute,
      component: () =>
        import(
          /* webpackChunkName: "ProgrammaticVoucherPage" */ '@/app/ui/views/Voucher/ProgrammaticVoucher/index.vue'
          ),
    },
    {
      ...createProgrammaticVoucherRoute,
      component: () =>
        import(
          /* webpackChunkName: "CreateProgrammaticVoucherPage" */ '@/app/ui/views/Voucher/ProgrammaticVoucher/Create/index.vue'
          ),
    },
    {
      ...programmaticVoucherUsageRoute,
      component: () =>
        import(
          /* webpackChunkName: "ProgrammaticVoucherUsagePage" */ '@/app/ui/views/Voucher/ProgrammaticVoucher/Usage/index.vue'
          ),
    },
    {
      ...programmaticVoucherEditRoute,
      component: () =>
        import(
          /* webpackChunkName: "ProgrammaticVoucherEditPage" */ '@/app/ui/views/Voucher/ProgrammaticVoucher/Edit/index.vue'
          ),
    },
    {
      ...programmaticVoucherDetailRoute,
      component: () =>
        import(
          /* webpackChunkName: "ProgrammaticVoucherDetailPage" */ '@/app/ui/views/Voucher/ProgrammaticVoucher/Detail/index.vue'
          ),
    },
    {
      ...publicVoucherRoute,
      component: () =>
        import(
          /* webpackChunkName: "PublicVoucherPage" */ '@/app/ui/views/Voucher/PublicVoucher/index.vue'
          ),
    },
    {
      ...createPublicVoucherRoute,
      component: () =>
        import(
          /* webpackChunkName: "CreatePublicVoucherPage" */ '@/app/ui/views/Voucher/PublicVoucher/Create/index.vue'
          ),
    },
    {
      ...publicVoucherUsageRoute,
      component: () =>
        import(
          /* webpackChunkName: "PublicVoucherUsagePage" */ '@/app/ui/views/Voucher/PublicVoucher/Usage/index.vue'
          ),
    },
    {
      ...publicVoucherEditRoute,
      component: () =>
        import(
          /* webpackChunkName: "PublicVoucherEditPage" */ '@/app/ui/views/Voucher/PublicVoucher/Edit/index.vue'
          ),
    },
    {
      ...publicVoucherDetailRoute,
      component: () =>
        import(
          /* webpackChunkName: "PublicVoucherDetailPage" */ '@/app/ui/views/Voucher/PublicVoucher/Detail/index.vue'
          ),
    },
    {
      ...voucherConfigRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigMainPage" */ '@/app/ui/views/Voucher/VoucherConfig/index.vue'
          ),
    },
    {
      ...voucherConfigSKUCreateRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigSKUCreatePage" */ '@/app/ui/views/Voucher/VoucherConfig/VoucherAndSKU/Create/index.vue'
          ),
    },
    {
      ...voucherConfigSKUEditRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigSKUEditPage" */ '@/app/ui/views/Voucher/VoucherConfig/VoucherAndSKU/Edit/index.vue'
          ),
    },
    {
      ...voucherConfigSKUPreviewUploadBulkyRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigSKUPreviewUploadBulkyPage" */ '@/app/ui/views/Voucher/VoucherConfig/PreviewUploadBulkySKU/index.vue'
          ),
    },
    {
      ...publicVoucherMultipleCodeRoute,
      component: () =>
        import(
          /* webpackChunkName: "PublicVoucherMultiplePage" */ '@/app/ui/views/Voucher/PublicVoucher/MultipleVoucher/index.vue'
          ),
    },
    {
      ...voucherConfigSetLimitListRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigSetLimitListPage" */ '@/app/ui/views/Voucher/VoucherConfig/SetLimit/List/index.vue'
          ),
    },
    {
      ...voucherConfigSetLimitCreateRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigSetLimitCreatePage" */ '@/app/ui/views/Voucher/VoucherConfig/SetLimit/Create/index.vue'
          ),
    },
    {
      ...voucherConfigSetLimitEditRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigSetLimitEditPage" */ '@/app/ui/views/Voucher/VoucherConfig/SetLimit/Edit/index.vue'
          ),
    },
    {
      ...voucherConfigHistoryBulkyRoute,
      component: () =>
        import(
          /* webpackChunkName: "VoucherConfigHistoryBulkyPage" */ '@/app/ui/views/Voucher/VoucherConfig/HistoryBulky/index.vue'
          ),
    }
  ],
}
