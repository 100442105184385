import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'

export const claimMBGListRouteName = 'ClaimListPage'
export const claimMBGDetailRouteName = 'ClaimDetailPage'
export const claimDamagedGoodsRouteName = 'ClaimDamagedGoodsPage'
export const claimDamagedGoodsDetailRouteName = 'ClaimDamagedGoodsDetailPage'

export const claim: RouteConfig = {
  path: '/claim',
  component: MainLayout,
  meta: {
    title: 'Claim',
    single: false,
    icon: 'MenuIconClaim',
  },
  redirect: '/claim',
  children: [
    {
      path: '',
      name: claimMBGListRouteName,
      component: () =>
        import(
          /* webpackChunkName: "ClaimListPage" */ '@/app/ui/views/Claim/MoneyBackGuarantee/index.vue'
        ),
      meta: {
        title: 'Money Back Guarantee',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.CLAIM_MBG,
        accessType: 'read',
      },
    },
    {
      path: 'goods',
      name: claimDamagedGoodsRouteName,
      component: () =>
        import(
          /* webpackChunkName: "ClaimDamagedGoodsPage" */ '@/app/ui/views/Claim/DamagedGoods/index.vue'
        ),
      meta: {
        title: 'Missing / Damaged Goods',
        slugFromBackend: BACKEND_SLUG.CLAIM_DAMAGED,
        accessType: 'read',
      },
    },
    {
      path: 'goods/:id',
      name: claimDamagedGoodsDetailRouteName,
      component: () =>
        import(
          /* webpackChunkName: "ClaimDamagedGoodsDetailPage" */ '@/app/ui/views/Claim/DamagedGoods/Detail/index.vue'
        ),
      meta: {
        title: 'Missing / Damaged Goods Detail',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.CLAIM_DAMAGED,
        accessType: 'read',
      },
    },
    {
      path: ':id',
      name: claimMBGDetailRouteName,
      component: () =>
        import(
          /* webpackChunkName: "ClaimDetailPage" */ '@/app/ui/views/Claim/MoneyBackGuarantee/Detail/index.vue'
        ),
      meta: {
        title: 'Money Back Guarantee Detail',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.CLAIM_MBG,
        accessType: 'read',
      },
    },
  ],
}
