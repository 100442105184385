import {
  VoucherConfigHistoryBulkyRepositoryInterface
} from '@/data/persistences/repositories/contracts/VoucherConfigHistoryBulkyRepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import {HistoryUploadBulkyVoucherConfig} from '@/domain/entities/VoucherConfigHistoryBulky'
import {VoucherConfigHistoryBulkyMapper} from '@/data/persistences/mappers/VoucherConfigHistoryBulkyMapper'
import {Endpoints} from '@/app/infrastructures/misc'

export class VoucherConfigHistoryBulkyRepository implements VoucherConfigHistoryBulkyRepositoryInterface {
  private service: ApiService
  private mapper: VoucherConfigHistoryBulkyMapper

  constructor(service: ApiService, mapper: VoucherConfigHistoryBulkyMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getHistoryUploadBulky(
    params: Map<string, string>
  ): Promise<HistoryUploadBulkyVoucherConfig> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku/bulk-upload/history`,
      params
    )

    return this.mapper.convertHistoryUploadBulky(resp)
  }
}

