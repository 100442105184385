export const BANNER_PAGINATION = 6
export const POPUP_BANNER_PAGINATION = 4
export const CLAIM_PAGINATION = 30
export const FAQ_PAGINATION = 30
export const POS_PAGINATION = 30
export const DEFAULT_PAGINATION = 20
export const PUSH_NOTIFICATION_PAGINATION = 25
export const RETRIEVED_SALDO_PAYMENT_PAGINATION = 20
export const PROGRAMMATIC_POINT_PAGINATION = 20
export const PROGRAMMATIC_POINT_USAGE_PAGINATION = 20
export const USER_MANAGEMENT_PAGINATION = 30
export const PROGRAMMATIC_VOUCHER_PAGINATION = 20
export const PROGRAMMATIC_VOUCHER_HISTORY_PAGINATION = 30
export const PUBLIC_VOUCHER_PAGINATION = 20
export const PUBLIC_VOUCHER_HISTORY_PAGINATION = 30
export const BRAND_PAGINATION = 15
export const MERCHANT_PAGINATION = 15
export const PRODUCT_PAGINATION = 15
export const ORDER_PAGINATION = 15
export const DRIVER_PAGINATION = 10
export const POD_TRACKER_DRIVER_PAGINATION = 10
export const POD_TRACKER_PAGINATION = 10
export const COD_TRACKER_PAGINATION = 10
export const WITHDRAWAL_PAGINATION = 30
export const PICKUP_PAGINATION = 50
export const HOMEPAGE_BANNER_PAGINATION = 6;
export const DRIVER_ANNOUNCEMENT_PAGINATION = 10;
export const REQUEST_TOP_SALDO_PAGINATION = 20
export const APPROVING_TOPUP_SALDO_PAGINATION = 50
export const APPROVING_PARCEL_POIN_PAGINATION = 50
export const ALL_DATA_ADJUSTMENT_PAGINATION = 20
export const APPROVAL_ADJUST_SALDO_PAGINATION = 10
export const PREVIEW_UPLOAD_BULKY_DEFAULT_PAGINATION = 50
export const LIST_EXTRAMULTIPLIER_PAGINATION = 10
