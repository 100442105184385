import { Pagination } from './Pagination'

export class Cancels {
  public pagination?: Pagination
  public data?: Cancel[]
  public summary?: Summary

  constructor(pagination?: Pagination, data?: Cancel[], summary?: Summary) {
    this.pagination = pagination
    this.data = data
    this.summary = summary
  }
}

export class Cancel {
  public shipmentId?: string
  public bookingId?: string
  public historyId?: number
  public courierId?: number
  public courierName?: string
  public courierPhoneNumber?: string
  public courierTypeValid?: string
  public courier3lc?: string
  public createdAt?: string
  public cancelReason?: string
  public cancelStatus?: string

  constructor(
    shipmentId?: string,
    bookingId?: string,
    historyId?: number,
    courierId?: number,
    courierName?: string,
    courierPhoneNumber?: string,
    courierTypeValid?: string,
    courier3lc?: string,
    createdAt?: string,
    cancelReason?: string,
    cancelStatus?: string
  ) {
    this.shipmentId = shipmentId
    this.bookingId = bookingId
    this.historyId = historyId
    this.courierId = courierId
    this.courierName = courierName
    this.courierPhoneNumber = courierPhoneNumber
    this.courierTypeValid = courierTypeValid
    this.courier3lc = courier3lc
    this.createdAt = createdAt
    this.cancelReason = cancelReason
    this.cancelStatus = cancelStatus
  }
}

export class Summary {
  public fraud?: number
  public needValidation?: number
  public valid?: number

  constructor(fraud?: number, needValidation?: number, valid?: number) {
    this.fraud = fraud
    this.needValidation = needValidation
    this.valid = valid
  }
}

export class ShipmentCancel {
  public shipmentId?: string
  public bookingId?: string
  public historyId?: string
  public createdAt?: string
  public cancelReason?: string
  public cancelStatus?: string

  constructor(
    shipmentId?: string,
    bookingId?: string,
    historyId?: string,
    cancelTime?: string,
    cancelReason?: string,
    statusPickup?: string
  ) {
    this.shipmentId = shipmentId
    this.bookingId = bookingId
    this.historyId = historyId
    this.createdAt = cancelTime
    this.cancelReason = cancelReason
    this.cancelStatus = statusPickup
  }
}

export class CourierShipmentCancels {
  public courierId?: string
  public courierName?: string
  public phoneNumber?: string
  public vehicleType?: string
  public vehiclePlate?: string
  public partnerType?: string
  public partnerName?: string
  public origin?: string
  public courierTypeValid?: string
  public data?: ShipmentCancel[]
  public pagination?: Pagination

  constructor(
    courierId?: string,
    courierName?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerType?: string,
    partnerName?: string,
    origin?: string,
    courierTypeValid?: string,
    data?: ShipmentCancel[],
    pagination?: Pagination
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerType = partnerType
    this.partnerName = partnerName
    this.origin = origin
    this.courierTypeValid = courierTypeValid
    this.data = data
    this.pagination = pagination
  }
}

export class CancelDetailData {
  public courierName?: string
  public courierTypeValid?: string
  public courierPhoneNumber?: string
  public courier3lc?: string
  public courierId?: string
  public shipmentId?: string
  public bookingId?: string
  public historyId?: number
  public createdAt?: string
  public cancelLocation?: string
  public cancelReason?: string
  public proofImage?: string[]
  public isCancelValid?: number
  public description?: string

  constructor(
    courierName?: string,
    courierTypeValid?: string,
    courierPhoneNumber?: string,
    courier3lc?: string,
    courierId?: string,
    shipmentId?: string,
    bookingId?: string,
    historyId?: number,
    createdAt?: string,
    cancelLocation?: string,
    cancelReason?: string,
    proofImage?: string[],
    isCancelValid?: number,
    description?: string
  ) {
    this.courierName = courierName
    this.courierTypeValid = courierTypeValid
    this.courierPhoneNumber = courierPhoneNumber
    this.courier3lc = courier3lc
    this.courierId = courierId
    this.shipmentId = shipmentId
    this.bookingId = bookingId
    this.historyId = historyId
    this.createdAt = createdAt
    this.cancelLocation = cancelLocation
    this.cancelReason = cancelReason
    this.proofImage = proofImage
    this.isCancelValid = isCancelValid
    this.description = description
  }
}

export class CancelDetailHistoryLogData {
  public updatedBy?: string
  public updatedDate?: string

  constructor(updatedBy?: string, updatedDate?: string) {
    this.updatedBy = updatedBy
    this.updatedDate = updatedDate
  }
}

export class CancelDetailHistoryLog {
  public data?: CancelDetailHistoryLogData[]
  public pagination?: Pagination

  constructor(data?: CancelDetailHistoryLogData[], pagination?: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}

export class CancelDetail {
  public detail?: CancelDetailData
  public historyLog?: CancelDetailHistoryLog

  constructor(detail?: CancelDetailData, historyLog?: CancelDetailHistoryLog) {
    this.detail = detail
    this.historyLog = historyLog
  }
}
