import {
  CreateProductRequestInterface,
  UpdateProductRequestInterface,
  UpdateProductStatusRequestInterface,
} from '@/data/payload/contracts/ProductRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { ProductRepositoryInterface } from '@/data/persistences/repositories/contracts/ProductRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { ProductMapper } from '@/data/persistences/mappers/ProductMapper'
import {
  HistoryLogBulkProducts,
  Product, Products,
  VariantProducts,
  ProductCategory
} from '@/domain/entities/Product'
import { UploadFileProductBulkRequest } from '@/data/payload/api/ProductRequest'

export class ProductApiRepository implements ProductRepositoryInterface {
  private service: ApiService
  private mapper: ProductMapper

  constructor(service: ApiService, mapper: ProductMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(productId: string): Promise<Product> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT}/${productId}`
    )
    return this.mapper.convertProductFromApi(resp)
  }

  public async getAll(
    params: Map<string, string | number | undefined>
  ): Promise<Products> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT}/data`,
      params
    )
    return this.mapper.convertProductsFromApi(resp)
  }

  public async getVariantList(productId: number): Promise<VariantProducts> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT}/${productId}/variant`
    )

    return this.mapper.convertVariantResponseFromApi(resp)
  }

  public async getCategoryLevelOne(): Promise<ProductCategory[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT_CATEGORY}/list`
    )

    return this.mapper.convertCategoryResponse(resp)
  }

  public async getCategoryLevelTwo(
    categoryTypeIdOne: number
  ): Promise<ProductCategory[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT_CATEGORY}/${categoryTypeIdOne}/list`
    )

    return this.mapper.convertCategoryResponse(resp)
  }

  public async getCategoryLevelThree(
    categoryTypeIdOne: number,
    categoryTypeIdTwo: number
  ): Promise<ProductCategory[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PRODUCT_CATEGORY}/${categoryTypeIdOne}/${categoryTypeIdTwo}/list`
    )

    return this.mapper.convertCategoryResponse(resp)
  }

  public async create(
    payload: CreateProductRequestInterface
  ): Promise<Product> {
    const resp = await this.service.invoke(
      'post',
      Endpoints.Route.PRODUCT,
      undefined,
      payload
    )
    return this.mapper.convertProductFromApi(resp)
  }

  public async update(
    productId: number,
    payload: UpdateProductRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.PRODUCT}/${productId}`,
      undefined,
      payload
    )
    return true
  }

  public async delete(productId: number): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.PRODUCT}/${productId}`,
      undefined
    )
    return true
  }

  public async setStatus(
    productId: number,
    payload: UpdateProductStatusRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'patch',
      `${Endpoints.Route.PRODUCT}/${productId}/status`,
      undefined,
      payload
    )
    return true
  }

  public async setStatusVariant(
    productId: number,
    payload: UpdateProductStatusRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'patch',
      `${Endpoints.Route.PRODUCT}/${productId}/status/variant`,
      undefined,
      payload
    )
    return true
  }

  public async uploadProductBulk(payload: UploadFileProductBulkRequest): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.PRODUCT}/bulk`,
      undefined,
      payload
    )
    return true
  }

  public async getHistoryLog(
    params: Map<string, string | number | undefined>
  ): Promise<HistoryLogBulkProducts> {
    const resp = await this.service.invoke(
      'GET',
      Endpoints.Route.HISTORY_LOG_BULK_UPLOAD_PRODUCT,
      params
    )

    return this.mapper.convertHistoryLogFromApi(resp)
  }
}
