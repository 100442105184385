import { injectable } from 'tsyringe'

import { DriverScoreboardRepositoryInterface } from '@/data/persistences/repositories/contracts/DriverScoreboardRepositoryInterface'
import {
  Drivers,
  DriverScoreboardConfigurations,
  DriverScoreReport,
  DriverScoreReportCalculate,
} from '@/domain/entities/DriverScoreboard'
import { UpdateDriverScoreRequestInterface } from '@/data/payload/contracts/DriverScoreboardRequest'

@injectable()
export class DriverScoreboardPresenter {
  private repository: DriverScoreboardRepositoryInterface

  constructor(repository: DriverScoreboardRepositoryInterface) {
    this.repository = repository
  }

  public getAll(): Promise<DriverScoreboardConfigurations> {
    return this.repository.getAll()
  }

  public updateConfiguration(
    payload: UpdateDriverScoreRequestInterface
  ): Promise<boolean> {
    return this.repository.updateConfiguration(payload)
  }

  public getDrivers(params: Map<string, string | number>): Promise<Drivers> {
    return this.repository.getDrivers(params)
  }

  public getDriverScoreReport(
    courierId: number,
    params: Map<string, string | number>
  ): Promise<DriverScoreReport> {
    return this.repository.getDriverScoreReport(courierId, params)
  }

  public getDriverScoreReportCalculate(
    courierId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<DriverScoreReportCalculate> {
    return this.repository.getDriverScoreReportCalculate(
      courierId,
      dateFrom,
      dateTo
    )
  }

  public exportDriverScoreReport(params: Map<string, string>): Promise<string> {
    return this.repository.exportDriverScoreReport(params)
  }
}
