import {
  VoucherConfigSetLimitRepositoryInterface
} from '@/data/persistences/repositories/contracts/VoucherConfigSetLimitRepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import {VoucherConfigSetLimitMapper} from '@/data/persistences/mappers/VoucherConfigSetLimitMapper'
import {VoucherConfigSetLimitRequestInterface} from '@/data/payload/contracts/VoucherConfigSetLimitRequest'
import {
  UploadBulkyVoucherSetLimitSpecificCustomer,
  VoucherSetLimit,
  VoucherSetLimitList
} from '@/domain/entities/VoucherConfigSetLimit'
import {Endpoints} from '@/app/infrastructures/misc'
import {UploadRequest} from '@/data/payload/api/UploadRequest'
import {CancelTokenSource} from 'axios'

export class VoucherConfigSetLimitRepository implements VoucherConfigSetLimitRepositoryInterface {
  private service: ApiService
  private mapper: VoucherConfigSetLimitMapper

  constructor(service: ApiService, mapper: VoucherConfigSetLimitMapper) {
    this.service = service
    this.mapper = mapper
  }

  async create(payload: VoucherConfigSetLimitRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit`,
      undefined,
      payload
    )
    return true
  }

  async update(id: number, payload: VoucherConfigSetLimitRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit/${id}`,
      undefined,
      payload
    )
    return true
  }

  async delete(id: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit/${id}`,
      undefined,
      undefined
    )
    return true
  }

  async getAll(params: Map<string, unknown>): Promise<VoucherSetLimitList> {
    const data = await this.service.invoke(
      'GET',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit`,
      params,
      undefined
    )
    return this.mapper.convertVoucherSetLimitList(data)
  }

  async getOne(id: number): Promise<VoucherSetLimit> {
    const data = await this.service.invoke(
      'GET',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit/${id}`,
      undefined,
      undefined
    )
    return this.mapper.convertVoucherSetLimit(data)
  }

  public async uploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    const customService = new ApiService(Endpoints.baseUrl, 200000, cancelToken)

    await customService.invoke(
      'POST',
      // `${Endpoints.Route.VOUCHER_CONFIG}/bulk-upload`,
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit/bulk-upload`,
      undefined,
      file
    )

    return true
  }

  public async previewUploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource,
    params?: Map<string, string>,
  ): Promise<UploadBulkyVoucherSetLimitSpecificCustomer> {
    const customService = new ApiService(Endpoints.baseUrl, 200000, cancelToken)

    const resp = await customService.invoke(
      'POST',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku-limit/bulk-upload/preview`,
      undefined,
      file,
    )
    return this.mapper.convertUploadBulkyFromApi(resp)
  }
}
