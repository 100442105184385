import { AxiosResponse } from 'axios'
import {
  Order,
  Orders,
  OrderDetail,
  ShortHistory,
  LongHistory,
  Product,
} from '@/domain/entities/Order'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'

export class OrderMapper extends BaseResponseMapper {
  public convertOrderFromApi(result: AxiosResponse): OrderDetail {
    const { data } = result
    return new OrderDetail(
      data.shipment_id,
      data.package_id,
      data.customer_id,
      data.buyer_name,
      data.buyer_email,
      data.buyer_phone_number,
      data.recipient_name,
      data.recipient_phone_number,
      data.recipient_address,
      data.invoice_id,
      data.note,
      data.sub_total,
      data.convenience_fee,
      data.delivery_fee,
      data.total_amount,
      data.courier_name,
      data.vehicle_plate,
      data.picture,
      data.payment_method,
      data.merchant_name,
      data.merchant_address,
      data.merchant_city,
      (data.short_histories as any[]).map(history =>
        Utils.toInstance(new ShortHistory(), history)
      ),
      (data.long_histories as any[]).map(history =>
        Utils.toInstance(new LongHistory(), history)
      ),
      (data.list_products as any[]).map(product =>
        Utils.toInstance(new Product(), product)
      ),
      data.shipping_type,
      data.prev_weight,
      data.curr_weight,
      data.prev_amount,
      data.curr_amount,
      data.diff_amount,
      data.is_overpay,
      data.is_underpay
    )
  }

  public convertOrdersFromApi(result: AxiosResponse): Orders {
    const {
      data: { data, pagination },
    } = result
    return new Orders(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (order: Record<string, string | number | string[]>) =>
          new Order(
            Number(order.customer_id),
            order.buyer_name as string,
            order.shipment_id as string,
            Number(order.merchant_id),
            order.merchant_name as string,
            order.order_date as string,
            order.status_id as string,
            order.short_status as string,
            order.products as string[],
            order.courier_name as string,
            order.vehicle_plate as string,
            order.picture as string,
            Number(order.total_amount),
            Number(order.sub_total),
            order.payment_method as string
          )
      )
    )
  }
}
