import { UpdateBonusPayrollRequestInterface } from "@/data/payload/contracts/PayrollBonusRequest";
import { PayrollBonusRepositoryInterface } from "@/data/persistences/repositories/contracts/PayrollBonusRepositoryInterface";
import { Bonus, BonusPayrolls } from "@/domain/entities/PayrollBonus";
import { injectable } from "tsyringe";

@injectable()
export class PayrollBonusPresenter implements PayrollBonusRepositoryInterface {
  private repository: PayrollBonusRepositoryInterface

  constructor(repository: PayrollBonusRepositoryInterface) {
    this.repository = repository
  }

  public getBonus(params: Map<string, string | number>): Promise<BonusPayrolls> {
    return this.repository.getBonus(params)
  }

  public get(id: string): Promise<Bonus> {
    return this.repository.get(id)
  }

  public export(params: Map<string, string | number>): Promise<string> {
    return this.repository.export(params)
  }
  
  public update( payload: UpdateBonusPayrollRequestInterface): Promise<boolean> {
    return this.repository.update(payload)
  }

  public recalculateBonus(params: Map<string, string | number>): Promise<boolean> {
    return this.repository.recalculateBonus(params)
  }
}
