export class Package {
  public packageId?: string
  public isManual?: boolean
  public origin?: string
  public destination?: string
  public sender?: Sender
  public recepient?: Recepient
  public productType?: string
  public serviceType?: string
  public quantity?: number
  public grossWeight?: number
  public volumeWeight?: number
  public chargeableWeight?: number
  public publishRate?: number
  public forwardRate?: number
  public shippingSurchargeRate?: number
  public commoditySurchargeRate?: number
  public heavyWeightSurchargeRate?: number
  public insuranceRate?: number
  public woodPackingRate?: number
  public isCod?: boolean
  public isWoodPacking?: boolean
  public isInsurance?: boolean
  public goodsValue?: number
  public codValue?: number
  public codFee?: number
  public createdAt?: string
  public updatedAt?: string
  public shipment?: Shipment
  public isAllowReorder?: boolean
  public histories?: History[]
  public estimatedDepartureDate?: string
  public location?: Location
  public invoiceStatus?: string

  constructor(
    packageId?: string,
    isManual?: boolean,
    origin?: string,
    destination?: string,
    sender?: Sender,
    recepient?: Recepient,
    productType?: string,
    serviceType?: string,
    quantity?: number,
    grossWeight?: number,
    volumeWeight?: number,
    chargeableWeight?: number,
    publishRate?: number,
    forwardRate?: number,
    shippingSurchargeRate?: number,
    commoditySurchargeRate?: number,
    heavyWeightSurchargeRate?: number,
    insuranceRate?: number,
    woodPackingRate?: number,
    isCod?: boolean,
    isWoodPacking?: boolean,
    isInsurance?: boolean,
    goodsValue?: number,
    codValue?: number,
    codFee?: number,
    createdAt?: string,
    updatedAt?: string,
    shipment?: Shipment,
    isAllowReorder?: boolean,
    histories?: History[],
    estimatedDepartureDate?: string,
    location?: Location,
    invoiceStatus?: string
  ) {
    this.packageId = packageId
    this.isManual = isManual
    this.origin = origin
    this.destination = destination
    this.sender = sender
    this.recepient = recepient
    this.productType = productType
    this.serviceType = serviceType
    this.quantity = quantity
    this.grossWeight = grossWeight
    this.volumeWeight = volumeWeight
    this.chargeableWeight = chargeableWeight
    this.publishRate = publishRate
    this.forwardRate = forwardRate
    this.shippingSurchargeRate = shippingSurchargeRate
    this.commoditySurchargeRate = commoditySurchargeRate
    this.heavyWeightSurchargeRate = heavyWeightSurchargeRate
    this.insuranceRate = insuranceRate
    this.woodPackingRate = woodPackingRate
    this.isCod = isCod
    this.isWoodPacking = isWoodPacking
    this.isInsurance = isInsurance
    this.goodsValue = goodsValue
    this.codValue = codValue
    this.codFee = codFee
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.shipment = shipment
    this.isAllowReorder = isAllowReorder
    this.histories = histories
    this.estimatedDepartureDate = estimatedDepartureDate
    this.location = location
    this.invoiceStatus = invoiceStatus
  }
}

export class Sender {
  public name?: string
  public address?: string
  public phone?: string

  constructor(name?: string, address?: string, phone?: string) {
    this.name = name
    this.address = address
    this.phone = phone
  }
}

export class Recepient {
  public name?: string
  public address?: string
  public phone?: string

  constructor(name?: string, address?: string, phone?: string) {
    this.name = name
    this.address = address
    this.phone = phone
  }
}

export class Shipment {
  public shipmentId?: string
  public shipmentStatus?: string
  public shipmentType?: string
  public shipmentGroupId?: string
  public customerId?: number
  public customerRole?: string
  public createdAt?: string
  public shippingType?: string

  constructor(
    shipmentId?: string,
    shipmentStatus?: string,
    shipmentType?: string,
    shipmentGroupId?: string,
    customerId?: number,
    customerRole?: string,
    createdAt?: string,
    shippingType?: string
  ) {
    this.shipmentId = shipmentId
    this.shipmentStatus = shipmentStatus
    this.shipmentType = shipmentType
    this.shipmentGroupId = shipmentGroupId
    this.customerId = customerId
    this.customerRole = customerRole
    this.createdAt = createdAt
    this.shippingType = shippingType
  }
}

export class History {
  public historyId?: number
  public historyType?: string
  public location?: string
  public city?: string
  public statusId?: string
  public status?: string
  public longStatus?: string
  public actorId?: number
  public actorRole?: string
  public actorName?: string
  public reasonId?: string
  public reason?: string
  public relationId?: string
  public relation?: string
  public personName?: string
  public note?: string
  public meta?: Meta
  public createdAt?: string
  public driverNumber?: string
  public metaLifeTime?: number
  public hasMeta?: boolean

  constructor(
    historyId?: number,
    historyType?: string,
    location?: string,
    city?: string,
    statusId?: string,
    status?: string,
    longStatus?: string,
    actorId?: number,
    actorRole?: string,
    actorName?: string,
    reasonId?: string,
    reason?: string,
    relationId?: string,
    relation?: string,
    personName?: string,
    note?: string,
    meta?: Meta,
    createdAt?: string,
    driverNumber?: string,
    metaLifeTime?: number,
    hasMeta?: boolean
  ) {
    this.historyId = historyId
    this.historyType = historyType
    this.location = location
    this.city = city
    this.statusId = statusId
    this.status = status
    this.longStatus = longStatus
    this.actorId = actorId
    this.actorRole = actorRole
    this.actorName = actorName
    this.reasonId = reasonId
    this.reason = reason
    this.relationId = relationId
    this.relation = relation
    this.personName = personName
    this.note = note
    this.meta = meta
    this.createdAt = createdAt
    this.driverNumber = driverNumber
    this.metaLifeTime = metaLifeTime
    this.hasMeta = hasMeta
  }
}

export class Meta {
  public proofs?: string[]
  public quantityConfirm?: number
  public quantityOriginal?: number
  public signature?: string

  constructor(
    proofs?: string[],
    quantityConfirm?: number,
    quantityOriginal?: number,
    signature?: string
  ) {
    this.proofs = proofs
    this.quantityConfirm = quantityConfirm
    this.quantityOriginal = quantityOriginal
    this.signature = signature
  }
}

export class Location {
  public latitude?: number
  public longitude?: number

  constructor(latitude?: number, longitude?: number) {
    this.latitude = latitude
    this.longitude = longitude
  }
}
