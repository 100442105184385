import { Pagination } from '@/domain/entities/Pagination'

export class UpdateAppVersion {
  public id: number
  public os: string
  public status: string
  public forceVersion: string
  public recommendVersion: string
  public versionValidation: string
  public createdAt: string
  public publishDateTime: string
  public updatedAt?: string
  public deletedAt?: string

  constructor(
    id: number,
    os: string,
    status: string,
    forceVersion: string,
    recommendVersion: string,
    versionValidation: string,
    createdAt: string,
    publishDateTime: string,
    updatedAt?: string,
    deletedAt?: string
  ) {
    this.id = id
    this.os = os
    this.status = status
    this.forceVersion = forceVersion
    this.recommendVersion = recommendVersion
    this.versionValidation = versionValidation
    this.createdAt = createdAt
    this.publishDateTime = publishDateTime
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
  }
}

export class UpdateAppVersions {
  public pagination: Pagination
  public data: UpdateAppVersion[]

  constructor(pagination: Pagination, data: UpdateAppVersion[]) {
    this.pagination = pagination
    this.data = data
  }
}
