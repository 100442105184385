import { injectable } from 'tsyringe'
import {
  ManageCaptainDetail,
  ManageCaptains,
} from '@/domain/entities/ManageCaptain'
import { ManageCaptainRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageCaptainRepositoryInterface'
import {
  CreateManageCaptainRequestInterface,
  UpdateManageCaptainRequestInterface,
} from '@/data/payload/contracts/ManageCaptainRequest'

@injectable()
export class ManageCaptainPresenter {
  private repository: ManageCaptainRepositoryInterface

  constructor(repository: ManageCaptainRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number>): Promise<ManageCaptains> {
    return this.repository.getAll(params)
  }

  public get(id: number): Promise<ManageCaptainDetail> {
    return this.repository.get(id)
  }

  public create(
    payload: CreateManageCaptainRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    id: string,
    payload: UpdateManageCaptainRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }
}
