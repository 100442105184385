import ApiService from '@/app/infrastructures/services/ApiService'
import { PointPurpose } from '@/domain/entities/PointPurpose'
import { PointPurposeRepositoryInterface } from '@/data/persistences/repositories/contracts/PointPurposeRepositoryInterface'
import { CreatePointPurposeRequestInterface } from '@/data/payload/contracts/PointPurposeRequest'
import { PointPurposeMapper } from '@/data/persistences/mappers/PointPurposeMapper'
import { Endpoints } from '@/app/infrastructures/misc'

export class PointPurposeApiRepository
  implements PointPurposeRepositoryInterface {
  private service: ApiService
  private mapper: PointPurposeMapper

  constructor(service: ApiService, mapper: PointPurposeMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async create(
    payload: CreatePointPurposeRequestInterface
  ): Promise<boolean> {
    throw new Error('Method not implemented.')
  }

  public async getAll(params: Map<string, any>): Promise<PointPurpose[]> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.POINT_PURPOSE,
      undefined
    )

    return this.mapper.convertPointPurposesFromApi(resp)
  }

  public async getActive(params: Map<string, any>): Promise<PointPurpose[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.POINT_PURPOSE}/active`,
      undefined
    )

    return this.mapper.convertPointPurposesFromApi(resp)
  }

  public async activate(id: string): Promise<boolean> {
    throw new Error('Method not implemented.')
  }

  public async delete(id: string): Promise<boolean> {
    throw new Error('Method not implemented.')
  }
}
