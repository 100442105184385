import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import {
  GeoAddress,
  ManageCourierDedicatedDetail,
  ManageCourierDedicateds,
} from '@/domain/entities/ManageCourierDedicated'
import { ManageCourierDedicatedRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageCourierDedicatedRepositoryInterface'
import { ManageCourierDedicatedMapper } from '@/data/persistences/mappers/ManageCourierDedicatedMapper'
import {
  CreateManageCourierDedicatedRequestInterface,
  UpdateManageCourierDedicatedRequestInterface,
} from '@/data/payload/contracts/ManageCourierDedicatedRequest'

export class ManageCourierDedicatedApiRepository
  implements ManageCourierDedicatedRepositoryInterface {
  private service: ApiService
  private mapper: ManageCourierDedicatedMapper

  constructor(service: ApiService, mapper: ManageCourierDedicatedMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(
    params: Map<string, number>
  ): Promise<ManageCourierDedicateds> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AREA_DEDICATED}/list`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertManageCourierDedicatedListFromApi(resp)
  }

  public async getAllCourierDedicated(
    params: Map<string, number>
  ): Promise<ManageCourierDedicateds> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.COURIER_DEDICATED}/list`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertCourierDedicatedListFromApi(resp)
  }

  public async getGeoAddress(
    params: Map<string, string>
  ): Promise<Array<GeoAddress>> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AREA_DEDICATED}/geo_address`,
      params
    )

    return this.mapper.convertGeoAddressListFromApi(resp)
  }

  public async delete(id: number): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.AREA_DEDICATED}/delete/${id}`,
      undefined
    )

    return true
  }

  public async get(id: number): Promise<ManageCourierDedicatedDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AREA_DEDICATED}/detail/${id}`
    )

    return this.mapper.convertDetailManageCourierDedicatedFromApi(resp)
  }

  public async create(
    payload: CreateManageCourierDedicatedRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.AREA_DEDICATED}/create`,
      undefined,
      <CreateManageCourierDedicatedRequestInterface>payload
    )

    return true
  }

  public async getMaxConfig(): Promise<number> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AREA_DEDICATED}/max_courier`
    )

    return this.mapper.convertMaxCourierConfigFromApi(resp)
  }

  public async update(
    id: number,
    payload: UpdateManageCourierDedicatedRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.AREA_DEDICATED}/update/${id}`,
      undefined,
      <UpdateManageCourierDedicatedRequestInterface>payload
    )

    return true
  }
}
