import {injectable} from 'tsyringe'
import {
  VoucherConfigSKURepositoryInterface
} from '@/data/persistences/repositories/contracts/VoucherConfigSKURepositoryInterface'
import {VoucherConfigSKUInterface} from '@/data/payload/contracts/VoucherConfigSKURequest'
import {
  UploadBulkyVoucherSKU,
  VoucherSKU,
  VoucherSKUList,
} from '@/domain/entities/VoucherConfigSKU'
import {UploadRequest} from '@/data/payload/api/UploadRequest'
import {CancelTokenSource} from 'axios'


@injectable()
export class VoucherConfigSKUPresenter {
  private readonly repository: VoucherConfigSKURepositoryInterface

  constructor(repo: VoucherConfigSKURepositoryInterface) {
    this.repository = repo
  }

  public getAll(params: Map<string, unknown>): Promise<VoucherSKUList> {
    return this.repository.getAll(params);
  }

  public getOne(id: number): Promise<VoucherSKU> {
    return this.repository.getOne(id);
  }

  public create(payload: VoucherConfigSKUInterface): Promise<boolean> {
    return this.repository.create(payload);
  }

  public update(id: number, payload: VoucherConfigSKUInterface): Promise<boolean> {
    return this.repository.update(id, payload);
  }

  public delete(id: number): Promise<boolean> {
    return this.repository.delete(id);
  }

  public uploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    return this.repository.uploadBulky(file, cancelToken)
  }

  public previewUploadBulky(file: UploadRequest, cancelToken: CancelTokenSource, params?: Map<string, string>): Promise<UploadBulkyVoucherSKU> {
    return this.repository.previewUploadBulky(file, cancelToken, params)
  }

}
