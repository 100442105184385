import { injectable } from 'tsyringe'

import { CODTrackerRepositoryInterface } from '@/data/persistences/repositories/contracts/CODTrackerRepositoryInterface'
import { CODTrackerDetail, CODTrackers } from '@/domain/entities/CODTracker'
import { OriginRoute } from '@/domain/entities/Route'

@injectable()
export class CODTrackerPresenter {
  private repository: CODTrackerRepositoryInterface

  constructor(repository: CODTrackerRepositoryInterface) {
    this.repository = repository
  }

  public getOrigins(): Promise<OriginRoute[]> {
    return this.repository.getOrigins()
  }

  public getCODTrackers(
    params: Map<string, string | number>
  ): Promise<CODTrackers> {
    return this.repository.getCODTrackers(params)
  }

  public exportCODReport(params: Map<string, string>): Promise<string> {
    return this.repository.exportCODReport(params)
  }

  public getCODDetail(packageId: string): Promise<CODTrackerDetail> {
    return this.repository.getCODDetail(packageId)
  }
}
