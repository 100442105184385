import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import {
  CourierDedicatedArea,
  CourierDedicated,
  CourierDedicatedList,
  CourierDedicatedListItem,
  CourierItem,
  GeoAddress,
  GeoAddressDedicated,
  ManageCourierDedicated,
  ManageCourierDedicatedDetail,
  ManageCourierDedicateds,
} from '@/domain/entities/ManageCourierDedicated'
import {
  AreaDedicatedItemResponse,
  CourierDedicatedListItemResponse,
  CourierItemResponse,
  CourierResponse,
  GeoAddressDistrictResponse,
  GeoAddressProvinceResponse,
  GeoAddressResponse,
  GeoAddressSubDistrictResponse,
  GeoAddressVillageResponse,
  ManageCourierDedicatedListItemResponse,
} from '@/data/responses/contracts/ManageCourierDedicatedResponse'

export class ManageCourierDedicatedMapper extends BaseResponseMapper {
  public convertManageCourierDedicatedListFromApi(
    result: AxiosResponse
  ): ManageCourierDedicateds {
    const {
      data: { data, pagination },
    } = result

    return new ManageCourierDedicateds(
      new Pagination(pagination.page, pagination.limit, pagination.total_item),
      data.map(
        (item: ManageCourierDedicatedListItemResponse) =>
          new ManageCourierDedicated(
            item.area_id,
            item.area_name,
            item.geo_address.map((geoItem: GeoAddressResponse) => {
              return new GeoAddressDedicated(
                geoItem.id,
                geoItem.province,
                geoItem.district,
                geoItem.sub_district,
                geoItem.village
              )
            }),
            item.courier_mp.map((courier: CourierResponse) => {
              return new CourierDedicated(
                courier.id,
                courier.name,
                courier.courier_type,
                courier.phone
              )
            }),
            item.courier_ca.map((courier: CourierResponse) => {
              return new CourierDedicated(
                courier.id,
                courier.name,
                courier.courier_type,
                courier.phone
              )
            }),
            item.courier_corporate.map((courier: CourierResponse) => {
              return new CourierDedicated(
                courier.id,
                courier.name,
                courier.courier_type,
                courier.phone
              )
            }),
            item.offer_type,
            item.is_active,
            item.created_at,
            item.updated_at
          )
      )
    )
  }

  public convertGeoAddressListFromApi(
    result: AxiosResponse
  ): Array<GeoAddress> {
    const {
      data: { data },
    } = result

    return data.map((province: GeoAddressProvinceResponse) => {
      return new GeoAddress(
        province.province_name,
        province.district.map((district: GeoAddressDistrictResponse) => {
          return new GeoAddress(
            district.district_name,
            district.sub_district.map(
              (subDistrict: GeoAddressSubDistrictResponse) => {
                return new GeoAddress(
                  subDistrict.sub_district_name,
                  subDistrict.village.map(
                    (village: GeoAddressVillageResponse) => {
                      return new GeoAddress(
                        village.village_name,
                        [],
                        village.geo_address_id,
                        village.area_id
                      )
                    }
                  )
                )
              }
            )
          )
        })
      )
    })
  }

  public convertDetailManageCourierDedicatedFromApi(
    result: AxiosResponse
  ): ManageCourierDedicatedDetail {
    const {
      data: { data },
    } = result

    return new ManageCourierDedicatedDetail(
      data.area_id,
      data.area_name,
      data.geo_address_ids,
      data.courier_mp.map((courier: CourierItemResponse) => {
        return new CourierItem(
          courier.courier_id,
          courier.name,
          courier.courier_type,
          courier.phone,
          courier.partner_name
        )
      }),
      data.courier_ca.map((courier: CourierItemResponse) => {
        return new CourierItem(
          courier.courier_id,
          courier.name,
          courier.courier_type,
          courier.phone,
          courier.partner_name
        )
      }),
      data?.courier_corporate ? data.courier_corporate.map((courier: CourierItemResponse) => {
        return new CourierItem(
          courier.courier_id,
          courier.name,
          courier.courier_type,
          courier.phone,
          courier.partner_name
        )
      }) : [],
      data.offer_type,
      data.is_active,
      data.created_at,
      data.updated_at,
      data.created_by,
      data.updated_by
    )
  }

  public convertMaxCourierConfigFromApi(result: AxiosResponse): number {
    const {
      data: { data },
    } = result
    return data.max_courier
  }

  public convertCourierDedicatedListFromApi(
    result: AxiosResponse
  ): CourierDedicatedList {
    const {
      data: { data, pagination },
    } = result

    return new CourierDedicatedList(
      new Pagination(pagination.page, pagination.limit, pagination.total_item),
      data.map(
        (item: CourierDedicatedListItemResponse) => {
          const areaMP = item.area_dedicated_mp || []
          const areaCA = item.area_dedicated_ca || []
          const areaClient = item.area_dedicated_client || []

          return new CourierDedicatedListItem(
            item.courier_id,
            item.courier_name,
            item.courier_type,
            item.courier_phone,
            item.partner_name,
            areaMP.map((courier: AreaDedicatedItemResponse) => {
              return new CourierDedicatedArea(
                courier.area_id,
                courier.area_name,
              )
            }),
            areaCA.map((courier: AreaDedicatedItemResponse) => {
              return new CourierDedicatedArea(
                courier.area_id,
                courier.area_name,
              )
            }),
            areaClient.map((courier: AreaDedicatedItemResponse) => {
              return new CourierDedicatedArea(
                courier.area_id,
                courier.area_name,
              )
            })
          )
        })
    )
  }
}
