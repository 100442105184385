import { injectable } from 'tsyringe'
import qs from 'qs'
import Axios, {
  AxiosInstance,
  Method,
  AxiosResponse,
  CancelTokenSource,
} from 'axios'
import Vue from 'vue'
import router from '@/app/ui/router'
import { ErrorResponse, ErrorMessage } from '@/domain/entities/BaseResponse'
import { Endpoints, LocalStorage } from '../misc'
import { Utils } from './../misc/Utils'

@injectable()
export default class ApiService {
  public client: AxiosInstance

  constructor(
    baseUrl: string = Endpoints.baseUrl,
    timeout: number | undefined = 30000,
    cancelToken?: CancelTokenSource // implement cancel by user
  ) {
    this.client = Axios.create({
      baseURL: baseUrl,
      timeout: timeout,
      cancelToken: cancelToken ? cancelToken.token : undefined,
    })

    this.client.interceptors.response.use(
      response => {
        return response
      },
      function(error) {
        // check if user cancel the request
        if (Axios.isCancel(error)) {
          return Promise.reject({
            data: {
              message: {
                en: 'Cancel by User',
                id: 'Dibatalkan User',
              },
            },
          })
        }

        if (error.response.status === 401) {
          // IF TOKEN EXPIRED
          LocalStorage.removeAllLocalStorage()
          router.push({ name: 'LoginPage' })
        }
        if (error.response.status === 403) {
          Vue.notify({
            title: 'Forbidden Access',
            text: 'Your Account is not permitted to request to some endpoints',
            type: 'error',
            duration: 8000,
          })
        }
        return Promise.reject(error.response)
      }
    )
  }

  public async invoke(
    method: Method,
    url: string,
    params: Map<string, any> = new Map<string, any>(),
    payload: any = null,
    headers: Map<string, string> = new Map<string, string>()
  ): Promise<AxiosResponse<any>> {
    // content-type application/json
    if (!headers.has('Content-Type')) {
      this.client.defaults.headers['Content-Type'] = 'application/json'
    }
    // set custom header if any
    headers.forEach((value: string, key: string) => {
      this.client.defaults.headers[key] = value
    })

    // set auth bearer
    this.client.defaults.headers.Authorization =
      'Bearer ' + LocalStorage.getLocalStorage('auth_token')
    return await this.client
      .request({
        url,
        params,
        paramsSerializer: par => qs.stringify(par, { encode: false }),
        data: payload ? payload.toPayload() : null,
        method,
      })
      .catch(onRejected => {
        if (!onRejected.data) {
          onRejected.error = new ErrorResponse('500', <ErrorMessage>{
            id: 'Tidak dapat menyambungkan ke server',
            en: 'Cannot connect to server',
          })
        } else {
          const message = Utils.toInstance(
            new ErrorMessage(),
            onRejected.data.message
          )
          onRejected.error = new ErrorResponse(
            onRejected.data.error_id,
            message
          )
        }
        return Promise.reject(onRejected)
      })
  }
}
