export class LocalStorage {
  public static TOKEN_KEY = 'auth_token'
  public static TOKEN_EXPIRED = 'auth_token_expired'
  public static LOGIN_IDENTITY = 'login_identity'
  public static ACCOUNT_ID = 'account_id'
  public static ACCESS_MENU = 'access_menus'
  public static MY_ROLE = 'my_role'

  public static setLocalStorage(
    key: string,
    value: unknown,
    encrypt?: boolean
  ): void {
    if (value && typeof value === 'string') {
      localStorage.setItem(key, encrypt ? window.btoa(value) : value)
      return
    }

    localStorage.setItem(
      key,
      encrypt ? window.btoa(JSON.stringify(value)) : JSON.stringify(value)
    )
  }

  public static getLocalStorage(key: string, decrypt?: boolean): string {
    const data = localStorage.getItem(key)
    try {
      return decrypt
        ? window.atob(JSON.parse(data as string))
        : JSON.parse(data as string)
    } catch (e) {
      return (decrypt ? window.atob(data as string) : data) as string
    }
  }

  public static removeLocalStorage(key: string): void {
    localStorage.removeItem(key)
  }

  public static removeAllLocalStorage(): void {
    localStorage.clear()
  }
}
