import { LoggingPubSubRequestInterface } from '@/data/payload/contracts/MixPanelRequest'

export class LoggingPubSubApiRequest implements LoggingPubSubRequestInterface {
  public logType: string
  public projectId: string
  public userId: string
  public username: string
  public action: string

  constructor(
    logType: string,
    projectId: string,
    userId: string,
    username: string,
    action: string
  ) {
    this.logType = logType
    this.projectId = projectId
    this.userId = userId
    this.username = username
    this.action = action
  }

  public toPayload(): string {
    const data = {
      log_type: this.logType,
      action: this.action,
      project_id: this.projectId,
      data: {
        user_id: parseInt(this.userId),
        username: this.username,
      }
    }
    return JSON.stringify({
      ...data,
    })
  }
}
