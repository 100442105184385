import {
  CreateHomepageBannerRequestInterface,
  UpdateHomepageBannerRequestInterface,
  HomepageBannerRepositionRequestInterface,
} from '@/data/payload/contracts/HomepageBannerRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { HomepageBannerRepositoryInterface } from '@/data/persistences/repositories/contracts/HomepageBannerRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { HomepageBannerMapper } from '@/data/persistences/mappers/HomepageBannerMapper'
import {
  HomepageBanner,
  HomepageBanners,
} from '@/domain/entities/HomepageBanner'
import { ErrorMessage } from '@/domain/entities/BaseResponse'

export class HomepageBannerApiRepository
  implements HomepageBannerRepositoryInterface {
  private service: ApiService
  private mapper: HomepageBannerMapper

  constructor(service: ApiService, mapper: HomepageBannerMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(homepageBannerId: number): Promise<HomepageBanner> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.HOMEPAGE_BANNER}/${homepageBannerId}`
    )
    return this.mapper.convertHomepageBannerFromApi(resp)
  }

  public async getAll(
    params: Map<string, string | number | undefined>
  ): Promise<HomepageBanners> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.HOMEPAGE_BANNER,
      params
    )
    return this.mapper.convertHomepageBannersFromApi(resp)
  }

  public async getActiveBanners(): Promise<HomepageBanner[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.HOMEPAGE_BANNER}/active`
    )
    return this.mapper.convertActiveHomepageBannersFromApi(resp)
  }

  public async create(
    payload: CreateHomepageBannerRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.HOMEPAGE_BANNER,
      undefined,
      payload as CreateHomepageBannerRequestInterface
    )
    return true
  }

  public async update(
    homepageBannerId: number,
    payload: UpdateHomepageBannerRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.HOMEPAGE_BANNER}/${homepageBannerId}`,
      undefined,
      payload as UpdateHomepageBannerRequestInterface
    )
    return true
  }

  public async reposition(
    payload: HomepageBannerRepositionRequestInterface
  ): Promise<ErrorMessage | null> {
    const res = await this.service.invoke(
      'patch',
      `${Endpoints.Route.HOMEPAGE_BANNER}/reposition`,
      undefined,
      payload as HomepageBannerRepositionRequestInterface
    )

    return res.data.message
      ? new ErrorMessage(res.data.message.en, res.data.message.id)
      : null
  }

  public async cancel(id: number): Promise<boolean> {
    await this.service.invoke(
      'patch',
      `${Endpoints.Route.HOMEPAGE_BANNER}/${id}/cancel`
    )
    return true
  }

  public async countActiveBanner(): Promise<number> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.HOMEPAGE_BANNER}/active/count`
    )
    return resp.data.count as number
  }
}
