import { Pagination } from '@/domain/entities/Pagination'

export class ManageCouriers {
  public pagination?: Pagination
  public data?: ManageCourier[]

  constructor(pagination?: Pagination, data?: ManageCourier[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCouriersAdjustSaldo {
  public pagination?: Pagination
  public data?: ManageCourierAdjustSaldo[]

  constructor(pagination?: Pagination, data?: ManageCourierAdjustSaldo[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCourierHistoryLogs {
  public pagination?: Pagination
  public data?: ManageCourierHistoryLog

  constructor(pagination?: Pagination, data?: ManageCourierHistoryLog) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCourierUploadBulk {
  public id?: number;
  public createdBy?: string;
  public createdAt?: string;
  public dataUpload?: ManageCourierUploadBulkDataUpload;

  constructor(
    id?: number,
    createdBy?: string,
    createdAt?: string,
    dataUpload?: ManageCourierUploadBulkDataUpload,
  ) {
    this.id = id
    this.createdBy = createdBy
    this.createdAt = createdAt
    this.dataUpload = dataUpload
  }
}

export class ManageCourierHistoryUploadBulks {
  public pagination?: Pagination
  public data?: ManageCourierHistoryUploadBulk

  constructor(
    pagination?: Pagination,
    data?: ManageCourierHistoryUploadBulk
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCourierOnLeaves {
  public pagination?: Pagination
  public data?: ManageCourierOnLeave[]

  constructor(pagination?: Pagination, data?: ManageCourierOnLeave[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCourier {
  public courierId?: number
  public fullName?: string
  public role?: string
  public phoneNumber?: string
  public vehicleType?: string
  public vehiclePlate?: string
  public partnerType?: string
  public partnerName?: string
  public origin?: string
  public courierType?: string
  public status?: string
  public courierTypeValid?: string
  public courierIsCod?: boolean

  constructor(
    courierId?: number,
    fullName?: string,
    role?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerType?: string,
    partnerName?: string,
    origin?: string,
    courierType?: string,
    status?: string,
    courierTypeValid?: string,
    courierIsCod?: boolean
  ) {
    this.courierId = courierId
    this.fullName = fullName
    this.role = role
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerType = partnerType
    this.partnerName = partnerName
    this.origin = origin
    this.courierType = courierType
    this.status = status
    this.courierTypeValid = courierTypeValid
    this.courierIsCod = courierIsCod
  }
}

export class ManageCourierBankInformation {
  public bankAccountName: string
  public bankId: string
  public bankName: string
  public bankAccount: string

  constructor(
    bankAccountName: string,
    bankId: string,
    bankName: string,
    bankAccount: string,
  ) {
    this.bankAccountName = bankAccountName;
    this.bankId = bankId;
    this.bankName = bankName;
    this.bankAccount = bankAccount;
  }
}
export class ManageCourierDetail {
  public courierId: number
  public fullName: string
  public role: string
  public phoneNumber: string
  public vehicleType: string
  public vehiclePlate: string
  public partnerType: string
  public partnerName: string
  public courierType: string
  public status: string
  public bankInformation: ManageCourierBankInformation
  public announcementCourierType: string
  public courierCreatedAt?: string
  public courierCreatedBy?: string
  public courierUpdatedAt?: string
  public courierUpdatedBy?: string
  public courierOnLeave?: CourierOnLeave[]
  public truckVendor?: TruckVendor
  public courierIsCod?: string

  constructor(
    courierId: number,
    fullName: string,
    role: string,
    phoneNumber: string,
    vehicleType: string,
    vehiclePlate: string,
    partnerType: string,
    partnerName: string,
    courierType: string,
    status: string,
    bankInformation: ManageCourierBankInformation,
    announcementCourierType: string,
    courierCreatedAt?: string,
    courierCreatedBy?: string,
    courierUpdatedAt?: string,
    courierUpdatedBy?: string,
    courierOnLeave?: CourierOnLeave[],
    truckVendor?: TruckVendor,
    courierIsCod?: string
  ) {
    this.courierId = courierId
    this.fullName = fullName
    this.role = role
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerType = partnerType
    this.partnerName = partnerName
    this.courierType = courierType
    this.status = status
    this.bankInformation = bankInformation
    this.announcementCourierType = announcementCourierType
    this.courierCreatedAt = courierCreatedAt
    this.courierCreatedBy = courierCreatedBy
    this.courierUpdatedAt = courierUpdatedAt
    this.courierUpdatedBy = courierUpdatedBy
    this.courierOnLeave = courierOnLeave
    this.truckVendor = truckVendor
    this.courierIsCod = courierIsCod
  }
}

export class ManageCourierAdjustSaldo {
  public courierId?: number
  public fullName?: string
  public phoneNumber?: string
  public announcementCourierType?: string
  public partnerName?: string

  constructor(
    courierId?: number,
    fullName?: string,
    phoneNumber?: string,
    announcementCourierType?: string,
    partnerName?: string
  ) {
    this.courierId = courierId
    this.fullName = fullName
    this.phoneNumber = phoneNumber
    this.announcementCourierType = announcementCourierType
    this.partnerName = partnerName
  }
}

export class Partner {
  public agentId?: number
  public name?: string
  public agentCode?: string
  public agentType?: string

  constructor(
    agentId?: number,
    name?: string,
    agentCode?: string,
    agentType?: string
  ) {
    this.agentId = agentId
    this.name = name
    this.agentCode = agentCode
    this.agentType = agentType
  }
}

export class CourierHistoryLog {
  public column?: string
  public before?: string
  public after?: string
  public updatedAt?: string
  public updatedBy?: string

  constructor(
     column?: string,
     before?: string,
     after?: string,
     updatedAt?: string,
     updatedBy?: string,
  ) {
    this.column = column
    this.before = before
    this.after = after
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
  }
}

export class ManageCourierHistoryLog {
  public courierData: ManageCourier
  public logCourierData: CourierHistoryLog[]

  constructor(
    courierData: ManageCourier,
    logCourierData: CourierHistoryLog[]
  ) {
    this.courierData = courierData
    this.logCourierData = logCourierData
  }
}

export class CourierOnLeave {
  public id?: number
  public dateLeave?: string
  public startTime?: string
  public endTime?: string
  public isCustomLeave?: boolean
  public isDelete?: boolean
  public isDisabled?: boolean

  constructor(
    id?: number,
    dateLeave?: string,
    startTime?: string,
    endTime?: string,
    isCustomLeave?: boolean,
    isDelete?: boolean,
    isDisabled?: boolean,
  ) {
    this.id = id
    this.dateLeave = dateLeave
    this.startTime = startTime
    this.endTime = endTime
    this.isCustomLeave = isCustomLeave
    this.isDelete = isDelete
    this.isDisabled = isDisabled
  }
}

export class ManageCourierOnLeave {
  public courierId?: number
  public courierOnLeaveId?: number
  public role?: string
  public fullName?: string
  public phoneNumber?: string
  public vehicleType?: string
  public vehiclePlate?: string
  public partnerName?: string
  public courierType?: string
  public courierTypeValid?: string
  public courierOnLeave?: CourierOnLeave
  public requestedBy?: string

  constructor (
    courierId?: number,
    courierOnLeaveId?: number,
    role?: string,
    fullName?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerName?: string,
    courierType?: string,
    courierTypeValid?: string,
    courierOnLeave?: CourierOnLeave,
    requestedBy?: string
  ) {
    this.courierId = courierId
    this.courierOnLeaveId = courierOnLeaveId
    this.role = role
    this.fullName = fullName
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerName = partnerName
    this.courierType = courierType
    this.courierTypeValid = courierTypeValid
    this.courierOnLeave = courierOnLeave
    this.requestedBy = requestedBy
  }
}

export class ManageCourierUploadBulkDataUpload {
  public filename?: string;
  public filepath?: string
  public summary?: ManageCourierUploadBulkSummary;
  public rows?: ManageCourierUploadBulkRow[];
  public pagination?: Pagination;

  constructor(
    filename?: string,
    filepath?: string,
    summary?: ManageCourierUploadBulkSummary,
    rows?: ManageCourierUploadBulkRow[],
    pagination?: Pagination,
  ) {
    this.filename = filename
    this.filepath = filepath
    this.summary = summary
    this.rows = rows
    this.pagination = pagination
  }
}

export class ManageCourierUploadBulkRow {
  public no?: number;
  public courierId?: string;
  public fullName?: string;
  public dateLeave?: string;
  public startTime?: string;
  public endTime?: string;
  public isCustomLeave?: boolean;
  public note?: string;
  public status?: string;
  public reason?: string;

  constructor(
    no?: number,
    courierId?: string,
    fullName?: string,
    dateLeave?: string,
    startTime?: string,
    endTime?: string,
    isCustomLeave?: boolean,
    note?: string,
    status?: string,
    reason?: string,
  ) {
    this.no = no
    this.courierId = courierId
    this.fullName = fullName
    this.dateLeave = dateLeave
    this.startTime = startTime
    this.endTime = endTime
    this.isCustomLeave = isCustomLeave
    this.note = note
    this.status = status
    this.reason = reason
  }
}

export class ManageCourierUploadBulkSummary {
  public rowValid?: number;
  public rowInvalid?: number;
  public courierValid?: number;
  public courierInvalid?: number;

  constructor(
    rowValid?: number,
    rowInvalid?: number,
    courierValid?: number,
    courierInvalid?: number,
  ) {
    this.rowValid = rowValid
    this.rowInvalid = rowInvalid
    this.courierValid = courierValid
    this.courierInvalid = courierInvalid
  }
}

export class ManageCourierHistoryUploadBulk {
  public id?: number
  public fileName?: string
  public filePath?: string
  public createdAt?: string
  public createdBy?: string

  constructor(
    id?: number,
    fileName?: string,
    filePath?: string,
    createdAt?: string,
    createdBy?: string
  ) {
    this.id = id
    this.fileName = fileName
    this.filePath = filePath
    this.createdAt = createdAt
    this.createdBy = createdBy
  }
}

export class TruckVendor {
  public id?: number
  public vendorId?: string
  public vendorName?: string

  constructor(
    id?: number,
    vendorId?: string,
    vendorName?: string
  ) {
    this.id = id
    this.vendorId = vendorId
    this.vendorName = vendorName
  }
}

export class ManageCourierLeaveRequest {
  public id?: number
  public dateLeave?: string
  public startTime?: string
  public endTime?: string
  public isCustomLeave?: boolean
  public reason?: string
  public courier?: ManageCourier

  constructor(
    id?: number,
    dateLeave?: string,
    startTime?: string,
    endTime?: string,
    isCustomLeave?: boolean,
    reason?: string,
    courier?: ManageCourier
  ) {
    this.id = id
    this.dateLeave = dateLeave
    this.startTime = startTime
    this.endTime = endTime
    this.isCustomLeave = isCustomLeave
    this.reason = reason
    this.courier = courier
  }
}

export class ManageCourierLeaveRequests {
  public pagination?: Pagination
  public data?: ManageCourierLeaveRequest[]

  constructor(pagination?: Pagination, data?: ManageCourierLeaveRequest[]) {
    this.pagination = pagination
    this.data = data
  }
}
