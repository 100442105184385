import { AxiosResponse } from 'axios'
import {
  CODTrackers,
  CODTracker,
  Metadata,
  CODTrackerDetail,
  CODMeta,
  ShipmentCODRejectDetail,
  PackageCODRejectDetail,
  DeliveryCODRejectDetail,
  DEXHistory,
} from '@/domain/entities/CODTracker'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'
import { OriginRoute } from '@/domain/entities/Route'

export class CODTrackerMapper extends BaseResponseMapper {
  public convertCODTrackersFromApi(result: AxiosResponse<any>): CODTrackers {
    const {
      data: { data, pagination, metadata },
    } = result
    return new CODTrackers(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (cod: Record<string, string | number>) =>
          new CODTracker(
            cod.package_id as string,
            cod.cod_value as number,
            cod.status_id as string,
            cod.destination as string,
            cod.courier_id as number,
            cod.courier_name as string,
            cod.updated_at as string,
            cod.origin as string,
          )
      ),
      new Metadata(
        metadata.total_cod_del,
        metadata.count_cod_del,
        metadata.total_cod_pod,
        metadata.count_cod_pod,
        metadata.total_cod_dex,
        metadata.count_cod_dex,
        metadata.total_cod_rejected,
        metadata.count_cod_rejected,
        metadata.sum_of_total_cod,
        metadata.count_of_total_cod
      )
    )
  }

  public convertOriginsFromApi(result: AxiosResponse<any>): OriginRoute[] {
    const {
      data: { data },
    } = result

    return data.map(
      (route: Record<string, string | boolean | number>) =>
        new OriginRoute(
          route.route_id as number,
          route.lc as string,
          route.city as string,
          route.route as string,
          route.is_origin as boolean,
          route.is_pickup as boolean,
          route.is_shop as boolean
        )
    )
  }

  public convertCODTrackerDetailFromApi(
    result: AxiosResponse<any>
  ): CODTrackerDetail {
    const { data } = result

    return new CODTrackerDetail(
      data.status_id,
      data.driver_name,
      data.driver_id,
      data.driver_phone,
      data.package_id,
      data.product_type,
      data.cod_value,
      data.recipient_name,
      data.recipient_phone,
      data.recipient_address,
      data.receiver_name,
      data.receiver_relation,
      data.date,
      new CODMeta(data.meta.proofs, data.meta.signature),
      data.shipment_cod_reject_detail
        ? new ShipmentCODRejectDetail(
            data.shipment_cod_reject_detail.shipment_id,
            data.shipment_cod_reject_detail.driver_name,
            data.shipment_cod_reject_detail.driver_id,
            data.shipment_cod_reject_detail.driver_phone,
            data.shipment_cod_reject_detail.quantity,
            data.shipment_cod_reject_detail.date
          )
        : undefined,
      data.package_cod_reject_detail
        ? new PackageCODRejectDetail(
            data.package_cod_reject_detail.package_id,
            data.package_cod_reject_detail.product_type,
            data.package_cod_reject_detail.weight,
            data.package_cod_reject_detail.recipient_name,
            data.package_cod_reject_detail.recipient_phone,
            data.package_cod_reject_detail.recipient_address,
            data.package_cod_reject_detail.status_id,
            data.package_cod_reject_detail.date
          )
        : undefined,
      data.delivery_cod_reject_detail
        ? new DeliveryCODRejectDetail(
            data.delivery_cod_reject_detail.status_id,
            data.delivery_cod_reject_detail.driver_name,
            data.delivery_cod_reject_detail.driver_id,
            data.delivery_cod_reject_detail.driver_phone,
            data.delivery_cod_reject_detail.package_id,
            data.delivery_cod_reject_detail.product_type,
            data.delivery_cod_reject_detail.cod_value,
            data.delivery_cod_reject_detail.recipient_name,
            data.delivery_cod_reject_detail.recipient_phone,
            data.delivery_cod_reject_detail.recipient_address,
            data.delivery_cod_reject_detail.receiver_name,
            data.delivery_cod_reject_detail.receiver_relation,
            data.delivery_cod_reject_detail.date,
            new CODMeta(
              data.delivery_cod_reject_detail.meta.proofs,
              data.delivery_cod_reject_detail.meta.signature
            ),
            (data.delivery_cod_reject_detail.dex_histories || []).map(
              (item: {
                date: string
                driver_name: string
                driver_id: number
                driver_phone: string
              }) =>
                new DEXHistory(
                  item.date,
                  item.driver_name,
                  item.driver_id,
                  item.driver_phone
                )
            )
          )
        : undefined
    )
  }
}
