import { injectable } from 'tsyringe'

import { MerchantRepositoryInterface } from '@/data/persistences/repositories/contracts/MerchantRepositoryInterface'
import { MerchantDetail, Merchants } from '@/domain/entities/Merchant'
import {
  CreateMerchantRequestInterface,
  UpdateMerchantRequestInterface,
  UpdateMerchantAccountRequestInterface,
  UpdateMerchantStatusRequestInterface,
} from '@/data/payload/contracts/MerchantRequest'

@injectable()
export class MerchantPresenter {
  private repository: MerchantRepositoryInterface

  constructor(repository: MerchantRepositoryInterface) {
    this.repository = repository
  }

  public get(merchantId: string): Promise<MerchantDetail> {
    return this.repository.get(merchantId)
  }

  public getAll(params: Map<string, string | number | undefined>): Promise<Merchants> {
    return this.repository.getAll(params)
  }

  public create(payload: CreateMerchantRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    merchantId: number,
    payload: UpdateMerchantRequestInterface
  ): Promise<boolean> {
    return this.repository.update(merchantId, payload)
  }

  public updateAccount(
    merchantId: number,
    payload: UpdateMerchantAccountRequestInterface
  ): Promise<boolean> {
    return this.repository.updateAccount(merchantId, payload)
  }

  public updateStatus(
    merchantId: number,
    payload: UpdateMerchantStatusRequestInterface
  ): Promise<boolean> {
    return this.repository.updateStatus(merchantId, payload)
  }
}
