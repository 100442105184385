































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Utils } from '@/app/infrastructures/misc/Utils'
import SearchIcon from '@/app/ui/assets/search_icon.vue'
import EyeIcon from '@/app/ui/assets/eye.vue'
import EyeDisabledIcon from '@/app/ui/assets/eye_disabled.vue'
import AttachIcon from '@/app/ui/assets/attach_icon.vue'

@Component({
  inheritAttrs: false, // We want to inherit props to <input/> not to <div/> wrapper
  components: { EyeIcon, EyeDisabledIcon, SearchIcon, AttachIcon },
})
/**
 * allowPasteNumber: boolean, allow paste on input type number/currency
 * allowNullNumber: boolean, allow null on input number/currency
 */
export default class TextInput extends Vue {
  /** precostumed special type: number, currency, textarea */
  @Prop({ default: 'text' }) private type!: string
  @Prop({ default: false }) private withCounter!: boolean
  @Prop({ default: 30 }) private maxCount!: number
  @Prop({ default: false }) private isError!: boolean
  @Prop({ default: null }) private imagePreview!: string
  @Prop({ default: false }) private allowPasteNumber!: boolean
  @Prop({ default: false }) private allowNullNumber!: boolean
  @Prop({ default: false }) private isTypeSearchNumber!: boolean
  @Prop({ type: String }) private newLineChar!: string
  @Prop({ type: Boolean }) private gray!: boolean
  @Prop({ default: '' }) private customClass!: string
  @Prop({ default: 'Rp' }) private prefixCurrency!: string
  @Prop({ type: Number, default: NaN }) public maxNumber!: number
  @Prop({ type: [String, Number], default: 0 }) public withDefaultVal!: string | number
  @Prop({ type: Boolean, default: true }) private showPrefix!: boolean
  @Prop({ type: Boolean, default: false }) private allowDot!: string

  isHidePassword = true
  fileName = ''

  get displayValue(): string {
    if (this.type === 'currency' || this.type === 'phone') {
      if (this.allowNullNumber && !this.$attrs.value) {
        return this.$attrs.value
      }
      if (this.type === 'currency') {
        return Utils.currencyDigit(Number(this.$attrs.value), this.withDefaultVal).toString()
      } else if (this.type === 'phone') {
        return Utils.numberOnly(this.$attrs.value.toString())
      }
    }

    return this.$attrs.value
  }
  set displayValue(modifiedValue: string) {
    if (modifiedValue === '') {
      this.$emit('input', '')
      return
    }

    if (this.type === 'currency' || this.type === 'number') {
      let newValue = this.allowDot
        ? Number(modifiedValue.replace(/[^0-9.]+/g, ''))
        : Number(modifiedValue.replace(/[^0-9]+/g, ''))
      if (isNaN(newValue)) {
        newValue = 0
      }

      // $emit the event so that parent component gets it
      this.$emit('input', newValue)

      return
    }

    if (this.type === 'phone') {
      const parseNumberOnly = Utils.numberOnly(modifiedValue)
      this.$emit('input', parseNumberOnly)
      return
    }

    this.$emit('input', modifiedValue)
  }

  get getType(): string {
    return this.isHidePassword ? 'password' : 'text'
  }

  get countWords(): number {
    return this.$attrs.value ? this.$attrs.value.length : 0
  }

  get countWordExceeded(): boolean {
    return this.isError || (this.withCounter && this.countWords > this.maxCount)
      ? true
      : false
  }

  private onKeypress($event: {
    keyCode: number
    which: number
    preventDefault: () => void
    target: HTMLInputElement
  }) {
    if (this.type === 'currency' || this.type === 'number' || this.type === 'phone' || this.isTypeSearchNumber) {
      const keyCode = $event.keyCode ? $event.keyCode : $event.which
      if (this.type === 'currency' || this.isTypeSearchNumber) {
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57)) {
          $event.preventDefault()
        }
      } else if (this.type === 'number' || this.type === 'phone') {
        if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          // 46 is dot
          $event.preventDefault()
        }
      }
    }

    if (this.newLineChar) {
      if ($event.keyCode === 13) {
        $event.preventDefault()

        const start = $event.target.selectionStart as number
        const end = $event.target.selectionEnd as number
        const value = $event.target.value

        if (
          start > 0 &&
          value[start - 1] !== this.newLineChar &&
          value[start] !== this.newLineChar
        ) {
          $event.target.value =
            value.slice(0, start) + this.newLineChar + value.slice(end)
          $event.target.selectionStart = $event.target.selectionEnd = start + 1
        }
      }
    }
  }

  private onBlur(event: { target: { value: string | number } }) {
    const { value } = event.target

    if (this.type === 'number' && value > this.maxNumber) {
      event.target.value = this.maxNumber
      this.$emit('input', this.maxNumber)
    }
    this.$emit('blur', event)
  }

  private onInputChange($event: { target: { files: { name: string }[] } }) {
    if (this.type === 'file') {
      this.fileName = `${$event.target.files[0].name}`
      this.$emit('change', $event.target.files[0])
    }
  }

  private onPaste($event: Event) {
    if (
      this.type === 'currency' ||
      this.type === 'number' ||
      this.type === 'phone'
    ) {
      if (this.allowPasteNumber) {
        setTimeout(() => {
          const target = $event.target as HTMLInputElement
          if (this.type === 'phone') {
            target.value = Utils.numberOnly(target.value)
          } else {
            target.value = this.allowDot
              ? target.value.replace(/[^0-9.]+/g, '')
              : target.value.replace(/[^0-9]+/g, '')
          }
        }, 100)
      } else {
        $event.preventDefault()
      }
    } else {
      this.$emit('paste', $event)
    }
  }
}
