import {
  ReschedulePickupRequestInterface,
  UpdateReschedulePickupRequestInterface
} from '@/data/payload/contracts/ReschedulePickupRequest'
import { ReschedulePickupMapper } from '@/data/persistences/mappers/ReschedulePickupMapper'
import { ReschedulePickupRepositoryInterface } from '@/data/persistences/repositories/contracts/ReschedulePickupRepositoryInterface'
import {
  ReschedulePickupDetail,
  ReschedulePickups,
  SubDistrict,
  Village
} from '@/domain/entities/ReschedulePickup'
import { Endpoints } from '@/app/infrastructures/misc'
import ApiService from '@/app/infrastructures/services/ApiService'

export class ReschedulePickupRepository implements ReschedulePickupRepositoryInterface {
  private service: ApiService
  private mapper: ReschedulePickupMapper

  constructor(service: ApiService, mapper: ReschedulePickupMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getSubDistrict(): Promise<SubDistrict> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.GEO_ADDRESS}/sub-district`,
      undefined,
      undefined,
      new Map([['Content-Type', '']])
    )
    return this.mapper.convertSubDistrictFromApi(resp)
  }

  public async getVillage(params: Map<string, string>): Promise<Village> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.GEO_ADDRESS}/village`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )
    return this.mapper.convertVillageFromApi(resp)
  }

  public async getAll(params: Map<string, string | number | Date>): Promise<ReschedulePickups> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.RESCHEDULE_PICKUP,
      params
    )
    return this.mapper.convertReschedulePickupsFromApi(resp)
  }

  public async postRequestApprove(payload: ReschedulePickupRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.RESCHEDULE_PICKUP}/approve`,
      undefined,
      payload
    )
    return true
  }

  public async get(id: string): Promise<ReschedulePickupDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.RESCHEDULE_PICKUP}/detail/${id}`
    )
    return this.mapper.convertReschedulePickupDetailFromApi(resp)
  }

  public async update(payload: UpdateReschedulePickupRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.RESCHEDULE_PICKUP}/update`,
      undefined,
      payload
    )
    return true
  }

  public async getCounter(): Promise<number> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.RESCHEDULE_PICKUP}/total-waiting`
    )
    return this.mapper.convertReschedulePickupTotalCounterFromApi(resp)
  }
}
