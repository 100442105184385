import ApiService from '@/app/infrastructures/services/ApiService'
import { ClaimMapper } from '@/data/persistences/mappers/ClaimMapper'
import { ClaimRepositoryInterface } from '@/data/persistences/repositories/contracts/ClaimRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { Claim, Claims } from '@/domain/entities/Claim'
import { UpdateClaimRequestInterface } from '@/data/payload/contracts/ClaimRequest'
import { UpdateClaimRequest } from '@/data/payload/api/ClaimRequest'

export class ClaimApiRepository implements ClaimRepositoryInterface {
  private service: ApiService
  private mapper: ClaimMapper

  constructor(service: ApiService, mapper: ClaimMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, any>): Promise<Claims> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.CLAIM}/list`,
      params
    )
    return this.mapper.convertClaimsFromApi(resp)
  }

  public async get(claimId: string): Promise<Claim> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.CLAIM}/${claimId}`
    )
    return this.mapper.convertClaimFromApi(resp)
  }

  public async update(
    claimId: string,
    payload: UpdateClaimRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.CLAIM}/${claimId}`,
      undefined,
      payload as UpdateClaimRequest
    )
    return true
  }
}
