import { AxiosResponse } from 'axios'
import {
  DriverAnnouncement,
  DriverAnnouncements,
} from '@/domain/entities/DriverAnnouncement'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class DriverAnnouncementMapper extends BaseResponseMapper {
  public convertDriverAnnouncementsFromApi(
    result: AxiosResponse<any>
  ): DriverAnnouncements {
    const {
      data: { data, pagination },
    } = result
    return new DriverAnnouncements(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (announcement: Record<string, string | number | string[]>) =>
          new DriverAnnouncement(
            Number(announcement.announcement_id),
            announcement.announcement_title as string,
            announcement.announcement_body as string,
            announcement.announcement_type as string,
            announcement.start_at as string,
            announcement.end_at as string,
            announcement.image_url as string,
            announcement.location as string,
            announcement.announcement_courier_type as string,
            announcement.status as string,
            announcement.created_at as string,
            announcement.updated_at as string
          )
      )
    )
  }

  public convertDriverAnnouncementFromApi(
    result: AxiosResponse<any>
  ): DriverAnnouncement {
    const { data } = result
    return new DriverAnnouncement(
      Number(data.announcement_id),
      data.announcement_title as string,
      data.announcement_body as string,
      data.announcement_type as string,
      data.start_at as string,
      data.end_at as string,
      data.image_url as string,
      data.location as string,
      data.announcement_courier_type as string,
      data.status as string,
      data.created_at as string,
      data.updated_at as string
    )
  }
}
