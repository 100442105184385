import {
  BulkInsertPOSRequest,
  UpdatePOSRequest,
  UpdateStatusAndSubscriptionPOSRequest,
} from '@/data/payload/api/POSRequest'
import {
  BulkInsertPOSRequestInterface,
  UpdatePOSRequestInterface,
  UpdateStatusAndSubscriptionPOSRequestInterface,
} from '@/data/payload/contracts/POSRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { POSRepositoryInterface } from '@/data/persistences/repositories/contracts/POSRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { POSMapper } from '@/data/persistences/mappers/POSMapper'
import { POS, POSBulkResponse, POSes, Subdistrict } from '@/domain/entities/POS'

export class POSApiRepository implements POSRepositoryInterface {
  private service: ApiService
  private mapper: POSMapper

  constructor(service: ApiService, mapper: POSMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async findSubdistrict(
    params: Map<string, any>
  ): Promise<Subdistrict[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AGENT}/subdistrict`,
      params
    )
    return this.mapper.convertSubdistrictsFromApi(resp)
  }

  public async bulkInsert(
    payload: BulkInsertPOSRequestInterface
  ): Promise<POSBulkResponse> {
    const resp = await this.service.invoke(
      'post',
      `${Endpoints.Route.AGENT}/bulk`,
      undefined,
      payload as BulkInsertPOSRequest
    )
    return this.mapper.convertPOSBulkResponseFromAPI(resp)
  }

  public async get(agentId: string): Promise<POS> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AGENT}/${agentId}`
    )
    return this.mapper.convertPOSFromApi(resp)
  }

  public async getAll(params: Map<string, any>): Promise<POSes> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.V2AGENT,
      params
    )
    return this.mapper.convertPOSesFromApi(resp)
  }

  public async export(params: Map<string, any>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.AGENT}/export`,
      params
    )
    return resp.data.url
  }

  public async updateStatusAndSubscription(
    agentId: string,
    payload: UpdateStatusAndSubscriptionPOSRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'patch',
      `${Endpoints.Route.AGENT}/${agentId}/status`,
      undefined,
      payload as UpdateStatusAndSubscriptionPOSRequest
    )

    return true
  }

  public async update(
    agentId: string,
    payload: UpdatePOSRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.AGENT}/${agentId}`,
      undefined,
      payload as UpdatePOSRequest
    )

    return true
  }
}
