import { injectable } from 'tsyringe'

import { PODTrackerRepositoryInterface } from '@/data/persistences/repositories/contracts/PODTrackerInterface'
import {
  PODDetail,
  PODs,
  PODTrackerDrivers,
} from '@/domain/entities/PODTracker'
import { OriginRoute } from '@/domain/entities/Route'
import { ExportPOD, UpdatePODHistory } from '@/data/payload/api/PODTrackerRequests'

@injectable()
export class PODTrackerPresenter {
  private repository: PODTrackerRepositoryInterface

  constructor(repository: PODTrackerRepositoryInterface) {
    this.repository = repository
  }

  public getDrivers(
    params: Map<string, string | number>
  ): Promise<PODTrackerDrivers> {
    return this.repository.getDrivers(params)
  }

  public getOrigins(): Promise<OriginRoute[]> {
    return this.repository.getOrigins()
  }

  public getPODs(params: Map<string, string | number>): Promise<PODs> {
    return this.repository.getPODs(params)
  }

  public getPODsByCourier(
    courierId: number,
    params: Map<string, string | number>
  ): Promise<PODs> {
    return this.repository.getPODsByCourier(courierId, params)
  }

  public getPODDetail(packageId: string): Promise<PODDetail> {
    return this.repository.getPODDetail(packageId)
  }

  public updatePODHistory(payload: UpdatePODHistory): Promise<boolean> {
    return this.repository.updatePODHistory(payload)
  }

  public exportPODReport(payload: ExportPOD): Promise<boolean> {
    return this.repository.exportPODReport(payload)
  }
}
