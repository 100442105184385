import { Utils } from "@/app/infrastructures/misc";
import { ReschedulePickupResponse } from "@/data/responses/contracts/ReschedulePickupResponseInterface";
import { BaseResponse } from "@/domain/entities/BaseResponse";
import { Pagination } from "@/domain/entities/Pagination";
import {
  ReschedulePickup,
  ReschedulePickupCourier,
  ReschedulePickupDetail,
  ReschedulePickups,
  SubDistrict,
  Village
} from "@/domain/entities/ReschedulePickup";
import { AxiosResponse } from "axios";

export class ReschedulePickupMapper extends BaseResponse {
  public convertSubDistrictFromApi(result: AxiosResponse): SubDistrict {
    const { data:
      { sub_districts }
    } = result
    return sub_districts.map((item: string ) => {
      return new SubDistrict(item)
    })
  }

  public convertVillageFromApi(result: AxiosResponse): Village {
    const { data:
    { villages }
  } = result
    return villages.map((item: string) => {
      return new Village(item)
    })
  }

  public convertReschedulePickupsFromApi(result: AxiosResponse): ReschedulePickups {
    const { data:
      { data, pagination }
    } = result

    return new ReschedulePickups(
      data.map((item: ReschedulePickupResponse) => {
        return new ReschedulePickup (
          item?.shipment_id,
          item?.group_id,
          item?.customer_name,
          item?.customer_phone_number,
          item?.statuses,
          item?.reschedule_type,
          new ReschedulePickupCourier(
            item?.courier?.courier_id,
            item?.courier?.fullname,
            item?.courier?.courier_type,
            item?.courier?.phone_number
          ),
          item?.rescheduled_at,
          item?.customer_address,
          item?.created_at,
          item?.duration,
          item?.status_approval
        )
      }),
      Utils.toInstance(new Pagination(), pagination),
    )
  }

  public convertReschedulePickupDetailFromApi(result: AxiosResponse): ReschedulePickupDetail {
    const { data } = result

    return new ReschedulePickupDetail(
      data?.shipment_id,
      data?.group_id,
      data?.customer_name,
      data?.customer_phone_number,
      data?.customer_address,
      data?.latitude,
      data?.longitude,
      data?.rescheduled_at,
      data?.created_at,
      data?.status_approval,
      data?.last_reason,
      data?.meta,
      data.assigned_courier ?
      new ReschedulePickupCourier(
        data?.assigned_courier?.courier_id,
        data?.assigned_courier?.fullname,
        data?.assigned_courier?.courier_type,
        data?.assigned_courier?.phone_number
      ) : null,
      new ReschedulePickupCourier(
        data?.last_courier?.courier_id,
        data?.last_courier?.fullname,
        data?.last_courier?.courier_type,
        data?.last_courier?.phone_number,
        data?.last_courier?.latitude,
        data?.last_courier?.longitude,
        data?.last_courier?.distance
      ),
    )
  }

  public convertReschedulePickupTotalCounterFromApi(result: AxiosResponse): number {
    const { data } = result

    return data.total
  }
}
