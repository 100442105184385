import { injectable } from 'tsyringe';
import { ManualPODPhotosRepositoryInterface } from '@/data/persistences/repositories/contracts/ManualPODPhotosRepositoryInterface';
import { UploadPODPhotosRequestInterface } from '@/data/payload/contracts/ManualPODPhotosRequest';
import { UploadManualPOD } from '@/domain/entities/ManualPODPhotos';


@injectable()
export class ManualPODPhotosPresenter {
  private repository: ManualPODPhotosRepositoryInterface

  constructor(repository: ManualPODPhotosRepositoryInterface) {
    this.repository = repository
  }

  public get(merchantId: string): Promise<UploadManualPOD> {
    return this.repository.get(merchantId)
  }

  public uploadImage(payload: UploadPODPhotosRequestInterface): Promise<boolean> {
    return this.repository.uploadImage(payload)
  }
}
