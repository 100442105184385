import { Pagination } from '@/domain/entities/Pagination'

export class Withdrawals {
  public pagination?: Pagination
  public data?: Withdrawal[]

  constructor(pagination?: Pagination, data?: Withdrawal[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class Withdrawal {
  public withdrawalId?: number
  public customerName?: string
  public bankId?: string
  public bankAccountOwner?: string
  public bankAccountNumber?: string
  public customerEmail?: string
  public amount?: number
  public debit?: number
  public fee?: number
  public note?: string
  public createdAt?: string
  public expiredAt?: string
  public withdrawalStatusId?: string
  public withdrawalHistoryCreatedAt?: string
  public pagination?: Pagination
  public history?: BalanceWithdrawalDetail[]
  public phoneNumber?: string
  public updatedBy?: string
  public currentBalance?: number

  constructor(
    withdrawalId?: number,
    customerName?: string,
    bankId?: string,
    bankAccountOwner?: string,
    bankAccountNumber?: string,
    customerEmail?: string,
    amount?: number,
    debit?: number,
    fee?: number,
    note?: string,
    createdAt?: string,
    expiredAt?: string,
    withdrawalStatusId?: string,
    withdrawalHistoryCreatedAt?: string,
    pagination?: Pagination,
    history?: BalanceWithdrawalDetail[],
    phoneNumber?: string,
    updatedBy?: string,
    currentBalance?: number,
  ) {
    this.withdrawalId = withdrawalId
    this.customerName = customerName
    this.bankId = bankId
    this.bankAccountOwner = bankAccountOwner
    this.bankAccountNumber = bankAccountNumber
    this.customerEmail = customerEmail
    this.amount = amount
    this.debit = debit
    this.fee = fee
    this.note = note
    this.createdAt = createdAt
    this.expiredAt = expiredAt
    this.withdrawalStatusId = withdrawalStatusId
    this.withdrawalHistoryCreatedAt = withdrawalHistoryCreatedAt
    this.pagination = pagination
    this.history = history
    this.phoneNumber = phoneNumber
    this.updatedBy = updatedBy
    this.currentBalance = currentBalance
  }
}

export class BalanceWithdrawalDetail {
  public claimId?: number
  public sttId?: string
  public description?: string
  public shipmentId?: string
  public timestamp?: string
  public deltaAmount?: number
  public balanceAmount?: number
  public status?: string

  constructor(
    claimId?: number,
    sttId?: string,
    description?: string,
    shipmentId?: string,
    timestamp?: string,
    deltaAmount?: number,
    balanceAmount?: number,
    status?: string
  ) {
    this.claimId = claimId
    this.sttId = sttId
    this.description = description
    this.shipmentId = shipmentId
    this.timestamp = timestamp
    this.deltaAmount = deltaAmount
    this.balanceAmount = balanceAmount
    this.status = status
  }
}
