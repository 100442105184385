import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { WhiteSellerRepositoryInterface } from '@/data/persistences/repositories/contracts/WhiteSellerRepositoryInterface'
import {
  CreateWhiteSellerRequestInterface,
  DeleteWhiteSellerRequestInterface,
  UpdateWhiteSellerRequestInterface
} from '@/data/payload/contracts/WhiteSellerRequest'
import { WhiteSellerMapper } from '@/data/persistences/mappers/WhiteSellerMapper'
import { WhiteListSeller, WhiteSeller } from '@/domain/entities/WhiteSeller'

export class WhiteSellerApiRepository implements WhiteSellerRepositoryInterface {
  private service: ApiService
  private mapper: WhiteSellerMapper

  constructor(service: ApiService, mapper: WhiteSellerMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, any>): Promise<WhiteListSeller> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.WHITE_SELLER}/list`,
      params
    )

    return this.mapper.convertWhiteListSellerFromApi(resp)
  }

  public async get(id: string): Promise<WhiteSeller> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.WHITE_SELLER}/detail?id=${id}`,
    )
    return this.mapper.convertDetailWhiteSellerFromApi(resp)
  }

  public async create(payload: CreateWhiteSellerRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.WHITE_SELLER}/create`,
      undefined,
      payload as CreateWhiteSellerRequestInterface
    )
    return true
  }

  public async update(payload: UpdateWhiteSellerRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.WHITE_SELLER}/edit`,
      undefined,
      payload as UpdateWhiteSellerRequestInterface
    )
    return true
  }

  public async delete(payload: CreateWhiteSellerRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.WHITE_SELLER}/delete`,
      undefined,
      payload as DeleteWhiteSellerRequestInterface
    )
    return true
  }

  public async export(): Promise<string> {
    const resp = await this.service.invoke(
      'post',
      `${Endpoints.Route.WHITE_SELLER}/export`
    )
    return this.mapper.convertExportWhiteSellerFromApi(resp)
  }
}
