import { ReschedulePickupRepository } from "@/app/infrastructures/repositories/api/ReschedulePickupRepository";
import { ReschedulePickupRequestInterface, UpdateReschedulePickupRequestInterface } from "@/data/payload/contracts/ReschedulePickupRequest";
import { ReschedulePickupDetail, ReschedulePickups, SubDistrict, Village } from "@/domain/entities/ReschedulePickup";
import { injectable } from "tsyringe";

@injectable()
export class ReschedulePickupPresenter {
  private repository: ReschedulePickupRepository

  constructor(repository: ReschedulePickupRepository) {
    this.repository = repository
  }

  public getSubDistrict(): Promise<SubDistrict> {
    return this.repository.getSubDistrict()
  }

  public getVillage(params: Map<string, string>): Promise<Village> {
    return this.repository.getVillage(params)
  }

  public getAll(params: Map<string, string | number | Date>): Promise<ReschedulePickups> {
    return this.repository.getAll(params)
  }

  public postRequestApprove(payload: ReschedulePickupRequestInterface): Promise<boolean> {
    return this.repository.postRequestApprove(payload)
  }

  public get(id: string): Promise<ReschedulePickupDetail> {
    return this.repository.get(id)
  }

  public update(payload: UpdateReschedulePickupRequestInterface): Promise<boolean> {
    return this.repository.update(payload)
  }

  public getCounter(): Promise<number> {
    return this.repository.getCounter();
  }
}
