import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import { ManageCaptain, ManageCaptainDetail, ManageCaptains } from '@/domain/entities/ManageCaptain'
import { ManageCaptainResponse } from '@/data/responses/contracts/ManageCaptainResponse'

export class ManageCaptainMapper extends BaseResponseMapper {
  public convertManageCaptainFromApi(result: AxiosResponse): ManageCaptains {
    const {
      data: { data, pagination },
    } = result

    return new ManageCaptains(
      new Pagination(
        pagination.page,
        pagination.limit,
        pagination.total_item
      ),
      data.map(
        (item: ManageCaptainResponse) =>
          new ManageCaptain(
            item.courier_captain_id,
            item.courier_id,
            item.sk_start_date,
            item.sk_end_date,
            item.status,
            item.fullname,
            item.phone_number,
            item.letter_codes
          )
      )
    )
  }

  public convertDetailManageCaptainFromApi(result: AxiosResponse): ManageCaptainDetail {
    const { data } = result

    return new ManageCaptainDetail(
      data.courier_captain_id,
      data.courier_id,
      data.sk_start_date,
      data.sk_end_date,
      data.status,
      data.fullname,
      data.phone_number,
      data.letter_codes,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by
    )
  }
}
