import { Pagination } from '@/domain/entities/Pagination'

export class Subdistrict {
  public id?: string
  public name?: string

  constructor(id?: string, name?: string) {
    this.id = id
    this.name = name
  }
}

export class POS {
  public id?: number
  public code?: string
  public name?: string
  public address?: string
  public subdistrictId?: number
  public subDistrictName?: string
  public letterCode?: string
  public bindingCode?: string
  public location?: string
  public phone?: string
  public subscriptionStatus?: boolean
  public status?: string
  public createdAt?: string
  public updatedAt?: string
  public createdBy?: string
  public updatedBy?: string
  public qrCode?: string

  constructor(
    id?: number,
    name?: string,
    address?: string,
    subDistrictName?: string,
    phone?: string,
    subscriptionStatus?: boolean,
    status?: string,
    code?: string,
    subdistrictId?: number,
    letterCode?: string,
    bindingCode?: string,
    location?: string,
    createdAt?: string,
    updatedAt?: string,
    createdBy?: string,
    updatedBy?: string,
    qrCode?: string
  ) {
    this.id = id
    this.name = name
    this.address = address
    this.subDistrictName = subDistrictName
    this.phone = phone
    this.subscriptionStatus = subscriptionStatus
    this.status = status
    this.code = code
    this.subdistrictId = subdistrictId
    this.letterCode = letterCode
    this.bindingCode = bindingCode
    this.location = location
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.qrCode = qrCode
  }
}

export class POSes {
  public pagination?: Pagination
  public data?: POS[]

  constructor(pagination?: Pagination, data?: POS[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class POSBulkResponse {
  public totalRows?: number
  public failedRows?: number
  public failedMessages?: string[]

  constructor(
    totalRows?: number,
    failedRows?: number,
    failedMessages?: string[]
  ) {
    this.totalRows = totalRows
    this.failedRows = failedRows
    this.failedMessages = failedMessages
  }
}
