import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { PayrollRepositoryInterface } from '@/data/persistences/repositories/contracts/PayrollRepositoryInterface'
import {
  CreatePayrollRequestInterface,
  SubmitCourierAttendanceApprovalRequestInterface,
  UpdatePayrollRuleRequestInterface,
} from '@/data/payload/contracts/PayrollRequest'
import { PayrollMapper } from '@/data/persistences/mappers/PayrollMapper'
import {
  CourierAttendanceDetails,
  CourierAttendanceRequestDetail,
  CourierAttendences,
  ManualAdjustBasicFeeHistories,
  ManualAdjustBasicFeeHistoryDetail,
  ManualAdjustBasicFees,
  Payrolls,
  PeriodRule,
} from '@/domain/entities/Payroll'
import {
  SubmitApprovalManualAdjustBasicFeeRequest,
  UploadBulkyManualAdjustBaicFeeRequest,
} from '@/data/payload/api/PayrollRequest'
export class PayrollApiRepository implements PayrollRepositoryInterface {
  private service: ApiService
  private mapper: PayrollMapper

  constructor(service: ApiService, mapper: PayrollMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, number>): Promise<Payrolls> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/period`,
      params
    )

    return this.mapper.convertPayrollFromApi(resp)
  }

  public async get(id: string): Promise<PeriodRule> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/master/${id}`
    )
    return this.mapper.convertDetailPeriodRuleFromApi(resp)
  }

  public async create(
    payload: CreatePayrollRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLL}/period`,
      undefined,
      payload as CreatePayrollRequestInterface
    )
    return true
  }

  public async update(
    id: string,
    payload: UpdatePayrollRuleRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLL}/rules/${id}`,
      undefined,
      payload as UpdatePayrollRuleRequestInterface
    )
    return true
  }

  public async getAllCourierAttendance(
    params: Map<string, string | number>,
    month: string
  ): Promise<CourierAttendences> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/courier/attendance/${month}`,
      params
    )

    return this.mapper.convertManageCourierAttendanceListFromApi(resp)
  }

  public async getCourierAttendanceDetail(
    params: Map<string, string | number>,
    month: string,
    courierId: number
  ): Promise<CourierAttendanceDetails> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/courier/attendance/${month}/${courierId}`,
      params
    )

    return this.mapper.convertManageCourierAttendanceDetailFromApi(resp)
  }

  public async downloadBasicFeeUploadBulkyTemplate(): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLL}/manual-adjust-basic-fee/download-template`
    )

    return this.mapper.convertManualBasicFeeUploadBulkyTemplateFromApi(resp)
  }

  public async getCourierRequestAttendance(date: string, courierId: number): Promise<CourierAttendanceRequestDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/courier/attendance/${date}/${courierId}/detail`
    )

    return this.mapper.convertManageCourierAttendanceRequestDetailFromApi(resp)
  }

  public async submitCourierAttendanceApproval(payload: SubmitCourierAttendanceApprovalRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.PAYROLLV1}/courier/attendance/approval`,
      undefined,
      payload
    )

    return true
  }

  public async getAllManualAdjustBasicFee(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFees> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment-basic-fee/admin/list`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertManualAdjustBasicFeeListFromApi(resp)
  }

  public async getAllManualAdjustBasicFeeHistory(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFeeHistories> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment-basic-fee/admin/history-list`,
      params,
      undefined
    )

    return this.mapper.convertManualAdjustBasicFeeHistoryListFromApi(resp)
  }

  public async getManualAdjustBasicFeeHistory(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFeeHistoryDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment-basic-fee/admin/approval/detail`,
      params
    )

    return this.mapper.convertManualAdjustBasicFeeHistoryDetailFromApi(resp)
  }

  public async uploadBulkyManualAdjustBasicFee(
    payload: UploadBulkyManualAdjustBaicFeeRequest
  ): Promise<ManualAdjustBasicFeeHistoryDetail> {
    const resp = await this.service.invoke(
      'POST',
      `${Endpoints.Route.PAYROLL}/manual-adjust-basic-fee/upload-bulky`,
      undefined,
      payload
    )

    return this.mapper.convertUploadBulkyManualAdjustBalanceListFromApi(resp)
  }

  public async getUploadBulkyManualAdjustBasicFeeList(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFeeHistoryDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PAYROLL}/manual-adjust-basic-fee/upload-bulky/list`,
      params
    )

    return this.mapper.convertUploadBulkyManualAdjustBalanceListFromApi(resp)
  }

  public async submitUploadBulkyManualAdjustBasicFee(): Promise<boolean> {
    const resp = await this.service.invoke(
      'POST',
      `${Endpoints.Route.PAYROLL}/manual-adjust-basic-fee/upload-bulky/submit`
    )

    return true
  }

  public async submitApprovalManualAdjustBasicFee(
    payload: SubmitApprovalManualAdjustBasicFeeRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment-basic-fee/admin/approval/submit`,
      undefined,
      payload
    )

    return true
  }
}
