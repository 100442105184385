import ApiService from '@/app/infrastructures/services/ApiService'
import { Validation } from '@/domain/entities/CampaignRamadhan'
import { CampaignRamadhanRepositoryInterface } from '@/data/persistences/repositories/contracts/CampaignRamadhanRepositoryInterface'
import { SubmitDonationRequestInterface } from '@/data/payload/contracts/CamapignRamadhanRequest'
import { CampaignRamadhanRequest } from '@/data/payload/api/CampaignRamadhanRequest'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'

export class CampaignRamadhanApiRepository
  implements CampaignRamadhanRepositoryInterface {
  private service: ApiService

  constructor(service: ApiService) {
    this.service = service
  }

  public async getValidationStatus(phoneNumber: string): Promise<Validation> {
    await this.service.invoke(
      'get',
      `${Endpoints.Route.CAMPAIGN}/validate-order?phone_number=${phoneNumber}`
    )

    return new Validation(false, false, true)
  }

  public async submitDonation(
    payload: SubmitDonationRequestInterface
  ): Promise<number> {
    const resp = await this.service.invoke(
      'post',
      Endpoints.Route.CAMPAIGN,
      undefined,
      payload as CampaignRamadhanRequest
    )

    return resp.data.order_id
  }
}
