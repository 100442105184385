import { AxiosResponse } from 'axios'
import {
  PickupLocation,
  PickupLocations,
} from '@/domain/entities/PickupLocation'
import { PickupLocationResponseInterface } from '@/data/responses/contracts/PickupLocationResponseInterface'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class PickupLocationMapper {
  convertPickupLocationResponseFromAPI(result: AxiosResponse): PickupLocations {
    const {
      data: { pagination, data },
    } = result
    return new PickupLocations(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: PickupLocationResponseInterface) => {
        return new PickupLocation(
          item.route_id,
          item.city,
          item.sub_district,
          item.status,
          item.updated_at,
          item.updated_by
        )
      })
    )
  }

  convertDetailLocationResponseFromAPI(
    result: AxiosResponse<PickupLocationResponseInterface>
  ): PickupLocation {
    const { data } = result
    return new PickupLocation(
      data.route_id,
      data.city,
      data.sub_district,
      data.status,
      data.updated_at,
      data.updated_by
    )
  }
}
