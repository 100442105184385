import { menu } from '@/app/infrastructures/misc/MenuDecorator'

class RouterConstant {
  @menu({ label: 'User Management' })
  public static USER_MANAGEMENT = 'user-management'

  @menu({ label: 'Mitra POS' })
  public static MITRA_POS = 'mitra-pos'

  @menu({ label: 'Banner In App', group: 'Banner' })
  public static BANNER_IN_APP = 'banner-in-app'

  @menu({ label: 'Pop Up Banner', group: 'Banner' })
  public static POP_UP_BANNER = 'pop-up-banner'

  @menu({ label: 'Programmatic Point', group: 'Saldo' })
  public static PROGRAMMATIC_POINT = 'programmatic-point'

  @menu({ label: 'Retrieved Payment', group: 'Saldo' })
  public static RETRIEVED_PAYMENT = 'retrieved-payment'

  @menu({ label: 'All Data Programmatic', group: 'Saldo' })
  public static ALL_DATA_PROGRAMMATIC = 'all-data-programmatic'

  @menu({ label: 'Frequently Asked Questions' })
  public static FAQ = 'faq'

  @menu({ label: 'Push Notification' })
  public static PUSH_NOTIFICATION = 'push-notification'

  @menu({ label: 'Money Back Guarantee', group: 'Claim' })
  public static CLAIM_MBG = 'claim-mbg'

  @menu({ label: 'Missing/Damaged Goods', group: 'Claim' })
  public static CLAIM_DAMAGED = 'claim-damaged'

  @menu({ label: 'Request Top-up Saldo', group: 'Adjustment' })
  public static ADJUSTMENT_REQUEST_TOPUP = 'adjustment-request-topup'

  @menu({ label: 'Approving Top-up Saldo', group: 'Adjustment' })
  public static ADJUSTMENT_APPROVING_TOPUP = 'approving-topup-saldo'

  @menu({ label: 'All Data Adjustment', group: 'Adjustment' })
  public static ADJUSTMENT_ALL_DATA = 'all-data-adjustment'

  @menu({ label: 'Public Voucher Code', group: 'Voucher' })
  public static PUBLIC_CODE = 'public-voucher'

  @menu({ label: 'Programmatic Voucher', group: 'Voucher' })
  public static PROGRAMMATIC_VOUCHER = 'programmatic-voucher'

  @menu({ label: 'Voucher Config', group: 'Voucher' })
  public static VOUCHER_CONFIG = 'voucher-config'

  @menu({ label: 'Brand', group: 'BisaBelanja' })
  public static BRAND = 'brand'

  @menu({ label: 'Merchant', group: 'BisaBelanja' })
  public static MERCHANT = 'merchant'

  @menu({ label: 'Product', group: 'BisaBelanja' })
  public static PRODUCT = 'product'

  @menu({ label: 'Order Management', group: 'BisaBelanja' })
  public static ORDER_MANAGEMENT = 'order-management'

  @menu({ label: 'Export Data' })
  public static EXPORT_DATA = 'export-data'

  @menu({ label: 'Account Settings', hide: true })
  public static ACCOUNT_SETTING = 'account-settings'

  @menu({ label: 'Manage Whitelist', group: 'Marketplace' })
  public static MANAGE_WHITELIST = 'manage-whitelist'

  @menu({ label: 'Driver Scoreboard', group: 'Driver' })
  public static DRIVER_SCOREBOARD = 'driver-scoreboard'

  @menu({ label: 'Manage Courier', group: 'ManageCourier' })
  public static MANAGE_COURIER = 'manage-courier'

  @menu({ label: 'Manage Customer', group: 'CustomerManagement' })
  public static MANAGE_CUSTOMER = 'manage-customer'

  @menu({ label: 'POD Tracker', group: 'Driver' })
  public static POD_TRACKER = 'pod-tracker'

  @menu({ label: 'Manual POD Photos', group: 'Driver' })
  public static MANUAL_POD_PHOTOS = 'manual-pod-photos'

  @menu({ label: 'Pickup Validation Radius', group: 'Driver' })
  public static PICKUP_VALIDATION_RADIUS = 'pickup-validation-radius'

  @menu({ label: 'Homepage', group: 'BisaBelanja' })
  public static BISA_BELANJA_HOMEPAGE = 'oleh-oleh-homepage'

  // @menu({ label: 'COD Tracker', group: 'Driver' })
  // public static COD_TRACKER = 'cod-tracker'

  @menu({ label: 'Withdrawal', group: 'Saldo' })
  public static WITHDRAWAL = 'withdrawal'

  @menu({ label: 'Pickup Summary', group: 'PickupDashboard' })
  public static PICKUP_SUMMARY = 'pickup-summary'

  @menu({ label: 'Pickup CRRQUE', group: 'PickupDashboard' })
  public static PICKUP_CRRQUE = 'pickup-crrque'

  @menu({ label: 'Pickup Corporate', group: 'PickupDashboard' })
  public static PICKUP_CORPORATE = 'pickup-corporate'

  @menu({ label: 'Pickup CRRNFD', group: 'PickupDashboard' })
  public static PICKUP_CRRNFD = 'pickup-crrnfd'

  @menu({ label: 'Pickup DOX', group: 'PickupDashboard' })
  public static PICKUP_DOX = 'pickup-dox'

  @menu({ label: 'Pickup CRROTW', group: 'PickupDashboard' })
  public static PICKUP_CRROTW = 'pickup-crrotw'

  @menu({ label: 'Pickup Tambah Waktu', group: 'PickupDashboard' })
  public static PICKUP_ADDITIONAL_TIME = 'pickup-additional-time'

  @menu({ label: 'Pickup CRRPIC', group: 'PickupDashboard' })
  public static PICKUP_CRRPIC = 'pickup-crrpic'

  @menu({ label: 'Pickup CRRDON', group: 'PickupDashboard' })
  public static PICKUP_CRRDON = 'pickup-crrdon'

  @menu({ label: 'Driver Announcement' })
  public static DRIVER_ANNOUNCEMENT = 'driver-announcement'

  @menu({ label: 'Manage Data Master', group: 'Penghasilan' })
  public static MANAGE_DATA_MASTER = 'manage-data-master'

  @menu({ label: 'Manage Adjust Saldo', group: 'Penghasilan' })
  public static MANUAL_ADJUST_SALDO = 'manual-adjust-saldo'

  @menu({ label: 'Manual Adjust Basic Fee', group: 'Penghasilan'})
  public static MANUAL_ADJUST_BASIC_FEE = 'manual-adjust-basic-fee'

  @menu({ label: 'Manage Jumlah Hari', group: 'Day' })
  public static MANAGE_DAYS = 'manage-days'

  @menu({ label: 'Bonus', group: 'Penghasilan' })
  public static BONUS = 'bonus'

  @menu({ label: 'Manage Captain', group: 'Penghasilan' })
  public static MANAGE_CAPTAIN = 'manage-captain'

  @menu({ label: 'Manage Data Penghasilan', group: 'Penghasilan' })
  public static MANAGE_DATA_PAYROLL = 'manage-data-penghasilan'

  @menu({ label: 'Adjust Saldo Approval', group: 'Penghasilan' })
  public static ADJUST_SALDO_APPROVAL = 'adjust-saldo-approval'

  @menu({ label: 'Adjust Basic Fee Saldo Approval', group: 'Penghasilan' })
  public static ADJUST_BASIC_FEE_APPROVAL = 'adjust-basic-fee-approval'

  @menu({ label: 'Pickup Location', group: 'PickupLocation' })
  public static PICKUP_LOCATION = 'pickup-location'

  @menu({ label: 'Atur Jadwal Pesanan', group: 'Offer' })
  public static SCHEDULE_ORDER = 'schedule-order'

  @menu({ label: 'Manage Kurir Dedicated', group: 'Offer' })
  public static MANAGE_COURIER_DEDICATED = 'manage-courier-dedicated'

  @menu({ label: 'Membership', group: 'Loyalty'})
  public static LOYALTY_MEMBERSHIP = 'membership'

  @menu({ label: 'Membership Poin Formula', group: 'Loyalty'})
  public static LOYALTY_MEMBERSHIP_FORMULA = 'membership-formula'

  @menu({ label: 'Get Poin Rule', group: 'Loyalty'})
  public static LOYALTY_GET_POIN_RULE = 'get-poin-rule'

  @menu({ label: 'Request Parcel Poin', group: 'AdjustmentParcelPoin' })
  public static ADJUSTMENT_PARCEL_POINT_REQUEST_POINT = 'request-poin'

  @menu({ label: 'Approving Parcel Poin', group: 'AdjustmentParcelPoin' })
  public static ADJUSTMENT_PARCEL_POINT_APPROVING_POINT = 'approving-poin'

  @menu({ label: 'All Data Adjustment', group: 'AdjustmentParcelPoin' })
  public static ADJUSTMENT_PARCEL_POINT_ALL_DATA_ADJUTSTMENT = 'all-data-adjustment-poin'

  @menu({ label: 'Extra Multiplier', group: 'Loyalty'})
  public static LOYALTY_EXTRA_MULTIPLIER = 'extra-mulltiplier'

  @menu({ label: 'Update App Version' })
  public static UPDATE_APP_VERSION = 'update-app-version'

  @menu({ label: 'Search', hide: true })
  public static SEARCH = 'search'

  @menu({ label: 'Cancel Validation', group: 'Driver'})
  public static CANCEL_VALIDATION = 'cancel-validation'

  @menu({ label: 'Pick Up Time Management', group: 'PickupTimeManagement' })
  public static PICKUP_TIME_MANAGEMENT = 'pickup-time-management'

  @menu({ label: 'Shuttle Management', group: 'ShuttleManagement'})
  public static SHUTTLE_MANAGEMENT = 'shuttle-management'

  @menu({ label: 'Kelola Biaya COD', group: 'COD' })
  public static COD_PAYMENT_SETTING = 'cod-payment-setting'

  @menu({ label: 'Kelola Pembayaran COD', group: 'COD'})
  public static COD_PAYMENT = 'cod-payment'

  @menu({ label: 'Manage Absen Kurir', group: 'Day' })
  public static MANAGE_COURIER_ATTENDANCE = 'manage-courier-attendance'
}

export default RouterConstant
