

















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import LionParcelLogo from '@/app/ui/assets/lion_parcel_logo.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import { IParams } from '@/data/infrastructures/misc/interfaces/search'
import { Dictionary } from 'vue-router/types/router'
import { Utils } from '@/app/infrastructures/misc'

@Component({
  components: {
    LionParcelLogo,
    TextInput
  },
})
export default class Navigation extends Vue {
  @Prop() private email!: string
  @Prop() private role!: string

  isOnline = true
  isExpandUserDropdown = false
  searchValue = ''

  mounted(): void {
    window.addEventListener('online', () => {
      this.isOnline = true
    })
    window.addEventListener('offline', this.updateOnlineStatus)
    this.searchValue = this.$route.query.searchQuery as string
  }

  beforeDestroy(): void {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  }
  
  get params(): IParams {
    return {
      searchQuery: this.searchValue,
    }
  }

  private updateOnlineStatus(e: { type: string }) {
    const { type } = e
    this.isOnline = type === 'online'
  }

  private toggleUserDropdown() {
    this.isExpandUserDropdown = !this.isExpandUserDropdown
  }

  private redirectToSearchPage(): void {
    if (this.searchValue.length < 3) {
      this.$notify({
        title: 'Error',
        text: 'Keyword cannot be less than 3 characters',
        type: 'error',
      })
    } else if (this.searchValue.length > 500) {
      this.$notify({
        title: 'Error',
        text: 'Keyword cannot be more than 500 characters',
        type: 'error',
      })
    } else {
      this.$router
        .push({ name: 'SearchPage', query: { searchQuery: this.searchValue } })
        .catch(() => null)
    }
  }

  @Watch('searchValue')
  onSearchValueChanged(val: string): void {
    if (typeof val !== 'string') return
    if (!val.length && this.$route.name === 'SearchPage') {
      this.$router
        .back()
    }
    this.searchValue = Utils.removeSpecialCharacters(val)
    this.searchValue = Utils.removeHTMLTags(this.searchValue)
    this.searchValue.trim()
  }

  @Watch('params')
  onParamsChanged(
    val: Dictionary<string | (string | null)[] | null | undefined> | undefined
  ): void {
    if (this.$route.name === 'SearchPage') {
      this.$router.replace({
        query: { ...val },
      })
      .catch(() => null)
    }
  }

  @Watch('$route')
  onRouteNameChanged(): void {
    if (this.$route.name === 'SearchPage' && this.$route.query.searchQuery) {
      this.searchValue = this.$route.query.searchQuery as string || ''
    }
  }
}
