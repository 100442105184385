import { injectable } from 'tsyringe'
import {
  CreatePayrollRequestInterface,
  SubmitCourierAttendanceApprovalRequestInterface,
  UpdatePayrollRuleRequestInterface,
} from '@/data/payload/contracts/PayrollRequest'
import { PayrollRepositoryInterface } from '@/data/persistences/repositories/contracts/PayrollRepositoryInterface'
import {
  CourierAttendanceDetails,
  CourierAttendanceRequestDetail,
  CourierAttendences,
  ManualAdjustBasicFeeHistories,
  ManualAdjustBasicFeeHistoryDetail,
  ManualAdjustBasicFees,
  Payrolls,
  PeriodRule,
} from '@/domain/entities/Payroll'
import {
  SubmitApprovalManualAdjustBasicFeeRequest,
  UploadBulkyManualAdjustBaicFeeRequest,
} from '@/data/payload/api/PayrollRequest'

@injectable()
export class PayrollPresenter implements PayrollRepositoryInterface {
  private repository: PayrollRepositoryInterface

  constructor(repository: PayrollRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number>): Promise<Payrolls> {
    return this.repository.getAll(params)
  }

  public get(id: string): Promise<PeriodRule> {
    return this.repository.get(id)
  }

  public create(payload: CreatePayrollRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    id: string,
    payload: UpdatePayrollRuleRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }

  public getAllCourierAttendance(
    params: Map<string, string | number>,
    month: string
  ): Promise<CourierAttendences> {
    return this.repository.getAllCourierAttendance(params, month)
  }

  public getCourierAttendanceDetail(
    params: Map<string, string | number>,
    month: string,
    courierId: number
  ): Promise<CourierAttendanceDetails> {
    return this.repository.getCourierAttendanceDetail(params, month, courierId)
  }
  
  public downloadBasicFeeUploadBulkyTemplate(): Promise<string> {
    return this.repository.downloadBasicFeeUploadBulkyTemplate()
  }

  public getCourierRequestAttendance(
    date: string,
    courierId: number
  ): Promise<CourierAttendanceRequestDetail> {
    return this.repository.getCourierRequestAttendance(date, courierId)
  }

  public submitCourierAttendanceApproval(
    payload: SubmitCourierAttendanceApprovalRequestInterface
  ): Promise<boolean> {
    return this.repository.submitCourierAttendanceApproval(payload)
  }

  public getAllManualAdjustBasicFee(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFees> {
    return this.repository.getAllManualAdjustBasicFee(params)
  }

  public getAllManualAdjustBasicFeeHistory(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFeeHistories> {
    return this.repository.getAllManualAdjustBasicFeeHistory(params)
  }

  public getManualAdjustBasicFeeHistory(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFeeHistoryDetail> {
    return this.repository.getManualAdjustBasicFeeHistory(params)
  }

  public uploadBulkyManualAdjustBasicFee(
    payload: UploadBulkyManualAdjustBaicFeeRequest
  ): Promise<ManualAdjustBasicFeeHistoryDetail> {
    return this.repository.uploadBulkyManualAdjustBasicFee(payload)
  }

  public getUploadBulkyManualAdjustBasicFeeList(
    params: Map<string, string | number>
  ): Promise<ManualAdjustBasicFeeHistoryDetail> {
    return this.repository.getUploadBulkyManualAdjustBasicFeeList(params)
  }

  public submitUploadBulkyManualAdjustBasicFee(): Promise<boolean> {
    return this.repository.submitUploadBulkyManualAdjustBasicFee()
  }

  public submitApprovalManualAdjustBasicFee(
    payload: SubmitApprovalManualAdjustBasicFeeRequest
  ): Promise<boolean> {
    return this.repository.submitApprovalManualAdjustBasicFee(payload)
  }
}
