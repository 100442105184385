import { RouteConfig } from 'vue-router'
import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'

export const adjustBasicFeeApproval: RouteConfig = {
  path: 'adjust-basic-fee-approval',
  name: 'AdjustBasicFeeApproval',
  component: {
    render: c => c('router-view'),
  },
  redirect: {
    name: 'AdjustBasicFeeApprovalList',
  },
  children: [
    {
      path: '',
      name: 'AdjustBasicFeeApprovalList',
      component: () =>
        import(
          /* webpackChunkName: "AdjustBasicFeeApprovalList"*/ '@/app/ui/views/Payroll/AdjustBasicFeeApproval/index.vue'
        ),
      meta: {
        title: 'Adjust Basic Fee Saldo Approval',
        accessType: 'read',
        slugFromBackend: BACKEND_SLUG.ADJUST_BASIC_FEE_APPROVAL,
      },
    },
    {
      path: 'detail/:historyId',
      name: 'AdjustBasicFeeApprovalDetail',
      component: () =>
        import(
          /* webpackChunkName: "AdjustBasicFeeApprovalDetail" */  '@/app/ui/views/Payroll/ManualAdjustBasicFee/Detail/index.vue'
        ),
      meta: {
        title: 'Detail Basic Fee Saldo Approval',
        accessType: 'read',
        slugFromBackend: BACKEND_SLUG.ADJUST_BASIC_FEE_APPROVAL,
      }
    }
  ],
  meta: {
    title: 'Adjust Basic Fee Saldo Approval',
    accessType: 'read',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.ADJUST_BASIC_FEE_APPROVAL,
  },
}
