import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import {
  CourierOnLeave,
  ManageCourier,
  ManageCourierAdjustSaldo,
  ManageCourierBankInformation,
  ManageCourierDetail,
  ManageCouriers,
  ManageCouriersAdjustSaldo,
  Partner,
  ManageCourierHistoryLogs,
  ManageCourierHistoryLog,
  CourierHistoryLog,
  ManageCourierOnLeaves,
  ManageCourierOnLeave,
  ManageCourierUploadBulk,
  ManageCourierUploadBulkDataUpload,
  ManageCourierUploadBulkSummary,
  ManageCourierUploadBulkRow,
  ManageCourierHistoryUploadBulks,
  ManageCourierHistoryUploadBulk,
  TruckVendor,
  ManageCourierLeaveRequests,
  ManageCourierLeaveRequest,
} from '@/domain/entities/ManageCourier'
import { PartnerResponse } from '@/data/responses/contracts/PartnerResponse'
import { ManageCourierLeaveRequestResponse } from '@/data/responses/contracts/ManageCourierResponse'

export class ManageCourierMapper extends BaseResponseMapper {
  public convertManageCouriersFromApi(result: AxiosResponse): ManageCouriers {
    const {
      data: { data, pagination },
    } = result

    return new ManageCouriers(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (withdrawal: Record<string, never>) =>
          new ManageCourier(
            withdrawal.courier_id,
            withdrawal.fullname,
            withdrawal.role,
            withdrawal.phone_number,
            withdrawal.vehicle_type,
            withdrawal.vehicle_plate,
            withdrawal.partner_type,
            withdrawal.partner_name,
            withdrawal.origin,
            withdrawal.courier_type,
            withdrawal.courier_status,
            withdrawal.announcement_courier_type,
            withdrawal.courier_is_cod
          )
      )
    )
  }

  public convertCourierDetailFromApi(
    result: AxiosResponse
  ): ManageCourierDetail {
    const { data } = result
    return new ManageCourierDetail(
      data.courier_id,
      data.fullname,
      data.role,
      data.phone_number,
      data.vehicle_type,
      data.vehicle_plate,
      data.partner_type,
      data.partner_name,
      data.courier_type,
      data.courier_status,
      new ManageCourierBankInformation(
        data.bank.bank_account_name,
        data.bank.bank_id,
        data.bank.bank_name,
        data.bank.bank_account
      ),
      data.announcement_courier_type,
      data.courier_created_at,
      data.courier_created_by,
      data.courier_updated_at,
      data.courier_updated_by,
      data.courier_on_leave.map((courierLeave: Record<string, never>) => {
        return new CourierOnLeave(
          courierLeave.id,
          courierLeave.date_leave,
          courierLeave.start_time,
          courierLeave.end_time,
          courierLeave.is_custom_leave
        )
      }),
      Utils.toInstance(new TruckVendor(), data.truck_vendor),
      data.courier_is_cod ? JSON.stringify(data.courier_is_cod) : "false"
    )
  }

  public convertManageCouriersAdjustSaldoFromApi(
    result: AxiosResponse
  ): ManageCouriers {
    const {
      data: { data, pagination },
    } = result

    return new ManageCouriersAdjustSaldo(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (courier: Record<string, never>) =>
          new ManageCourierAdjustSaldo(
            courier.courier_id,
            courier.fullname,
            courier.phone_number,
            courier.announcement_courier_type,
            courier.partner_name
          )
      )
    )
  }

  public convertPartnersFromApi(result: AxiosResponse): Partner[] {
    const {
      data: { data },
    } = result

    return data.map(
      (data: PartnerResponse) =>
        new Partner(data.agent_id, data.name, data.agent_code, data.agent_type)
    )
  }

  public convertManageCourierExportFromApi(result: AxiosResponse): string {
    const { data } = result
    return data
  }

  public convertManageCourierHistoryLogsFromApi(
    result: AxiosResponse
  ): ManageCourierHistoryLogs {
    const {
      data: { pagination, data }
    } = result

    return new ManageCourierHistoryLogs(
      Utils.toInstance(new Pagination(), pagination),
      new ManageCourierHistoryLog(
        new ManageCourier(
          data.courier_data.courier_id,
          data.courier_data.fullname,
          data.courier_data.role,
          data.courier_data.phone_number,
          data.courier_data.vehicle_type,
          data.courier_data.vehicle_plate,
          data.courier_data.partner_type,
          data.courier_data.partner_name,
          data.courier_data.origin,
          data.courier_data.courier_type,
          data.courier_data.courier_status,
          data.courier_data.courier_type_valid
        ),
        data.log_courier_data?.length ? data.log_courier_data.map((log: Record<string, never>) => 
          new CourierHistoryLog(
            log.column,
            log.before,
            log.after,
            log.updated_at,
            log.updated_by
          )
        ) : []
      )
    )
  }

  public convertManageCourierOnLeavesFromApi(
    result: AxiosResponse
  ): ManageCourierOnLeaves {
    const {
      data: { pagination, data },
    } = result

    return new ManageCourierOnLeaves(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (courierLeave: Record<string, never>) =>
          new ManageCourierOnLeave(
            courierLeave.courier_id,
            courierLeave.id,
            courierLeave.role,
            courierLeave.fullname,
            courierLeave.phone_number,
            courierLeave.vehicle_type,
            courierLeave.vehicle_plate,
            courierLeave.partner_name,
            courierLeave.courier_type,
            courierLeave.courier_type_valid,
            new CourierOnLeave(
              courierLeave.id,
              courierLeave.date_leave,
              courierLeave.start_time,
              courierLeave.end_time,
              courierLeave.is_custom_leave
            ),
            courierLeave.requested_by,
          )
      )
    )
  }

  public convertDownloadTemplateManageCourierLeaveFromApi(result: AxiosResponse): string {
    const {
      data
    } = result

    return data.url
  }

  public convertUploadBulkResFromApi(
    result: AxiosResponse,
    isUpload: boolean
  ): ManageCourierUploadBulk {
    const {
      data: { data },
    } = result

    let summary
    let filename
    let rows
    let pagination
    let filepath

    if (isUpload) {
      const dataUpload = data.data_upload
      filename = dataUpload.filename
      summary = dataUpload.summary
      rows = dataUpload.rows
      pagination = dataUpload.pagination
      filepath = dataUpload.filepath
    } else {
      filename = data.filename
      summary = data.summary
      rows = data.rows
      pagination = data.pagination
      filepath = data.filepath
    }
    return new ManageCourierUploadBulk(
      undefined,
      data.created_by,
      data.created_at,
      new ManageCourierUploadBulkDataUpload(
        filename,
        filepath,
        new ManageCourierUploadBulkSummary(
          summary.row_valid,
          summary.row_invalid,
          summary.courier_valid,
          summary.courier_invalid
        ),
        rows.map((row: Record<string, never>) => {
          return new ManageCourierUploadBulkRow(
            row.no,
            row.courier_id,
            row.courier_name || row.fullname,
            row.date_leave,
            row.start_time,
            row.end_time,
            row.is_custom_leave,
            row.note,
            row.status,
            row.reason
          )
        }),
        pagination &&
          new Pagination(
            pagination.page,
            pagination.limit,
            pagination.total_item
          )
      )
    )
  }

  public convertManageCourierHistoryUploadBulksFromApi(
    result: AxiosResponse
  ): ManageCourierHistoryUploadBulks {
    const {
      data: { pagination, data },
    } = result

    return new ManageCourierHistoryUploadBulks(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (history: Record<string, never>) =>
          new ManageCourierHistoryUploadBulk(
            history.id,
            history.filename,
            history.filepath,
            history.created_at,
            history.created_by
          )
      )
    )
  }

  public convertManageCourierDetailHistoryUploadBulkFromApi(
    result: AxiosResponse
  ): ManageCourierUploadBulk {
    const {
      data: {
        data,
        pagination,
        id,
        filename,
        filepath,
        created_at,
        created_by,
        total_valid,
        total_invalid,
      },
    } = result

    return new ManageCourierUploadBulk(
      id,
      created_by,
      created_at,
      new ManageCourierUploadBulkDataUpload(
        filename,
        filepath,
        new ManageCourierUploadBulkSummary(total_valid, total_invalid),
        data.map((row: Record<string, never>) => {
          return new ManageCourierUploadBulkRow(
            row.no,
            row.courier_id,
            row.fullname,
            row.date_leave,
            row.start_time,
            row.end_time,
            row.is_custom_leave,
            undefined,
            row.status,
            undefined
          )
        }),
        Utils.toInstance(new Pagination(), pagination)
      )
    )
  }

  public convertTruckVendorsFromApi(result: AxiosResponse): TruckVendor[] {
    const {
      data: { data },
    } = result

    return data.map(
      (data: string) =>
        Utils.toInstance(new TruckVendor(), data)
    )
  }

  public convertCourierLeaveRequestsFromApi(result: AxiosResponse): ManageCourierLeaveRequests {
    const {
      data: {data, pagination}
    } = result

    return new ManageCourierLeaveRequests(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: ManageCourierLeaveRequestResponse) => {
        return new ManageCourierLeaveRequest(
          item.id,
          item.date_leave,
          item.start_time,
          item.end_time,
          item.is_custom_leave,
          item.reason,
          new ManageCourier(
            item.courier.courier_id,
            item.courier.fullname,
            item.courier.role,
            item.courier.phone_number,
            item.courier.vehicle_type,
            '',
            '',
            item.courier.partner_name,
            '',
            item.courier.courier_type,
            '',
            item.courier.courier_type_valid
          )
        )
      })
    )
  }

  public convertCourierLeaveTotalRequestFromApi(result: AxiosResponse): number {
    const {
      data: { data: { total_leave_request } }
    } = result

    return total_leave_request
  }
}
