export class Schedule {
  startTime?: string
  endTime?: string
  offerTime?: string

  constructor(
    start_time?: string,
    end_time?: string,
    offer_time?: string
  ) {
    this.startTime =  start_time
    this.endTime =  end_time
    this.offerTime =  offer_time
  }
}

export class ScheduleOrder {
  day?: number
  isActive?: boolean
  updatedAt?: Date
  updatedBy?: string
  schedule?: Schedule[]

  constructor (
    day?: number,
    is_active?: boolean,
    updated_at?: Date,
    updated_by?: string,
    schedule?: Schedule[],
  ) {
    this.day = day
    this.isActive = is_active
    this.updatedAt = updated_at
    this.updatedBy = updated_by
    this.schedule = schedule
  }
}
