

















































































import { RouteConfig, Route } from 'vue-router'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import MenuIconBanner from '@/app/ui/assets/menu_icon_banner.vue'
import MenuIconMitraPOS from '@/app/ui/assets/menu_icon_mitra_pos.vue'
import MenuIconSaldo from '@/app/ui/assets/menu_icon_saldo.vue'
import MenuIconUserManagement from '@/app/ui/assets/menu_icon_user_management.vue'
import MenuIconFAQ from '@/app/ui/assets/menu_icon_faq.vue'
import MenuIconPN from '@/app/ui/assets/menu_icon_notification.vue'
import MenuIconClaim from '@/app/ui/assets/menu_icon_claim.vue'
import MenuIconVoucher from '@/app/ui/assets/menu_icon_voucher.vue'
import MenuIconSouvenir from '@/app/ui/assets/menu_icon_souvenir.vue'
import MenuIconExport from '@/app/ui/assets/menu_icon_export.vue'
import MenuIconDriver from '@/app/ui/assets/menu_icon_driver.vue'
import MenuIconWithdrawal from '@/app/ui/assets/menu_icon_withdrawal.vue'
import MenuIconPickup from '@/app/ui/assets/menu_icon_pickup.vue'
import MenuIconManageCourier from '@/app/ui/assets/menu_icon_manage_courier.vue'
import MenuIconManageCustomer from '@/app/ui/assets/menu_icon_manage_customer.vue'
import MenuIconMarketPlace from '@/app/ui/assets/menu_icon_marketplace.vue'
import MenuIconPayroll from '@/app/ui/assets/menu_icon_payroll.vue'
import MenuIconAdjustment from '@/app/ui/assets/menu_icon_adjustment.vue'
import MenuIconPickupLocation from '@/app/ui/assets/menu_icon_pickup_location.vue'
import MenuIconOffer from '@/app/ui/assets/menu_icon_offer.vue'
import MenuIconLoyaltyProgram from '@/app/ui/assets/menu_icon_loyalty_program.vue'
import MenuIconAdjustmentParcelPoint from '@/app/ui/assets/menu_icon_adjustment_parcel_point.vue'
import MenuIconUpdateAppVersion from '@/app/ui/assets/menu_icon_update_app_version.vue'
import MenuIconPickupTimeManagement from '@/app/ui/assets/menu_icon_pickup_time_management.vue'
import MenuIconShuttleManagement from '@/app/ui/assets/menu_icon_shuttle_management.vue'
import MenuIconCOD from '@/app/ui/assets/menu_icon_cod.vue'
import MenuIconDay from '@/app/ui/assets/menu_icon_day.vue'
import { LocalStorage } from '@/app/infrastructures/misc'
import controller from '@/app/ui/controllers/MixPanelController'

@Component({
  components: {
    ExpandIcon,
    MenuIconBanner,
    MenuIconMitraPOS,
    MenuIconSaldo,
    MenuIconUserManagement,
    MenuIconFAQ,
    MenuIconPN,
    MenuIconClaim,
    MenuIconVoucher,
    MenuIconSouvenir,
    MenuIconExport,
    MenuIconDriver,
    MenuIconWithdrawal,
    MenuIconPickup,
    MenuIconManageCourier,
    MenuIconManageCustomer,
    MenuIconMarketPlace,
    MenuIconPayroll,
    MenuIconAdjustment,
    MenuIconPickupLocation,
    MenuIconOffer,
    MenuIconLoyaltyProgram,
    MenuIconAdjustmentParcelPoint,
    MenuIconUpdateAppVersion,
    MenuIconPickupTimeManagement,
    MenuIconShuttleManagement,
    MenuIconCOD,
    MenuIconDay,
  },
})
export default class MenuItem extends Vue {
  @Prop({ default: false }) private isAlwaysExpand!: boolean
  @Prop({ required: true }) private item!: RouteConfig
  @Prop({ default: '' }) private basePath!: string

  accountId = ''
  username = ''
  currentRoute = this.$router.currentRoute
  isOpen = this.item.meta.single
    ? false
    : this.$props.isAlwaysExpand || this.isCurrentPathMatch

  created(): void {
    this.accountId = LocalStorage.getLocalStorage(LocalStorage.ACCOUNT_ID, true)
    this.username = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )
  }

  @Watch('$route')
  onRouteChange(now: Route): void {
    this.currentRoute = now
  }

  get theIcon(): string {
    return this.item.meta.icon
  }

  get isCurrentPathMatch(): boolean {
    if (this.currentRoute) {
      if (
        !this.item.meta.single &&
        this.item.children &&
        this.item.children.length > 0
      ) {
        return (
          Boolean(
            this.item.children.find(
              item =>
                this.realPath(item.path) ===
                this.currentRoute.path.replace(/[/][0-9]+/g, '/:id')
            )
          ) ||
          this.currentRoute.path.split('/')[1] === this.basePath.split('/')[1]
        )
      } else {
        return (
          this.currentRoute.path.split('/')[1] === this.basePath.split('/')[1]
        )
      }
    }
    return false
  }

  private realPath(path: string) {
    return `${this.basePath}/${path}`
  }

  private onClickParent() {
    if (!this.item.meta.single) {
      this.isOpen = !this.isOpen
    }
  }

  private isMenuActive(menu: RouteConfig) {
    if (menu.name === this.$route.name) {
      return true
    }

    if (
      menu.meta.hasSubChildren &&
      menu.children?.some(submenu => submenu.name === this.$route.name)
    ) {
      return true
    }

    const menuName =
      menu.name || menu.children?.find(child => child.path === '')?.name || ''

    if (
      this.$route.matched.some(
        matched =>
          !!matched.name && !!menuName && matched.name.includes(menuName)
      )
    ) {
      return true
    }

    return false
  }

  private onPubSub(child: RouteConfig) {
    let menuActive = this.isMenuActive(child)
    const pathParent = this.item.path
    const path = child.path.toLowerCase()
    let payload = {
      logType: 'mixpanel',
      action: '',
      projectId: 'da',
      userId: this.accountId,
      username: this.username,
    }
    const dataMixPanel = [
      {
        path: '/pickup',
        payload: { ...payload, action: `liberta-pickupDashboard-${path}` },
      },
      {
        path: '/manage-user-&-courier',
        payload: { ...payload, action: 'liberta-manageUser-courier' },
      },
      {
        path: '/driver-announcement',
        payload: {
          ...payload,
          action: 'liberta-driverAnnouncement-announcement',
        },
      },
      {
        path: '/driver',
        children: [
          {
            path: 'scoreboard',
            payload: { ...payload, action: 'liberta-driver-driverScoreBoard' },
          },
          {
            path: 'pod-tracker',
            payload: { ...payload, action: 'liberta-driver-podTracker' },
          },
          {
            path: 'cod-tracker',
            payload: { ...payload, action: 'liberta-driver-codTracker' },
          },
          {
            path: 'manual-pod-photos',
            payload: { ...payload, action: 'liberta-driver-manualPODPhoto' },
          },
        ],
      },
    ]

    if (!menuActive) {
      const currentData = dataMixPanel.find(item => item.path === pathParent)
      if (currentData) {
        if (currentData.payload) {
          controller.sendLoggingPubSub(currentData.payload)
        } else if (currentData.children) {
          const item = currentData.children.find(unit => unit.path === path)
          if (item) controller.sendLoggingPubSub(item.payload)
        }
      }
    }
  }
}
