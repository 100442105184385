import { injectable } from 'tsyringe'
import { ExportDataRepositoryInterface } from '@/data/persistences/repositories/contracts/ExportDataRepositoryInterface'

@injectable()
export class ExportDataPresenter {
  private repository: ExportDataRepositoryInterface

  constructor(repository: ExportDataRepositoryInterface) {
    this.repository = repository
  }

  public export(params: Map<string, any>): Promise<string> {
    return this.repository.export(params)
  }
}
