import { injectable } from 'tsyringe'

import { DriverAnnouncementRepositoryInterface } from '@/data/persistences/repositories/contracts/DriverAnnouncementRepositoryInterface'
import {
  DriverAnnouncement,
  DriverAnnouncements,
} from '@/domain/entities/DriverAnnouncement'
import {
  CreateDriverAnnouncementRequestInterface,
  UpdateDriverAnnouncementRequestInterface,
  UploadDriverAnnouncementRequestInterface,
} from '@/data/payload/contracts/DriverAnnouncementRequest'

@injectable()
export class DriverAnnouncementPresenter {
  private repository: DriverAnnouncementRepositoryInterface

  constructor(repository: DriverAnnouncementRepositoryInterface) {
    this.repository = repository
  }

  public get(announcementId: number): Promise<DriverAnnouncement> {
    return this.repository.get(announcementId)
  }

  public getAll(
    params: Map<string, string | number>
  ): Promise<DriverAnnouncements> {
    return this.repository.getAll(params)
  }

  public create(
    payload: CreateDriverAnnouncementRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    announcementId: number,
    payload: UpdateDriverAnnouncementRequestInterface
  ): Promise<boolean> {
    return this.repository.update(announcementId, payload)
  }

  public delete(announcementId: number): Promise<boolean> {
    return this.repository.delete(announcementId)
  }

  public uploadImage(
    payload: UploadDriverAnnouncementRequestInterface
  ): Promise<string> {
    return this.repository.uploadImage(payload)
  }
}
