import { Utils } from '@/app/infrastructures/misc'
import {
  PickupHistoryLogResponseInterface,
  PickupRadiusValidationResponseInterface,
} from '@/data/responses/contracts/PickupRadiusValidationResponseInterface'
import { Pagination } from '@/domain/entities/Pagination'
import {
  HistoryLog,
  HistoryLogs,
  PickupRadiusDetail,
  PickupRadiusValidation,
  PickupRadiusValidationDetail,
  PickupRadiusValidations,
  Summary,
} from '@/domain/entities/PickupRadiusValidation'
import { AxiosResponse } from 'axios'

export class PickupRadiusValidationMapper {
  convertPickupRadiusValidationResponseFromAPI(
    result: AxiosResponse
  ): PickupRadiusValidations {
    const {
      data: { pagination, data, summary },
    } = result
    return new PickupRadiusValidations(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: PickupRadiusValidationResponseInterface) => {
        return new PickupRadiusValidation(
          item.id,
          item.shipment_id,
          item.courier_id,
          item.courier_name,
          item.courier_phone_number,
          item.courier_type_valid,
          item.courier_3lc,
          item.created_at,
          item.status_pickup
        )
      }),
      Utils.toInstance(new Summary(), summary)
    )
  }

  convertPickupRadiusValidationDetailResponseFromAPI(
    result: AxiosResponse
  ): PickupRadiusDetail {
    const {
      data : { data: {
        detail,
        history_log: { data, pagination },
      }
    },
    } = result
    return new PickupRadiusDetail(
      new PickupRadiusValidationDetail(
        detail.courier_name,
        detail.courier_type_valid,
        detail.courier_phone_number,
        detail.courier_3lc,
        detail.shipment_id,
        detail.created_at,
        detail.actual_pickup_location,
        detail.pickup_location,
        detail.pickup_radius,
        detail.proof_image,
        detail.is_pickup_valid,
        detail.address,
        detail.description
      ),
      new HistoryLogs(
        Utils.toInstance(new Pagination(), pagination),
        data.map((item: PickupHistoryLogResponseInterface) => {
          return new HistoryLog(item.updated_by, item.updated_date)
        })
      )
    )
  }

  convertPickupRadiusValidationExportPickupLogResponseFromAPI(
    result: AxiosResponse
  ): string {
    const { data: { url } } = result
    return url
  }
}
