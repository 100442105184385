import { injectable } from 'tsyringe'
import { Claim, Claims } from '@/domain/entities/Claim'
import { ClaimRepositoryInterface } from '@/data/persistences/repositories/contracts/ClaimRepositoryInterface'
import { UpdateClaimRequestInterface } from '@/data/payload/contracts/ClaimRequest'

@injectable()
export class ClaimPresenter {
  private repository: ClaimRepositoryInterface

  constructor(repository: ClaimRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, any>): Promise<Claims> {
    return this.repository.getAll(params)
  }

  public get(claimId: string): Promise<Claim> {
    return this.repository.get(claimId)
  }

  public update(
    claimId: string,
    payload: UpdateClaimRequestInterface
  ): Promise<boolean> {
    return this.repository.update(claimId, payload)
  }
}
