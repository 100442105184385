import { OriginRoute } from '@/domain/entities/Route'
import { Pagination } from '@/domain/entities/Pagination'

export class ProgramHistory {
  public id?: number
  public name?: string
  public userId?: number
  public userName?: string
  public userEmail?: string
  public city?: string
  public getPointDate?: string
  public expiredDate?: string
  public saldoPoint?: number
  public pointSpent?: number
  public disbursedAt?: string

  constructor(
    id?: number,
    name?: string,
    userId?: number,
    userName?: string,
    userEmail?: string,
    city?: string,
    getPointDate?: string,
    expiredDate?: string,
    saldoPoint?: number,
    pointSpent?: number,
    disbursedAt?: string
  ) {
    this.id = id
    this.name = name
    this.userId = userId
    this.userName = userName
    this.userEmail = userEmail
    this.city = city
    this.getPointDate = getPointDate
    this.expiredDate = expiredDate
    this.saldoPoint = saldoPoint
    this.pointSpent = pointSpent
    this.disbursedAt = disbursedAt
  }
}

export class Program {
  public id?: number
  public name?: string
  public points?: number
  public period?: string
  public target?: string
  public triggerBy?: string
  public routes?: OriginRoute[]
  public status?: string
  public createdAt?: string
  public updatedAt?: string
  public purpose?: string
  public expiryDay?: number
  public limitPerDay?: number
  public startDate?: string
  public endDate?: string
  public selectedUsers?: string
  public scheduledAt?: string
  public invoiceSource?: string
  public paymentMethodUsed?: string
  public minimumTransaction?: number
  public percentOfTransaction?: number
  public maxAmountGiven?: number
  public expiredDate?: string
  public limitPerUser?: number
  public budgetSource?: string
  public membershipLevel?: { label: string, value: number }[]
  public budgetAmount?: number | null | undefined
  public budgetUsed?: number | null | undefined
  public updatedBy?: string
  public pointValue?: string[]
  public extraRules?: ExtraRules
  public pointPurposePercentage?: PointPurposePercentage
  public disburseType?: string
  public pointDisburseDate?: number
  public pointValues?: PointValues
  public limitProgram?: LimitProgram
  public limitUser?: LimitUser
  public disburseStatus?: string
  public extraRulesActive?: boolean
  public totalAmountTransaction?: null |number | undefined
  public totalAmountTransactionTime?: string | null
  public totalNumberTransaction?: null | number| undefined
  public totalNumberTransactionTime?: string | null
  public logicType?: string
  public statusType?: string[]
  public shipmentType?: string[]
  public productType?: string[]
  public minimumAmount?: null | number| undefined
  public pointValueOpt?: string | number | null
  public pointValueAmountForInviter?: null | number | undefined
  public pointValueAmountForInviterDisburseTo?: string | null
  public pointValueAmountForInviterApprovalType?: string | null
  public pointValueAmountForInvitee?: null |number | undefined
  public pointValueAmountForInviteeDisburseTo?: string | null
  public pointValueAmountForInviteeApprovalType?: string | null
  public pointValueAmountForGeneral?: null | number | undefined
  public pointValueAmountForGeneralDisburseTo?: string | null
  public pointValueAmountForGeneralApprovalType?: string | null
  public pointValuePercentageForInviter?: null | number | undefined
  public pointValuePercentageForInviterMax?: null | number | undefined
  public pointValuePercentageForInvitee?: null | number | undefined
  public pointValuePercentageForInviteeMax?: null | number | undefined
  public pointValuePercentageForGeneral?: null | number | undefined
  public pointValuePercentageForGeneralMax?: null | number  |undefined
  public pointDisburseType?: string | null
  public shipmentStatus?: string | null
  public specificDate?: string | number | null
  public pointDisburseLimitPerProgram?: string | null
  public totalNumberDisbursePerProgram?: null | number | undefined
  public totalAmountDisbursePerProgram?: null | number | undefined
  public pointDisburseLimitPerUser?: string | null
  public totalNumberDisbursePerUser?: null | number | undefined
  public totalAmountDisbursePerUser?: null | number | undefined
  public deviceIdLimitCustomer?: number | null
  public pointValuePercentageForInviterDisburseTo?: string | null
  public pointValuePercentageForInviterApprovalType?: string | null
  public pointValuePercentageForInviteeDisburseTo?: string | null
  public pointValuePercentageForInviteeApprovalType?: string | null
  public pointValuePercentageForGeneralDisburseTo?: string | null
  public pointValuePercentageForGeneralApprovalType?: string | null

  constructor(
    id?: number,
    name?: string,
    period?: string,
    target?: string,
    triggerBy?: string,
    routes?: OriginRoute[],
    points?: number,
    status?: string,
    createdAt?: string,
    updatedAt?: string,
    purpose?: string,
    expiryDay?: number,
    limitPerDay?: number,
    startDate?: string,
    endDate?: string,
    selectedUsers?: string,
    scheduledAt?: string,
    invoiceSource?: string,
    paymentMethodUsed?: string,
    minimumTransaction?: number,
    percentOfTransaction?: number,
    maxAmountGiven?: number,
    expiredDate?: string,
    limitPerUser?: number,
    budgetSource?: string,
    membershipLevel?: { label: string, value: number }[],
    budgetAmount?: number | null,
    budgetUsed?: number | null,
    updatedBy?: string,
    pointValue?: string[],
    extraRules?: ExtraRules,
    pointPurposePercentage?: PointPurposePercentage,
    disburseType?: string,
    pointDisburseDate?: number,
    pointValues?: PointValues,
    limitProgram?: LimitProgram,
    limitUser?: LimitUser,
    disburseStatus?: string,
    extraRulesActive?: boolean,
    totalAmountTransaction?: null |number | undefined,
    totalAmountTransactionTime?: string | null,
    totalNumberTransaction?: null | number| undefined,
    totalNumberTransactionTime?: string | null,
    logicType?: string,
    statusType?: string[],
    shipmentType?: string[],
    productType?: string[],
    minimumAmount?: null | number| undefined,
    pointValueOpt?: string | number | null,
    pointValueAmountForInviter?: null | number | undefined,
    pointValueAmountForInviterDisburseTo?: string | null,
    pointValueAmountForInviterApprovalType?: string | null,
    pointValueAmountForInvitee?: null |number | undefined,
    pointValueAmountForInviteeDisburseTo?: string | null,
    pointValueAmountForInviteeApprovalType?: string | null,
    pointValueAmountForGeneral?: null | number | undefined,
    pointValueAmountForGeneralDisburseTo?: string | null,
    pointValueAmountForGeneralApprovalType?: string | null,
    pointValuePercentageForInviter?: null | number | undefined,
    pointValuePercentageForInviterMax?: null | number | undefined,
    pointValuePercentageForInvitee?: null | number | undefined,
    pointValuePercentageForInviteeMax?: null | number | undefined,
    pointValuePercentageForGeneral?: null | number | undefined,
    pointValuePercentageForGeneralMax?: null | number  |undefined,
    pointDisburseType?: string | null,
    shipmentStatus?: string | null,
    specificDate?: string | number | null,
    pointDisburseLimitPerProgram?: string | null,
    totalNumberDisbursePerProgram?: null | number | undefined,
    totalAmountDisbursePerProgram?: null | number | undefined,
    pointDisburseLimitPerUser?: string | null,
    totalNumberDisbursePerUser?: null | number | undefined,
    totalAmountDisbursePerUser?: null | number | undefined,
    deviceIdLimitCustomer?: number | null,
    pointValuePercentageForInviterDisburseTo?: string | null,
    pointValuePercentageForInviterApprovalType?: string | null,
    pointValuePercentageForInviteeDisburseTo?: string | null,
    pointValuePercentageForInviteeApprovalType?: string | null,
    pointValuePercentageForGeneralDisburseTo?: string | null,
    pointValuePercentageForGeneralApprovalType?: string | null,
  ) {
    this.id = id
    this.name = name
    this.points = points
    this.period = period
    this.target = target
    this.triggerBy = triggerBy
    this.routes = routes
    this.status = status
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.purpose = purpose
    this.expiryDay = expiryDay
    this.limitPerDay = limitPerDay
    this.startDate = startDate
    this.endDate = endDate
    this.selectedUsers = selectedUsers
    this.scheduledAt = scheduledAt
    this.invoiceSource = invoiceSource
    this.paymentMethodUsed = paymentMethodUsed
    this.minimumTransaction = minimumTransaction
    this.percentOfTransaction = percentOfTransaction
    this.maxAmountGiven = maxAmountGiven
    this.expiredDate = expiredDate
    this.limitPerUser = limitPerUser
    this.budgetSource = budgetSource
    this.membershipLevel = membershipLevel
    this.budgetAmount = budgetAmount
    this.budgetUsed = budgetUsed
    this.updatedBy = updatedBy
    this.pointValue = pointValue
    this.extraRules = extraRules
    this.pointPurposePercentage = pointPurposePercentage
    this.disburseType = disburseType
    this.pointDisburseDate = pointDisburseDate
    this.pointValues = pointValues
    this.limitProgram = limitProgram
    this.limitUser = limitUser
    this.disburseStatus = disburseStatus
    this.extraRulesActive = extraRulesActive
    this.totalAmountTransaction = totalAmountTransaction
    this.totalAmountTransactionTime = totalAmountTransactionTime
    this.totalNumberTransaction = totalNumberTransaction
    this.totalNumberTransactionTime = totalNumberTransactionTime
    this.logicType = logicType
    this.statusType = statusType
    this.shipmentType = shipmentType
    this.productType = productType
    this.minimumAmount = minimumAmount
    this.pointValueOpt = pointValueOpt
    this.pointValueAmountForInviter = pointValueAmountForInviter
    this.pointValueAmountForInviterDisburseTo = pointValueAmountForInviterDisburseTo
    this.pointValueAmountForInviterApprovalType = pointValueAmountForInviterApprovalType
    this.pointValueAmountForInvitee = pointValueAmountForInvitee
    this.pointValueAmountForInviteeDisburseTo = pointValueAmountForInviteeDisburseTo
    this.pointValueAmountForInviteeApprovalType = pointValueAmountForInviteeApprovalType
    this.pointValueAmountForGeneral = pointValueAmountForGeneral
    this.pointValueAmountForGeneralDisburseTo = pointValueAmountForGeneralDisburseTo
    this.pointValueAmountForGeneralApprovalType = pointValueAmountForGeneralApprovalType
    this.pointValuePercentageForInviter = pointValuePercentageForInviter
    this.pointValuePercentageForInviterMax = pointValuePercentageForInviterMax
    this.pointValuePercentageForInvitee = pointValuePercentageForInvitee
    this.pointValuePercentageForInviteeMax = pointValuePercentageForInviteeMax
    this.pointValuePercentageForGeneral = pointValuePercentageForGeneral
    this.pointValuePercentageForGeneralMax = pointValuePercentageForGeneralMax
    this.pointDisburseType = pointDisburseType
    this.shipmentStatus = shipmentStatus
    this.specificDate = specificDate
    this.pointDisburseLimitPerProgram = pointDisburseLimitPerProgram
    this.totalNumberDisbursePerProgram = totalNumberDisbursePerProgram
    this.totalAmountDisbursePerProgram = totalAmountDisbursePerProgram
    this.pointDisburseLimitPerUser = pointDisburseLimitPerUser
    this.totalNumberDisbursePerUser = totalNumberDisbursePerUser
    this.totalAmountDisbursePerUser = totalAmountDisbursePerUser
    this.deviceIdLimitCustomer = deviceIdLimitCustomer
    this.pointValuePercentageForInviterDisburseTo = pointValuePercentageForInviterDisburseTo
    this.pointValuePercentageForInviterApprovalType = pointValuePercentageForInviterApprovalType
    this.pointValuePercentageForInviteeDisburseTo = pointValuePercentageForInviteeDisburseTo
    this.pointValuePercentageForInviteeApprovalType = pointValuePercentageForInviteeApprovalType
    this.pointValuePercentageForGeneralDisburseTo = pointValuePercentageForGeneralDisburseTo
    this.pointValuePercentageForGeneralApprovalType = pointValuePercentageForGeneralApprovalType
  }
}

export class LimitProgram {
  public duration?: string
  public totalAmount?: number
  public totalNumber?: number

  constructor(
    duration?: string,
    totalAmount?: number,
    totalNumber?: number
  ) {
    this.duration = duration
    this.totalAmount = totalAmount
    this.totalNumber = totalNumber
  }
}

export class LimitUser {
  public duration?: string
  public totalAmount?: number
  public totalNumber?: number

  constructor(
    duration?: string,
    totalAmount?: number,
    totalNumber?: number
  ) {
    this.duration = duration
    this.totalAmount = totalAmount
    this.totalNumber = totalNumber
  }
}

export class PointValues {
  public amount?: number
  public generalPercentage?: number
  public maxAmountGeneralPercentage?: number
  public amountInvitee?: number
  public inviteePercentage?: number
  public maxAmountInviteePercentage?: number
  public amountInviter?: number
  public inviterPercentage?: number
  public maxAmountInviterPercentage?: number

  constructor(
    amount?: number,
    generalPercentage?: number,
    maxAmountGeneralPercentage?: number,
    amountInvitee?: number,
    inviteePercentage?: number,
    maxAmountInviteePercentage?: number,
    amountInviter?: number,
    inviterPercentage?: number,
    maxAmountInviterPercentage?: number
  ) {
    this.amount = amount
    this.generalPercentage = generalPercentage
    this.maxAmountGeneralPercentage = maxAmountGeneralPercentage
    this.amountInvitee = amountInvitee
    this.inviteePercentage = inviteePercentage
    this.maxAmountInviteePercentage = maxAmountInviteePercentage
    this.amountInviter = amountInviter
    this.inviterPercentage = inviterPercentage
    this.maxAmountInviterPercentage = maxAmountInviterPercentage
  }
}

export class PointPurposePercentage {
  public pickup?: number
  public dropoff?: number
  public pickupFavorite?: number
  public dropoffFavorite?: number
  public shop?: number
  public redeemVoucher?: number

  constructor(
    pickup?: number,
    dropoff?: number,
    pickupFavorite?: number,
    dropoffFavorite?: number,
    shop?: number,
    redeemVoucher?: number
  ) {
    this.pickup = pickup
    this.dropoff = dropoff
    this.pickupFavorite = pickupFavorite
    this.dropoffFavorite = dropoffFavorite
    this.shop = shop
    this.redeemVoucher = redeemVoucher
  }
}

export class ExtraRules {
  public totalAmountTransaction?: number
  public durationTotalAmount?: string
  public totalNumberTransaction?: number
  public durationTotalNumberTransaction?: string
  public typeValidation?: string

  constructor(
    totalAmountTransaction?: number,
    durationTotalAmount?: string,
    totalNumberTransaction?: number,
    durationTotalNumberTransaction?: string,
    typeValidation?: string
  ) {
    this.totalAmountTransaction = totalAmountTransaction
    this.durationTotalAmount = durationTotalAmount
    this.totalNumberTransaction = totalNumberTransaction
    this.durationTotalNumberTransaction = durationTotalNumberTransaction
    this.typeValidation = typeValidation
  }
}

export class Programs {
  public pagination?: Pagination
  public data?: Program[]

  constructor(pagination?: Pagination, data?: Program[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ProgramHistories {
  public pagination?: Pagination
  public data?: ProgramHistory[]

  constructor(pagination?: Pagination, data?: ProgramHistory[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class Trigger {
  public triggerId: string
  public triggerName: string

  constructor(triggerId: string, triggerName: string) {
    this.triggerId = triggerId
    this.triggerName = triggerName
  }
}

export class ProgrammaticParcelPoinAndSaldoPagination extends Pagination {
  startPeriod?: string
  endPeriod?: string

  constructor(
    page = 1,
    perPage = 20,
    totalItem = 0,
    keyword?: string,
    startPeriod?: string,
    endPeriod?: string
  ) {
    super(page, perPage, totalItem, keyword, undefined, undefined, undefined)
    this.startPeriod = startPeriod
    this.endPeriod = endPeriod
  }
}

export class ProgrammaticParcelPoinAndSaldo {
  programmaticEligibleUserId: number;
  programmaticPointId: number;
  programmaticPointName: string;
  customerId: number;
  customerName: string;
  inviteeInviter: string;
  point: number;
  period: string;
  periodDate: string;
  status: string;
  disburseTo: string;
  hasChild: boolean;

  constructor(
    programmaticEligibleUserId: number,
    programmaticPointId: number,
    programmaticPointName: string,
    customerId: number,
    customerName: string,
    inviteeInviter: string,
    point: number,
    period: string,
    periodDate: string,
    status: string,
    disburseTo: string,
    hasChild: boolean
  ) {
    this.programmaticEligibleUserId = programmaticEligibleUserId;
    this.programmaticPointId = programmaticPointId;
    this.programmaticPointName = programmaticPointName;
    this.customerId = customerId;
    this.customerName = customerName;
    this.inviteeInviter = inviteeInviter;
    this.point = point;
    this.period = period;
    this.periodDate = periodDate;
    this.status = status;
    this.disburseTo = disburseTo;
    this.hasChild = hasChild;
  }
}

export class ProgrammaticParcelPoinAndSaldos {
  pagination: ProgrammaticParcelPoinAndSaldoPagination;
  data?: ProgrammaticParcelPoinAndSaldo[];

  constructor(pagination: ProgrammaticParcelPoinAndSaldoPagination, data?: ProgrammaticParcelPoinAndSaldo[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ProgrammaticParcelPoinAndSaldoInviteeInviter {
  programmaticPointHistoryId: number;
  programmaticPointName: string;
  disburseTo: string;
  customerId: number;
  inviteeCustomerId: number;
  packageId: string;
  bkdDate: string;
  podDate: string;
  amountPaid: number;
  percentage: number;
  totalEarning: number;
  status: string;
  approvedBy: string;
  fixedAmount: number
  shippingFee: number;

  constructor(
    programmaticPointHistoryId: number,
    programmaticPointName: string,
    disburseTo: string,
    customerId: number,
    inviteeCustomerId: number,
    packageId: string,
    bkdDate: string,
    podDate: string,
    amountPaid: number,
    percentage: number,
    totalEarning: number,
    status: string,
    approvedBy: string,
    fixedAmount: number,
    shippingFee: number
  ) {
    this.programmaticPointHistoryId = programmaticPointHistoryId;
    this.programmaticPointName = programmaticPointName
    this.disburseTo = disburseTo
    this.customerId = customerId;
    this.inviteeCustomerId = inviteeCustomerId;
    this.packageId = packageId;
    this.bkdDate = bkdDate;
    this.podDate = podDate;
    this.amountPaid = amountPaid;
    this.percentage = percentage;
    this.totalEarning = totalEarning;
    this.status = status;
    this.approvedBy = approvedBy;
    this.fixedAmount = fixedAmount
    this.shippingFee = shippingFee;
  }
}

export class ProgrammaticParcelPoinAndSaldoInviteeInviterPagination extends Pagination {
  startPeriod?: string
  endPeriod?: string
  nextCursor?: string

  constructor(
    page = 1,
    perPage = 20,
    totalItem = 0,
    keyword?: string,
    startPeriod?: string,
    endPeriod?: string,
    nextCursor?: string
  ) {
    super(page, perPage, totalItem, keyword, undefined, undefined, undefined)
    this.startPeriod = startPeriod
    this.endPeriod = endPeriod
    this.nextCursor = nextCursor
  }
}

export class ProgrammaticParcelPoinAndSaldoInviteeInviterData {
  pagination: ProgrammaticParcelPoinAndSaldoInviteeInviterPagination;
  data?: ProgrammaticParcelPoinAndSaldoInviteeInviter[];

  constructor(pagination: ProgrammaticParcelPoinAndSaldoInviteeInviterPagination, data?: ProgrammaticParcelPoinAndSaldoInviteeInviter[]) {
    this.pagination = pagination;
    this.data = data;
  }
}
