import Vue from 'vue'
export const EventBus = new Vue()
export const EventBusConstants = {
  CANCEL_PROGRAM_SUCCESS: 'CANCEL_PROGRAM_SUCCESS',
  SAVE_PROGRAM_SUCCESS: 'SAVE_PROGRAM_SUCCESS',
  SAVE_BANNER_SUCCESS: 'SAVE_BANNER_SUCCESS',
  CANCEL_BANNER_SUCCESS: 'CANCEL_BANNER_SUCCESS',
  UPLOAD_BULK_UPDATE_POST_SUCCESS: 'UPLOAD_BULK_UPDATE_POST_SUCCESS',
  UPDATE_POS_SUCCESS: 'UPDATE_POS_SUCCESS',
  DOWNLOAD_POS_READY: 'DOWNLOAD_POS_READY',
  DOWNLOAD_SALDO_PAYMENT_READY: 'DOWNLOAD_SALDO_PAYMENT_READY',
  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  CREATE_ACCOUNT_SUCCESS: 'CREATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  CREATE_FAQ_SUCCESS: 'CREATE_FAQ_SUCCESS',
  UPDATE_FAQ_SUCCESS: 'UPDATE_FAQ_SUCCESS',
  DETAIL_FAQ_SUCCESS: 'DETAIL_FAQ_SUCCESS',
  STAR_QUESTION_SUCCESS: 'STAR_QUESTION_SUCCESS',
  UNSTAR_QUESTION_SUCCESS: 'UNSTAR_QUESTION_SUCCESS',
  UPDATE_REORDER_STAR_QUESTION_SUCCESS: 'UPDATE_REORDER_STAR_QUESTION_SUCCESS',
  UPDATE_REORDER_FAQ_SUCCESS: 'UPDATE_REORDER_FAQ_SUCCESS',
  UPDATE_FAQ_STATUS_SUCCESS: 'UPDATE_FAQ_STATUS_SUCCESS',
  UNPOST_FAQ_SUCCESS: 'UNPOST_FAQ_SUCCESS',
  REPOST_FAQ_SUCCESS: 'REPOST_FAQ_SUCCESS',
  DELETE_FAQ_SUCCESS: 'DELETE_FAQ_SUCCESS',
  DELETE_SUB_CATEGORY_SUCCESS: 'DELETE_SUB_CATEGORY_SUCCESS',
  CREATE_PN_SUCCESS: 'CREATE_PN_SUCCESS',
  UPDATE_PN_SUCCESS: 'UPDATE_PN_SUCCESS',
  CANCEL_PN_SUCCESS: 'CANCEL_PN_SUCCESS',
  UPDATE_CLAIM_SUCCESS: 'UPDATE_CLAIM_SUCCESS',
  CREATE_VOUCHER_SUCCESS: 'CREATE_VOUCHER_SUCCESS',
  UPDATE_VOUCHER_SUCCESS: 'UPDATE_VOUCHER_SUCCESS',
  DELETE_VOUCHER_SUCCESS: 'DELETE_VOUCHER_SUCCESS',
  DOWNLOAD_VOUCHER_SUCCESS: 'DOWNLOAD_VOUCHER_SUCCESS',
  CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
  CREATE_BRAND_FAILED: 'CREATE_BRAND_FAILED',
  UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
  UPDATE_BRAND_STATUS_SUCCESS: 'UPDATE_BRAND_STATUS_SUCCESS',
  CREATE_MERCHANT_SUCCESS: 'CREATE_MERCHANT_SUCCESS',
  UPDATE_MERCHANT_SUCCESS: 'UPDATE_MERCHANT_SUCCESS',
  UPDATE_MERCHANT_ACCOUNT_SUCCESS: 'UPDATE_MERCHANT_ACCOUNT_SUCCESS',
  UPDATE_MERCHANT_STATUS_SUCCESS: 'UPDATE_MERCHANT_STATUS_SUCCESS',
  CREATE_PRODUCT_SUCCESS: 'CREATE_PRODUCT_SUCCESS',
  UPDATE_PRODUCT_SUCCESS: 'UPDATE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
  UPDATE_STATUS_PRODUCT_SUCCESS: 'UPDATE_STATUS_PRODUCT_SUCCESS',
  UPDATE_STATUS_PRODUCT_VARIANT_SUCCESS:
    'UPDATE_STATUS_PRODUCT_VARIANT_SUCCESS',
  CANCEL_ORDER_SUCCESS: 'CANCEL_ORDER_SUCCESS',
  DOWNLOAD_EXPORT_DATA_READY: 'DOWNLOAD_EXPORT_DATA_READY',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  UPDATE_DRIVER_SCOREBOARD_SUCCESS: 'UPDATE_DRIVER_SCOREBOARD_SUCCESS',
  UPDATE_POD_HISTORY_SUCCESS: 'UPDATE_POD_HISTORY_SUCCESS',
  EXPORT_POD_SUCCESS: 'EXPORT_POD_SUCCESS',
  SAVE_SELECTED_MERCHANT_SUCCESS: 'SAVE_SELECTED_MERCHANT_SUCCESS',
  CREATE_CATEGORY_SECTION_SUCCESS: 'CREATE_CATEGORY_SECTION_SUCCESS',
  UPDATE_CATEGORY_SECTION_SUCCESS: 'UPDATE_CATEGORY_SECTION_SUCCESS',
  DELETE_CATEGORY_SECTION_SUCCESS: 'DELETE_CATEGORY_SECTION_SUCCESS',
  REPOSITION_CATEGORY_SECTION_SUCCESS: 'REPOSITION_CATEGORY_SECTION_SUCCESS',
  UPDATE_WITHDRAWAL_SUCCESS: 'UPDATE_WITHDRAWAL_SUCCESS',
  MANUAL_ASSIGNEE_SUCCESS: 'MANUAL_ASSIGNEE_SUCCESS',
  PICKUP_MARK_AS_DONE_SUCCESS: 'PICKUP_MARK_AS_DONE_SUCCESS',
  CANCEL_PICKUP_SUCCESS: 'CANCEL_PICKUP_SUCCESS',
  CREATE_HOMEPAGE_BANNER_SUCCESS: 'CREATE_HOMEPAGE_BANNER_SUCCESS',
  UPDATE_HOMEPAGE_BANNER_SUCCESS: 'UPDATE_HOMEPAGE_BANNER_SUCCESS',
  CANCEL_HOMEPAGE_BANNER_SUCCESS: 'CANCEL_HOMEPAGE_BANNER_SUCCESS',
  REPOSITION_HOMEPAGE_BANNER_SUCCESS: 'REPOSITION_HOMEPAGE_BANNER_SUCCESS',
  CREATE_DRIVER_ANNOUNCEMENT_SUCCESS: 'CREATE_DRIVER_ANNOUNCEMENT_SUCCESS',
  UPDATE_DRIVER_ANNOUNCEMENT_SUCCESS: 'UPDATE_DRIVER_ANNOUNCEMENT_SUCCESS',
  DELETE_DRIVER_ANNOUNCEMENT_SUCCESS: 'DELETE_DRIVER_ANNOUNCEMENT_SUCCESS',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_LIMIT: 'FORGOT_PASSWORD_LIMIT',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  ADD_COURIER_SUCCESS: 'ADD_COURIER_SUCCESS',
  ADD_WHITE_SELLER_SUCCESS: 'ADD_WHITE_SELLER_SUCCESS',
  EDIT_WHITE_SELLER_SUCCESS: 'EDIT_WHITE_SELLER_SUCCESS',
  DELETE_WHITE_SELLER_SUCCESS: 'DELETE_WHITE_SELLER_SUCCESS',
  BLOCK_CUSTOMER_SUCCESS: 'BLOCK_CUSTOMER_SUCCESS',
  UNBLOCK_CUSTOMER_SUCCESS: 'UNBLOCK_CUSTOMER_SUCCESS',
  SUSPEND_CUSTOMER_SUCCESS: 'SUSPEND_CUSTOMER_SUCCESS',
  ADD_PAYROLL_SUCCESS: 'ADD_PAYROLL_SUCCESS',
  EDIT_PERIOD_WITHDRAW_SUCCESS: 'EDIT_PERIOD_WITHDRAW_SUCCESS',
  EDIT_PERIOD_BASIC_FEE_SUCCESS: 'EDIT_PERIOD_BASIC_FEE_SUCCESS',
  EDIT_PERIOD_BONUS_SUCCESS: 'EDIT_PERIOD_BONUS_SUCCESS',
  ADD_MANUAL_ADJUSTMENT_BALANCE_SUCCESS:
    'ADD_MANUAL_ADJUSTMENT_BALANCE_SUCCESS',
  EDIT_MANAGE_DAY_SUCCESS: 'EDIT_MANAGE_DAY_SUCCESS',
  UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE_SUCCESS:
    'UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE_SUCCESS',
  UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE_FAILED:
    'UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE_FAILED',
  UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE:
    'UPLOAD_BULKY_MANUAL_ADJUSTMENT_BALANCE',
  SUBMIT_BULKY_MANUAL_ADJUSTMENT_BALANCE:
    'SUBMIT_BULKY_MANUAL_ADJUSTMENT_BALANCE',
  EDIT_BONUS_PAYROLL_STATUS_SUCCESS: 'EDIT_BONUS_PAYROLL_STATUS_SUCCESS',
  EDIT_BONUS_PAYROLL_BULK_STATUS_SUCCESS:
    'EDIT_BONUS_PAYROLL_BULK_STATUS_SUCCESS',
  CREATE_REQUEST_TOPUP_SUCCESS: 'CREATE_REQUEST_TOPUP_SUCCESS',
  APPROVE_APPROVING_TOPUP_SUCCESS: 'APPROVE_APPROVING_TOPUP_SUCCESS',
  REJECT_APPROVING_TOPUP_SUCCESS: 'REJECT_APPROVING_TOPUP_SUCCESS',
  APPROVE_APPROVING_TOPUP_FAILED: 'APPROVE_APPROVING_TOPUP_FAILED',
  REJECT_APPROVING_TOPUP_FAILED: 'REJECT_APPROVING_TOPUP_FAILED',
  CREATE_POPUP_BANNER_SUCCESS: 'CREATE_POPUP_BANNER_SUCCESS',
  UPDATE_POPUP_BANNER_SUCCESS: 'UPDATE_POPUP_BANNER_SUCCESS',
  INACTIVE_POPUP_BANNER_SUCCESS: 'INACTIVE_POPUP_BANNER_SUCCESS',
  ADD_MANAGE_CAPTAIN_SUCCESS: 'ADD_MANAGE_CAPTAIN_SUCCESS',
  EDIT_MANAGE_CAPTAIN_SUCCESS: 'EDIT_MANAGE_CAPTAIN_SUCCESS',
  UPDATE_MANAGE_DATA_PAYROLL_SUCCESS: 'UPDATE_MANAGE_DATA_PAYROLL_SUCCESS',
  ACTIVATE_DEACTIVATE_PICKUP_LOCATION_SUCCESS:
    'ACTIVATE_DEACTIVATE_PICKUP_LOCATION_SUCCESS',
  ACTIVATE_DEACTIVATE_PICKUP_LOCATION_DETAIL_SUCCESS:
    'ACTIVATE_DEACTIVATE_PICKUP_LOCATION_DETAIL_SUCCESS',
  EDIT_SCHEDULE_ORDER_SUCCESS: 'EDIT_SCHEDULE_ORDER_SUCCESS',
  DELETE_MANAGE_COURIER_DEDICATED_SUCCESS:
    'DELETE_MANAGE_COURIER_DEDICATED_SUCCESS',
  ADD_MANAGE_COURIER_DEDICATED_SUCCESS: 'ADD_MANAGE_COURIER_DEDICATED_SUCCESS',
  EDIT_MANAGE_COURIER_DEDICATED_SUCCESS:
    'EDIT_MANAGE_COURIER_DEDICATED_SUCCESS',
  CREATE_LOYALTY_PROGRAM_MEMBERSHIP: 'CREATE_LOYALTY_PROGRAM_MEMBERSHIP',
  ADJUSTMENT_PARCEL_POIN_REQUEST_SUCCESS:
    'ADJUSTMENT_PARCEL_POIN_REQUEST_SUCCESS',
  ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_SUCCESS:
    'ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_SUCCESS',
  ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_FAILED:
    'ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_FAILED',
  ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_SUCCESS:
    'ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_SUCCESS',
  ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_FAILED:
    'ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_FAILED',
  UPDATE_GET_POINT_RULES: 'UPDATE_GET_POINT_RULES',
  CREATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA:
    'CREATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA',
  UPDATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA:
    'UPDATE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA',
  DELETE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA:
    'DELETE_LOYALTY_PROGRAM_MEMBERSHIP_FORMULA',
  REJECTED_APPROVAL_ADJUST_SALDO: 'REJECTED_APPROVAL_ADJUST_SALDO',
  APPROVE_APPROVAL_ADJUST_SALDO: 'APPROVE_APPROVAL_ADJUST_SALDO',
  APPROVE_SALDO_BULKY_SUCCESS: 'APPROVE_SALDO_BULKY_SUCCESS',
  APPROVE_SALDO_BULKY_FAILED: 'APPROVE_SALDO_BULKY_FAILED',
  REJECT_SALDO_BULKY_SUCCESS: 'REJECT_SALDO_BULKY_SUCCESS',
  REJECT_SALDO_BULKY_FAILED: 'REJECT_SALDO_BULKY_FAILED',
  PROCESS_UPLOAD_SALDO_BULKY_SUCCESS: 'PROCESS_UPLOAD_SALDO_BULKY_SUCCESS',
  PROCESS_UPLOAD_SALDO_BULKY_RETRY: 'PROCESS_UPLOAD_SALDO_BULKY_RETRY',
  PROCESS_UPLOAD_SALDO_BULKY_NOT_RETRY: 'PROCESS_UPLOAD_SALDO_BULKY_NOT_RETRY',
  PROCESS_UPLOAD_SALDO_BULKY_FAILED: 'PROCESS_UPLOAD_SALDO_BULKY_FAILED',
  UPLOAD_BULKY_MANAGE_COURIER_LEAVE: 'UPLOAD_BULKY_MANAGE_COURIER_LEAVE',
  SUBMIT_BULKY_MANAGE_COURIER_LEAVE: 'SUBMIT_BULKY_MANAGE_COURIER_LEAVE',
  APPROVE_BULKY_PARCEL_POIN_SUCCESS: 'APPROVE_BULKY_PARCEL_POIN_SUCCESS',
  APPROVE_BULKY_PARCEL_POIN_FAILED: 'APPROVE_BULKY_PARCEL_POIN_FAILED',
  REJECT_BULKY_PARCEL_POIN_SUCCESS: 'REJECT_BULKY_PARCEL_POIN_SUCCESS',
  REJECT_BULKY_PARCEL_POIN_FAILED: 'REJECT_BULKY_PARCEL_POIN_FAILED',
  CREATE_EXTRA_MULTIPLIER_SUCCESS: 'CREATE_EXTRA_MULTIPLIER_SUCCESS',
  DELETE_EXTRA_MULTIPLIER_SUCCESS: 'DELETE_EXTRA_MULTIPLIER_SUCCESS',
  UPDATE_EXTRA_MULTIPLIER_SUCCESS: 'UPDATE_EXTRA_MULTIPLIER_SUCCESS',
}
