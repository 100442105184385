import { Pagination } from "./Pagination"

export class BonusPayrolls {
  public pagination?: Pagination
  public data?: BonusPayroll[]

  constructor(
    pagination?: Pagination,
    data?: BonusPayroll[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class BonusPayroll {
  public courier?: Courier
  public id?: number
  public totalAmount?: number
  public status?: string
  public month?: number
  public year?: number

  constructor(
    courier?: Courier,
    id?: number,
    total_amount?: number,
    status?: string,
    month?: number,
    year?: number
  ) {
    this.courier = courier
    this.id = id
    this.totalAmount = total_amount
    this.status = status
    this.month = month
    this.year = year
  }
}

export class Courier {
  public id?: number
  public type?: string
  public status?: string
  public fullname?: string
  public phoneNumber?: string

  constructor(
    id?: number,
    type?: string,
    status?: string,
    fullname?: string,
    phone_number?: string
  ) {
    this.id = id
    this.type = type
    this.status = status
    this.fullname = fullname
    this.phoneNumber = phone_number
  }
}

export class Bonus {
  public id?: number
  public courierId?: number
  public courierName?: string
  public phoneNumber?: string
  public totalDelivery?: number
  public totalPickup?: number
  public status?: string
  public totalBonusPersentage?: string
  public totalBonusCaptain?: string
  public totalBonus?: string
  public componentPayableId?: number
  public manageDayId?: number
  public month?: number
  public year?: number
  public createdAt?: string
  public createdBy?: string
  public updatedAt?: string
  public updatedBy?: string

  constructor(
    id?: number,
    courier_id?: number,
    courier_name?: string,
    phone_number?: string,
    total_delivery?: number,
    total_pickup?: number,
    status?: string,
    total_bonus_persentage?: string,
    total_bonus_captain?: string,
    total_bonus?: string,
    component_payable_id?: number,
    manage_day_id?: number,
    month?: number,
    year?: number,
    created_at?: string,
    created_by?: string,
    updated_at?: string,
    updated_by?: string
  ) {
    this.id = id,
    this.courierId = courier_id,
    this.courierName = courier_name,
    this.phoneNumber = phone_number,
    this.totalDelivery = total_delivery,
    this.totalPickup = total_pickup,
    this.status = status,
    this.totalBonusPersentage = total_bonus_persentage,
    this.totalBonusCaptain = total_bonus_captain,
    this.totalBonus = total_bonus,
    this.componentPayableId = component_payable_id,
    this.manageDayId = manage_day_id,
    this.month = month
    this.year = year
    this.createdAt = created_at,
    this.createdBy = created_by,
    this.updatedAt = updated_at,
    this.updatedBy = updated_by
  }
}
