import {
  CreateBannerRequestInterface,
  CreatePopUpBannerRequestInterface,
  UpdateBannerImageRequestInterface,
  UpdateBannerRequestInterface,
  UpdatePopUpBannerRequestInterface,
} from '@/data/payload/contracts/BannerRequest'
import { injectable } from 'tsyringe'
import {
  Banners,
  BannerType,
  Banner,
  OrderBanner,
  PopUpBanners,
  PopUpBanner,
} from '@/domain/entities/Banner'

import { BannerRepositoryInterface } from '@/data/persistences/repositories/contracts/BannerRepositoryInterface'

@injectable()
export class BannerPresenter {
  private repository: BannerRepositoryInterface

  constructor(repository: BannerRepositoryInterface) {
    this.repository = repository
  }

  public get(id: string): Promise<Banner> {
    return this.repository.get(id)
  }

  public getAll(params: Map<string, never | unknown>): Promise<Banners> {
    return this.repository.getAll(params)
  }

  public getTypes(): Promise<BannerType[]> {
    return this.repository.getTypes()
  }

  public create(payload: CreateBannerRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    id: string,
    payload: UpdateBannerRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }

  public updateImage(
    id: string,
    payload: UpdateBannerImageRequestInterface
  ): Promise<boolean> {
    return this.repository.updateImage(id, payload)
  }

  public getOrderBanner(): Promise<OrderBanner> {
    return this.repository.getOrderBanner()
  }

  public delete(id: string): Promise<boolean> {
    return this.repository.delete(id)
  }

  public getPopUpBanners(params: Map<string, never | unknown>): Promise<PopUpBanners> {
    return this.repository.getPopUpBanners(params)
  }

  public getPopUpBanner(id: string): Promise<PopUpBanner> {
    return this.repository.getPopUpBanner(id)
  }

  public createPopUpBanner(
    payload: CreatePopUpBannerRequestInterface
  ): Promise<boolean> {
    return this.repository.createPopUpBanner(payload)
  }

  public updatePopUpBanner(
    id: string | number | undefined,
    payload: UpdatePopUpBannerRequestInterface
  ): Promise<boolean> {
    return this.repository.updatePopUpBanner(id, payload)
  }

  public inactivePopUpBanner(id: string): Promise<boolean> {
    return this.repository.inactivePopUpBanner(id)
  }
}
