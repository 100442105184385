import { Pagination } from "./Pagination"

export class ManageDays {
  public data?: ManageDay[]

  constructor(
    data?: ManageDay[]
  ) {
    this.data = data
  }
}

export class ManageDay {
  public id?: number
  public month?: string
  public year?: number
  public totalMinimalDay?: number
  public status?: boolean
  public createdAt?: string
  public updatedAt?: string
  public createdBy?: string
  public updatedBy?: string

  constructor(
    id?: number,
    month?: string,
    year?: number,
    total_minimalDay?: number,
    status?: boolean,
    created_at?: string,
    updated_at?: string,
    created_by?: string,
    updated_by?: string,
  ) {
    this.id = id,
    this.month = month,
    this.year = year,
    this.totalMinimalDay = total_minimalDay,
    this.status = status,
    this.createdAt = created_at,
    this.updatedAt = updated_at,
    this.createdBy = created_by,
    this.updatedBy = updated_by
  }
}

export class ManageDayYearList {
  public success?: boolean
  public data?: ManageDayYear[]

  constructor(
    success?: boolean,
    data?: ManageDayYear[]
  ) {
    this.success = success
    this.data = data
  }
}

export class ManageDayYear {
  public year?: number

  constructor(
    year?: number
  ) {
    this.year = year
  }
}
