import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import {
  ApprovalAdjustSaldoList,
  ApprovalAdjustSaldo,
  ApprovalAdjustSaldoDetail,
  DataUploadApproval,
  SummaryDataUpload,
  AmountApproval,
  RowsApproval
} from '@/domain/entities/ApprovalAdjustSaldo'
import {
  ApprovalAdjustSaldoResponse,
  RowsApprovalResponse
} from '@/data/responses/contracts/ApprovalAdjustSaldoResponseInterface'

export class ApprovalAdjustSaldoMapper extends BaseResponseMapper {
  public convertApprovalAdjustSaldoListFromApi(result: AxiosResponse): ApprovalAdjustSaldoList {
    const {
      data: { data, pagination }
    } = result

    return new ApprovalAdjustSaldoList(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (item: ApprovalAdjustSaldoResponse) => {
          return new ApprovalAdjustSaldo(
            item.id,
            item.filename,
            item.created_by,
            item.created_at,
            item.updated_by,
            item.updated_at,
            item.status_approval,
            item.reason
          )
        }
      )
    )
  }

  public convertApprovalAdjustSaldoDetailFromAPi(result: AxiosResponse): ApprovalAdjustSaldoDetail {
    const { data } = result
    return new ApprovalAdjustSaldoDetail(
      data.created_by,
      data.created_at,
      data.updated_by,
      data.updated_at,
      data.status_approval,
      data.reason,
      new DataUploadApproval(
        data.data_upload.filename,
        data.data_upload.filepath,
        new SummaryDataUpload(
          data.data_upload.summary.row_valid,
          data.data_upload.summary.row_invalid,
          data.data_upload.summary.courier_valid,
          data.data_upload.summary.courier_invalid,
          new AmountApproval(
            data.data_upload.summary.amount_valid.addition,
            data.data_upload.summary.amount_valid.reduction
          ),
          new AmountApproval(
            data.data_upload.summary.amount_invalid.addition,
            data.data_upload.summary.amount_invalid.reduction
          )
        ),
        data.data_upload.rows.map((item: RowsApprovalResponse) => {
          return new RowsApproval(
            item.no,
            item.courier_id,
            item.amount,
            item.note,
            item.status,
            item.reason
          )
        })
      ),
      Utils.toInstance(new Pagination(), data.pagination)
    )
  }
}
