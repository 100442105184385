import { Pagination } from './Pagination'

export class HomepageBanner {
  public id?: number
  public title?: string
  public imageUrl?: string
  public urlLink?: string
  public startTime?: string
  public endTime?: string
  public status?: string
  public slideText?: string

  constructor(
    id?: number,
    title?: string,
    imageUrl?: string,
    urlLink?: string,
    startTime?: string,
    endTime?: string,
    status?: string,
    slideText?: string
  ) {
    this.id = id
    this.title = title
    this.imageUrl = imageUrl
    this.urlLink = urlLink
    this.startTime = startTime
    this.endTime = endTime
    this.status = status
    this.slideText = slideText
  }
}

export class HomepageBanners {
  public pagination?: Pagination
  public data?: HomepageBanner[]

  constructor(pagination?: Pagination, data?: HomepageBanner[]) {
    this.pagination = pagination
    this.data = data
  }
}
