import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'

export const search: RouteConfig = {
  path: '/search',
  component: MainLayout,
  redirect: '/search',
  meta: {
    title: 'Cari yang Anda butuhkan',
  },
  children: [
    {
      path: '',
      name: 'SearchPage',
      component: () =>
        import(
          /* webpackChunkName: "SearchPage" */ '@/app/ui/views/Search/index.vue'
        ),
      meta: {
        title: 'Cari yang Anda butuhkan',
        slugFromBackend: BACKEND_SLUG.SEARCH,
        accessType: 'write',
        allUserCanAccess: true,
      },
    },
    {
      path: 'shipment/:shipmentId',
      name: 'SearchShipmentDetailPage',
      component: () =>
        import(
          /* webpackChunkName: "SearchShipmentDetailPage" */ '@/app/ui/views/Pickup/Detail/index.vue'
        ),
      meta: {
        title: 'Shipment Tracking',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.SEARCH,
        accessType: 'read',
        allUserCanAccess: true,
      },
    },
    {
      path: 'package/:packageId',
      name: 'SearchPackageDetailPage',
      component: () =>
        import(
          /* webpackChunkName: "SearchPackageDetailPage" */ '@/app/ui/views/Package/Detail/index.vue'
        ),
      meta: {
        title: 'Package Tracking',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.SEARCH,
        accessType: 'read',
        allUserCanAccess: true,
      },
    }
  ],
}
