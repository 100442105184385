import { Pagination } from '@/domain/entities/Pagination'

export class BannerType {
  public id?: string
  public name?: string
  public description?: string

  constructor(id?: string, name?: string, description?: string) {
    this.id = id
    this.name = name
    this.description = description
  }
}

export class Banner {
  public id?: number
  public imageUrl?: string
  public title?: string
  public description?: string
  public status?: string
  public startDate?: string
  public endDate?: string
  public type?: string
  public category?: string
  public url?: string
  public order?: number

  constructor(
    id?: number,
    imageUrl?: string,
    title?: string,
    description?: string,
    status?: string,
    startDate?: string,
    endDate?: string,
    type?: string,
    category?: string,
    url?: string,
    order?: number
  ) {
    this.id = id
    this.imageUrl = imageUrl
    this.title = title
    this.description = description
    this.status = status
    this.startDate = startDate
    this.endDate = endDate
    this.type = type
    this.category = category
    this.url = url
    this.order = order
  }
}

export class Banners {
  public pagination?: Pagination
  public data?: Banner[]

  constructor(pagination?: Pagination, data?: Banner[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class OrderBanner {
  public value?: number

  constructor(value?: number) {
    this.value = value
  }
}


export class PopUpBanner {
  public id?: number
  public type?: string
  public title?: string
  public location?: string
  public image?: string
  public url?: string
  public timer?: number
  public showTime?: string
  public startDate?: string
  public endDate?: string
  public status?: string
  public createdAt?: string

  constructor(
    id?: number,
    type?: string,
    title?: string,
    location?: string,
    image?: string,
    url?: string,
    timer?: number,
    showTime?: string,
    startDate?: string,
    endDate?: string,
    status?: string,
    createdAt?: string
  ) {
    this.id = id
    this.type = type
    this.title = title
    this.location = location
    this.image = image
    this.url = url
    this.timer = timer
    this.showTime = showTime
    this.startDate = startDate
    this.endDate = endDate
    this.status = status
    this.createdAt = createdAt
  }
}

export class PopUpBanners {
  public pagination?: Pagination
  public data?: Array<PopUpBanner>

  constructor(pagination?: Pagination, data?: Array<PopUpBanner>) {
    this.pagination = pagination
    this.data = data
  }
}
