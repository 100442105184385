import { injectable } from 'tsyringe'
import { UpdateAppVersionRepositoryInterface } from '@/data/persistences/repositories/contracts/UpdateAppVersionRepositoryInterface'
import { UpdateAppVersionRequestInterface } from '@/data/payload/contracts/UpdateAppVersionRequest'
import {
  UpdateAppVersions,
  UpdateAppVersion,
} from '@/domain/entities/UpdateAppVersion'

@injectable()
export class UpdateAppVersionPresenter {
  private readonly repository: UpdateAppVersionRepositoryInterface

  constructor(authRepository: UpdateAppVersionRepositoryInterface) {
    this.repository = authRepository
  }

  public getAll(params: Map<string, unknown>): Promise<UpdateAppVersions> {
    return this.repository.getAll(params)
  }

  public getOne(id: number): Promise<UpdateAppVersion> {
    return this.repository.getOne(id)
  }

  public create(payload: UpdateAppVersionRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    id: number,
    payload: UpdateAppVersionRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }

  public cancel(id: number): Promise<boolean> {
    return this.repository.cancel(id)
  }
}
