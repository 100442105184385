import { injectable } from 'tsyringe'
import {
  DetailAdjustmentParcelPoin,
  HistoryUploadBulkyParcelPoin,
  ListAdjustmentParcelPoin,
  ListHistoryLogPoinUser,
  UploadBulkyParcelPoin,
} from '@/domain/entities/AdjustmentParcelPoin'
import { AdjustmentParcelPoinRepository } from '@/app/infrastructures/repositories/api/AdjustmentParcelPoinRepository'
import {
  AdjustmentParcelPoinApprovingPoinRequestInterface,
  AdjustmentParcelPoinRequestPoinRequestInterface,
} from '@/data/payload/contracts/AdjustmentParcelPoinRequest'
import { UploadRequest } from '@/data/payload/api/UploadRequest'
import { CancelTokenSource } from 'axios'
import { ApproveRejectBulkyParcelPoinRequest } from '@/data/payload/api/AdjustmentParcelPoinRequest'

@injectable()
export class AdjustmentParcelPoinPresenter {
  private repository: AdjustmentParcelPoinRepository

  constructor(repository: AdjustmentParcelPoinRepository) {
    this.repository = repository
  }

  public getAll(
    params: Map<string, string | number>
  ): Promise<ListAdjustmentParcelPoin> {
    return this.repository.getAll(params)
  }

  public postRequestPoin(
    payload: AdjustmentParcelPoinRequestPoinRequestInterface
  ): Promise<boolean> {
    return this.repository.postRequestPoin(payload)
  }

  public getHistoryLogPoinUser(
    params: Map<string, number>
  ): Promise<ListHistoryLogPoinUser> {
    return this.repository.getHistoryLogPoinUser(params)
  }

  public getDetail(id: number): Promise<DetailAdjustmentParcelPoin> {
    return this.repository.getDetail(id)
  }

  public postApproving(
    payload: AdjustmentParcelPoinApprovingPoinRequestInterface
  ): Promise<boolean> {
    return this.repository.postApprovingPoin(payload)
  }

  public uploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    return this.repository.uploadBulky(file, cancelToken)
  }

  public getHistoryUploadBulky(
    params: Map<string, string>
  ): Promise<HistoryUploadBulkyParcelPoin> {
    return this.repository.getHistoryUploadBulky(params)
  }

  public previewUploadBulky(params: Map<string, string>): Promise<UploadBulkyParcelPoin> {
    return this.repository.previewUploadBulky(params)
  }

  public processBulkyRequestParcelPoin(): Promise<boolean> {
    return this.repository.processBulkyRequestParcelPoin()
  }

  public postApproveRejectBulkyParcelPoin(
    payload: ApproveRejectBulkyParcelPoinRequest
  ): Promise<boolean> {
    return this.repository.postApproveRejectBulkyParcelPoin(payload)
  }
}
