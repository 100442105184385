import { BaseResponseMapper } from "./BaseMapper";
import { AxiosResponse } from "axios";
import { ManageDay, ManageDays, ManageDayYear, ManageDayYearList } from "@/domain/entities/ManageDay";

export class ManageDayMapper extends BaseResponseMapper {
  public convertManageDayFromApi(result: AxiosResponse): ManageDays {
    const {
      data: { data },
    } = result

    return new ManageDays(
      data.map(
        (item: Record<string, number | string | boolean | Date>) => {
          return new ManageDay(
            <number>item.id,
            <string>item.month,
            <number>item.year,
            <number>item.total_minimal_day,
            <boolean>item.status,
            <string>item.created_at,
            <string>item.updated_at,
            <string>item.created_by,
            <string>item.updated_by,
          )
        }
      )
    )
  }

  public convertManageDayYearListFromApi(result: AxiosResponse): ManageDayYearList {
    const {
      data: { success, data },
    } = result

    return new ManageDayYearList(
      success,
      data.map(
        (item: Record<string, number>) => {
          return new ManageDayYear(
            item.year
          )
        }
      )
    )
  }
}
