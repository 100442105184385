import { Pagination } from "./Pagination"

export class ManualAdjustmentBalances {
  public pagination?: Pagination
  public data?: ManualAdjustmentBalance[]

  constructor(
    pagination?: Pagination,
    data?: ManualAdjustmentBalance[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManualAdjustmentBalance {
  public id?: number
  public notes?: string
  public insertType?: string
  public meta?: string[]
  public amount?: number
  public status?: string
  public activity?: string
  public type?: string
  public createdBy?: string
  public createdAt?: string
  public approvedBy?: string
  public approvedAt?: string
  public filename?: string
  public courier?: ManualAdjustmentBalanceCourier

  constructor(
    id?: number,
    notes?: string,
    insertType?: string,
    meta?: string[],
    amount?: number,
    status?: string,
    activity?: string,
    type?: string,
    created_by?: string,
    created_at?: string,
    approvedBy?: string,
    approvedAt?: string,
    filename?: string,
    courier?: ManualAdjustmentBalanceCourier,
  ) {
    this.id = id
    this.notes = notes
    this.insertType = insertType
    this.meta = meta
    this.amount = amount
    this.status = status
    this.activity = activity
    this.type = type
    this.createdBy = created_by
    this.createdAt = created_at
    this.approvedBy = approvedBy
    this.approvedAt = approvedAt
    this.filename = filename
    this.courier = courier
  }
}

export class ManualAdjustmentBalanceCourier {
  public id?: number
  public type?: string
  public status?: string
  public fullname?: string
  public phoneNumber?: string

  constructor(
    id?: number,
    type?: string,
    status?: string,
    fullname?: string,
    phone_number?: string
  ) {
    this.id = id
    this.type = type
    this.status = status
    this.fullname = fullname
    this.phoneNumber = phone_number
  }
}

export class ManualAdjustmentUploadHistories {
  public pagination?: Pagination
  public data?: ManualAdjustmentUploadHistory[]

  constructor(
    pagination?: Pagination,
    data?: ManualAdjustmentUploadHistory[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManualAdjustmentUploadHistory {
  public id?: number
  public filename?: string
  public filepath?: string
  public statusId?: string
  public createdBy?: string
  public createdAt?: string
  public deletedBy?: string
  public deletedAt?: string
  public updatedBy?: string
  public updatedAt?: string
  public reason?: string
  public status?: string

  constructor(
    id?: number,
    filename?: string,
    filepath?: string,
    statusId?: string,
    createdBy?: string,
    createdAt?: string,
    deletedBy?: string,
    deletedAt?: string,
    updatedBy?: string,
    updatedAt?: string,
    reason?: string,
    statusApproval?: string
  ) {
    this.id = id
    this.filename = filename
    this.filepath = filepath
    this.statusId = statusId
    this.createdBy = createdBy
    this.createdAt = createdAt
    this.deletedBy = deletedBy
    this.deletedAt = deletedAt
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt
    this.reason = reason
    this.status = statusApproval
  }
}

export class UploadBulkData {
  public createdBy?: string;
  public createdAt?: Date;
  public dataUpload?: UploadBulkDataUpload;

  constructor(
    createdBy?: string,
    createdAt?: Date,
    dataUpload?: UploadBulkDataUpload,
  ) {
    this.createdBy = createdBy
    this.createdAt = createdAt
    this.dataUpload = dataUpload
  }
}

export class UploadBulkDataUpload {
  public filename?: string;
  public filepath?: string
  public summary?: UploadBulkSummary;
  public rows?: UploadBulkRow[];
  public pagination?: Pagination;

  constructor(
    filename?: string,
    filepath?: string,
    summary?: UploadBulkSummary,
    rows?: UploadBulkRow[],
    pagination?: Pagination,
  ) {
    this.filename = filename
    this.filepath = filepath
    this.summary = summary
    this.rows = rows
    this.pagination = pagination
  }
}

export class UploadBulkRow {
  public no?: number;
  public courierId?: number;
  public amount?: number;
  public note?: string;
  public status?: string;
  public reason?: string;

  constructor(
    no?: number,
    courierId?: number,
    amount?: number,
    note?: string,
    status?: string,
    reason?: string,
  ) {
    this.no = no
    this.courierId = courierId
    this.amount = amount
    this.note = note
    this.status = status
    this.reason = reason
  }
}

export class UploadBulkSummary {
  public rowValid?: number;
  public rowInvalid?: number;
  public courierValid?: number;
  public courierInvalid?: number;
  public amountValid?: UploadBulkBalanceAmount;
  public amountInvalid?: UploadBulkBalanceAmount;

  constructor(
    rowValid?: number,
    rowInvalid?: number,
    courierValid?: number,
    courierInvalid?: number,
    amountValid?: UploadBulkBalanceAmount,
    amountInvalid?: UploadBulkBalanceAmount,
  ) {
    this.rowValid = rowValid
    this.rowInvalid = rowInvalid
    this.courierValid = courierValid
    this.courierInvalid = courierInvalid
    this.amountValid = amountValid
    this.amountInvalid = amountInvalid
  }
}

export class UploadBulkBalanceAmount {
  public addition?: number;
  public reduction?: number;

  constructor(
    addition?: number,
    reduction?: number,
  ) {
    this.addition = addition
    this.reduction = reduction
  }
}

