import { Pagination } from './Pagination'

export class Token {
  public accessToken?: string
  public expiredAt?: number

  constructor(accessToken?: string, expiredAt?: number) {
    this.accessToken = accessToken
    this.expiredAt = expiredAt
  }
}

export class Account {
  public id?: number
  public email?: string
  public role?: string
  public accessMenu?: string
  public accessMenus?: AccessMenu[]

  constructor(
    id?: number,
    email?: string,
    role?: string,
    accessMenu?: string,
    accessMenus?: AccessMenu[]
  ) {
    this.id = id
    this.email = email
    this.role = role
    this.accessMenu = accessMenu
    this.accessMenus = accessMenus
  }
}

export class Role {
  public role?: string

  constructor(role?: string) {
    this.role = role
  }
}

export class AccessMenu {
  public id?: number
  public slug?: string
  public name?: string
  public group?: string
  public isGranted?: boolean

  constructor(
    id?: number,
    slug?: string,
    name?: string,
    group?: string,
    isGranted?: boolean
  ) {
    this.id = id
    this.slug = slug
    this.name = name
    this.group = group
    this.isGranted = isGranted
  }
}

export class Accounts {
  public pagination?: Pagination
  public data?: Account[]

  constructor(pagination?: Pagination, data?: Account[]) {
    this.pagination = pagination
    this.data = data
  }
}
