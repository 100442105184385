import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import { SaldoPayment, SaldoPayments } from '@/domain/entities/SaldoPayment'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/data/infrastructures/misc/Utils'

export class SaldoPaymentMapper extends BaseResponseMapper {
  public convertSaldoPaymentsFromApi(
    result: AxiosResponse<any>
  ): SaldoPayments {
    const {
      data: { pagination, data },
    } = result
    return new SaldoPayments(
      Utils.toInstance(new Pagination(), pagination),
      data.map((payments: Record<string, any>) => {
        return new SaldoPayment(
          payments.invoice_id,
          payments.customer_id,
          payments.amount,
          payments.status_id,
          payments.expired_at,
          payments.initated_at,
          payments.finished_at,
          payments.reminder,
          payments.reminder_at,
          payments.payment_method,
          payments.package_price,
          payments.total_amount_invoice,
          payments.saldo_amount_used,
          payments.package_id,
          payments.package_created_at,
          payments.transaction_status,
          payments.bank,
          payments.payment_type,
          payments.VA_number,
          payments.created_at
        )
      })
    )
  }
}
