import ApiService from '@/app/infrastructures/services/ApiService'
import {
  Banners,
  BannerType,
  Banner,
  OrderBanner,
  PopUpBanner,
  PopUpBanners,
} from '@/domain/entities/Banner'
import { BannerRepositoryInterface } from '@/data/persistences/repositories/contracts/BannerRepositoryInterface'
import { BannerMapper } from '@/data/persistences/mappers/BannerMapper'
import { Endpoints } from '@/app/infrastructures/misc'
import {
  CreateBannerRequestInterface,
  CreatePopUpBannerRequestInterface,
  UpdateBannerImageRequestInterface,
  UpdateBannerRequestInterface,
  UpdatePopUpBannerRequestInterface,
} from '@/data/payload/contracts/BannerRequest'

export class BannerApiRepository implements BannerRepositoryInterface {
  private service: ApiService
  private mapper: BannerMapper

  constructor(service: ApiService, mapper: BannerMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async create(payload: CreateBannerRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.BANNER,
      undefined,
      payload as CreateBannerRequestInterface,
      new Map([
        [
          'Content-Type',
          'multipart/form-data; ------WebKitFormBoundary7MA4YWxkTrZu0gW',
        ],
      ])
    )

    return true
  }

  public async update(
    id: string,
    payload: UpdateBannerRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.BANNER}/${id}`,
      undefined,
      payload as UpdateBannerRequestInterface
    )

    return true
  }

  public async updateImage(
    id: string,
    payload: UpdateBannerImageRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.BANNER}/${id}/image`,
      undefined,
      payload as UpdateBannerImageRequestInterface,
      new Map([
        [
          'Content-Type',
          'multipart/form-data; ------WebKitFormBoundary7MA4YWxkTrZu0gW',
        ],
      ])
    )

    return true
  }

  public async get(id: string): Promise<Banner> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.BANNER}/${id}`
    )

    return this.mapper.convertBannerFromApi(resp)
  }

  public async getOrderBanner(): Promise<OrderBanner> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.BANNER}/max-count`
    )
    return this.mapper.convertOrderBannerFromApi(resp)
  }

  public async getAll(params: Map<string, any>): Promise<Banners> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.BANNER,
      params
    )

    return this.mapper.convertBannersFromApi(resp)
  }

  public async getTypes(): Promise<BannerType[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.BANNER}/type`
    )

    return this.mapper.convertBannerTypesFromApi(resp)
  }

  public async delete(id: string): Promise<boolean> {
    await this.service.invoke('delete', `${Endpoints.Route.BANNER}/${id}`)

    return true
  }

  public async getPopUpBanners(
    params: Map<string, unknown | never>
  ): Promise<PopUpBanners> {
    const resp = await this.service.invoke(
      `get`,
      `${Endpoints.Route.POPUP_BANNER}`,
      params
    )

    return this.mapper.convertPopUpBannersFromApi(resp)
  }

  public async getPopUpBanner(id: string): Promise<PopUpBanner> {
    const resp =
      await this.service.invoke(`get`, `${Endpoints.Route.POPUP_BANNER}/${id}`)
    return this.mapper.convertPopUpBannerFromApi(resp)
  }

  public async createPopUpBanner(
    payload: CreatePopUpBannerRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      `post`,
      `${Endpoints.Route.POPUP_BANNER}`,
      undefined,
      <CreatePopUpBannerRequestInterface>payload,
    )

    return true
  }

  public async updatePopUpBanner(
    id: string,
    payload: UpdatePopUpBannerRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      `post`,
      `${Endpoints.Route.POPUP_BANNER}/${id}`,
      undefined,
      <UpdatePopUpBannerRequestInterface>payload,
    )

    return true
  }

  public async inactivePopUpBanner(id: string): Promise<boolean> {
    await this.service.invoke('delete', `${Endpoints.Route.POPUP_BANNER}/${id}`)
    return true
  }
}
