import { AxiosResponse } from 'axios'
import {
  CategorySection,
  CategorySectionProduct,
} from '@/domain/entities/CategorySection'
import { BaseResponseMapper } from './BaseMapper'
import { ProductDropdown } from '@/domain/entities/Product'

export class CategorySectionMapper extends BaseResponseMapper {
  public convertCategorySectionFromApi(
    result: AxiosResponse<any>
  ): CategorySection {
    const {
      data: { data },
    } = result
    return new CategorySection(
      data.section_id,
      data.category_name,
      data.banner_homepage_image,
      data.banner_detail_image,
      data.list_products.map(
        (product: {
          product_id: number | undefined
          product_name: string | undefined
          merchant_id: number | undefined
          merchant_name: string | undefined
        }) =>
          new CategorySectionProduct(
            product.product_id,
            product.product_name,
            product.merchant_id,
            product.merchant_name
          )
      )
    )
  }

  public convertCategorySectionsFromApi(
    result: AxiosResponse<any>
  ): CategorySection[] {
    const {
      data: { data },
    } = result
    if (!data || data.length === 0) {
      return []
    }
    return data.map(
      (category: Record<string, any>) =>
        new CategorySection(
          category.section_id,
          category.category_name,
          category.banner_homepage_image,
          category.banner_detail_image,
          category.list_products.map(
            (product: {
              product_id: number | undefined
              product_name: string | undefined
              merchant_id: number | undefined
              merchant_name: string | undefined
            }) =>
              new CategorySectionProduct(
                product.product_id,
                product.product_name,
                product.merchant_id,
                product.merchant_name
              )
          )
        )
    )
  }

  public convertProductDropdownsFromApi(
    result: AxiosResponse<any>
  ): ProductDropdown[] {
    const {
      data: { data },
    } = result
    if (!data || data.length === 0) {
      return []
    }
    return data.map(
      (data: Record<string, string | number>) =>
        new ProductDropdown(
          data.product_id as number,
          data.product_name as string,
          data.merchant_id as number,
          data.merchant_name as string
        )
    )
  }
}
