import 'reflect-metadata'
import Vue from 'vue'
import Component from 'vue-class-component'
import Vuelidate from 'vuelidate'
import Notifications from 'vue-notification'
import App from './app/ui/App.vue'
import router from './app/ui/router'
import store from './app/ui/store'
import { AppComponent } from './app/infrastructures/di/AppComponents'
import * as Sentry from "@sentry/vue";

import './main.scss'
import './app/ui/router/precondition'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
])

Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(Notifications)
AppComponent.init()

Sentry.init({
  Vue,
  dsn: 'https://ae1dc6472652440ab6dab0cc6f70a7e3@sentry.thelionparcel.com/6',
  integrations: [
    Sentry.browserTracingIntegration({router}),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', process.env.VUE_APP_BASE_URL],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
