import { injectable } from 'tsyringe'
import { ChangePasswordRequestInterface } from '@/data/payload/contracts/AuthRequest'
import { AuthRepositoryInterface } from '@/data/persistences/repositories/contracts/AuthRepositoryInterface'

@injectable()
export class SettingPresenter {
  private authRepository: AuthRepositoryInterface

  constructor(authRepository: AuthRepositoryInterface) {
    this.authRepository = authRepository
  }

  public changePassword(
    payload: ChangePasswordRequestInterface
  ): Promise<boolean> {
    return this.authRepository.changePassword(payload)
  }
}
