import ApiService from '@/app/infrastructures/services/ApiService'
import { DriverScoreboardMapper } from '@/data/persistences/mappers/DriverScoreboardMapper'
import { DriverScoreboardRepositoryInterface } from '@/data/persistences/repositories/contracts/DriverScoreboardRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import {
  Drivers,
  DriverScoreboardConfigurations,
  DriverScoreReport,
  DriverScoreReportCalculate,
} from '@/domain/entities/DriverScoreboard'
import { UpdateDriverScoreRequestInterface } from '@/data/payload/contracts/DriverScoreboardRequest'

export class DriverScoreboardApiRepository
  implements DriverScoreboardRepositoryInterface {
  private service: ApiService
  private mapper: DriverScoreboardMapper

  constructor(service: ApiService, mapper: DriverScoreboardMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(): Promise<DriverScoreboardConfigurations> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_SCORE}/configuration`
    )
    return this.mapper.convertDriverScoreboardConfigFromApi(resp)
  }

  public async updateConfiguration(
    payload: UpdateDriverScoreRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.DRIVER_SCORE}/configuration`,
      undefined,
      payload
    )

    return true
  }

  public async getDrivers(
    params: Map<string, string | number>
  ): Promise<Drivers> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_SCORE}/report`,
      params
    )

    return this.mapper.convertDriversFromApi(resp)
  }

  public async getDriverScoreReport(
    courierId: number,
    params: Map<string, string>
  ): Promise<DriverScoreReport> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_SCORE}/report/detail/${courierId}`,
      params
    )

    return this.mapper.convertDriverReportFromApi(resp)
  }

  public async getDriverScoreReportCalculate(
    courierId: number,
    dateFrom: string,
    dateTo: string
  ): Promise<DriverScoreReportCalculate> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_SCORE}/report/detail-calculate/${courierId}?date_from=${dateFrom}&date_to=${dateTo}`
    )

    return this.mapper.convertDriverReportCalculateFromApi(resp)
  }

  public async exportDriverScoreReport(
    params: Map<string, string>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_SCORE}/report/export`,
      params
    )

    return resp.data.url
  }
}
