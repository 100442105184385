import ApiService from '@/app/infrastructures/services/ApiService'
import { DriverAnnouncementMapper } from '@/data/persistences/mappers/DriverAnnouncementMapper'
import { DriverAnnouncementRepositoryInterface } from '@/data/persistences/repositories/contracts/DriverAnnouncementRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import {
  DriverAnnouncement,
  DriverAnnouncements,
} from '@/domain/entities/DriverAnnouncement'
import {
  CreateDriverAnnouncementRequestInterface,
  UpdateDriverAnnouncementRequestInterface,
  UploadDriverAnnouncementRequestInterface,
} from '@/data/payload/contracts/DriverAnnouncementRequest'

export class DriverAnnouncementApiRepository
  implements DriverAnnouncementRepositoryInterface {
  private service: ApiService
  private mapper: DriverAnnouncementMapper

  constructor(service: ApiService, mapper: DriverAnnouncementMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(announcementId: number): Promise<DriverAnnouncement> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_ANNOUNCEMENT}/${announcementId}`
    )
    return this.mapper.convertDriverAnnouncementFromApi(resp)
  }

  public async getAll(
    params: Map<string, string | number | undefined>
  ): Promise<DriverAnnouncements> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.DRIVER_ANNOUNCEMENT}`,
      params
    )
    return this.mapper.convertDriverAnnouncementsFromApi(resp)
  }

  public async create(
    payload: CreateDriverAnnouncementRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.DRIVER_ANNOUNCEMENTV2}/create`,
      undefined,
      payload as CreateDriverAnnouncementRequestInterface
    )
    return true
  }

  public async update(
    announcementId: number,
    payload: UpdateDriverAnnouncementRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.DRIVER_ANNOUNCEMENTV2}/${announcementId}`,
      undefined,
      payload as UpdateDriverAnnouncementRequestInterface
    )
    return true
  }

  public async delete(announcementId: number): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.DRIVER_ANNOUNCEMENT}/${announcementId}`
    )
    return true
  }

  public async uploadImage(
    payload: UploadDriverAnnouncementRequestInterface
  ): Promise<string> {
    const res = await this.service.invoke(
      'post',
      Endpoints.Route.UPLOAD_IMAGE,
      undefined,
      payload
    )

    return res.data.data.path_images
  }
}
