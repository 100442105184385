import { injectable } from 'tsyringe'

import {
  ProgrammaticVoucher,
  ProgrammaticVouchers,
  ProgrammaticVoucherHistories,
  VoucherDistributions,
} from '@/domain/entities/ProgrammaticVoucher'
import { ProgrammaticVoucherRepositoryInterface } from '@/data/persistences/repositories/contracts/ProgrammaticVoucherRepositoryInterface'
import {
  CreateProgrammaticVoucherRequestInterface,
  DeleteProgrammaticVoucherRequestInterface,
  UpdateProgrammaticVoucherRequestInterface,
  UploadProgrammaticVoucherRequestInterface,
} from '@/data/payload/contracts/ProgrammaticVoucherRequest'
import { ProgramaticPointRepositoryInterface } from '@/data/persistences/repositories/contracts/ProgramaticPointRepositoryInterface'
import { Trigger } from '@/domain/entities/Program'

@injectable()
export class ProgrammaticVoucherPresenter {
  private repository: ProgrammaticVoucherRepositoryInterface
  private programaticPointRepository: ProgramaticPointRepositoryInterface

  constructor(
    repository: ProgrammaticVoucherRepositoryInterface,
    programaticPointRepository: ProgramaticPointRepositoryInterface
  ) {
    this.repository = repository
    this.programaticPointRepository = programaticPointRepository
  }

  public getAll(params: Map<string, unknown>): Promise<ProgrammaticVouchers> {
    return this.repository.getAll(params)
  }

  public get(voucherId: string): Promise<ProgrammaticVoucher> {
    return this.repository.get(voucherId)
  }

  public getTriggers(): Promise<Trigger[]> {
    return this.repository.getTriggers()
  }

  public uploadFile(
    payload: UploadProgrammaticVoucherRequestInterface
  ): Promise<Record<string, unknown>> {
    return this.repository.uploadUserFile(payload)
  }

  public create(
    payload: CreateProgrammaticVoucherRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    voucherId: string,
    payload: UpdateProgrammaticVoucherRequestInterface
  ): Promise<boolean> {
    return this.repository.update(voucherId, payload)
  }

  public delete(
    voucherId: string,
    payload: DeleteProgrammaticVoucherRequestInterface
  ): Promise<boolean> {
    return this.repository.delete(voucherId, payload)
  }

  public getVoucherDistributions(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<VoucherDistributions> {
    return this.repository.getVoucherDistributions(voucherId, params)
  }

  public getHistories(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<ProgrammaticVoucherHistories> {
    return this.repository.getVoucherHistories(voucherId, params)
  }

  public getHistoriesV2(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<ProgrammaticVoucherHistories> {
    return this.repository.getVoucherHistoriesV2(voucherId, params)
  }

  public exportHistories(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    return this.repository.exportVoucherHistories(voucherId, params)
  }

  public exportHistoriesV2(
    voucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    return this.repository.exportVoucherHistoriesV2(voucherId, params)
  }
}
