import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'

export const brandListPage = 'BrandListPage'
export const createBrand = 'CreateBrand'
export const editBrand = 'EditBrand'
export const brandPage = 'BrandPage'
export const merchantListPage = 'MerchantListPage'
export const createMerchant = 'CreateMerchant'
export const merchantDetailProfilePage = 'MerchantDetailProfilePage'
export const merchantDetailAccountPage = 'MerchantDetailAccountPage'
export const merchantProductListPage = 'MerchantProductListPage'
export const editMerchant = 'EditMerchant'
export const editMerchantAccount = 'EditMerchantAccount'
export const createMerchantProduct = 'CreateMerchantProduct'
export const editMerchantProduct = 'EditMerchantProduct'
export const productPage = 'ProductPage'
export const orderManagementListPage = 'OrderManagementListPage'
export const orderManagementDetailPage = 'OrderManagementDetailPage'
export const homePage = 'HomePage'
export const homepageBannerList = 'HomepageBannerList'
export const createHomepageBanner = 'CreateHomepageBanner'
export const editHomepageBanner = 'EditHomepageBanner'
export const homepageBannerReposition = 'HomepageBannerReposition'
export const homePageSelectedMerchantList = 'HomePageSelectedMerchantList'
export const homePageCategorySectionList = 'HomePageCategorySectionList'
export const homePageCategorySectionCreate = 'HomePageCategorySectionCreate'
export const homePageCategorySectionEdit = 'HomePageCategorySectionEdit'

export const bisaBelanja: RouteConfig = {
  path: '/bisa-belanja',
  component: MainLayout,
  redirect: {
    name: brandListPage,
  },
  meta: {
    title: 'BisaBelanja',
    single: false,
    icon: 'MenuIconSouvenir',
  },
  children: [
    {
      path: 'brand',
      name: brandListPage,
      component: () =>
        import(
          /* webpackChunkName: "BrandListPage" */ '@/app/ui/views/BisaBelanja/Brand/index.vue'
        ),
      meta: {
        title: 'Brand',
        slugFromBackend: BACKEND_SLUG.BRAND,
        accessType: 'read',
        isParent: true,
      },
    },
    {
      path: 'brand/create',
      name: createBrand,
      component: () =>
        import(
          /* webpackChunkName: "CreateBrand" */ '@/app/ui/views/BisaBelanja/Brand/Create/index.vue'
        ),
      meta: {
        title: 'Create Brand',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.BRAND,
        accessType: 'write',
      },
    },
    {
      path: 'brand/:id/edit',
      name: editBrand,
      component: () =>
        import(
          /* webpackChunkName: "EditBrand" */ '@/app/ui/views/BisaBelanja/Brand/Edit/index.vue'
        ),
      meta: {
        title: 'Edit Brand',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.BRAND,
        accessType: 'write',
      },
    },
    {
      path: 'brand/:id',
      name: brandPage,
      component: () =>
        import(
          /* webpackChunkName: "BrandDetailPage" */ '@/app/ui/views/BisaBelanja/Brand/Detail/index.vue'
        ),
      meta: {
        title: 'Brand',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.BRAND,
        accessType: 'read',
      },
    },
    {
      path: 'merchant',
      name: merchantListPage,
      component: () =>
        import(
          /* webpackChunkName: "MerchantListPage" */ '@/app/ui/views/BisaBelanja/Merchant/index.vue'
        ),
      meta: {
        title: 'Merchant',
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'read',
        isParent: true,
      },
    },
    {
      path: 'merchant/create/:id?',
      name: createMerchant,
      component: () =>
        import(
          /* webpackChunkName: "CreateMerchant" */ '@/app/ui/views/BisaBelanja/Merchant/Create/index.vue'
        ),
      meta: {
        title: 'Create Merchant',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'write',
      },
    },
    {
      path: 'merchant/:id',
      component: () =>
        import(
          /* webpackChunkName: "MerchantDetailPage" */ '@/app/ui/views/BisaBelanja/Merchant/Detail/layout/index.vue'
        ),
      meta: {
        title: 'Merchant',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'read',
      },
      children: [
        {
          path: '/',
          name: merchantDetailProfilePage,
          component: () =>
            import(
              /* webpackChunkName: "MerchantDetailProfilePage" */ '@/app/ui/views/BisaBelanja/Merchant/Detail/Profile/index.vue'
            ),
          meta: {
            title: 'Merchant Detail',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.MERCHANT,
            accessType: 'read',
          },
        },
        {
          path: 'account',
          name: merchantDetailAccountPage,
          component: () =>
            import(
              /* webpackChunkName: "MerchantDetailAccountPage" */ '@/app/ui/views/BisaBelanja/Merchant/Detail/Account/index.vue'
            ),
          meta: {
            title: 'Merchant Detail',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.MERCHANT,
            accessType: 'read',
          },
        },
        {
          path: 'product',
          name: merchantProductListPage,
          component: () =>
            import(
              /* webpackChunkName: "MerchantProductListPage" */ '@/app/ui/views/BisaBelanja/Merchant/Product/index.vue'
            ),
          meta: {
            title: 'Merchant Detail',
            isHidden: true,
            slugFromBackend: BACKEND_SLUG.MERCHANT,
            accessType: 'read',
          },
        },
      ],
    },
    {
      path: 'merchant/:id/edit/profile',
      name: editMerchant,
      component: () =>
        import(
          /* webpackChunkName: "EditMerchant" */ '@/app/ui/views/BisaBelanja/Merchant/Edit/Profile/index.vue'
        ),
      meta: {
        title: 'Edit Profile',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'write',
      },
    },
    {
      path: 'merchant/:id/edit/account',
      name: editMerchantAccount,
      component: () =>
        import(
          /* webpackChunkName: "EditMerchantAccount" */ '@/app/ui/views/BisaBelanja/Merchant/Edit/Account/index.vue'
        ),
      meta: {
        title: 'Edit Account',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'write',
      },
    },
    {
      path: 'merchant/:id/product/create',
      name: createMerchantProduct,
      component: () =>
        import(
          /* webpackChunkName: "CreateMerchantProduct" */ '@/app/ui/views/BisaBelanja/Merchant/Product/Create/index.vue'
        ),
      meta: {
        title: 'Create Product',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'write',
      },
    },
    {
      path: 'merchant/:id/product/:productId/edit',
      name: editMerchantProduct,
      component: () =>
        import(
          /* webpackChunkName: "EditMerchantProduct" */ '@/app/ui/views/BisaBelanja/Merchant/Product/Edit/index.vue'
        ),
      meta: {
        title: 'Edit Product',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.MERCHANT,
        accessType: 'write',
      },
    },
    {
      path: 'product',
      name: productPage,
      component: () =>
        import(
          /* webpackChunkName: "ProductPage" */ '@/app/ui/views/BisaBelanja/Product/index.vue'
        ),
      meta: {
        title: 'Product',
        slugFromBackend: BACKEND_SLUG.PRODUCT,
        accessType: 'read',
        isParent: true,
      },
    },
    {
      path: 'order-management',
      name: orderManagementListPage,
      component: () =>
        import(
          /* webpackChunkName: "OrderManagementListPage" */ '@/app/ui/views/BisaBelanja/OrderManagement/index.vue'
        ),
      meta: {
        title: 'Order Management',
        slugFromBackend: BACKEND_SLUG.ORDER_MANAGEMENT,
        accessType: 'read',
        isParent: true,
      },
    },
    {
      path: 'order-management/:id',
      name: orderManagementDetailPage,
      component: () =>
        import(
          /* webpackChunkName: "OrderManagementDetailPage" */ '@/app/ui/views/BisaBelanja/OrderManagement/Detail/index.vue'
        ),
      meta: {
        title: 'Order Management',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.ORDER_MANAGEMENT,
        accessType: 'read',
      },
    },
    {
      path: 'homepage',
      name: homePage,
      redirect: {
        name: homepageBannerList,
      },
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Homepage',
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
        accessType: 'read',
        hasSubChildren: true,
      },
      children: [
        {
          path: 'homepage-banner',
          component: {
            render: c => c('router-view'),
          },
          meta: {
            title: 'Homepage Banner',
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
            isParent: true,
          },
          children: [
            {
              path: '',
              name: homepageBannerList,
              component: () =>
                import(
                  /* webpackChunkName: "HomepageBannerList" */ '@/app/ui/views/BisaBelanja/HomePage/HomepageBanner/index.vue'
                ),
              meta: {
                title: 'Homepage Banner',
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
                isParent: true,
              },
            },
            {
              path: 'create',
              name: createHomepageBanner,
              component: () =>
                import(
                  /* webpackChunkName: "CreateHomepageBanner" */ '@/app/ui/views/BisaBelanja/HomePage/HomepageBanner/Create/index.vue'
                ),
              meta: {
                title: 'Create',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
                isParent: true,
              },
            },
            {
              path: 'edit/:id',
              name: editHomepageBanner,
              component: () =>
                import(
                  /* webpackChunkName: "EditHomepageBanner" */ '@/app/ui/views/BisaBelanja/HomePage/HomepageBanner/Edit/index.vue'
                ),
              meta: {
                title: 'Edit',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
                isParent: true,
              },
            },
            {
              path: 'reposition',
              name: homepageBannerReposition,
              component: () =>
                import(
                  /* webpackChunkName: "HomepageBannerReposition" */ '@/app/ui/views/BisaBelanja/HomePage/HomepageBanner/Reposition/index.vue'
                ),
              meta: {
                title: 'Reposition',
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
                isParent: true,
              },
            },
          ],
        },
        {
          path: 'selected-merchant',
          name: homePageSelectedMerchantList,
          component: () =>
            import(
              /* webpackChunkName: "HomePageSelectedMerchantList" */ '@/app/ui/views/BisaBelanja/HomePage/SelectedMerchant/index.vue'
            ),
          meta: {
            title: 'Selected Merchant',
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
            isParent: true,
          },
        },
        {
          path: 'category',
          component: {
            render: c => c('router-view'),
          },
          meta: {
            title: 'Categories Section',
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
            isParent: true,
          },
          children: [
            {
              path: '',
              name: homePageCategorySectionList,
              component: () =>
                import(
                  /* webpackChunkName: "HomePageCategorySectionList" */ '@/app/ui/views/BisaBelanja/HomePage/CategorySection/index.vue'
                ),
              meta: {
                title: 'Categories Section',
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
                isParent: true,
              },
            },
            {
              path: 'create',
              name: homePageCategorySectionCreate,
              component: () =>
                import(
                  /* webpackChunkName: "HomePageCategorySectionCreate" */ '@/app/ui/views/BisaBelanja/HomePage/CategorySection/Create/index.vue'
                ),
              meta: {
                title: 'Create',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
              },
            },
            {
              path: ':categoryId',
              name: homePageCategorySectionEdit,
              component: () =>
                import(
                  /* webpackChunkName: "HomePageCategorySectionEdit" */ '@/app/ui/views/BisaBelanja/HomePage/CategorySection/Edit/index.vue'
                ),
              meta: {
                title: 'Edit',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.BISA_BELANJA_HOMEPAGE,
              },
            },
          ],
        },
      ],
    },
  ],
}
