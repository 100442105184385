

















import { routes } from '@/app/ui/router/index'
import { RouteConfig } from 'vue-router/types/router'
import { LocalStorage } from '@/app/infrastructures/misc'
import { Component, Vue } from 'vue-property-decorator'
import MenuItem from './MenuItem/index.vue'

@Component({
  components: {
    MenuItem,
  },
})
export default class Sidebar extends Vue {
  myAccessMenus = ['']
  myRole = ''

  created(): void {
    this.myRole = LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true)
    this.myAccessMenus = JSON.parse(
      LocalStorage.getLocalStorage(LocalStorage.ACCESS_MENU, true)
    )
  }

  get routes(): RouteConfig[] {
    const tempRoutes: RouteConfig[] = JSON.parse(JSON.stringify(routes))
    const _routes: RouteConfig[] = []
    if (this.myRole !== 'ADMIN') {
      for (let i = 0; i < tempRoutes.length; i++) {
        const children = tempRoutes[i].children || []
        if (
          (tempRoutes[i].meta &&
            children.length > 0 &&
            children?.some(r => this.myAccessMenus.includes(r?.meta?.slugFromBackend?.slug))) ||
          (children.length > 0 &&
            children[0].meta.accessType === 'write' &&
            this.myRole !== 'CMS_GUEST')
        ) {
          _routes.push(tempRoutes[i])

          if (!tempRoutes[i].meta.single) {
            tempRoutes[i].children = tempRoutes[i].children?.filter((item) =>
              this.myAccessMenus.includes(item.meta.slugFromBackend.slug)
            )
          }
        }
      }
    } else {
      // role = 'ADMIN' is traditional role name that always have all access
      _routes.push(...tempRoutes)
    }
    return _routes.filter(
      (it) => it.meta && it.children && !it.meta.hideFromSidebar
    )
  }

  private logout() {
    LocalStorage.removeAllLocalStorage()
    this.$router.push({ name: 'LoginPage' })
  }
}
