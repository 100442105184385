import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
export const loyaltyProgramMemberShipListRouteName = 'LoyaltyProgramMembershipListPage'
export const loyaltyProgramMembershipFormulaListRouteName = 'LoyaltyProgramMembershipFormulaListPage'
export const createLoyaltyProgramMembershipFormulaListRouteName = 'CreateLoyaltyProgramMembershipFormulaListPage'
export const editLoyaltyProgramMembershipFormulaListRouteName = 'EditLoyaltyProgramMembershipFormulaListPage'
export const loyaltyProgramExtraMultiplierRouteName =  'LoyaltyProgramExtraMultiplierPage'
export const createLoyaltyProgramExtraMultiplierRouteName = 'CreateLoyaltyProgramExtraMultiplierPage'
export const editLoyaltyProgramExtraMultiplierRouteName = 'EditLoyaltyProgramExtraMultiplierPage'
export const loyaltyProgramGetPoinRule = 'LoyaltyProgramGetPoinRulePage'
export const loyaltyProgramMemberShipCreateRouteName =  'CreateLoyaltyProgramMembershipListPage'
export const loyaltyProgramMemberShipEditRouteName =  'loyaltyProgramMemberShipEditRouteName'
export const loyaltyProgramMemberShipDetailRouteName =  'loyaltyProgramMemberShipDetailRouteName'

export const loyaltyProgram = {
  path: '/loyalty-program',
  component: MainLayout,
  redirect: '/loyalty-program/membership',
  meta: {
    title: 'Loyalty Program',
    single: false,
    icon: 'MenuIconLoyaltyProgram',
  },
  children: [
    {
      path: 'membership',
      name: loyaltyProgramMemberShipListRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "LoyaltyProgramMembershipListPage" */ '@/app/ui/views/LoyaltyProgram/Membership/List/index.vue'
      ),
      meta: {
        title: 'Membership',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP,
        accessType: 'read',
      },
    },
    {
      path: 'membership/create',
      name: loyaltyProgramMemberShipCreateRouteName,
      component: (): unknown => import(
    /* webpackChunkName: "LoyaltyProgramMembershipCreatePage" */ '@/app/ui/views/LoyaltyProgram/Membership/Create/index.vue'
      ),
      meta: {
        title: 'Create Membership Program',
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP,
        accessType: 'write',
        isHidden: true,
      },
    },
    {
      path: 'membership/edit/:id',
      name: loyaltyProgramMemberShipEditRouteName,
      component: (): unknown => import(
      /* webpackChunkName: "LoyaltyProgramMembershipEditPage" */ '@/app/ui/views/LoyaltyProgram/Membership/Edit/index.vue'
      ),
      meta: {
        title: 'Edit Membership Program',
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP,
        accessType: 'write',
        isHidden: true,
      },
    },
    {
      path: 'membership/detail/:id',
      name: loyaltyProgramMemberShipDetailRouteName,
      component: (): unknown => import(
      /* webpackChunkName: "LoyaltyProgramMembershipDetailPage" */ '@/app/ui/views/LoyaltyProgram/Membership/Detail/index.vue'
      ),
      meta: {
        title: 'View Detail Membership Program',
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP,
        accessType: 'read',
        isHidden: true,
      }
    },
    {
      path: 'membership-poin-formula',
      name: loyaltyProgramMembershipFormulaListRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "LoyaltyProgramMembershipFormulaListPage" */ '@/app/ui/views/LoyaltyProgram/MembershipFormula/List/index.vue'
      ),
      meta: {
        title: 'Membership Poin Formula',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP_FORMULA,
        accessType: 'read',
      },
    },
    {
      path: 'membership-poin-formula/create',
      name: createLoyaltyProgramMembershipFormulaListRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "CreateLoyaltyProgramMembershipFormulaListPage" */ '@/app/ui/views/LoyaltyProgram/MembershipFormula/Create/index.vue'
      ),
      meta: {
        title: 'Create New',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP_FORMULA,
        accessType: 'write',
      },
    },
    {
      path: 'membership-poin-formula/edit/:id',
      name: editLoyaltyProgramMembershipFormulaListRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "EditLoyaltyProgramMembershipFormulaListPage" */ '@/app/ui/views/LoyaltyProgram/MembershipFormula/Edit/index.vue'
      ),
      meta: {
        title: 'Edit',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_MEMBERSHIP_FORMULA,
        accessType: 'write',
      },
    },
    /**
     *  start of extra multiplier
     */
    {
      path: 'extra-multiplier',
      name: loyaltyProgramExtraMultiplierRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "LoyaltyProgramExtraMultiplierListPage" */ '@/app/ui/views/LoyaltyProgram/ExtraMultiplier/List/index.vue'
      ),
      meta: {
        title: 'Extra Multiplier',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_EXTRA_MULTIPLIER,
        accessType: 'read',
      },
    },
    {
      path: 'extra-multiplier/create',
      name: createLoyaltyProgramExtraMultiplierRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "CreateLoyaltyProgramExtraMultiplierPage" */ '@/app/ui/views/LoyaltyProgram/ExtraMultiplier/Create/index.vue'
      ),
      meta: {
        title: 'Create Extra Multiplier',
        customBreedcrumbs: [
          {
            title: 'Extra Multiplier',
            path: '/loyalty-program/extra-multiplier'
          },
          {
            title:  'Create New',
            path: ''
          }
        ],
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_EXTRA_MULTIPLIER,
        accessType: 'write',
      },
    },
    {
      path: 'extra-multiplier/edit/:id',
      name: editLoyaltyProgramExtraMultiplierRouteName,
      component: (): unknown => import(
          /* webpackChunkName: "EditLoyaltyProgramExtraMultiplierPage" */ '@/app/ui/views/LoyaltyProgram/ExtraMultiplier/Edit/index.vue'
      ),
      meta: {
        title: 'Edit Extra Multiplier',
        customBreedcrumbs: [
          {
            title: 'Extra Multiplier',
            path: '/loyalty-program/extra-multiplier'
          },
          {
            title:  'Edit',
            path: ''
          }
        ],
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_EXTRA_MULTIPLIER,
        accessType: 'write',
      },
    },
    /**
     * end of extra multiplier
     */
    {
      path: 'get-poin-rule',
      name: loyaltyProgramGetPoinRule,
      component: (): unknown => import(
          /* webpackChunkName: "LoyaltyProgramGetPoinRulePage" */ '@/app/ui/views/LoyaltyProgram/GetPoinRule/index.vue'
      ),
      meta: {
        title: 'Get Poin Rule',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.LOYALTY_GET_POIN_RULE,
        accessType: 'read',
      },
    },
  ],
}
