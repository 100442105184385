import { Utils } from '@/data/infrastructures/misc/Utils'
import { Account, Accounts, AccessMenu, Role } from '@/domain/entities/Account'
import { Pagination } from '@/domain/entities/Pagination'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class UserManagementMapper extends BaseResponseMapper {
  public convertAccountsFromApi(result: AxiosResponse<any>): Accounts {
    const {
      data: { pagination, data },
    } = result
    return new Accounts(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (account: Record<string, never>) =>
          new Account(
            account.account_id,
            account.email,
            account.role,
            account.access_menu
          )
      )
    )
  }

  public convertAccountFromApi(result: AxiosResponse<any>): Account {
    const { data } = result
    return new Account(
      data.account_id,
      data.email,
      data.role,
      undefined,
      data.access_menu.map(
        (menu: Record<string, never>) =>
          new AccessMenu(menu.id, menu.slug, menu.name, menu.group)
      )
    )
  }

  public convertRolesFromApi(result: AxiosResponse<any>): Role[] {
    const {
      data: { data },
    } = result
    return data.map(
      (role: Record<string, never>) => new Role(role.account_role)
    )
  }

  public convertAccessMenusFromApi(result: AxiosResponse<any>): AccessMenu[] {
    const {
      data: { data },
    } = result
    return data.map(
      (menu: Record<string, never>) =>
        new AccessMenu(menu.id, menu.slug, menu.name, menu.group)
    )
  }
}
