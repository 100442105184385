import { AxiosResponse } from 'axios'
import { FAQ, FAQs, QnA, QnASubCategory, StarQuestion } from '@/domain/entities/FAQ'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'

export class FAQMapper extends BaseResponseMapper {
  public convertFAQFromApi(result: AxiosResponse): FAQ {
    const { data } = result
    return new FAQ(
      data.category_id,
      data.category_name,
      data.order,
      undefined,
      data.data?.map(
        (faq: Record<string, any>) =>
          new QnA(
            faq.id,
            faq.question,
            faq.answer,
            faq.order,
            faq.is_draft,
            faq.is_star,
            faq.updated_at
          )
      ) || [],
      data.is_active,
      data.icon_image,
      data.sub_category_list?.map(
        (item: Record<string, any>) =>
          new QnASubCategory(
            item.id,
            item.order,
            item.sub_category_name,
            item.data?.map(
              (unit: Record<string, any>) =>
              new QnA(
                unit.id,
                unit.question,
                unit.answer,
                unit.order,
                unit.is_draft,
                unit.is_star,
                unit.updated_at
              )
            )
          )
      ) || [],
      data.with_sub_category,
      data.created_at,
      data.updated_at
    )
  }

  public convertFAQsFromApi(result: AxiosResponse): FAQs {
    const {
      data: { data, pagination },
    } = result
    return new FAQs(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (faq: Record<string, any>) =>
          new FAQ(
            faq.id,
            faq.category_name,
            faq.order,
            faq.questions,
            undefined,
            faq.is_active,
            undefined,
            faq.updated_at
          )
      )
    )
  }

  public convertListQuestionFromApi(result: AxiosResponse): StarQuestion[] {
    const { data } = result
    return data?.map(
      (item: Record<string, number | string | boolean | undefined>) =>
        new StarQuestion(
          item.id as number,
          item.question as string,
          item.answer as string,
          item.icon_image as string,
          item.star_order as number,
          item.is_draft as boolean,
          item.is_star as boolean,
          item.updated_at as string
        ))
  }
}
