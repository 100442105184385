import { Pagination } from "./Pagination"

export class ManageCustomers {
  public pagination?: Pagination
  public data?: ManageCustomer[]

  constructor(pagination?: Pagination, data?: ManageCustomer[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCustomer {
  public id?: number
  public fullname?: string
  public phoneNumber?: string
  public email?: string
  public membershipLevel?: string
  public status?: string
  public gender?: string
  public birthdate?: string
  public address?: string
  public city?: string
  public totalPoints?: number
  public os?: string
  public createdAt?: string
  public deletedAt?: string

  constructor(
    id?: number,
    fullname?: string,
    phoneNumber?: string,
    email?: string,
    membershipLevel?: string,
    status?: string,
    gender?: string,
    birthdate?: string,
    address?: string,
    city?: string,
    totalPoints?: number,
    os?: string,
    createdAt?: string,
    deletedAt?: string
  ) {
    this.id = id
    this.fullname = fullname
    this.phoneNumber = phoneNumber
    this.email = email
    this.membershipLevel = membershipLevel
    this.status = status
    this.gender = gender
    this.birthdate = birthdate
    this.address = address
    this.city = city
    this.totalPoints = totalPoints
    this.os = os
    this.createdAt = createdAt
    this.deletedAt = deletedAt
  }
}

export class HistoryStatusCustomer {
  public adminId?: number
  public adminName?: string
  public banHistoryId?: number
  public isBanned?: boolean
  public reason?: string
  public createdAt?: string
  public customerStatus?: string

  constructor(
    adminId?: number,
    adminName?: string,
    banHistoryId?: number,
    isBanned?: boolean,
    reason?: string,
    createdAt?: string,
    customerStatus?: string
  ) {
    this.adminId = adminId
    this.adminName = adminName
    this.banHistoryId = banHistoryId
    this.isBanned = isBanned
    this.reason = reason
    this.createdAt = createdAt
    this.customerStatus = customerStatus
  }
}
