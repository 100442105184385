import { injectable } from 'tsyringe'

import { HomepageBannerRepositoryInterface } from '@/data/persistences/repositories/contracts/HomepageBannerRepositoryInterface'
import {
  HomepageBanners,
  HomepageBanner,
} from '@/domain/entities/HomepageBanner'
import {
  CreateHomepageBannerRequestInterface,
  UpdateHomepageBannerRequestInterface,
  HomepageBannerRepositionRequestInterface,
} from '@/data/payload/contracts/HomepageBannerRequest'
import { ErrorMessage } from '@/domain/entities/BaseResponse'

@injectable()
export class HomepageBannerPresenter {
  private repository: HomepageBannerRepositoryInterface

  constructor(repository: HomepageBannerRepositoryInterface) {
    this.repository = repository
  }

  public get(homepageBannerId: number): Promise<HomepageBanner> {
    return this.repository.get(homepageBannerId)
  }

  public getAll(
    params: Map<string, string | number | undefined>
  ): Promise<HomepageBanners> {
    return this.repository.getAll(params)
  }

  public getActiveBanners(): Promise<HomepageBanner[]> {
    return this.repository.getActiveBanners()
  }

  public create(
    payload: CreateHomepageBannerRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    homepageBannerId: number,
    payload: UpdateHomepageBannerRequestInterface
  ): Promise<boolean> {
    return this.repository.update(homepageBannerId, payload)
  }

  public cancel(homepageBannerId: number): Promise<boolean> {
    return this.repository.cancel(homepageBannerId)
  }

  public reposition(
    bannerIds: HomepageBannerRepositionRequestInterface
  ): Promise<ErrorMessage | null> {
    return this.repository.reposition(bannerIds)
  }

  public countActiveBanner(): Promise<number> {
    return this.repository.countActiveBanner()
  }
}
