import { HistoryResponse } from '@/data/responses/contracts/PackageResponse'
import {
  History,
  Meta,
  Package,
  Recepient,
  Sender,
  Shipment,
} from '@/domain/entities/Package'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class PackageMapper extends BaseResponseMapper {
  public convertPackageFromApi(result: AxiosResponse): Package {
    const { data } = result

    return new Package(
      data.package_id,
      data.is_manual,
      data.origin,
      data.destination,
      new Sender(data.sender.name, data.sender.address, data.sender.phone),
      new Recepient(
        data.recipient.name,
        data.recipient.address,
        data.recipient.phone
      ),
      data.product_type,
      data.service_type,
      data.quantity,
      data.gross_weight,
      data.volume_weight,
      data.chargeable_weight,
      data.publish_rate,
      data.forward_rate,
      data.shipping_surcharge_rate,
      data.commodity_surcharge_rate,
      data.heavy_weight_surcharge_rate,
      data.insurance_rate,
      data.wood_packing_rate,
      data.is_cod,
      data.is_wood_packing,
      data.is_insurance,
      data.goods_value,
      data.cod_value,
      data.cod_fee,
      data.created_at,
      data.updated_at,
      new Shipment(
        data.shipment?.shipment_id,
        data.shipment?.shipment_status,
        data.shipment?.shipment_type,
        data.shipment?.shipment_group_id,
        data.shipment?.customer_id,
        data.shipment?.customer_role,
        data.shipment?.created_at,
        data.shipment?.shipping_type
      ),
      data.is_allow_reorder,
      data.histories.map(
        (history: HistoryResponse) =>
          new History(
            <number>history.history_id,
            <string>history.history_type,
            <string>history.location,
            <string>history.city,
            <string>history.status_id,
            <string>history.status,
            <string>history.long_status,
            <number>history.actor_id,
            <string>history.actor_role,
            <string>history.actor_name,
            <string>history.reason_id,
            <string>history.reason,
            <string>history.relation_id,
            <string>history.relation,
            <string>history.person_name,
            <string>history.note,
            new Meta(
              <string[]>history.meta?.proofs,
              <number>history.meta?.quantity_confirm,
              <number>history.meta?.quantity_original,
              <string>history.meta?.signature
            ),
            <string>history.created_at,
            <string>history.driver_number,
            <number>history.meta_life_time,
            <boolean>history.has_meta
          )
      ),
      data.estimated_departure_date,
      data.location,
      data.invoice_status
    )
  }
}
