import { Pagination } from './Pagination'

export class SaldoPayment {
  public invoiceId?: string
  public customerId?: string
  public amount?: string
  public statusId?: string
  public expiredAt?: string
  public initiatedAt?: string
  public finishedAt?: string
  public reminder?: string
  public reminderAt?: string
  public paymentMethod?: string
  public packagePrice?: string
  public totalAmountInvoice?: string
  public saldoAmountUsed?: string
  public packageId?: string
  public packageCreatedAt?: string
  public transactionStatus?: string
  public bank?: string
  public paymentType?: string
  public vaNumber?: string
  public createdAt?: string

  constructor(
    invoiceId?: string,
    customerId?: string,
    amount?: string,
    statusId?: string,
    expiredAt?: string,
    initiatedAt?: string,
    finishedAt?: string,
    reminder?: string,
    reminderAt?: string,
    paymentMethod?: string,
    packagePrice?: string,
    totalAmountInvoice?: string,
    saldoAmountUsed?: string,
    packageId?: string,
    packageCreatedAt?: string,
    transactionStatus?: string,
    bank?: string,
    paymentType?: string,
    vaNumber?: string,
    createdAt?: string
  ) {
    this.invoiceId = invoiceId
    this.customerId = customerId
    this.amount = amount
    this.statusId = statusId
    this.expiredAt = expiredAt
    this.initiatedAt = initiatedAt
    this.finishedAt = finishedAt
    this.reminder = reminder
    this.reminderAt = reminderAt
    this.paymentMethod = paymentMethod
    this.packagePrice = packagePrice
    this.totalAmountInvoice = totalAmountInvoice
    this.saldoAmountUsed = saldoAmountUsed
    this.packageId = packageId
    this.packageCreatedAt = packageCreatedAt
    this.transactionStatus = transactionStatus
    this.bank = bank
    this.paymentType = paymentType
    this.vaNumber = vaNumber
    this.createdAt = createdAt
  }
}

export class SaldoPayments {
  public pagination?: Pagination
  public data?: SaldoPayment[]

  constructor(pagination?: Pagination, data?: SaldoPayment[]) {
    this.pagination = pagination
    this.data = data
  }
}
