import {injectable} from 'tsyringe'
import {
  VoucherConfigHistoryBulkyRepositoryInterface
} from '@/data/persistences/repositories/contracts/VoucherConfigHistoryBulkyRepositoryInterface'
import {HistoryUploadBulkyVoucherConfig} from '@/domain/entities/VoucherConfigHistoryBulky'

@injectable()
export class VoucherConfigHistoryBulkyPresenter {
  private readonly repository: VoucherConfigHistoryBulkyRepositoryInterface

  constructor(repo: VoucherConfigHistoryBulkyRepositoryInterface) {
    this.repository = repo
  }

  public getHistoryUploadBulky(
    params: Map<string, string>
  ): Promise<HistoryUploadBulkyVoucherConfig> {
    return this.repository.getHistoryUploadBulky(params)
  }
}
