import { RouteConfig } from 'vue-router'
import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'

export const manageCourier: RouteConfig = {
  path: '/manage-user-&-courier',
  component: MainLayout,
  redirect: { name: 'ManageCourierPageList' },
  meta: {
    title: 'Manage User & Courier',
    single: false,
    icon: 'MenuIconManageCourier',
  },
  children: [
    {
      path: 'manage-courier',
      name: 'ManageCourierPage',
      component: {
        render: c => c('router-view'),
      },
      redirect: { name: 'ManageCourierPageLayout' },
      meta: {
        title: 'Manage Courier',
        slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'ManageCourierPageLayout',
          component: () =>
            import(
              /* webpackChunkName: "ManageCourierPageLayout" */ '@/app/ui/views/ManageUserAndCourier/tabbing/index.vue'
            ),
          redirect: { name: 'ManageCourierPageList' },
          meta: {
            title: 'Manage Courier',
            hasSubChildren: true,
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            accessType: 'read',
          },
          children: [
            {
              path: '',
              name: 'ManageCourierPageList',
              component: () =>
                import(
                  /* webpackChunkName: "ManageCourierPageList" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/index.vue'
                ),
              meta: {
                isParent: true,
                slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
                accessType: 'read',
                isHidden: true,
              },
            },
            {
              path: 'history-upload',
              name: 'ManageCourierHistoryUploadBulkyPageList',
              component: () =>
                import(
                  /* webpackChunkName: "ManageCourierHistoryUploadBulkyPageList" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/HistoryUploadBulky/index.vue'
                ),
              meta: {
                isParent: true,
                slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
                accessType: 'read',
                isHidden: true,
              },
            },
          ],
        },
        {
          path: '/manage-user-&-courier/manage-courier/create',
          name: 'CreateManageCourierPage',
          component: () =>
            import(
              /* webpackChunkName: "CreateManageCourierPage" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/create/index.vue'
            ),
          meta: {
            title: 'Add Courier',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isHidden: true,
            accessType: 'write',
          },
        },
        {
          path: '/manage-user-&-courier/manage-courier/edit/:id',
          name: 'EditManageCourierPage',
          component: () =>
            import(
              /* webpackChunkName: "EditManageCourierPage" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/edit/index.vue'
            ),
          meta: {
            title: 'Edit Courier',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isHidden: true,
            accessType: 'write',
          },
        },
        {
          path: '/manage-user-&-courier/manage-courier/history-log/:id',
          name: 'ManageCourierHistoryLog',
          component: () =>
            import(
              /* webpackChunkName: "ManageCourierHistoryLog" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/HistoryLog/index.vue'
            ),
          meta: {
            title: 'Log History',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isHidden: true,
            accessType: 'write',
          },
        },
        {
          path: '/manage-user-&-courier/manage-courier/courier-leave',
          name: 'ManageCourierLeaveTab',
          redirect: {
            name: 'ManageCourierLeaveTabList',
          },
          component: () =>
            import(
              /* webpackChunkName: "ManageCourierLeaveTabPage" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/CourierLeaveTab/index.vue'
            ),
          children: [
            {
              path: 'list',
              name: 'ManageCourierLeaveTabList',
              component: () =>
                import(
                  /* webpackChunkName: "ManageCourierLeavePage" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/CourierLeave/index.vue'
                ),
              meta: {
                slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
                isHidden: true,
                accessType: 'write',
              },
            },
            {
              path: 'approval-list',
              name: 'ManageCourierLeaveTabApprovalList',
              component: () =>
                import(
                  /* webpackChunkName: "ManageCourierLeaveApprovalPage" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/CourierLeaveApproval/index.vue'
                ),
              meta: {
                slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
                isHidden: true,
                accessType: 'write',
              },
            },
          ],
          meta: {
            title: 'Cek Jadwal Cuti',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isParent: true,
            isHidden: true,
            accessType: 'write',
          },
        },
        {
          path: '/manage-user-&-courier/manage-courier/upload-bulky',
          name: 'ManageCourierUploadBulkyPage',
          component: () =>
            import(
              /* webpackChunkName: "ManageCourierUploadBulkyPage" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/UploadBulky/index.vue'
            ),
          meta: {
            title: 'Upload Bulky',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isHidden: true,
            accessType: 'write',
          },
        },
        {
          path: '/manage-user-&-courier/manage-courier/upload-bulky/list',
          name: 'ManageCourierUploadBulkyPageList',
          component: () =>
            import(
              /* webpackChunkName: "ManageCourierUploadBulkyPageList" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/UploadBulky/index.vue'
            ),
          meta: {
            title: 'Upload Bulky',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isHidden: true,
            accessType: 'write',
          },
        },
        {
          path: '/manage-user-&-courier/manage-courier/history-upload/:id',
          name: 'ManageCourierHistoryUploadBulkyPageDetail',
          component: () =>
            import(
              /* webpackChunkName: "ManageCourierHistoryUploadBulkyPageDetail" */ '@/app/ui/views/ManageUserAndCourier/ManageCourier/UploadBulky/index.vue'
            ),
          meta: {
            title: 'History Upload',
            slugFromBackend: BACKEND_SLUG.MANAGE_COURIER,
            isHidden: true,
            accessType: 'write',
          },
        },
      ],
    },
  ],
}
