import { Pagination } from './Pagination'

export class DriverBank {
  public bankId?: string
  public bankName?: string
  public bankAccount?: string

  constructor(bankId?: string, bankName?: string, bankAccount?: string) {
    this.bankId = bankId
    this.bankName = bankName
    this.bankAccount = bankAccount
  }
}

export class PODTrackerDriver {
  public courierId?: number
  public role?: string
  public fullname?: string
  public phoneNumber?: string
  public vehicleType?: string
  public vehiclePlate?: string
  public partnerType?: string
  public partnerName?: string
  public origin?: string
  public picture?: string
  public agentId?: number
  public score?: number
  public announcementCourierType?: string
  public createdAt?: string
  public courierType?: string
  public courierStatus?: string
  public bank?: DriverBank

  constructor(
    courierId?: number,
    role?: string,
    fullname?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerType?: string,
    partnerName?: string,
    origin?: string,
    picture?: string,
    agentId?: number,
    score?: number,
    announcementCourierType?: string,
    createdAt?: string,
    courierType?: string,
    courierStatus?: string,
    bank?: DriverBank
  ) {
    this.courierId = courierId
    this.role = role
    this.fullname = fullname
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerType = partnerType
    this.partnerName = partnerName
    this.origin = origin
    this.picture = picture
    this.agentId = agentId
    this.score = score
    this.announcementCourierType = announcementCourierType
    this.createdAt = createdAt
    this.courierType = courierType
    this.courierStatus = courierStatus
    this.bank = bank
  }
}

export class PODTrackerDrivers {
  public pagination?: Pagination
  public data?: PODTrackerDriver[]

  constructor(pagination?: Pagination, data?: PODTrackerDriver[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PODDestination {
  public address?: string
  public latitude?: number
  public longitude?: number

  constructor(address?: string, latitude?: number, longitude?: number) {
    this.address = address
    this.latitude = latitude
    this.longitude = longitude
  }
}

export class Geolocation {
  public latitude?: number
  public longitude?: number

  constructor(latitude?: number, longitude?: number) {
    this.latitude = latitude
    this.longitude = longitude
  }
}

export class POD {
  public packageId?: string
  public courierId?: number
  public courierName?: string
  public podDate?: string
  public podDestination?: PODDestination
  public podActual?: Geolocation
  public isPodValid?: boolean

  constructor(
    packageId?: string,
    courierId?: number,
    courierName?: string,
    podDate?: string,
    podDestination?: PODDestination,
    podActual?: Geolocation,
    isPodValid?: boolean
  ) {
    this.packageId = packageId
    this.courierId = courierId
    this.courierName = courierName
    this.podDate = podDate
    this.podDestination = podDestination
    this.podActual = podActual
    this.isPodValid = isPodValid
  }
}

export class PODs {
  public pagination?: Pagination
  public data?: POD[]
  public courierId?: number
  public courierName?: string
  public phoneNumber?: string
  public vehicleType?: string
  public vehiclePlate?: string
  public partnerType?: string
  public partnerName?: string
  public origin?: string
  public courierTypeValid?: string

  constructor(
    pagination?: Pagination,
    data?: POD[],
    courierId?: number,
    courierName?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerType?: string,
    partnerName?: string,
    origin?: string,
    courierTypeValid?: string
  ) {
    this.pagination = pagination
    this.data = data
    this.courierId = courierId
    this.courierName = courierName
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerType = partnerType
    this.partnerName = partnerName
    this.origin = origin
    this.courierTypeValid = courierTypeValid
  }
}

export class PODHistoryMeta {
  public proofs?: string[]
  public quantityConfirm?: number
  public quantityOriginal?: number
  public signature?: string

  constructor(
    proofs?: string[],
    quantityConfirm?: number,
    quantityOriginal?: number,
    signature?: string
  ) {
    this.proofs = proofs
    this.quantityConfirm = quantityConfirm
    this.quantityOriginal = quantityOriginal
    this.signature = signature
  }
}

export class PODHistory {
  public historyId?: number
  public historyType?: string
  public location?: string
  public city?: string
  public statusId?: string
  public status?: string
  public longStatus?: string
  public actorId?: string
  public actorRole?: string
  public actorName?: string
  public reasonId?: string
  public reason?: string
  public relationId?: string
  public relation?: string
  public personName?: string
  public note?: string
  public meta?: PODHistoryMeta
  public createdAt?: string

  constructor(
    historyId?: number,
    historyType?: string,
    location?: string,
    city?: string,
    statusId?: string,
    status?: string,
    longStatus?: string,
    actorId?: string,
    actorRole?: string,
    actorName?: string,
    reasonId?: string,
    reason?: string,
    relationId?: string,
    relation?: string,
    personName?: string,
    note?: string,
    meta?: PODHistoryMeta,
    createdAt?: string
  ) {
    this.historyId = historyId
    this.historyType = historyType
    this.location = location
    this.city = city
    this.statusId = statusId
    this.status = status
    this.longStatus = longStatus
    this.actorId = actorId
    this.actorRole = actorRole
    this.actorName = actorName
    this.reasonId = reasonId
    this.reason = reason
    this.relationId = relationId
    this.relation = relation
    this.personName = personName
    this.note = note
    this.meta = meta
    this.createdAt = createdAt
  }
}

export class PODDetailData {
  public packageId?: string
  public podDate?: string
  public podDestination?: PODDestination
  public podActual?: Geolocation
  public isValidPod?: boolean
  public history?: PODHistory

  constructor(
    packageId?: string,
    podDate?: string,
    podDestination?: PODDestination,
    podActual?: Geolocation,
    isValidPod?: boolean,
    history?: PODHistory
  ) {
    this.packageId = packageId
    this.podDate = podDate
    this.podDestination = podDestination
    this.podActual = podActual
    this.isValidPod = isValidPod
    this.history = history
  }
}

export class PODDetail {
  public courierId?: number
  public courierName?: string
  public phoneNumber?: string
  public vehicleType?: string
  public vehiclePlate?: string
  public partnerType?: string
  public partnerName?: string
  public origin?: string
  public courierTypeValid?: string
  public detailPOD?: PODDetailData

  constructor(
    courierId?: number,
    courierName?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerType?: string,
    partnerName?: string,
    origin?: string,
    courierTypeValid?: string,
    detailPOD?: PODDetailData
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.phoneNumber = phoneNumber
    this.vehicleType = vehicleType
    this.vehiclePlate = vehiclePlate
    this.partnerType = partnerType
    this.partnerName = partnerName
    this.origin = origin
    this.courierTypeValid = courierTypeValid
    this.detailPOD = detailPOD
  }
}
