import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { ManualPODPhotosRepositoryInterface } from '@/data/persistences/repositories/contracts/ManualPODPhotosRepositoryInterface'
import { UploadPODPhotosRequestInterface } from '@/data/payload/contracts/ManualPODPhotosRequest'
import { ManualPODPhotosMapper } from '@/data/persistences/mappers/ManualPODPhotosMapper'
import { UploadManualPOD } from '@/domain/entities/ManualPODPhotos'

export class ManualPODPhotosApiRepository implements ManualPODPhotosRepositoryInterface {
  private service: ApiService
  private mapper: ManualPODPhotosMapper

  constructor(service: ApiService, mapper: ManualPODPhotosMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(id: string): Promise<UploadManualPOD> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.GET_PACKAGE_POD}/${id}`
    )
    return this.mapper.convertUploadManualPODPhotosFromApi(resp)
  }

  public async uploadImage(payload: UploadPODPhotosRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.UPLOAD_IMAGE_POD}`,
      undefined,
      payload
    )

    return true
  }
}
