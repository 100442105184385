import { injectable } from 'tsyringe'
import { ScheduleOrder } from '@/domain/entities/ScheduleOrder'
import { ScheduleOrderRepositoryInterface } from '@/data/persistences/repositories/contracts/ScheduleOrderRepositoryInterface'
import { UpdateScheduleOrderRequestInterface } from '@/data/payload/contracts/ScheduleOrderRequest'

@injectable()
export class ScheduleOrderPresenter {
  private repository: ScheduleOrderRepositoryInterface

  constructor(repository: ScheduleOrderRepositoryInterface) {
    this.repository = repository
  }

  public getAll(
    params: Map<string, string | number>
  ): Promise<ScheduleOrder[]> {
    return this.repository.getAll(params)
  }

  public update(
    payload: UpdateScheduleOrderRequestInterface
  ): Promise<boolean> {
    return this.repository.update(payload)
  }
}
