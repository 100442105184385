import { Pagination } from './Pagination'

export class DriverScoreboardConfiguration {
  public id?: number
  public scoreType?: string
  public label?: string
  public scoreValue?: string
  public point?: number
  public action?: string
  public startDate?: string
  public endDate?: string
  public createdAt?: string

  constructor(
    id?: number,
    scoreType?: string,
    label?: string,
    scoreValue?: string,
    point?: number,
    action?: string,
    startDate?: string,
    endDate?: string,
    createdAt?: string
  ) {
    this.id = id
    this.scoreType = scoreType
    this.label = label
    this.scoreValue = scoreValue
    this.point = point
    this.action = action
    this.startDate = startDate
    this.endDate = endDate
    this.createdAt = createdAt
  }
}

export class DriverScoreboardConfigurations {
  public data?: DriverScoreboardConfiguration[]

  constructor(data?: DriverScoreboardConfiguration[]) {
    this.data = data
  }
}

export class Driver {
  public id?: number
  public fullName?: string
  public phoneNumber?: string
  public courierType?: string
  public score?: number

  constructor(
    id?: number,
    fullName?: string,
    phoneNumber?: string,
    courierType?: string,
    score?: number
  ) {
    this.id = id
    this.fullName = fullName
    this.phoneNumber = phoneNumber
    this.courierType = courierType
    this.score = score
  }
}

export class Drivers {
  public pagination?: Pagination
  public data?: Driver[]

  constructor(pagination?: Pagination, data?: Driver[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class DriverScoreReportData {
  public rules?: string
  public point?: number
  public totalShipment?: number
  public totalPoint?: number

  constructor(
    rules?: string,
    point?: number,
    totalShipment?: number,
    totalPoint?: number
  ) {
    this.rules = rules
    this.point = point
    this.totalShipment = totalShipment
    this.totalPoint = totalPoint
  }
}

export interface DriverScoreData {
  [key: string]: DriverScoreReportData[]
}

export class DriverScoreReport {
  public id?: string
  public courierName?: string
  public courierOrigin?: string
  public courierPhone?: string
  public courierVehicleNumber?: string
  public courierType?: string
  public data?: DriverScoreData

  constructor(
    id?: string,
    courierName?: string,
    courierOrigin?: string,
    courierPhone?: string,
    courierVehicleNumber?: string,
    courierType?: string,
    data?: DriverScoreData
  ) {
    this.id = id
    this.courierName = courierName
    this.courierOrigin = courierOrigin
    this.courierPhone = courierPhone
    this.courierVehicleNumber = courierVehicleNumber
    this.courierType = courierType
    this.data = data
  }
}

export class DetailScorePerDay {
  public date?: string
  public totalShipment?: number
  public totalPoint?: number

  constructor(date?: string, totalShipment?: number, totalPoint?: number) {
    this.date = date
    this.totalShipment = totalShipment
    this.totalPoint = totalPoint
  }
}

export class DriverScoreReportCalculateData {
  public rules?: string
  public point?: number
  public detail?: DetailScorePerDay[]

  constructor(
    rules?: string,
    point?: number,
    detail?: DetailScorePerDay[]
  ) {
    this.rules = rules
    this.point = point
    this.detail = detail
  }
}

export interface DriverScoreCalculateData {
  [key: string]: DriverScoreReportCalculateData[]
}

export class DriverScoreReportCalculate {
  public id?: number
  public courierName?: string
  public courierOrigin?: string
  public courierPhone?: string
  public courierVehicleNumber?: string
  public courierType?: string
  public data?: DriverScoreCalculateData

  constructor(
    id?: number,
    courierName?: string,
    courierOrigin?: string,
    courierPhone?: string,
    courierVehicleNumber?: string,
    courierType?: string,
    data?: DriverScoreCalculateData
  ) {
    this.id = id
    this.courierName = courierName
    this.courierOrigin = courierOrigin
    this.courierPhone = courierPhone
    this.courierVehicleNumber = courierVehicleNumber
    this.courierType = courierType
    this.data = data
  }
}
