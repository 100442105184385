import { injectable } from 'tsyringe';
import { WithdrawalRepositoryInterface } from '@/data/persistences/repositories/contracts/WithdrawalRepositoryInterface'
import { Withdrawal, Withdrawals } from '@/domain/entities/Withdrawal'
import { UpdateWithdrawalRequestInterface } from '@/data/payload/contracts/WithdrawalRequest'


@injectable()
export class WithdrawalPresenter {
  private repository: WithdrawalRepositoryInterface

  constructor(repository: WithdrawalRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, any>): Promise<Withdrawals> {
    return this.repository.getAll(params)
  }

  public get(withdrawalId: string, params: Map<string, any>): Promise<Withdrawal> {
    return this.repository.get(withdrawalId, params)
  }

  public update(
    withdrawalId: string,
    payload: UpdateWithdrawalRequestInterface
  ): Promise<boolean> {
    return this.repository.update(withdrawalId, payload)
  }
}
