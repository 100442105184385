import ApiService from '@/app/infrastructures/services/ApiService'
import {
  PublicVouchers,
  PublicVoucher,
  PublicVoucherHistories, PublicVoucherCodeMultipleExternalPartner,
} from '@/domain/entities/PublicVoucher'
import { PublicVoucherRepositoryInterface } from '@/data/persistences/repositories/contracts/PublicVoucherRepositoryInterface'
import {
  CreatePublicVoucherRequestInterface,
  UpdatePublicVoucherRequestInterface,
  DeletePublicVoucherRequestInterface,
} from '@/data/payload/contracts/PublicVoucherRequest'
import { PublicVoucherMapper } from '@/data/persistences/mappers/PublicVoucherMapper'
import {
  CreatePublicVoucherRequest,
  UpdatePublicVoucherRequest,
  DeletePublicVoucherRequest,
} from '@/data/payload/api/PublicVoucherRequest'
import { Endpoints } from '@/app/infrastructures/misc'

export class PublicVoucherApiRepository
  implements PublicVoucherRepositoryInterface {
  private service: ApiService
  private mapper: PublicVoucherMapper

  constructor(service: ApiService, mapper: PublicVoucherMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async createPublicVoucher(
    payload: CreatePublicVoucherRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.PUBLIC_VOUCHER,
      undefined,
      payload as CreatePublicVoucherRequest
    )

    return true
  }

  public async updatePublicVoucher(
    publicVoucherId: string,
    payload: UpdatePublicVoucherRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PUBLIC_VOUCHER}/${publicVoucherId}`,
      undefined,
      payload as UpdatePublicVoucherRequest
    )

    return true
  }

  public async deletePublicVoucher(
    publicVoucherId: string,
    payload: DeletePublicVoucherRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.PUBLIC_VOUCHER}/${publicVoucherId}`,
      undefined,
      payload as DeletePublicVoucherRequest
    )

    return true
  }

  public async getAllPublicVoucher(
    params: Map<string, unknown>
  ): Promise<PublicVouchers> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PUBLIC_VOUCHER,
      params
    )

    return this.mapper.convertPublicVouchersFromApi(resp)
  }

  public async getPublicVoucher(
    publicVoucherId: string
  ): Promise<PublicVoucher> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER}/${publicVoucherId}`
    )

    return this.mapper.convertPublicVoucherFromApi(resp)
  }

  public async getPublicVoucherHistories(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<PublicVoucherHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER}/${publicVoucherId}/history`,
      params
    )

    return this.mapper.convertPublicVoucherHistoriesFromApi(resp)
  }

  public async getPublicVoucherHistoriesV2(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<PublicVoucherHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER_V2}/${publicVoucherId}/history`,
      params
    )

    return this.mapper.convertPublicVoucherHistoriesFromApi(resp)
  }

  public async exportPublicVoucherHistories(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER}/${publicVoucherId}/history/export`,
      params
    )

    return resp.data.url
  }

  public async exportPublicVoucherHistoriesV2(
    publicVoucherId: string,
    params: Map<string, unknown>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER_V2}/${publicVoucherId}/history/export`,
      params
    )

    return resp.data.url
  }

  public async getPublicVoucherCodePartner (): Promise<PublicVoucherCodeMultipleExternalPartner[]> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PUBLIC_VOUCHER_PARTNER,
    )

    return this.mapper.convertPublicVoucherCodeExternalPartnerFromApi(resp)
  }

  public async getMultipleVouchers(params: Map<string, unknown>): Promise<PublicVoucherHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER}/history`,
      params
    )

    return this.mapper.convertPublicVoucherHistoriesFromApi(resp)
  }

  public async exportPublicMultipleVoucherHistories(params: Map<string, unknown>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUBLIC_VOUCHER}/history/export`,
      params
    )

    return resp.data.url
  }
}
