import { AxiosResponse } from 'axios'
import {
  ListAdjustmentParcelPoin,
  AdjustmentParcelPoin,
  ListHistoryLogPoinUser,
  HistoryLogPoinUser,
  DetailAdjustmentParcelPoin,
  HistoryUploadBulkyParcelPoin,
  dataHistoryUploadBulkyParcelPoin,
  UploadBulkyParcelPoin,
  ResultUploadBulkyRequestParcelPoin,
  DetailUploadBulkyRequestParcelPoin,
} from '@/domain/entities/AdjustmentParcelPoin'
import {
  DataHistoryUploadBulkyParcelPoinResponseInterface,
  DetailAdjustmentParcelPoinResponseInterface,
  DetailUploadBulkyRequestParcelPoinInterface,
  HistoryLogPoinResponseInterface,
  HistoryUploadBulkyResponseInterface,
  ListAdjustmentParcelPoinResponseInterface,
  PreviewUploadBulkyParcelPoinInterface,
} from '@/data/responses/contracts/AdjustmentParcelPoinResponseInterface'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class AdjustmentParcelPoinMapper {
  convertResponseListAdjustmentParcelPoinFromApi(
    result: AxiosResponse
  ): ListAdjustmentParcelPoin {
    const {
      data: { data, pagination },
    } = result

    return new ListAdjustmentParcelPoin(
      Utils.toInstance(new Pagination(), pagination),
      data.map((item: ListAdjustmentParcelPoinResponseInterface) => {
        return new AdjustmentParcelPoin(
          item.customer_id,
          item.phone_number,
          item.total_point,
          item.version,
          item.created_at
        )
      })
    )
  }

  convertResponseListHistoryLogPoinUserFromApi(
    result: AxiosResponse
  ): ListHistoryLogPoinUser {
    const {
      data: { pagination, phone_number, data },
    } = result

    return new ListHistoryLogPoinUser(
      Utils.toInstance(new Pagination(), pagination),
      phone_number,
      data.map((item: HistoryLogPoinResponseInterface) => {
        return new HistoryLogPoinUser(
          item.id,
          item.customer_id,
          item.delta,
          item.requester,
          item.approval,
          item.note,
          item.status,
          item.created_at,
          item.updated_at,
          item.phone_number,
        )
      })
    )
  }

  convertResponseDetailAdjustmentParcelPoinFromApi(
    result: AxiosResponse<DetailAdjustmentParcelPoinResponseInterface>
  ): DetailAdjustmentParcelPoin {
    const {
      data
    } = result

    return new DetailAdjustmentParcelPoin(
      data.point_history_id,
      data.delta,
      data.note,
      data.customer_phone_number,
    )
  }

  public convertHistoryUploadBulky(result: AxiosResponse): HistoryUploadBulkyParcelPoin {
    const { data, pagination } = <HistoryUploadBulkyResponseInterface>result.data

    return new HistoryUploadBulkyParcelPoin(
      data.map((val: DataHistoryUploadBulkyParcelPoinResponseInterface) => {
        return new dataHistoryUploadBulkyParcelPoin(
          val.id,
          val.filename,
          val.actor,
          val.failed_customer_ids,
          val.created_at,
        )
      }),
      new Pagination(
        pagination.page,
        pagination.per_page,
        pagination.total
      )
    )
  }

  public convertUploadBulkyFromApi(result: AxiosResponse): UploadBulkyParcelPoin {
    const {
      data, pagination, metadata
    } = <PreviewUploadBulkyParcelPoinInterface>result.data
    return new UploadBulkyParcelPoin(
      new ResultUploadBulkyRequestParcelPoin(
        metadata?.filename || '',
        Number(metadata?.total_valid_rows),
        Number(metadata?.total_invalid_rows),
        Number(metadata?.total_valid_amount_negative),
        Number(metadata?.total_valid_amount_positive),
        Number(metadata?.total_invalid_amount_negative),
        Number(metadata?.total_invalid_amount_positive)
      ),
      data?.map((val: DetailUploadBulkyRequestParcelPoinInterface | null) => {
        return new DetailUploadBulkyRequestParcelPoin(
          Number(val?.customer_id),
          Number(val?.amount),
          val?.note || '',
          val?.is_valid || false,
          val?.invalid_reason || '',
          Number(val?.row_number),
        )
      }),
      new Pagination(
        pagination.page,
        pagination.per_page,
        pagination.total,
      )
    )
  }
}
