import { Utils } from '@/app/infrastructures/misc'
import {
  Cancel,
  CancelDetail,
  CancelDetailData,
  Cancels,
  CourierShipmentCancels,
  Summary,
  CancelDetailHistoryLog,
  CancelDetailHistoryLogData,
} from '@/domain/entities/CancelValidation'
import { Pagination } from '@/domain/entities/Pagination'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class CancelValidationMapper extends BaseResponseMapper {
  public convertCancelListFromApi(result: AxiosResponse): Cancels {
    const {
      data: { data, pagination, summary },
    } = result

    return new Cancels(
      Utils.toInstance(new Pagination(), pagination),
      data.map((cancel: string) => Utils.toInstance(new Cancel(), cancel)),
      Utils.toInstance(new Summary(), summary)
    )
  }

  public convertCourierShipmentCancelListFromApi(
    result: AxiosResponse
  ): CourierShipmentCancels {
    const {
      data: { data, pagination, ...driver },
    } = result

    return new CourierShipmentCancels(
      <string>driver.courier_id,
      <string>driver.courier_name,
      <string>driver.phone_number,
      <string>driver.vehicle_type,
      <string>driver.vehicle_plate,
      <string>driver.partner_type,
      <string>driver.partner_name,
      <string>driver.origin,
      <string>driver.courier_type_valid,
      data.map((cancel: string) => Utils.toInstance(new Cancel(), cancel)),
      Utils.toInstance(new Pagination(), pagination)
    )
  }

  public convertCourierDetailFromApi(result: AxiosResponse): CancelDetail {
    const {
      data: {
        data: { detail, history_log },
      },
    } = result

    return new CancelDetail(
      Utils.toInstance(new CancelDetailData(), detail),
      new CancelDetailHistoryLog(
        history_log.data.map((history: string) =>
          Utils.toInstance(new CancelDetailHistoryLogData(), history)
        ),
        Utils.toInstance(new Pagination(), history_log.pagination)
      )
    )
  }

  public convertExportCancelListFromApi(result: AxiosResponse): string {
    const {
      data: { url },
    } = result

    return url
  }
}
