import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import {
  LoyaltyMembershipInfo,
  DetailLoyaltyProgramMembership,
  EnumStatusLoyaltyMembership,
  LoyaltyMembershipLevel,
  LoyaltyMembershipDateSchedule,
  LoyaltyMembershipHistory,
  ListLoyaltyProgramMembership
} from '@/domain/entities/LoyaltyProgramMembership'
import {
  LoyaltyMembershipInfoInterface,
  LoyaltyMembershipLevelInterface,
  LoyaltyMembershipHistoryInterface,
  LoyaltyMembershipDateScheduleInterface,
} from '@/data/responses/contracts/LoyaltyProgramMembership'
import { BaseListResponse } from '@/data/responses/contracts/BaseListResponse'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class LoyaltyProgramMembershipMapper extends BaseResponseMapper {
  public convertLoyaltyProgramMembershipAPI(
    result: AxiosResponse<BaseListResponse<LoyaltyMembershipInfoInterface[]>>
  ): ListLoyaltyProgramMembership {
    const { data } = result
    const listInfo: LoyaltyMembershipInfoInterface[] = data.data
    return new ListLoyaltyProgramMembership(
      new Pagination(data.pagination.page, data.pagination.per_page, data.pagination.total_item),
      listInfo.map((val: LoyaltyMembershipInfoInterface ) => this._setInfo(val))
    )
  }

  public convertDetailLoyaltyMembershipAPI(result: AxiosResponse): DetailLoyaltyProgramMembership {
    const { data } = result
    const info: LoyaltyMembershipInfoInterface = data.membership_program
    const levels: LoyaltyMembershipLevelInterface[] = data.membership_program_levels || []
    const dateSchedules: LoyaltyMembershipDateScheduleInterface[] = data.membership_program_date_schedules || []
    const histories: LoyaltyMembershipHistoryInterface[] = data.membership_program_histories || []

    return new DetailLoyaltyProgramMembership(
      this._setInfo(info),
      this._setLevels(levels),
      this._setSchedules(dateSchedules),
      this._setHistories(histories),
    )
  }

  private _setInfo(info: LoyaltyMembershipInfoInterface): LoyaltyMembershipInfo {
    return new LoyaltyMembershipInfo(
      info.id,
      info.start_date,
      info.end_date,
      Utils.setListToIgnoreEmpty(info.prefix_stt_shipments || info.prefix_stt),
      Utils.setListToIgnoreEmpty(info.prefix_shipment_ids || info.prefix_shipment_id),
      Utils.setListToIgnoreEmpty(info.package_statuses || info.package_status),
      <EnumStatusLoyaltyMembership>info.status,
      info.is_include_stt_non_ca,
      info.backward_month_period,
      info.created_at,
      info.updated_at,
      info.created_by,
      info.updated_by,
      info.membership_level_name,
    )
  }

  private _setLevels(levels: LoyaltyMembershipLevelInterface[]): LoyaltyMembershipLevel[] {
    return levels.map((val: LoyaltyMembershipLevelInterface) => {
      return new LoyaltyMembershipLevel(
        val.id,
        val.level_name,
        val.min_amount_transaction,
        val.min_number_transaction,
      )
    })
  }

  private _setSchedules(dateSchedules: LoyaltyMembershipDateScheduleInterface[]): LoyaltyMembershipDateSchedule[] {
    return dateSchedules.map((val: LoyaltyMembershipDateScheduleInterface) => {
      return new LoyaltyMembershipDateSchedule(
        val.id,
        val.date,
        val.month,
      )
    })
  }

  private _setHistories(histories: LoyaltyMembershipHistoryInterface[]): LoyaltyMembershipHistory[] {
    return histories.map((val: LoyaltyMembershipHistoryInterface) => {
      return new LoyaltyMembershipHistory(
        val.id,
        val.status,
        val.remarks,
        val.created_at,
        val.updated_at,
      )
    })
  }
}
