import { AxiosResponse } from 'axios'
import { POS, POSBulkResponse, POSes, Subdistrict } from '@/domain/entities/POS'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'

export class POSMapper extends BaseResponseMapper {
  public convertPOSBulkResponseFromAPI(
    result: AxiosResponse
  ): POSBulkResponse {
    const {
      data: { data },
    } = result

    return new POSBulkResponse(
      data.total_rows,
      data.failed_rows,
      data.failed_messages
    )
  }

  public convertPOSFromApi(result: AxiosResponse): POS {
    const { data } = result
    return new POS(
      data.agent_id,
      data.name,
      data.address,
      data.sub_district_name,
      data.phone,
      data.is_subscription,
      data.status,
      data.agent_code,
      data.sub_district_id,
      data.letter_code,
      data.binding_code,
      data.location,
      data.created_at,
      data.updated_at,
      data.created_by,
      data.updated_by,
      data.qr_code
    )
  }

  public convertPOSesFromApi(result: AxiosResponse): POSes {
    const {
      data: { data, pagination },
    } = result
    return new POSes(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (pos: Record<string, any>) =>
          new POS(
            pos.agent_id,
            pos.name,
            pos.address,
            pos.sub_district_name,
            pos.phone,
            pos.is_subscription,
            pos.status,
            pos.agent_code,
            pos.sub_district_id,
            pos.letter_code,
            pos.binding_code,
            pos.location,
            pos.created_at,
            pos.updated_at,
            pos.created_by,
            pos.updated_by,
            pos.qr_code
          )
      )
    )
  }

  public convertSubdistrictsFromApi(result: AxiosResponse): Subdistrict[] {
    const {
      data: { data },
    } = result
    return data.map(
      (pos: Record<string, any>) =>
        new Subdistrict(pos.sub_district_id, pos.sub_district_name)
    )
  }
}
