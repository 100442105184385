import { injectable } from 'tsyringe'

import { PickupRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupRepositoryInterface'
import { NearbyCourier, PickupAdditionalTimes, PickupCorporates, PickupCorporateSummary, PickupCRROTWs, PickupCRRQUEs, PickupDetail, PickupDetailSnapshot, PickupForceDetail, Pickups, PickupSummary  } from '@/domain/entities/Pickup'
import {
  CancelPickupRequestInterface,
  ExportPickupRequestInterface,
  ManualAssigneeRequestInterface,
  MarkAsDoneRequestInterface,
  PickupDedicatedCRROTWRequestInterface,
  PickupForceCRRCNCRequestInterface,
} from '@/data/payload/contracts/PickupRequest'
import { ForceCancelCRRQUERequest, ForceCRRSRCRequest, UpdateIsContactedCRROTWRequest, UpdateIsContactedCRRQUERequest } from '@/data/payload/api/PickupRequest'

@injectable()
export class PickupPresenter {
  private repository: PickupRepositoryInterface

  constructor(repository: PickupRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number>): Promise<Pickups> {
    return this.repository.getAll(params)
  }

  public get(shipmentId: string): Promise<PickupDetail> {
    return this.repository.get(shipmentId)
  }

  public getBulk(params: Map<string, string | number>): Promise<PickupDetail[]> {
    return this.repository.getBulk(params)
  }

  public getPickupCRRNFD(params: Map<string, string | number>): Promise<Pickups> {
    return this.repository.getListCRRNFD(params)
  }

  public getPickupCRRDON(params: Map<string, string | number>): Promise<Pickups> {
    return this.repository.getListCRRDON(params)
  }

  public getPickupDetail(params: Map<string, string | number>): Promise<PickupForceDetail> {
    return this.repository.getPickupDetail(params)
  }

  public getSnapshot(shipmentId: string): Promise<PickupDetailSnapshot[]> {
    return this.repository.getSnapshot(shipmentId)
  }

  public getSummary(params: Map<string, string>): Promise<PickupSummary> {
    return this.repository.getSummary(params)
  }

  public export(payload: ExportPickupRequestInterface): Promise<string[]> {
    return this.repository.export(payload)
  }

  public exportCRRNFD(params: Map<string, string>): Promise<string> {
    return this.repository.exportCRRNFD(params)
  }

  public getNearbyCourier(params: Map<string, number>): Promise<NearbyCourier[]> {
    return this.repository.getNearbyCourier(params)
  }

  public manualAssignee(
    payload: ManualAssigneeRequestInterface
  ): Promise<boolean> {
    return this.repository.manualAssignee(payload)
  }

  public markAsDone(
    payload: MarkAsDoneRequestInterface,
    group: boolean
  ): Promise<boolean> {
    return this.repository.markAsDone(payload, group)
  }

  public cancelPickup(payload: CancelPickupRequestInterface): Promise<boolean> {
    return this.repository.cancelPickup(payload)
  }

  public pickupDedicatedCRROTW(payload: PickupDedicatedCRROTWRequestInterface): Promise<boolean> {
    return this.repository.pickupDedicatedCRROTW(payload)
  }

  public getPickupAdditionalTime(params: Map<string, string>): Promise<PickupAdditionalTimes> {
    return this.repository.getPickupAdditionalTime(params)
  }

  public exportPickupList(params: Map<string, string>): Promise<string> {
    return this.repository.exportPickupList(params)
  }

  public getListCRROTW(params: Map<string, string>): Promise<PickupCRROTWs> {
    return this.repository.getListCRROTW(params)
  }

  public forceCRRCNC(payload: PickupForceCRRCNCRequestInterface): Promise<boolean> {
    return this.repository.forceCRRCNC(payload)
  }

  public updateIsContactedCRROTW(taskId: number, payload: UpdateIsContactedCRROTWRequest): Promise<boolean> {
    return this.repository.updateIsContactedCRROTW(taskId, payload)
  }

  public exportCRROTW(params: Map<string, string>): Promise<string> {
    return this.repository.exportCRROTW(params)
  }

  public forceCRRSRC(payload: ForceCRRSRCRequest): Promise<boolean> {
    return this.repository.forceCRRSRC(payload)
  }

  public getListCRRQUE(params: Map<string, string | number>): Promise<PickupCRRQUEs> {
    return this.repository.getListCRRQUE(params)
  }

  public forceCancelCRRQUE(payload: ForceCancelCRRQUERequest): Promise<boolean> {
    return this.repository.forceCancelCRRQUE(payload)
  }

  public updateIsContactedCRRQUE(shipmentId: string, payload: UpdateIsContactedCRRQUERequest): Promise<boolean> {
    return this.repository.updateIsContactedCRRQUE(shipmentId, payload)
  }

  public exportCRRQUE(params: Map<string ,string | number>): Promise<string> {
    return this.repository.exportCRRQUE(params)
  }

  public exportManualAssignLog(params: Map<string, string>): Promise<string> {
    return this.repository.exportManualAssignLog(params)
  }

  public getListCorporate(params: Map<string, string | number>): Promise<PickupCorporates> {
    return this.repository.getListCorporate(params);
  }

  public getCorporateSummary(): Promise<PickupCorporateSummary> {
    return this.repository.getCorporateSummary();
  }
}
