export const HEADER_REQUEST_TOPUP_HISTORY = [
  {
    title: 'Date',
    customStyle: { maxWidth: '220px' },
  },
  {
    title: 'Saldo Top-up',
    customStyle: { minWidth: '125px', maxWidth: '125px' },
  },
  {
    title: 'Current Balance',
    customStyle: { minWidth: '140px', maxWidth: '140px' },
  },
  {
    title: 'Requestor',
    customStyle: { minWidth: '140px', maxWidth: '140px' },
  },
  {
    title: 'Approval',
    customStyle: { minWidth: '140px', maxWidth: '140px' },
  },
  {
    title: 'Notes',
    customStyle: { minWidth: '155px', maxWidth: '155px' },
  },
  {
    title: 'Status',
    customStyle: { minWidth: '110px', maxWidth: '110px' },
  },
]

export const MODAL_CONFIRM_REQUEST_TOPUP = {
  title: 'Confirmation Saldo',
  description: 'Are you sure the saldo is correct?',
}

export const MODAL_CONFIRM_APROVING_TOPUP = {
  title: 'Confirmation Top Up Saldo',
  description: 'Are you sure the topup saldo is correct?',
}

export const MODAL_SUCCESS_REQUEST_TOPUP = {
  title: 'Saldo Successfully Requested',
  description: `Requested saldo has been sent to the user account according to your request`,
}

export const MODAL_REJECT_REQUEST_TOPUP = {
  title: 'Are you sure you want to reject this request?',
  subtitle: 'Reason to Reject',
  message: 'Must between 10 and 90 characters long',
  hint: 'Once you reject, to will be informed to the user',
}

export const MODAL_SUCCESS_APPROVE_TOPUP = {
  title: 'Saldo Successfully Approved',
  subtitle: 'Saldo has been sent to the user account according to your request',
}

export const MODAL_SUCCESS_REJECT_TOPUP = {
  title: 'Saldo Successfully Rejected',
  subtitle: 'You have successfully denied this request',
}

export enum EnumTypeBalanceStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum EnumTypeAnotherBalanceStatus {
  REQUESTED = 'REQUESTED',
}

export const AdjustmentStatus = {
  ...EnumTypeBalanceStatus,
  ...EnumTypeAnotherBalanceStatus,
}

export const MODAL_CONFIRM_REQUEST_POIN = {
  title: 'Confirmation Poin Parcel',
  description: 'Are you sure the poin is correct?',
}

export const MODAL_SUCCESS_REQUEST_POIN = {
  title: 'Poin Parcel Successfully Requested',
  description:
    'Requested poin has been sent to the user account according to your request',
}

export enum EnumTransactionParcelPoinRequest {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export const MODAL_REJECT_REQUEST_POIN = {
  title: 'Are you sure you want to refuse approve poin?',
  subtitle: 'Reason to Reject',
  message: 'Must between 10 and 90 characters long',
  hint: 'Once you reject, to will be informed to the user',
  title_bulk: 'Are you sure you want to refuse approve adjustment parcel poin?',
}

export const MODAL_SUCCESS_APPROVE_POIN = {
  title: 'Parcel Poin Successfully Approved',
  subtitle: 'Poin has been sent to the user account according to your request',
}

export const MODAL_SUCCESS_REJECT_POIN = {
  title: 'Parcel Poin Successfully Rejected',
  subtitle: 'You have successfully denied this request',
}

export enum EnumFilterStatusPreviewUploadBulky {
  ALL = '',
  VALID = 'VALID',
  INVALID = 'INVALID',
}

export enum EnumTopUpBalanceType {
  PLUS = 'CREDIT',
  MINUS = 'DEBIT',
}

export enum EnumBalanceID {
  TOP_UP = "TOP_UP",
  DEDUCT = "DEDUCT",
}
