import ApiService from '@/app/infrastructures/services/ApiService'
import { AxiosResponse } from 'axios'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import {
  PickupLocations,
  PickupLocation,
} from '@/domain/entities/PickupLocation'
import { PickupLocationMapper } from '@/data/persistences/mappers/PickupLocationMapper'
import {
  PickupLocationResponseInterface,
  PaginationResponseInterface,
} from '@/data/responses/contracts/PickupLocationResponseInterface'
import { PickupLocationActivateOrDeactivateRequestInterface } from '@/data/payload/contracts/PickupLocationRequest'
import { PickupLocationRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupLocationRepositoryInterface'

export class PickupLocationApiRepository
  implements PickupLocationRepositoryInterface {
  private http: ApiService
  private mapper: PickupLocationMapper

  constructor(http: ApiService, mapper: PickupLocationMapper) {
    this.http = http
    this.mapper = mapper
  }

  public async getPickupLocations(
    params: Map<string, string | number>
  ): Promise<PickupLocations> {
    const resp = <
      AxiosResponse<{
        pagination: PaginationResponseInterface
        data: PickupLocationResponseInterface
      }>
    >(
			await this.http.invoke('get', `${Endpoints.Route.PICKUP_LOCATION}`, params)
		)
    return this.mapper.convertPickupLocationResponseFromAPI(resp)
  }

  public async getPickupLocation(id: string): Promise<PickupLocation> {
    const resp = <AxiosResponse<PickupLocationResponseInterface>>(
      await this.http.invoke('get', `${Endpoints.Route.PICKUP_LOCATION}/${id}`)
    )
    return this.mapper.convertDetailLocationResponseFromAPI(resp)
  }

  public async activateOrDeactivatePickupLocation(
    payload: PickupLocationActivateOrDeactivateRequestInterface
  ): Promise<boolean> {
    await this.http.invoke(
      'put',
      `${Endpoints.Route.PICKUP_LOCATION}/activate-deactivate`,
      undefined,
      payload
    )
    return true
  }

  public async getCity(): Promise<Array<string>> {
    const resp = <AxiosResponse<Array<string>>>(
      await this.http.invoke(
        'get',
        `${Endpoints.Route.PICKUP_LOCATION}/cities`
      )
    )
    return resp.data
  }
}
