import ApiService from '@/app/infrastructures/services/ApiService'

import { Endpoints } from '@/app/infrastructures/misc'

import { SaldoPaymentRepositoryInterface } from '@/data/persistences/repositories/contracts/SaldoPaymentRepositoryInterface'
import { SaldoPaymentMapper } from '@/data/persistences/mappers/SaldoPaymentMapper'
import { SaldoPayments } from '@/domain/entities/SaldoPayment'

export class SaldoPaymentApiRepository
  implements SaldoPaymentRepositoryInterface {
  private service: ApiService
  private mapper: SaldoPaymentMapper

  constructor(service: ApiService, mapper: SaldoPaymentMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, any>): Promise<SaldoPayments> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYMENT_SALDO}/data`,
      params
    )

    return this.mapper.convertSaldoPaymentsFromApi(resp)
  }

  public async export(params: Map<string, any>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYMENT_SALDO}/export`,
      params
    )
    return resp.data.url
  }
}
