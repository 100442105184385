import {Pagination} from '@/domain/entities/Pagination'

export class dataHistoryUploadBulky {
  public id: string
  public fileId: string
  public filename: string
  public email: string
  public createdAt: string

  constructor(
    id: string,
    fileId: string,
    filename: string,
    email: string,
    createdAt: string
  ) {
    this.id = id
    this.fileId = fileId
    this.filename = filename
    this.email = email
    this.createdAt = createdAt
  }
}

export class HistoryUploadBulkyVoucherConfig {
  public data: Array<dataHistoryUploadBulky>
  public pagination: Pagination

  constructor(
    data: Array<dataHistoryUploadBulky>,
    pagination: Pagination
  ) {
    this.data = data
    this.pagination = pagination
  }
}
