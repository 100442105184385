import { UpdateLoyaltyProgramGetPointRules } from '@/data/payload/api/LoyaltyProgramGetPointRules'
import { LoyaltyProgramGetPointRulesInterface } from '@/data/persistences/repositories/contracts/LoyaltyProgramGetPointRulesInterface'
import { LoyaltyProgramGetPointRules } from '@/domain/entities/LoyaltyProgramGetPointRules'
import { injectable } from 'tsyringe'

@injectable()
export class LoyaltyGetPointRulesPresenter {
  private repository: LoyaltyProgramGetPointRulesInterface

  constructor(repository: LoyaltyProgramGetPointRulesInterface) {
    this.repository = repository
  }

  public get(): Promise<LoyaltyProgramGetPointRules> {
    return this.repository.get()
  }

  public update(payload: UpdateLoyaltyProgramGetPointRules): Promise<boolean> {
    return this.repository.update(payload)
  }
}
