import { injectable } from 'tsyringe'
import {
  ForgotPasswordRequestInterface,
  ForgotPasswordVerifyRequestInterface,
  LoginRequestInterface,
  ResetPasswordRequestInterface,
} from '@/data/payload/contracts/AuthRequest'
import { AuthRepositoryInterface } from '@/data/persistences/repositories/contracts/AuthRepositoryInterface'
import { Account, Token } from '@/domain/entities/Account'

@injectable()
export class AuthPresenter {
  private repository: AuthRepositoryInterface

  constructor(repository: AuthRepositoryInterface) {
    this.repository = repository
  }

  public getMe(): Promise<Account> {
    return this.repository.getMe()
  }

  public onLogin(payload: LoginRequestInterface): Promise<Token> {
    return this.repository.login(payload)
  }

  public forgotPassword(
    payload: ForgotPasswordRequestInterface
  ): Promise<boolean> {
    return this.repository.forgotPassword(payload)
  }

  public forgotPasswordVerify(
    payload: ForgotPasswordVerifyRequestInterface
  ): Promise<boolean> {
    return this.repository.forgotPasswordVerify(payload)
  }

  public resetPassword(
    payload: ResetPasswordRequestInterface
  ): Promise<boolean> {
    return this.repository.resetPassword(payload)
  }
}
