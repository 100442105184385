import ApiService from '@/app/infrastructures/services/ApiService'

import { Endpoints } from '@/app/infrastructures/misc'

import { ExportDataRepositoryInterface } from '@/data/persistences/repositories/contracts/ExportDataRepositoryInterface'

export class ExportDataApiRepository implements ExportDataRepositoryInterface {
  private service: ApiService

  constructor(service: ApiService) {
    this.service = service
  }

  public async export(params: Map<string, any>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.V3INVOICE}/export`,
      params
    )
    return resp.data.url
  }
}
