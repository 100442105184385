import { Pagination } from '@/domain/entities/Pagination'

interface DropdownValue {
  value: string
  label: string
}

export class MerchantDetail {
  public id?: number
  public name?: string
  public brand?: DropdownValue
  public city?: DropdownValue
  public district?: DropdownValue
  public description?: string
  public address?: string
  public latitude?: string | number
  public longitude?: string | number
  public imageBannerUrl?: string
  public bank?: string
  public bankAccountName?: string
  public bankAccountNumber?: string
  public accountName?: string
  public accountEmail?: string
  public accountPhone?: string
  public createdAt?: string
  public status?: string

  constructor(
    id?: number,
    name?: string,
    brand?: DropdownValue,
    city?: DropdownValue,
    district?: DropdownValue,
    description?: string,
    address?: string,
    latitude?: string | number,
    longitude?: string | number,
    imageBannerUrl?: string,
    bank?: string,
    bankAccountName?: string,
    bankAccountNumber?: string,
    accountName?: string,
    accountEmail?: string,
    accountPhone?: string,
    createdAt?: string,
    status?: string
  ) {
    this.id = id
    this.name = name
    this.brand = brand
    this.city = city
    this.district = district
    this.description = description
    this.address = address
    this.latitude = latitude
    this.longitude = longitude
    this.imageBannerUrl = imageBannerUrl
    this.bank = bank
    this.bankAccountName = bankAccountName
    this.bankAccountNumber = bankAccountNumber
    this.accountName = accountName
    this.accountEmail = accountEmail
    this.accountPhone = accountPhone
    this.createdAt = createdAt
    this.status = status
  }
}

export class Merchant {
  public id?: number
  public name?: string
  public brand?: string
  public description?: string
  public city?: string
  public createdAt?: string
  public status?: string

  constructor(
    id?: number,
    name?: string,
    brand?: string,
    description?: string,
    city?: string,
    createdAt?: string,
    status?: string
  ) {
    this.id = id
    this.name = name
    this.brand = brand
    this.city = city
    this.description = description
    this.createdAt = createdAt
    this.status = status?.replace('suspend', 'suspended')
  }
}

export class Merchants {
  public pagination?: Pagination
  public data?: Merchant[]

  constructor(pagination?: Pagination, data?: Merchant[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class MerchantDropdown {
  merchantID!: number
  merchantName!: string
  status!: string

  constructor(merchantID: number, merchantName: string, status: string) {
    this.merchantID = merchantID
    this.merchantName = merchantName
    this.status = status
  }

}
