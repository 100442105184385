import { Pagination } from './Pagination'

export class ManageCourierDedicateds {
  public pagination?: Pagination
  public data?: ManageCourierDedicated[]

  constructor(pagination?: Pagination, data?: ManageCourierDedicated[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageCourierDedicated {
  public areaId?: number
  public areaName?: string
  public geoAddress?: Array<GeoAddressDedicated>
  public courierMp?: Array<CourierDedicated>
  public courierCa?: Array<CourierDedicated>
  public courierClient?: Array<CourierDedicated>
  public offerType?: string
  public isActive?: boolean
  public createdAt?: Date
  public updatedAt?: Date

  constructor(
    areaId?: number,
    areaName?: string,
    geoAddress?: Array<GeoAddressDedicated>,
    courierMp?: Array<CourierDedicated>,
    courierCa?: Array<CourierDedicated>,
    courierClient?: Array<CourierDedicated>,
    offerType?: string,
    isActive?: boolean,
    createdAt?: Date,
    updatedAt?: Date
  ) {
    this.areaId = areaId
    this.areaName = areaName
    this.geoAddress = geoAddress
    this.courierMp = courierMp
    this.courierCa = courierCa
    this.courierClient = courierClient
    this.offerType = offerType
    this.isActive = isActive
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export class GeoAddressDedicated {
  public id?: number
  public province?: string
  public district?: string
  public subDistrict?: string
  public village?: string

  constructor(
    id?: number,
    province?: string,
    district?: string,
    subDistrict?: string,
    village?: string
  ) {
    this.id = id
    this.province = province
    this.district = district
    this.subDistrict = subDistrict
    this.village = village
  }
}

export class CourierDedicated {
  public courierId?: number
  public name?: string
  public courierType?: string
  public phone?: string
  public partnerName?: string

  constructor(
    courierId?: number,
    name?: string,
    courierType?: string,
    phone?: string,
    partnerName?: string
  ) {
    this.courierId = courierId
    this.name = name
    this.courierType = courierType
    this.phone = phone
    this.partnerName = partnerName
  }
}

export class GeoAddress {
  public name?: string
  public children?: Array<GeoAddress>
  public geoAddressId?: number
  public areaId?: number

  constructor(
    name?: string,
    children?: Array<GeoAddress>,
    geoAddressId?: number,
    areaId?: number
  ) {
    this.name = name
    this.children = children

    if (geoAddressId) {
      this.geoAddressId = geoAddressId
    }

    if (areaId) {
      this.areaId = areaId
    }
  }
}

export class ManageCourierDedicatedDetail {
  public areaId?: number
  public areaName?: string
  public geoAddressIds?: Array<number>
  public courierMp?: Array<CourierItem>
  public courierCa?: Array<CourierItem>
  public courierCorporate?: Array<CourierItem>
  public offerType?: string
  public isActive?: boolean
  public createdAt?: string
  public updatedAt?: string
  public createdBy?: string
  public updatedBy?: string

  constructor(
    areaId?: number,
    areaName?: string,
    geoAddressIds?: Array<number>,
    courierMp?: Array<CourierItem>,
    courierCa?: Array<CourierItem>,
    courierCorporate?: Array<CourierItem>,
    offerType?: string,
    isActive?: boolean,
    createdAt?: string,
    updatedAt?: string,
    createdBy?: string,
    updatedBy?: string
  ) {
    this.areaId = areaId
    this.areaName = areaName
    this.geoAddressIds = geoAddressIds
    this.courierMp = courierMp
    this.courierCa = courierCa
    this.courierCorporate = courierCorporate
    this.offerType = offerType
    this.isActive = isActive
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.createdBy = createdBy
    this.updatedBy = updatedBy
  }
}

export class CourierItem {
[x: string]: any
  public courierId?: number
  public name?: string
  public courierType?: string
  public phone?: string
  public partnerName?: string

  constructor(
    courierId?: number,
    name?: string,
    courierType?: string,
    phone?: string,
    partnerName?: string
  ) {
    this.courierId = courierId
    this.name = name
    this.courierType = courierType
    this.phone = phone
    this.partnerName = partnerName
  }
}

export class GeoAddressProvince {
  public proviceName?: string
  public district?: GeoAddressDistrict[]

  constructor(
    proviceName?: string,
    district?: GeoAddressDistrict[],
  ) {
    this.proviceName = proviceName
    this.district = district
  }
}

export class GeoAddressDistrict {
  public districtName?: string
  public subDistrict?: GeoAddressSubDistrict[]

  constructor(
    districtName?: string,
    subDistrict?: GeoAddressSubDistrict[],
  ) {
    this.districtName = districtName
    this.subDistrict = subDistrict
  }
}

export class GeoAddressSubDistrict {
  public subDistrictName?: string
  public village?: GeoAddressVillage[]

  constructor(
    subDistrictName?: string,
    village?: GeoAddressVillage[],
  ) {
    this.subDistrictName = subDistrictName
    this.village = village
  }
}

export class GeoAddressVillage {
  public geoAddressId?: number
  public villageName?: string
  public areaId?: number

  constructor(
    geoAddressId?: number,
    villageName?: string,
    areaId?: number,
  ) {
    this.geoAddressId = geoAddressId
    this.villageName = villageName
    this.areaId = areaId
  }
}

export class CourierDedicatedList {
  public pagination?: Pagination
  public data?: any

  constructor(
    pagination: Pagination,
    data: Array<CourierDedicatedListItem>
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class CourierDedicatedListItem {
  public courierId?: number
  public courierName?: string
  public courierType?: string
  public courierPhone?: string
  public partnerName?: string
  public areaDedicatedMP?: Array<CourierDedicatedArea>
  public areaDedicatedCA?: Array<CourierDedicatedArea>
  public areaDedicatedClient?: Array<CourierDedicatedArea>

  constructor (
    courierId?: number,
    courierName?: string,
    courierType?: string,
    courierPhone?: string,
    partnerName?: string,
    areaDedicatedMP?: Array<CourierDedicatedArea>,
    areaDedicatedCA?: Array<CourierDedicatedArea>,
    areaDedicatedClient?: Array<CourierDedicatedArea>,
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.courierType = courierType
    this.courierPhone = courierPhone
    this.partnerName = partnerName
    this.areaDedicatedMP = areaDedicatedMP
    this.areaDedicatedCA = areaDedicatedCA
    this.areaDedicatedClient = areaDedicatedClient
  }
}

export class CourierDedicatedArea {
  public areaId?: number
  public areaName?: string

  constructor (
    areaId?: number,
    areaName?: string
  ) {
    this.areaId = areaId
    this.areaName = areaName
  }
}
