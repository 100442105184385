import { Pagination } from './Pagination'

export class ManageDataPayrolls {
  public pagination?: Pagination
  public data?: ManageDataPayroll[]

  constructor(pagination?: Pagination, data?: ManageDataPayroll[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageDataPayroll {
  public courierId?: number
  public courierName?: string
  public phoneNumber?: string
  public balance?: number
  public isActive?: boolean

  constructor(
    courier_id?: number,
    courier_name?: string,
    phone_number?: string,
    balance?: number,
    is_active?: boolean
  ) {
    this.courierId = courier_id
    this.courierName = courier_name
    this.phoneNumber = phone_number
    this.balance = balance
    this.isActive = is_active
  }
}

export class ManageDataPayrollDetails {
  public pagination?: Pagination
  public data?: ManageDataPayrollDetail[]

  constructor(pagination?: Pagination, data?: ManageDataPayrollDetail[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageDataPayrollDetail {
  public mode?: string
  public shipmentId?: string
  public packageId?: string
  public createdAt?: Date
  public amount?: number
  public hasAdjustment?: boolean

  constructor(
    mode?: string,
    shipment_id?: string,
    package_id?: string,
    created_at?: Date,
    amount?: number,
    has_adjustment?: boolean
  ) {
    this.mode = mode
    this.shipmentId = shipment_id
    this.packageId = package_id
    this.createdAt = created_at
    this.amount = amount
    this.hasAdjustment = has_adjustment
  }
}

export class ManageDataPayrollHistories {
  public pagination?: Pagination
  public data?: ManageDataPayrollHistory[]

  constructor(pagination?: Pagination, data?: ManageDataPayrollHistory[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ManageDataPayrollHistory {
  public withdrawalId?: number
  public createdAt?: Date
  public amount?: number
  public bankName?: string
  public adminFee?: number
  public received?: number
  public status?: string
  public description?: string

  constructor(
    withdrawal_id?: number,
    created_at?: Date,
    amount?: number,
    bank_name?: string,
    admin_fee?: number,
    received?: number,
    status?: string,
    description?: string
  ) {
    this.withdrawalId = withdrawal_id
    this.createdAt = created_at
    this.amount = amount
    this.bankName = bank_name
    this.adminFee = admin_fee
    this.received = received
    this.status = status
    this.description = description
  }
}
