import {
  CreateBrandRequest,
  UpdateBrandRequest,
  UpdateBrandStatusRequest,
} from '@/data/payload/api/BrandRequest'
import {
  CreateBrandRequestInterface,
  UpdateBrandRequestInterface,
  UpdateBrandStatusRequestInterface,
} from '@/data/payload/contracts/BrandRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { BrandRepositoryInterface } from '@/data/persistences/repositories/contracts/BrandRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { BrandMapper } from '@/data/persistences/mappers/BrandDataMapper'
import { Brand, Brands } from '@/domain/entities/Brand'

export class BrandApiRepository implements BrandRepositoryInterface {
  private service: ApiService
  private mapper: BrandMapper

  constructor(service: ApiService, mapper: BrandMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(brandId: string): Promise<Brand> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.BRAND}/${brandId}`
    )
    return this.mapper.convertBrandFromApi(resp)
  }

  public async getAll(params: Map<string, string | number | undefined>): Promise<Brands> {
    const resp = await this.service.invoke('get', Endpoints.Route.BRAND, params)
    return this.mapper.convertBrandsFromApi(resp)
  }

  public async create(payload: CreateBrandRequestInterface): Promise<Brand> {
    const resp = await this.service.invoke(
      'post',
      Endpoints.Route.BRAND,
      undefined,
      payload as CreateBrandRequest
    )
    return this.mapper.convertBrandFromApi(resp)
  }

  public async update(
    brandId: number,
    payload: UpdateBrandRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.BRAND}/${brandId}`,
      undefined,
      payload as UpdateBrandRequest
    )
    return true
  }

  public async updateStatus(
    brandId: number,
    payload: UpdateBrandStatusRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.BRAND}/${brandId}`,
      undefined,
      payload as UpdateBrandStatusRequest
    )
    return true
  }
}
