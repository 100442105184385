import { AxiosResponse } from 'axios'
import { Brand, Brands } from '@/domain/entities/Brand'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'

export class BrandMapper extends BaseResponseMapper {
  public convertBrandFromApi(result: AxiosResponse<any>): Brand {
    const { data } = result
    return new Brand(
      data.id,
      data.name,
      data.image_url,
      data.active
    )
  }

  public convertBrandsFromApi(result: AxiosResponse<any>): Brands {
    const {
      data: { data, pagination },
    } = result
    return new Brands(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (brand: Record<string, any>) =>
          new Brand(
            brand.id,
            brand.name,
            brand.image_url,
            brand.active
          )
      )
    )
  }
}
