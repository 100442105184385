import { Pagination } from './Pagination'

export class AdjustmentParcelPoin {
  public customerId?: number
  public phoneNumber?: string
  public totalPoint?: number
  public version?: number
  public createdAt?: string

  constructor(
    customerId?: number,
    phoneNumber?: string,
    totalPoint?: number,
    version?: number,
    createdAt?: string
  ) {
    this.customerId = customerId
    this.phoneNumber = phoneNumber
    this.totalPoint = totalPoint
    this.version = version
    this.createdAt = createdAt
  }
}

export class HistoryLogPoinUser {
  public id?: number
  public customerId?: number
  public delta?: number
  public requester?: string
  public approval?: string
  public note?: string
  public status?: string
  public createdAt?: string
  public updatedAt?: string
  public phoneNumber?: string

  constructor(
    id?: number,
    customerId?: number,
    delta?: number,
    requester?: string,
    approval?: string,
    note?: string,
    status?: string,
    createdAt?: string,
    updatedAt?: string,
    phoneNumber?: string
  ) {
    this.id = id
    this.customerId = customerId
    this.delta = delta
    this.requester = requester
    this.approval = approval
    this.note = note
    this.status = status
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.phoneNumber = phoneNumber
  }
}

export class ListAdjustmentParcelPoin {
  public pagination?: Pagination
  public data?: Array<AdjustmentParcelPoin>

  constructor(pagination?: Pagination, data?: Array<AdjustmentParcelPoin>) {
    this.pagination = pagination
    this.data = data
  }
}

export class ListHistoryLogPoinUser {
  public pagination?: Pagination
  public phoneNumber?: string
  public data?: Array<HistoryLogPoinUser>

  constructor(
    pagination?: Pagination,
    phoneNumber?: string,
    data?: Array<HistoryLogPoinUser>
  ) {
    this.pagination = pagination
    this.phoneNumber = phoneNumber
    this.data = data
  }
}

export class DetailAdjustmentParcelPoin {
  public id?: number
  public delta?: number
  public note?: string
  public phoneNumber?: string

  constructor(
    id?: number,
    delta?: number,
    note?: string,
    phoneNumber?: string
  ) {
    this.id = id
    this.delta = delta
    this.note = note
    this.phoneNumber = phoneNumber
  }
}

export class dataHistoryUploadBulkyParcelPoin {
  public id: string
  public filename: string
  public actor: string
  public failedCustomerIDs: Array<number>
  public createdAt: string

  constructor(
    id: string,
    filename: string,
    actor: string,
    failedCustomerIDs: Array<number>,
    createdAt: string
  ) {
    this.id = id
    this.filename = filename
    this.actor = actor
    this.failedCustomerIDs = failedCustomerIDs
    this.createdAt = createdAt
  }
}

export class HistoryUploadBulkyParcelPoin {
  public data: Array<dataHistoryUploadBulkyParcelPoin>
  public pagination: Pagination

  constructor(
    data: Array<dataHistoryUploadBulkyParcelPoin>,
    pagination: Pagination
  ) {
    this.data = data
    this.pagination = pagination
  }
}

export class DetailUploadBulkyParcelPoin {
  public customerID: number
  public totalSaldo: number
  public note: string
  public isValid: boolean
  public reasonInvalid?: string
  public rowNumber?: number

  constructor(
    customerID: number,
    totalSaldo: number,
    note: string,
    isValid: boolean,
    reasonInvalid?: string,
    rowNumber?: number
  ) {
    this.customerID = customerID
    this.totalSaldo = totalSaldo
    this.note = note
    this.isValid = isValid
    this.reasonInvalid = reasonInvalid
    this.rowNumber = rowNumber
  }
}

export class UploadBulkyParcelPoin {
  public result: ResultUploadBulkyRequestParcelPoin
  public data: DetailUploadBulkyParcelPoin[]
  public pagination: Pagination

  constructor(
    result: ResultUploadBulkyRequestParcelPoin,
    data: DetailUploadBulkyParcelPoin[],
    pagination: Pagination
  ) {
    this.result = result
    this.data = data
    this.pagination = pagination
  }
}

export class ResultUploadBulkyRequestParcelPoin {
  public fileName: string
  public totalRowValid: number
  public totalRowInvalid: number
  public totalValidAmountNegative: number
  public totalValidAmountPositive: number
  public totalInvalidAmountNegative: number
  public totalInvalidAmountPositive: number

  constructor(
    fileName: string,
    totalRowValid: number,
    totalRowInvalid: number,
    totalValidAmountNegative: number,
    totalValidAmountPositive: number,
    totalInvalidAmountNegative: number,
    totalInvalidAmountPositive: number,
  ) {
    this.fileName = fileName
    this.totalRowValid = totalRowValid
    this.totalRowInvalid = totalRowInvalid
    this.totalValidAmountNegative = totalValidAmountNegative
    this.totalValidAmountPositive = totalValidAmountPositive
    this.totalInvalidAmountNegative = totalInvalidAmountNegative
    this.totalInvalidAmountPositive = totalInvalidAmountPositive
  }
}

export class DetailUploadBulkyRequestParcelPoin {
  public customerID: number
  public totalSaldo: number
  public note: string
  public isValid: boolean
  public reasonInvalid?: string
  public rowNumber?: number

  constructor(
    customerID: number,
    totalSaldo: number,
    note: string,
    isValid: boolean,
    reasonInvalid?: string,
    rowNumber?: number
  ) {
    this.customerID = customerID
    this.totalSaldo = totalSaldo
    this.note = note
    this.isValid = isValid
    this.reasonInvalid = reasonInvalid
    this.rowNumber = rowNumber
  }
}
