























































import { Component, Vue, Watch } from 'vue-property-decorator'
import HomeIcon from '@/app/ui/assets/home_icon.vue'
import ExpandIcon from '@/app/ui/assets/expand_icon.vue'
import Navigation from './Navigation/index.vue'
import Sidebar from './Sidebar/index.vue'
import { LocalStorage, Utils } from '@/app/infrastructures/misc'
import { Route, RouteConfig, RouteRecord } from 'vue-router'
import controller from '@/app/ui/controllers/MixPanelController'

interface RouteMenu {
  path: string
  title: string
  parent: RouteRecord | undefined
}

@Component({
  components: {
    HomeIcon,
    Navigation,
    Sidebar,
    ExpandIcon,
  },
})
export default class Main extends Vue {
  myEmail = ''
  myRole = ''
  accountId = ''
  currentRoute = this.$router.currentRoute

  created(): void {
    this.myEmail = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )
    this.myRole = Utils.roleName(
      LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true)
    )
    this.accountId = LocalStorage.getLocalStorage(
      LocalStorage.ACCOUNT_ID,
      true
    )
  }

  @Watch('$route')
  onRouteChange(now: Route): void {
    this.currentRoute = now
  }

  get routeArray(): RouteMenu[] {
    const seen = new Set()

    return this.$route.matched
      .map(item => ({
        path: item.path,
        title: item.meta.title,
        parent: item.parent,
        customBreedcrumbs: item.meta?.customBreedcrumbs,
      }))
      .filter(item => {
        if (!item.title) return false

        const path = item.path.replace(/^\/|\/$/g, '')
        const duplicate = seen.has(path)
        seen.add(path)
        return !duplicate
      })
  }

  get isParent(): boolean {
    return !!this.$route.meta.isParent
  }

  get urlArray(): string[] {
    return this.$route.path.substring(1).split('/')
  }

  get isSearchPage(): boolean {
    return this.$route.name === 'SearchPage'
  }

  private onBackPressed() {
    let previousRoute = sessionStorage.getItem('previousRoute')
    if (!previousRoute) {
      previousRoute = this.urlArray.length > 3 ? '..' : '.'
    }

    this.$router.push(previousRoute)
  }

  private breadcrumbTitle(title: string | ((route: Route) => string)) {
    if (typeof title === 'function') {
      return title(this.$route)
    }

    return title
  }

  private getUrlPath(path: string): string {
    return path.replace(/:([a-zA-Z]+)/g, (_, match) => {
      return this.$route.params[match]
    })
  }

  private onPubSub(item: RouteConfig) {
    const currentPath = item.path
    let payload = {
      logType: 'mixpanel',
      action: '',
      projectId: 'da',
      userId: this.accountId,
      username: this.myEmail,
    }

    const data = [
      {
        path: '/pickup',
        payload: { ...payload, action: 'liberta-pickupDashboard-summary' }
      },
      {
        path: '/pickup/CRRNFD',
        payload: { ...payload, action: 'liberta-pickupDashboard-crrnfd' }
      },
      {
        path: '/pickup/DOX',
        payload: { ...payload, action: 'liberta-pickupDashboard-dox' }
      },
      {
        path: '/pickup/CRROTW',
        payload: { ...payload, action: 'liberta-pickupDashboard-crrotw' }
      },
      {
        path: '/pickup/CRRPIC',
        payload: { ...payload, action: 'liberta-pickupDashboard-crrpic' }
      },
      {
        path: '/pickup/CRRDON',
        payload: { ...payload, action: 'liberta-pickupDashboard-crrdon' }
      },
      {
        path: '/manage-user-&-courier',
        payload: { ...payload, action: 'liberta-manageUser-courier' }
      },
      {
        path: '/driver',
        payload: { ...payload, action: 'liberta-driver-driverScoreBoard' }
      },
      {
        path: '/driver/pod-tracker',
        payload: { ...payload, action: 'liberta-driver-podTracker' }
      },
      {
        path: '/driver/pod-tracker/driver-pod',
        payload: { ...payload, action: 'liberta-driver-podTracker-listKurir' }
      },
      {
        path: '/driver/cod-tracker',
        payload: { ...payload, action: 'liberta-driver-codTracker' }
      },
      {
        path: '/driver-announcement',
        payload: { ...payload, action: 'liberta-driverAnnouncement-announcement' }
      },
      {
        path: '/manage-user-&-courier',
        payload: { ...payload, action: 'liberta-manageUser-courier' }
      },
      {
        path: '/manage-user-&-courier/manage-courier',
        payload: { ...payload, action: 'liberta-manageUser-courier' }
      }
    ]

    if (this.currentRoute.redirectedFrom !== currentPath) {
      const currentData = data.find(item => item.path === currentPath)
      if (currentData && currentData.payload) controller.sendLoggingPubSub(currentData.payload)
    }
  }
}
