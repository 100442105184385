import { UpdatePickupLogStatusRequest } from '@/data/payload/api/PickupRadiusValidationRequest'
import { PickupRadiusValidationRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupRadiusValidationRepositoryInterface'
import { PickupRadiusDetail, PickupRadiusValidations } from '@/domain/entities/PickupRadiusValidation'
import { injectable } from 'tsyringe'

@injectable()
export class PickupRadiusValidationPresenter {
  private repository: PickupRadiusValidationRepositoryInterface

  constructor(repository: PickupRadiusValidationRepositoryInterface) {
    this.repository = repository
  }

  getPickupRadiusValidations(
    params: Map<string, string | number>
  ): Promise<PickupRadiusValidations> {
    return this.repository.getPickupRadiusValidations(params)
  }

  getPickupRadiusValidationDetail(
    pickupLogId: number,
    params: Map<string, string | number>
  ): Promise<PickupRadiusDetail> {
    return this.repository.getPickupRadiusValidationDetail(pickupLogId, params)
  }

  updatePickupRadiusValidation(
    pickupLogId: number,
    payload: UpdatePickupLogStatusRequest
  ): Promise<boolean> {
    return this.repository.updatePickupLogStatus(pickupLogId, payload)
  }

  exportPickupLog(
    params: Map<string, string | number>
  ): Promise<string> {
    return this.repository.exportPickupLog(params)
  }
}
