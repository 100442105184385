import { injectable } from 'tsyringe'
import {
  PushNotifications,
  PushNotification,
  PushNotificationProgramMembership,
} from '@/domain/entities/PN'
import { PNRepositoryInterface } from '@/data/persistences/repositories/contracts/PNRepositoryInterface'
import { ProgramaticPointRepositoryInterface } from '@/data/persistences/repositories/contracts/ProgramaticPointRepositoryInterface'
import {
  CreatePNRequestInterface,
  UpdatePNRequestInterface,
} from '@/data/payload/contracts/PNRequest'

@injectable()
export class PNPresenter {
  private repository: PNRepositoryInterface
  private programaticPointRepository: ProgramaticPointRepositoryInterface

  constructor(
    repository: PNRepositoryInterface,
    programaticPointRepository: ProgramaticPointRepositoryInterface
  ) {
    this.repository = repository
    this.programaticPointRepository = programaticPointRepository
  }

  public create(payload: CreatePNRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    id: string,
    payload: UpdatePNRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }

  public cancel(id: string): Promise<boolean> {
    return this.repository.cancel(id)
  }

  public getAll(params: Map<string, any>): Promise<PushNotifications> {
    return this.repository.getAll(params)
  }

  public get(id: string): Promise<PushNotification> {
    return this.repository.get(id)
  }

  public getCategoryOptions(): Promise<string[]> {
    return this.repository.getCatategoryOptions()
  }

  public getListProgramMembership(): Promise<
    PushNotificationProgramMembership[]
  > {
    return this.repository.getListProgramMembership()
  }
}
