export enum EnumFilterByStatus {
  ALL_STATUS = 'ALL_STATUS',
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
}

export enum EnumShowTimeOptions {
  ALWAYS_SHOW = 'ALWAYS_SHOW',
  ONLY_ONCE = 'ONLY_ONCE',
  ONCE_A_DAY = 'ONCE_A_DAY',
}

export enum EnumFilterByType {
    ALL_TYPE = 'ALL_TYPE',
    INFO = 'INFO',
    PROMO = 'PROMO',
    OTHERS = 'OTHERS'
}

export interface filterOptionsInterface {
  label: number | string
  value: string | number | undefined
}

export interface parameterInterface {
  page: number
  perPage: number
  keyword: string | null
  status: undefined | filterOptionsInterface
  type: undefined | filterOptionsInterface
  sortBy: undefined | filterOptionsInterface
  isActive: boolean
}

export interface paramsInterface {
  page: number
  perPage: number
  keyword: string | null
  status: string | number | undefined
  type: string | number | undefined
  sortBy: string | number | undefined
  isActive: boolean
}

export interface FormInterface {
  type: filterOptionsInterface | null,
  title: string,
  location: filterOptionsInterface | null,
  image: File[] | undefined[],
  url: string,
  timer: number,
  showTime: filterOptionsInterface | null,
  startDate: string,
  endDate: string,
  startTime: string,
  endTime: string,
}

export const typeOptions: Array<filterOptionsInterface> = [
  {
    label: EnumFilterByType.INFO,
    value: EnumFilterByType.INFO.toLowerCase(),
  },
  {
    label: EnumFilterByType.PROMO,
    value: EnumFilterByType.PROMO.toLowerCase(),
  },
  {
    label: EnumFilterByType.OTHERS,
    value: EnumFilterByType.OTHERS.toLowerCase(),
  },
]

export const showTimeOptions: Array<filterOptionsInterface> = [
  {
    label: 'Always Show (Show pop up banner everytime user open app)',
    value: EnumShowTimeOptions.ALWAYS_SHOW,
  },
  {
    label: 'Only Once (Show pop up banner only one time after user install)',
    value: EnumShowTimeOptions.ONLY_ONCE,
  },
  {
    label: 'Once a Day',
    value: EnumShowTimeOptions.ONCE_A_DAY,
  },
]

export const locationOptions: Array<filterOptionsInterface> = [
  {
    label: 'Homepage',
    value: 'home_page',
  },
]

export interface PopUpBannerForm {
  id?: string | number
  type?: string | number
  title: string
  location?: string | number
  image: File
  url: string
  timer: number
  showTime?: string | number
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  isUpdatedImage?: boolean
}

export enum EnumShowTimeLabel {
  ALWAYS_SHOW = "Always Show (Show pop up banner everytime user open app)",
  ONLY_ONCE = "Only Once (Show pop up banner only one time after user install)",
  ONCE_A_DAY = "Once a Day"
}
