import {
  BaseResponse,
  ErrorResponse,
  ErrorMessage,
} from '@/domain/entities/BaseResponse'
import { AxiosResponse } from 'axios'

export class BaseResponseMapper {
  public convertBaseResponseFromApi(result: AxiosResponse<any>): BaseResponse {
    const { data } = result
    return new BaseResponse(data.success)
  }

  public convertErrorResponseFromApi(
    result: AxiosResponse<any>
  ): ErrorResponse {
    const { data } = result
    return new ErrorResponse(data.error_id, data.message as ErrorMessage)
  }
}
