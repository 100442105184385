import { RouteConfig } from 'vue-router'
import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '../constants'

export const cod: RouteConfig = {
  path: '/cod',
  component: MainLayout,
  meta: {
    title: 'COD',
    single: false,
    icon: 'MenuIconCOD',
  },
  children: [
    {
      path: 'payment-setting',
      name: 'CODPaymentSetting',
      redirect: {
        name: 'CODPaymentSettingList',
      },
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Kelola Biaya COD',
        accessType: 'read',
        slugFromBackend: BACKEND_SLUG.COD_PAYMENT_SETTING,
      },
      children: [
        {
          path: '',
          name: 'CODPaymentSettingList',
          component: () =>
            import(
              /* webpackChunkName: 'CODPaymentSettingList' */ '@/app/ui/views/COD/CODPaymentSetting/index.vue'
            ),
          meta: {
            title: 'Kelola Biaya COD',
            accessType: 'read',
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT_SETTING,
          },
        },
        {
          path: 'create',
          name: 'CODPaymentSettingCreate',
          component: () =>
            import(
              /* webpackChunkName: 'CODPaymentSettingCreate' */ '@/app/ui/views/COD/CODPaymentSetting/CODFeeForm/index.vue'
            ),
          meta: {
            title: 'Tambah Biaya COD',
            accessType: 'write',
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT_SETTING,
          },
        },
        {
          path: 'edit/:codFeeId',
          name: 'CODPaymentSettingEdit',
          component: () =>
            import(
              /* webpackChunkName: 'CODPaymentSettingEdit' */ '@/app/ui/views/COD/CODPaymentSetting/CODFeeForm/index.vue'
            ),
          meta: {
            title: 'Edit Biaya COD',
            accessType: 'write',
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT_SETTING,
          },
        },
        {
          path: ':codFeeId',
          name: 'CODPaymentSettingDetail',
          component: () =>
            import(
              /* webpackChunkName: 'CODPaymentSettingDetail' */ '@/app/ui/views/COD/CODPaymentSetting/CODFeeDetail/index.vue'
            ),
          meta: {
            title: 'Detail Biaya COD',
            accessType: 'read',
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT_SETTING,
          },
        },
      ],
    },
    {
      path: 'manage-payment',
      name: 'ManagePayment',
      redirect: {
        name: 'ManagePaymentList',
      },
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: 'Kelola Pembayaran COD',
        accessType: 'read',
        slugFromBackend: BACKEND_SLUG.COD_PAYMENT,
      },
      children: [
        {
          path: '',
          name: 'CODPaymentList',
          component: () =>
            import(
              /* webpackChunkName: 'ManagePaymentList' */ '@/app/ui/views/COD/CODPayment/index.vue'
            ),
          meta: {
            title: 'Kelola Pembayaran COD',
            accessType: 'read',
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT,
          },
        },
        {
          path: ':packageId',
          name: 'ManagePaymentDetail',
          component: () =>
            import(
              /* webpackChunkName: 'ManagePaymentDetail' */ '@/app/ui/views/COD/CODPayment/Detail/index.vue'
            ),
          meta: {
            title: 'Detail',
            accessType: 'read',
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT,
          },
        },
        {
          path: 'package/:packageId',
          name: 'ManagePaymentPackageDetail',
          component: () =>
            import(
              /* webpackChunkName: 'ManagePaymentPackageDetail' */ '@/app/ui/views/Package/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.COD_PAYMENT,
            accessType: 'read',
          },
        },
      ],
    },
  ],
}
