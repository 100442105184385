import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { ManageDayRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageDayRepositoryInterface'
import { ManageDays } from '@/domain/entities/ManageDay'
import { ManageDayMapper } from '@/data/persistences/mappers/ManageDayMapper'
import { UpdateManageDayRequestInterface } from '@/data/payload/contracts/ManageDayRequest'

export class ManageDayApiRepository implements ManageDayRepositoryInterface {
  private service: ApiService
  private mapper: ManageDayMapper

  constructor(service: ApiService, mapper: ManageDayMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, string | number>): Promise<ManageDays> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/manage-day`,
      params
    )

    return this.mapper.convertManageDayFromApi(resp)
  }

  public async getYearList(): Promise<ManageDays> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/manage-day/year`,
    )

    return this.mapper.convertManageDayYearListFromApi(resp)
  }

  public async update(id: number, payload: UpdateManageDayRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLL}/manage-day/edit/${id}`,
      undefined,
      payload as UpdateManageDayRequestInterface
    )

    return true
  }
}
