import ApiService from '@/app/infrastructures/services/ApiService'
import { PODTrackerMapper } from '@/data/persistences/mappers/PODTrackerMapper'
import { PODTrackerRepositoryInterface } from '@/data/persistences/repositories/contracts/PODTrackerInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import {
  PODDetail,
  PODs,
  PODTrackerDrivers,
} from '@/domain/entities/PODTracker'
import { OriginRoute } from '@/domain/entities/Route'
import {
  ExportPOD,
  UpdatePODHistory,
} from '@/data/payload/api/PODTrackerRequests'

export class PODTrackerApiRepository implements PODTrackerRepositoryInterface {
  private service: ApiService
  private mapper: PODTrackerMapper

  constructor(service: ApiService, mapper: PODTrackerMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getDrivers(
    params: Map<string, string | number>
  ): Promise<PODTrackerDrivers> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.COURIER,
      params
    )

    return this.mapper.convertDriversFromApi(resp)
  }

  public async getOrigins(): Promise<OriginRoute[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ROUTE}/city`
    )
    return this.mapper.convertOriginsFromApi(resp)
  }

  public async getPODs(params: Map<string, string | number>): Promise<PODs> {
    const resp = await this.service.invoke('get', Endpoints.Route.V2POD, params)
    return this.mapper.convertPODsFromApi(resp)
  }

  public async getPODsByCourier(
    courierId: number,
    params: Map<string, string | number>
  ): Promise<PODs> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.V2POD}/${courierId}`,
      params
    )
    return this.mapper.convertPODsFromApi(resp)
  }

  public async getPODDetail(packageId: string): Promise<PODDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.POD}/detail/${packageId}`
    )
    return this.mapper.convertPODDetailFromApi(resp)
  }

  public async updatePODHistory(payload: UpdatePODHistory): Promise<boolean> {
    await this.service.invoke('post', Endpoints.Route.POD, undefined, payload)

    return true
  }

  public async exportPODReport(payload: ExportPOD): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.POD}/export`,
      undefined,
      payload
    )

    return true
  }
}
