import {AxiosResponse} from 'axios'
import {BaseResponseMapper} from '@/data/persistences/mappers/BaseMapper'
import {
  DataHistoryUploadBulkyResponseInterface, HistoryUploadBulkyResponseInterface,
} from '@/data/responses/contracts/VoucherConfigResponseInterface'
import {Pagination} from '@/domain/entities/Pagination'
import {HistoryUploadBulkyVoucherConfig, dataHistoryUploadBulky} from '@/domain/entities/VoucherConfigHistoryBulky'

export class VoucherConfigHistoryBulkyMapper extends BaseResponseMapper {
  public convertHistoryUploadBulky(result: AxiosResponse): HistoryUploadBulkyVoucherConfig {
    const {data, pagination} = <HistoryUploadBulkyResponseInterface>result.data

    return new HistoryUploadBulkyVoucherConfig(
      (data || []).map((val: DataHistoryUploadBulkyResponseInterface) => {
        return new dataHistoryUploadBulky(
          val.id,
          val.file_id,
          val.file_name,
          val.created_by_email,
          val.created_at,
        )
      }),
      new Pagination(
        pagination.page,
        pagination.per_page,
        pagination.total_item || pagination.total,
      )
    )
  }
}

