import { AxiosResponse } from 'axios'
import { PointPurpose } from '@/domain/entities/PointPurpose'
import { BaseResponseMapper } from './BaseMapper'

export class PointPurposeMapper extends BaseResponseMapper {
  public convertPointPurposesFromApi(
    result: AxiosResponse<any>
  ): PointPurpose[] {
    const {
      data: { data },
    } = result
    return data.map(
      (purpose: Record<string, any>) =>
        new PointPurpose(purpose.point_purpose_id, purpose.point_purpose_name)
    )
  }
}
