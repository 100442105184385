import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'
import { RouteInterface } from '@/app/ui/router/interface'

export const programaticPointRouteName = 'ProgramaticPointPage'
export const createProgramRouteName = 'CreateProgramPage'
export const editProgramRouteName = 'EditProgramPage'
export const retrievedPaymentRouteName = 'RetrievedPaymentPage'
export const programDetailRouteName = 'ProgramDetailPage'
export const usagePointRouteName = 'UsagePointPage'
export const allDataProgramaticRouteName = 'AllDataProgramaticPage'
export const allDataProgramaticDetailRouteName = 'AllDataProgramaticDetailPage'

export const programaticPointRoute: RouteInterface = {
  path: 'programatic-point',
  name: programaticPointRouteName,
  meta: {
    title: 'Programatic Point',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_POINT,
    accessType: 'read',
  },
}

export const createProgramRoute: RouteInterface = {
  path: 'programatic-point/create',
  name: createProgramRouteName,
  meta: {
    title: 'Create Program',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_POINT,
    accessType: 'write',
  },
}

export const editProgramRoute: RouteInterface = {
  path: 'programatic-point/edit/:id',
  name: editProgramRouteName,
  meta: {
    title: 'Edit Program',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_POINT,
    accessType: 'write',
  },
}

export const retrievedPaymentRoute: RouteInterface = {
  path: 'programatic-point/retrieve-payment',
  name: retrievedPaymentRouteName,
  meta: {
    title: 'Retrieve Payment Saldo',
    slugFromBackend: BACKEND_SLUG.RETRIEVED_PAYMENT,
    accessType: 'read',
  },
}

export const usagePointRoute: RouteInterface = {
  path: 'programatic-point/:id/usage',
  name: usagePointRouteName,
  meta: {
    title: 'Point Usage',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_POINT,
    accessType: 'read',
  },
}

export const allDataProgrammatic: RouteInterface = {
  path: 'all-data-programatic',
  name: allDataProgramaticRouteName,
  meta: {
    title: 'All Data Programmatic [New]',
    isHidden: false,
    slugFromBackend: BACKEND_SLUG.ALL_DATA_PROGRAMMATIC,
    accessType: 'read',
  },
}

export const allDataProgrammaticDetail: RouteInterface = {
  path: 'all-data-programatic/detail',
  name: allDataProgramaticDetailRouteName,
  meta: {
    title: 'Detail Data Programmatic',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.ALL_DATA_PROGRAMMATIC,
    accessType: 'read',
  },
}

export const programDetailRoute: RouteInterface = {
  path: 'programatic-point/:id',
  name: programDetailRouteName,
  meta: {
    title: 'Programatic Detail',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.PROGRAMMATIC_POINT,
    accessType: 'read',
  },
}

export const saldo: RouteConfig = {
  path: '/saldo',
  component: MainLayout,
  redirect: '/saldo/programatic-point',
  meta: {
    title: 'Saldo',
    single: false,
    icon: 'MenuIconSaldo',
  },
  children: [
    {
      ...programaticPointRoute,
      component: () =>
        import(
          /* webpackChunkName: "ProgramaticPointPage" */ '@/app/ui/views/Saldo/ProgramaticPoint/index.vue'
        ),
    },
    {
      ...createProgramRoute,
      component: () =>
        import(
          /* webpackChunkName: "CreateProgramPage" */ '@/app/ui/views/Saldo/ProgramaticPoint/CreateProgram/index.vue'
        ),
    },
    {
      ...editProgramRoute,
      component: () =>
        import(
          /* webpackChunkName: "EditProgramPage" */ '@/app/ui/views/Saldo/ProgramaticPoint/EditProgram/index.vue'
        ),
    },
    {
      ...retrievedPaymentRoute,
      component: () =>
        import(
          /* webpackChunkName: "RetrievedPaymentPage" */ '@/app/ui/views/Saldo/RetrievedPayment/index.vue'
        ),
    },
    {
      ...programDetailRoute,
      component: () =>
        import(
          /* webpackChunkName: "ProgramDetailPage" */ '@/app/ui/views/Saldo/ProgramaticPoint/ProgramDetail/index.vue'
        ),
    },
    {
      ...usagePointRoute,
      component: () =>
        import(
          /* webpackChunkName: "UsagePointPage" */ '@/app/ui/views/Saldo/ProgramaticPoint/UsagePoint/index.vue'
        ),
    },
    {
      ...allDataProgrammatic,
      component: () =>
        import(
          /* webpackChunkName: "AllDataProgrammaticListPage" */ '@/app/ui/views/Saldo/AllDataProgrammatic/index.vue'
        ),
    },
    {
      ...allDataProgrammaticDetail,
      component: () =>
        import(
          /* webpackChunkName: "AllDataProgrammaticDetailPage" */ '@/app/ui/views/Saldo/AllDataProgrammatic/ProgrammaticData/Detail/ProgrammaticDataDetail.vue'
        ),
    }
  ],
}
