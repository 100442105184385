import { AxiosResponse } from 'axios'
import { LetterCode, OriginRoute, Route } from '@/domain/entities/Route'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/app/infrastructures/misc'

export class RouteMapper extends BaseResponseMapper {
  public convertOriginFromApi(result: AxiosResponse): OriginRoute[] {
    const {
      data: { data },
    } = result
    return data.map((route: Record<string, string | boolean | number>) => {
      return new OriginRoute(
        route.route_id as number,
        route.lc as string,
        route.city as string,
        route.route as string,
        route.is_origin as boolean,
        route.is_pickup as boolean,
        route.is_shop as boolean
      )
    })
  }

  public convertRoutesFromApi(result: AxiosResponse): Route[] {
    const {
      data: { data },
    } = result

    return data
      ? data.map((route: { city: string; routes: string[] }) => {
          return new Route(
            undefined,
            undefined,
            route.city,
            route.routes.map(item => Utils.toInstance(new Route(), item)),
            false
          )
        })
      : []
  }

  public convertLetterCodesFromApi(result: AxiosResponse): LetterCode[] {
    const {
      data: { data },
    } = result

    return data.map(
      (route: { lc: string; city: string }) =>
        new LetterCode(route.lc, route.city)
    )
  }
}
