import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import {
  DataExtraMultiplier,
  ListExtraMultiplier,
  ListMembershipFormula,
  ListMultiplierLevel,
  MembershipFormula,
  MultiplierLevel,
} from '@/domain/entities/MembershipFormula'
import {
  LoyaltyMembershipFormulaInterface,
  MultiplierLevelInterface,
} from '@/data/responses/contracts/MembershipFormulaResponse'
import {
  DataExtraMultiplierResponse,
  ListExtraMultiplierResponse,
} from '@/data/responses/contracts/ExtraMultiplierResponseInterface'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class MembershipFormulaMapper extends BaseResponseMapper {
  public convertLoyaltyProgramMembershipFormulaFromApi(
    result: AxiosResponse
  ): ListMembershipFormula {
    const {
      data: { data },
    } = result
    return {
      data: data.map((item: LoyaltyMembershipFormulaInterface) => {
        return new MembershipFormula(
          item.membership_point_formula_id,
          item.channel,
          item.nominal,
          item.is_sender,
          item.is_receiver,
          item.level_info,
          item.start_date,
          item.end_date,
          item.status
        )
      }),
    }
  }

  public convertDetailMembershipFormulaFromApi(
    result: AxiosResponse
  ): MembershipFormula {
    const {
      data: { data },
    } = result

    if (data.length === 0) return new MembershipFormula()

    return new MembershipFormula(
      data[0].membership_point_formula_id,
      data[0].channel,
      data[0].nominal,
      data[0].is_sender,
      data[0].is_receiver,
      data[0].level_info,
      data[0].start_date,
      data[0].end_date,
      data[0].status
    )
  }

  public convertMultiplierLevelMembershipProgram(
    result: AxiosResponse
  ): ListMultiplierLevel {
    const {
      data: { data },
    } = result
    return data.map((item: MultiplierLevelInterface) => {
      return new MultiplierLevel(
        item.level_id,
        item.level_name,
        item.minimum_amount_transaction,
        item.minimum_number_transaction
      )
    })
  }

  public convertListExtraMultiplier(
    result: AxiosResponse
  ): ListExtraMultiplier {
    const { data, pagination } = <ListExtraMultiplierResponse>result.data

    return new ListExtraMultiplier(
      Utils.toInstance(new Pagination(), pagination),
      data.map((extraMultiplier: DataExtraMultiplierResponse) => {
        return new DataExtraMultiplier(
          extraMultiplier.membership_extra_multiplier_id,
          extraMultiplier.channel,
          extraMultiplier.product_type,
          extraMultiplier.extra_multiplier_info,
          extraMultiplier.created_at,
          extraMultiplier.updated_at,
          extraMultiplier.deleted_at
        )
      })
    )
  }

  public convertDetailExtraMultiplier(
    result: AxiosResponse
  ): DataExtraMultiplier {
    const { data } = result.data

    const dataExtraMultiplier: DataExtraMultiplierResponse = data

    return new DataExtraMultiplier(
      dataExtraMultiplier.membership_extra_multiplier_id,
      dataExtraMultiplier.channel,
      dataExtraMultiplier.product_type,
      dataExtraMultiplier.extra_multiplier_info,
      dataExtraMultiplier.created_at,
      dataExtraMultiplier.updated_at,
      dataExtraMultiplier.deleted_at
    )
  }
}
