export const HEADERS = [
  {
    title: 'City',
    customStyle: { maxWidth: '300px', minWidth: '300px' },
  },
  {
    title: 'Sub District',
    customStyle: { maxWidth: '300px', minWidth: '300px' },
  },
  {
    title: 'Status',
    customStyle: { maxWidth: '210px', minWidth: '210px' },
  },
  {
    title: 'View Detail',
    customStyle: { maxWidth: '200px', minWidth: '200px' },
  },
]

export enum EnumStatus {
  ACTIVE = 'ACTIVE',
  NOT_ACTIVE = 'NOT_ACTIVE',
}

export const OPTION_STATUS = [
  { label: 'Show All', value: '' },
  { label: 'Active', value: EnumStatus.ACTIVE.toLocaleLowerCase() },
  { label: 'Not Active', value: EnumStatus.NOT_ACTIVE.toLocaleLowerCase() },
]

export interface OptionInterface {
  label: number | string
  value: string | number
}

export interface ParameterPickupLocationInterface {
  keyword: string | null
  status: undefined | OptionInterface
  city: undefined | OptionInterface
  sortBy: string
  page: number
  perPage: number
}

export interface ParamsPickupLocationInterface {
  keyword: string | null
  status: string | number | undefined
  city: string | number | undefined
  page: number
  perPage: number
}

export interface HeadersPickupLocationInterface {
  title: string,
  customStyle: Record<string, string>
}

export const PICKUP_LOCATION_PAGINATION = 25
export const PICKUP_LOCATION_TITLE_SUCCESS_ACTIVATE = 'Successfull Activate City'
export const PICKUP_LOCATION_TITLE_SUCCESS_DEACTIVATE = 'Successfull Deactivate City'
export const PICKUP_LOCATION_BODY_SUCCESS_ACTIVATE = 'Pick Up feature was active for users to use'
export const PICKUP_LOCATION_BODY_SUCCESS_DEACTIVATE = 'Pick Up feature was deactivated & cannot be used by the user'
