import {Pagination} from '@/domain/entities/Pagination'

export class VoucherSKU {
  id: number
  skuCode: string
  voucherName: string
  voucherAmount: number
  createdAt: string
  updatedAt: string

  constructor(
    id: number,
    skuCode: string,
    voucherName: string,
    voucherAmount: number,
    createdAt: string,
    updatedAt: string
  ) {
    this.id = id
    this.skuCode = skuCode
    this.voucherName = voucherName
    this.voucherAmount = voucherAmount
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }

}

export class VoucherSKUList {
  public pagination: Pagination
  public data: VoucherSKU[]

  constructor(pagination: Pagination, data: VoucherSKU[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class ResultUploadBulkyVoucherSKU {
  fileName: string
  totalValid: number
  totalInvalid: number

  constructor(
    fileName: string,
    totalValid: number,
    totalInvalid: number
  ) {
    this.fileName = fileName
    this.totalValid = totalValid
    this.totalInvalid = totalInvalid
  }
}

export class DetailUploadBulkyVoucherSKU {
  sku: string
  name: string
  amount: number
  status: string

  constructor(
    sku: string,
    name: string,
    amount: number,
    status: string
  ) {
    this.sku = sku
    this.name = name
    this.amount = amount
    this.status = status
  }
}

export class UploadBulkyVoucherSKU {
  public result: ResultUploadBulkyVoucherSKU
  public data: DetailUploadBulkyVoucherSKU[]

  constructor(
    result: ResultUploadBulkyVoucherSKU,
    data: DetailUploadBulkyVoucherSKU[],
  ) {
    this.result = result
    this.data = data
  }
}
