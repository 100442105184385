import { injectable } from 'tsyringe'

import {
  LoyaltyProgramMembershipRepositoryInterface,
} from '@/data/persistences/repositories/contracts/LoyaltyProgramMembershipInterface'
import {
  DetailLoyaltyProgramMembership,
  ListLoyaltyProgramMembership
} from '@/domain/entities/LoyaltyProgramMembership'
import {
  CreateLoyaltyProgramMembershipRequestInterface, UpdateLoyaltyProgramMembershipRequestInterface,
} from '@/data/payload/contracts/LoyaltyProgramMembershipRequest'

@injectable()
export class LoyaltyProgramMembershipPresenter {
  private repository: LoyaltyProgramMembershipRepositoryInterface

  constructor(repository: LoyaltyProgramMembershipRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, never | unknown>): Promise<ListLoyaltyProgramMembership> {
    return this.repository.getAll(params)
  }

  public create(payload: CreateLoyaltyProgramMembershipRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public getOne(id: number): Promise<DetailLoyaltyProgramMembership> {
    return this.repository.getOne(id)
  }

  public update(id: number, payload: UpdateLoyaltyProgramMembershipRequestInterface): Promise<boolean> {
    return this.repository.update(id, payload)
  }
}
