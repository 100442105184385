import { UploadManualPOD } from '@/domain/entities/ManualPODPhotos'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class ManualPODPhotosMapper extends BaseResponseMapper {
  public convertUploadManualPODPhotosFromApi(result: AxiosResponse): UploadManualPOD {
    const { data } = result

    return new UploadManualPOD(
      data.is_with_proof_file,
      data.photo_url
    )
  }
}
