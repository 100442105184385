export class PointPurpose {
  public id?: string
  public name?: string
  public createdAt?: string
  public deletedAt?: string

  constructor(
    id?: string,
    name?: string,
    createdAt?: string,
    deletedAt?: string
  ) {
    this.id = id
    this.name = name
    this.createdAt = createdAt
    this.deletedAt = deletedAt
  }
}
