import { Pagination } from "./Pagination"

export class SubDistrict {
  public subDistrict?: string

  constructor(subDistrict?: string) {
    this.subDistrict = subDistrict
  }
}

export class Village {
  public village?: string

  constructor(village?: string) {
    this.village = village
  }
}

export class ReschedulePickups {
  public data?: ReschedulePickup[]
  public pagination?: Pagination

  constructor(
    data?: ReschedulePickup[],
    pagination?: Pagination
  ) {
    this.data = data
    this.pagination = pagination
  }
}

export class ReschedulePickup {
  public shipmentId?: string
  public groupId?: string
  public customerName?: string
  public customerPhoneNumber?: string
  public statuses?: string[]
  public rescheduleType?: string
  public courier?: ReschedulePickupCourier
  public rescheduledAt?: string
  public customerAddress?: string
  public createdAt?: string
  public duration?: string
  public statusApproval?: string

  constructor(
    shipmentId?: string,
    groupId?: string,
    customerName?: string,
    customerPhoneNumber?: string,
    statuses?: string[],
    rescheduleType?: string,
    courier?: ReschedulePickupCourier,
    rescheduledAt?: string,
    customerAddress?: string,
    createdAt?: string,
    duration?: string,
    statusApproval?: string
  ) {
    this.shipmentId = shipmentId
    this.groupId = groupId
    this.customerName = customerName
    this.customerPhoneNumber = customerPhoneNumber
    this.statuses = statuses
    this.rescheduleType = rescheduleType
    this.courier = courier
    this.rescheduledAt = rescheduledAt
    this.customerAddress = customerAddress
    this.createdAt = createdAt
    this.duration = duration
    this.statusApproval = statusApproval
  }
}

export class ReschedulePickupCourier {
  public courierId?: number
  public fullname?: string
  public courierType?: string
  public phoneNumber?: string
  public latitude?: number
  public longitude?: number
  public distance?: number

  constructor(
    courierId?: number,
    fullname?: string,
    courierType?: string,
    phoneNumber?: string,
    latitude?: number,
    longitude?: number,
    distance?: number
  ) {
    this.courierId = courierId
    this.fullname = fullname
    this.courierType = courierType
    this.phoneNumber = phoneNumber
    this.latitude = latitude
    this.longitude = longitude
    this.distance = distance
  }
}

export class ReschedulePickupDetail {
  public shipmentId?: string
  public groupId?: string
  public customerName?: string
  public customerPhoneNumber?: string
  public customerAddress?: string
  public latitude?: number
  public longitude?: number
  public rescheduledAt?: string
  public createdAt?: string
  public statusApproval?: string
  public lastReason?: string
  public meta?: Record<string, string[]>
  public assignCourier?: ReschedulePickupCourier | null
  public lastCourier?: ReschedulePickupCourier

  constructor(
    shipmentId?: string,
    groupId?: string,
    customerName?: string,
    customerPhoneNumber?: string,
    customerAddress?: string,
    latitude?: number,
    longitude?: number,
    rescheduledAt?: string,
    createdAt?: string,
    statusApproval?: string,
    lastReason?: string,
    meta?: Record<string, string[]>,
    assignCourier?: ReschedulePickupCourier | null,
    lastCourier?: ReschedulePickupCourier
    ) {
    this.shipmentId = shipmentId
    this.groupId = groupId
    this.customerName = customerName
    this.customerPhoneNumber = customerPhoneNumber
    this.customerAddress = customerAddress
    this.latitude = latitude
    this.longitude = longitude
    this.rescheduledAt = rescheduledAt
    this.createdAt = createdAt
    this.statusApproval = statusApproval
    this.meta = meta
    this.assignCourier = assignCourier
    this.lastCourier = lastCourier
    this.lastReason = lastReason
  }
}
