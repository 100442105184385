import { UploadBulkBalanceAmount } from "./ManualAdjustmentBalance"
import { Pagination } from "./Pagination"

export class Payrolls {
  public pagination?: Pagination
  public data?: Payroll[]

  constructor(
    pagination?: Pagination,
    data?: Payroll[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class Payroll {
  public id?: number
  public startAt?: string
  public endAt?: string
  public status?: string

  constructor(
    id?: number,
    start_at?: string,
    end_at?: string,
    status?: string,
  ) {
    this.id = id
    this.startAt = start_at
    this.endAt = end_at
    this.status = status
  }
}

export class PeriodRule {
  public id?: number;
  public basicFeeId?: number;
  public basicFee?: BasicFee;
  public withdrawId?: number;
  public withdraw?: Withdraw;
  public bonusFeeId?: number;
  public bonusFee?: BonusFee
  public updatedBy?: string;
  public updatedAt?: Date;
  public createdAt?: Date;
  public createdBy?: string;
  public status?: string;

  constructor(
    id?: number,
    basic_fee_id?: number,
    basic_fee?: BasicFee,
    withdraw_id?: number,
    withdraw?: Withdraw,
    bonus_fee_id?: number,
    bonus_fee?: BonusFee,
    updated_by?: string,
    updated_at?: Date,
    created_at?: Date,
    created_by?: string,
    status?: string
  ) {
    this.id = id
    this.basicFeeId = basic_fee_id
    this.basicFee = basic_fee
    this.withdrawId = withdraw_id
    this.withdraw = withdraw
    this.bonusFeeId = bonus_fee_id
    this.bonusFee = bonus_fee
    this.updatedBy = updated_by
    this.updatedAt = updated_at
    this.createdAt = created_at
    this.createdBy = created_by
    this.status = status
  }
}

export class BasicFee {
  public calculationMethod?: string[];
  public multiplyType?: string[];
  public productType?: string[];
  public rules?: Rule[];

  constructor(
    calculation_method?: string[],
    multiply_type?: string[],
    product_type?: string[],
    rules?: Rule[],
  ) {
    this.calculationMethod = calculation_method
    this.multiplyType = multiply_type
    this.productType = product_type
    this.rules = rules
  }
}

export class Withdraw {
  public minPayoutAmount?: number;
  public minSaldo?: number;
  public payoutFee?: number;

  constructor(
    min_payout_amount?: number,
    min_saldo?: number,
    payout_fee?: number,
  ) {
    this.minPayoutAmount = min_payout_amount
    this.minSaldo = min_saldo
    this.payoutFee = payout_fee
  }
}

export class Rule {
  public calculationMethod?: string;
  public deliveryTier?: Setting[];
  public pickupTier?: Setting[];
  public productType?: string;

  constructor(
    calculation_method?: string,
    delivery_tier?: Setting[],
    pickup_tier?: Setting[],
    product_type?: string,
  ) {
    this.calculationMethod = calculation_method
    this.deliveryTier = delivery_tier
    this.pickupTier = pickup_tier
    this.productType = product_type
  }
}

export class Setting {
  public prefix?: string[]
  public setting?: Tier[]

  constructor(prefix?: string[], setting?: Tier[]) {
    this.prefix = prefix
    this.setting = setting
  }
}

export class Tier {
  public fee?: number;
  public minWeight?: number;
  public multiplyType?: string;
  public parkingFee?: number;
  public codMultiplyType?: string;
  public codFee?: number;

  constructor(
    fee?: number,
    min_weight?: number,
    multiply_type?: string,
    parking_fee?: number,
    codMultiplyType?: string,
    codFee?: number
  ) {
    this.fee = fee
    this.minWeight = min_weight
    this.multiplyType = multiply_type
    this.parkingFee = parking_fee
    this.codMultiplyType = codMultiplyType
    this.codFee = codFee
  }
}

export class BonusFee {
  public bonusRecipient?: string[]
  public captainBonus?: string[]
  public captainBonusFee?: number
  public captainBonusOptions?: string[]
  public insurance?: boolean
  public percentageBonus?: boolean
  public percentageBonusData?: PercentageBonusData[]

  constructor(
    bonus_recipient?: string[],
    captain_bonus?: string[],
    captain_bonus_fee?: number,
    captain_bonus_options?: string[],
    insurance?: boolean,
    percentage_bonus?: boolean,
    percentage_bonus_data?: PercentageBonusData[],
  ) {
    this.bonusRecipient = bonus_recipient
    this.captainBonus = captain_bonus
    this.captainBonusFee = captain_bonus_fee
    this.captainBonusOptions = captain_bonus_options
    this.insurance = insurance
    this.percentageBonus = percentage_bonus
    this.percentageBonusData = percentage_bonus_data
  }
}

export class BonusData {
  public bonus?: number
  public minStt?: number

  constructor(
    bonus?: number,
    min_stt?: number
  ) {
    this.bonus = bonus
    this.minStt = min_stt
  }
}

export class PercentageBonusData {
  public adjustment?: number
  public bonusMaxLimit?: number
  public percentage?: number

  constructor(
    adjustment?: number,
    bonus_max_limit?: number,
    percentage?: number
  ) {
    this.adjustment = adjustment
    this.bonusMaxLimit = bonus_max_limit
    this.percentage = percentage
  }
}

export class CourierAttendance {
  public courierId?: number
  public courierName?: string
  public attendanceTotal?: number
  public requestAttendance?: number
  public dayTotal?: number
  public courier3lc?: string
  public shuttleId?: string
  public shuttleName?: string
  public shuttlePrefix?: string

  constructor(
    courierId?: number,
    courierName?: string,
    attendanceTotal?: number,
    requestAttendance?: number,
    dayTotal?: number,
    courier3lc?: string,
    shuttleId?: string,
    shuttleName?: string,
    shuttlePrefix?: string
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.attendanceTotal = attendanceTotal
    this.requestAttendance = requestAttendance
    this.dayTotal = dayTotal
    this.courier3lc = courier3lc
    this.shuttleId = shuttleId
    this.shuttleName = shuttleName
    this.shuttlePrefix = shuttlePrefix
  }
}

export class CourierAttendences {
  public pagination?: Pagination
  public data?: CourierAttendance[]
  public summary?: number

  constructor(pagination?: Pagination, data?: CourierAttendance[], summary?: number) {
    this.pagination = pagination
    this.data = data
    this.summary = summary
  }
}

export class CourierAttendanceCourierData {
  public courierId?: number
  public courierName?: string
  public courierPhone?: string
  public courier3lc?: string

  constructor(
    courierId?: number,
    courierName?: string,
    courierPhone?: string,
    courier3lc?: string
  ) {
    this.courierId = courierId
    this.courierName = courierName
    this.courierPhone = courierPhone
    this.courier3lc = courier3lc
  }
}

export class CourierAttendancePresenceDetail {
  public datePresence?: string
  public status?: string

  constructor(dataPresence?: string, status?: string) {
    this.datePresence = dataPresence
    this.status = status
  }
}

export class CourierAttendanceSummary {
  public presence?: number
  public attendance?: number
  public absent?: number
  public notVerify?: number
  public waitingAttendance?: number

  constructor(
    presence?: number,
    attendance?: number,
    absent?: number,
    notVerify?: number,
    waitingAttendance?: number
  ) {
    this.presence = presence
    this.attendance = attendance
    this.absent = absent
    this.notVerify = notVerify
    this.waitingAttendance = waitingAttendance
  }
}

export class CourierAttendanceDetails {
  public dataCourier?: CourierAttendanceCourierData
  public totalDayPresence?: number
  public totalDayOfMonth?: number
  public presenceDetail?: CourierAttendancePresenceDetail[]
  public summary?: CourierAttendanceSummary
  public shuttleId?: string
  public shuttleName?: string
  public shuttlePrefix?: string

  constructor(
    dataCourier?: CourierAttendanceCourierData,
    totalDayPresence?: number,
    totalDayOfMonth?: number,
    presenceDetail?: CourierAttendancePresenceDetail[],
    summary?: CourierAttendanceSummary,
    shuttleId?: string,
    shuttleName?: string,
    shuttlePrefix?: string
  ) {
    this.dataCourier = dataCourier
    this.totalDayPresence = totalDayPresence
    this.totalDayOfMonth = totalDayOfMonth
    this.presenceDetail = presenceDetail
    this.summary = summary
    this.shuttleId = shuttleId
    this.shuttleName = shuttleName
    this.shuttlePrefix = shuttlePrefix
  }
}

export class CourierAttendanceRequestMetaProofsData {
  public fileName?: string
  public url?: string

  constructor(fileName?: string, url?: string) {
    this.fileName = fileName
    this.url = url
  }
}

export class CourierAttendanceRequestMetaProofs {
  public admin?: CourierAttendanceRequestMetaProofsData[]
  public courier?: CourierAttendanceRequestMetaProofsData[]

  constructor(
    admin?: CourierAttendanceRequestMetaProofsData[],
    courier?: CourierAttendanceRequestMetaProofsData[]
  ) {
    this.admin = admin
    this.courier = courier
  }
}

export class CourierAttendanceRequestMeta {
  public proofs?: CourierAttendanceRequestMetaProofs

  constructor(proofs?: CourierAttendanceRequestMetaProofs) {
    this.proofs = proofs
  }
}

export class CourierAttendanceRequest {
  public attendanceId?: number
  public status?: string
  public attendanceDate?: string
  public meta?: CourierAttendanceRequestMeta

  constructor(
    attendanceId?: number,
    status?: string,
    attendanceDate?: string,
    meta?: CourierAttendanceRequestMeta
  ) {
    this.attendanceId = attendanceId
    this.status = status
    this.attendanceDate = attendanceDate
    this.meta = meta
  }
}

export class ManualAdjustBasicFeeCourierData {
  public id?: number
  public fullname?: string
  public phoneNumber?: string
  public status?: string
  public type?: string

  constructor(
    id?: number,
    fullname?: string,
    phoneNumber?: string,
    status?: string,
    type?: string
  ) {
    this.id = id
    this.fullname = fullname
    this.phoneNumber = phoneNumber
    this.status = status
    this.type = type
  }
}

export class ManualAdjustBasicFee {
  public id?: number
  public activity?: string
  public amount?: number
  public approvedAt?: string
  public approvedBy?: string
  public courier?: ManualAdjustBasicFeeCourierData
  public createdAt?: string
  public createdBy?: string
  public filename?: string
  public insertType?: string
  public notes?: string
  public status?: string
  public type?: string
  public packageId?: string
  public taskType?: string
  public meta?: string[]

  constructor(
    id?: number,
    activity?: string,
    amount?: number,
    approvedAt?: string,
    approvedBy?: string,
    courier?: ManualAdjustBasicFeeCourierData,
    createdAt?: string,
    createdBy?: string,
    filename?: string,
    insertType?: string,
    notes?: string,
    status?: string,
    type?: string,
    packageId?: string,
    taskType?: string,
    meta?: string[]
  ) {
    this.id = id
    this.activity = activity
    this.amount = amount
    this.approvedAt = approvedAt
    this.approvedBy = approvedBy
    this.courier = courier
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.filename = filename
    this.insertType = insertType
    this.notes = notes
    this.status = status
    this.type = type
    this.packageId = packageId
    this.taskType = taskType
    this.meta = meta
  }
}

export class CourierAttendanceRequestHistory {
  public username?: string
  public note?: string
  public status?: string
  public createdAt?: string
  
  constructor(
    username?: string,
    note?: string,
    status?: string,
    createdAt?: string
  ) {
    this.username = username
    this.note = note
    this.status = status
    this.createdAt = createdAt
  }
}

export class CourierAttendanceRequestDetail {
  public status?: string
  public attendanceTotal?: number
  public dayTotal?: number
  public absenceDate?: string
  public presenceId?: string
  public courier?: CourierAttendanceCourierData
  public attendanceNote?: string
  public histories?: CourierAttendanceRequestHistory[]
  public attendance?: CourierAttendanceRequest

  constructor(
    status?: string,
    attendanceTotal?: number,
    dayTotal?: number,
    absenceDate?: string,
    presenceId?: string,
    courier?: CourierAttendanceCourierData,
    attendanceNote?: string,
    histories?: CourierAttendanceRequestHistory[],
    attendance?: CourierAttendanceRequest
  ) {
    this.status = status
    this.attendanceTotal = attendanceTotal
    this.dayTotal = dayTotal
    this.absenceDate = absenceDate
    this.presenceId = presenceId
    this.courier = courier
    this.attendanceNote = attendanceNote
    this.histories = histories
    this.attendance = attendance
  }
}

export class ManualAdjustBasicFees {
  public data?: ManualAdjustBasicFee[]
  public pagination?: Pagination

  constructor(data?: ManualAdjustBasicFee[], pagination?: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}

export class ManualAdjustBasicFeeHistory {
  public id?: number
  public createdAt?: string
  public createdBy?: string
  public filename?: string
  public deletedAt?: string
  public deletedBy?: string
  public filepath?: string
  public reason?: string
  public statusApproval?: string
  public statusId?: string
  public updatedAt?: string
  public updatedBy?: string
  

  constructor(
    id?: number,
    createdAt?: string,
    createdBy?: string,
    filename?: string,
    deletedAt?: string,
    deletedBy?: string,
    filepath?: string,
    reason?: string,
    statusApproval?: string,
    statusId?: string,
    updatedAt?: string,
    updatedBy?: string,
  ) {
    this.id = id
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.filename = filename
    this.deletedAt = deletedAt
    this.deletedBy = deletedBy
    this.filepath = filepath
    this.reason = reason
    this.statusApproval = statusApproval
    this.statusId = statusId
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
  }
}

export class ManualAdjustBasicFeeHistories {
  public data?: ManualAdjustBasicFeeHistory[]
  public pagination?: Pagination

  constructor(data?: ManualAdjustBasicFeeHistory[], pagination?: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}

export class ManualAdjustBasicFeeHistoryDetailSummary {
  public totalRowValid?: number
  public totalRowInvalid?: number
  public totalCourier?: number
  public totalCourierValid?: number
  public totalCourierInvalid?: number
  public totalAmountValid?: UploadBulkBalanceAmount
  public totalAmountInvalid?: UploadBulkBalanceAmount

  constructor(
    totalRowValid?: number,
    totalRowInvalid?: number,
    totalCourier?: number,
    totalCourierValid?: number,
    totalCourierInvalid?: number,
    totalAmountValid?: UploadBulkBalanceAmount,
    totalAmountInvalid?: UploadBulkBalanceAmount
  ) {
    this.totalAmountInvalid = totalAmountInvalid
    this.totalAmountValid = totalAmountValid
    this.totalRowValid = totalRowValid
    this.totalRowInvalid = totalRowInvalid
    this.totalCourier = totalCourier
    this.totalCourierInvalid = totalCourierInvalid
    this.totalCourierValid = totalCourierValid
  }
}

export class ManualAdjustBasicFeeHistoryDetailDataUpload {
  public courierId?: number
  public sttNumber?: string
  public deliveryType?: string
  public amount?: number
  public note?: string
  public activityType?: string
  public status?: string
  public number?: number
  public reason?: string

  constructor(
    courierId?: number,
    sttNumber?: string,
    deliveryType?: string,
    amount?: number,
    note?: string,
    activityType?: string,
    status?: string,
    number?: number,
    reason?: string
  ) {
    this.courierId = courierId
    this.sttNumber = sttNumber
    this.deliveryType = deliveryType
    this.amount = amount
    this.note = note
    this.activityType = activityType
    this.status = status
    this.number = number
    this.reason = reason
  }
}

export class ManualAdjustBasicFeeHistoryDetailData {
  public filename?: string
  public filepath?: string
  public createdBy?: string 
  public createdAt?: string
  public status?: string
  public updatedAt?: string
  public updatedBy?: string
  public reason?: string
  public summary?: ManualAdjustBasicFeeHistoryDetailSummary
  public dataUpload?: ManualAdjustBasicFeeHistoryDetailDataUpload[]
  public dataValidStatus?: boolean
  public pagination?: boolean
  public filepathData?: string

  constructor(
    filename?: string,
    filepath?: string,
    createdBy?: string,
    createdAt?: string,
    status?: string,
    updatedAt?: string,
    updatedBy?: string,
    reason?: string,
    summary?: ManualAdjustBasicFeeHistoryDetailSummary,
    dataUpload?: ManualAdjustBasicFeeHistoryDetailDataUpload[],
    dataValidStatus?: boolean,
    pagination?: boolean,
    filepathData?: string
  ) {
    this.filename = filename
    this.filepath = filepath
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.status = status
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
    this.reason = reason
    this.summary = summary
    this.dataUpload = dataUpload
    this.dataValidStatus = dataValidStatus
    this.pagination = pagination
    this.filepathData = filepathData
  }
}

export class ManualAdjustBasicFeeHistoryDetail {
  public pagination?: Pagination
  public data?: ManualAdjustBasicFeeHistoryDetailData

  constructor(
    pagination?: Pagination,
    data?: ManualAdjustBasicFeeHistoryDetailData
  ) {
    this.pagination = pagination
    this.data = data
  }
}
