import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { MixPanelRepositoryInterface } from '@/data/persistences/repositories/contracts/MixPanelRepositoryInterface'
import { LoggingPubSubRequestInterface } from '@/data/payload/contracts/MixPanelRequest'

export class MixPanelApiRepository implements MixPanelRepositoryInterface {
  private service: ApiService

  constructor(service: ApiService) {
    this.service = service
  }

  public async loggingPubSub(payload: LoggingPubSubRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.MIX_PANEL,
      undefined,
      payload as LoggingPubSubRequestInterface
    )
    return true   
  }
}
