import { injectable } from 'tsyringe'
import { PackageApiRepository } from '@/app/infrastructures/repositories/api/PackageApiRepository'
import { Package } from '@/domain/entities/Package'

@injectable()
export class PackagePresenter {
  private repository: PackageApiRepository

  constructor(repository: PackageApiRepository) {
    this.repository = repository
  }

  public get(id: string): Promise<Package> {
    return this.repository.get(id)
  }
}
