import { Pagination } from "./Pagination"

export class WhiteListSeller {
  public pagination?: Pagination
  public data?: WhiteSeller[]

  constructor(
    pagination?: Pagination,
    data?: WhiteSeller[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class WhiteSeller {
  public id?: number
  public name?: string
  public partnerPOS?: string
  public partnerCode?: string
  public phone?: string
  public role?: string
  public status?: string
  public partnerId?: number
  public codHandling?: string
  public createdAt?: string
  public createdBy?: string
  public updatedAt?: string
  public updatedBy?: string

  constructor(
    id?: number,
    name?: string,
    partnerPOS?: string,
    partnerCode?: string,
    phone?: string,
    role?: string,
    status?: string,
    partnerId?: number,
    codHandling?: string,
    createdAt?: string,
    createdBy?: string,
    updatedAt?: string,
    updatedBy?: string
  ) {
    this.id = id
    this.name = name
    this.partnerPOS = partnerPOS
    this.partnerCode = partnerCode
    this.phone = phone
    this.role = role
    this.status = status
    this.partnerId = partnerId
    this.codHandling = codHandling
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
  }
}
