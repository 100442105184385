import {
  VoucherConfigSKURepositoryInterface
} from '@/data/persistences/repositories/contracts/VoucherConfigSKURepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import {VoucherConfigSKUMapper} from '@/data/persistences/mappers/VoucherConfigSKUMapper'
import {VoucherConfigSKUInterface} from '@/data/payload/contracts/VoucherConfigSKURequest'
import {UploadBulkyVoucherSKU, VoucherSKU, VoucherSKUList} from '@/domain/entities/VoucherConfigSKU'
import {Endpoints} from '@/app/infrastructures/misc'
import {UploadRequest} from '@/data/payload/api/UploadRequest'
import {CancelTokenSource} from 'axios'

export class VoucherConfigSKURepository implements VoucherConfigSKURepositoryInterface {
  private service: ApiService
  private mapper: VoucherConfigSKUMapper

  constructor(service: ApiService, mapper: VoucherConfigSKUMapper) {
    this.service = service
    this.mapper = mapper
  }

  async create(payload: VoucherConfigSKUInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku`,
      undefined,
      payload
    )
    return true
  }

  async update(id: number, payload: VoucherConfigSKUInterface): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku/${id}`,
      undefined,
      payload
    )
    return true
  }

  async delete(id: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku/${id}`,
      undefined,
      undefined
    )
    return true
  }

  async getAll(params: Map<string, unknown>): Promise<VoucherSKUList> {
    const data = await this.service.invoke(
      'GET',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku`,
      params,
      undefined
    )
    return this.mapper.convertVoucherSKUList(data)
  }

  async getOne(id: number): Promise<VoucherSKU> {
    const data = await this.service.invoke(
      'GET',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku/${id}`,
      undefined,
      undefined
    )
    return this.mapper.convertVoucherSKU(data)
  }

  public async uploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    const customService = new ApiService(Endpoints.baseUrl, 200000, cancelToken)

    await customService.invoke(
      'POST',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku/bulk-upload`,
      undefined,
      file
    )

    return true
  }

  public async previewUploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource,
    params?: Map<string, string>,
  ): Promise<UploadBulkyVoucherSKU> {
    const customService = new ApiService(Endpoints.baseUrl, 200000, cancelToken)

    const resp = await customService.invoke(
      'POST',
      `${Endpoints.Route.VOUCHER_CONFIG}/sku/bulk-upload/preview`,
      undefined,
      file,
    )
    return this.mapper.convertUploadBulkyFromApi(resp)
  }
}
