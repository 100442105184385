import { Pagination } from '@/domain/entities/Pagination'

export class QnA {
  public id?: number
  public question?: string
  public answer?: string
  public order?: number
  public isDraft?: boolean
  public isStar?: boolean
  public updatedAt?: string

  constructor(
    id?: number,
    question?: string,
    answer?: string,
    order?: number,
    isDraft?: boolean,
    isStar?: boolean,
    updatedAt?: string
  ) {
    this.id = id
    this.question = question
    this.answer = answer
    this.order = order
    this.isDraft = isDraft
    this.isStar = isStar
    this.updatedAt = updatedAt
  }
}

export class QnASubCategory {
  public id?: number
  public order?: number
  public subCategoryName?: string
  public data?: QnA[]

  constructor(
    id?: number,
    order?: number,
    subCategoryName?: string,
    data?: QnA[]
  ) {
    this.id = id
    this.order = order
    this.subCategoryName = subCategoryName
    this.data = data
  }
}

export class FAQ {
  public id?: number
  public categoryName?: string
  public order?: number
  public questionsCount?: number
  public questionsData?: QnA[]
  public isActive?: boolean
  public iconImage?: string
  public subCategoryList?: QnASubCategory[]
  public withSubCategory?: boolean
  public createdAt?: string
  public updatedAt?: string

  constructor(
    id?: number,
    categoryName?: string,
    order?: number,
    questionsCount?: number,
    questionsData?: QnA[],
    isActive?: boolean,
    iconImage?: string,
    subCategoryList?: QnASubCategory[],
    withSubCategory?: boolean,
    createdAt?: string,
    updatedAt?: string
  ) {
    this.id = id
    this.categoryName = categoryName
    this.order = order
    this.questionsCount = questionsCount
    this.questionsData = questionsData
    this.isActive = isActive
    this.iconImage = iconImage
    this.subCategoryList = subCategoryList
    this.withSubCategory = withSubCategory
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export class FAQs {
  public pagination?: Pagination
  public data?: FAQ[]

  constructor(pagination?: Pagination, data?: FAQ[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class FAQSubCategory {
  public subCategory?: string
  public order?: number
  public data?: QnA[]

  constructor(subCategory?: string, order?: number, data?: QnA[]) {
    this.subCategory = subCategory
    this.order = order
    this.data = data
  }
}

export class StarQuestion {
  public id?: number
  public question?: string
  public answer?: string
  public iconImage?: string
  public starOrder?: number
  public isDraft?: boolean
  public isStar?: boolean
  public updatedAt?: string

  constructor(
    id?: number,
    question?: string,
    answer?: string,
    iconImage?: string,
    starOrder?: number,
    isDraft?: boolean,
    isStar?: boolean,
    updatedAt?: string
  ) {
    this.id = id
    this.question = question
    this.answer = answer
    this.iconImage = iconImage
    this.starOrder = starOrder
    this.isDraft = isDraft
    this.isStar = isStar
    this.updatedAt = updatedAt
  }
}
