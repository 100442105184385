import { Pagination } from '@/domain/entities/Pagination'
import {
  Claim,
  ClaimHistory,
  Claims,
  InvoiceDetail,
  TimelineHistory,
  PackageInfo,
  ShipmentDetail,
  ShipmentInfo,
} from '@/domain/entities/Claim'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/data/infrastructures/misc/Utils'

export class ClaimMapper extends BaseResponseMapper {
  public convertClaimsFromApi(result: AxiosResponse): Claims {
    const {
      data: { data, pagination },
    } = result
    return new Claims(
      Utils.toInstance(new Pagination(), pagination),
      data.map((claim: Record<string, never>) => {
        return new Claim(
          claim.claim_id,
          claim.external_claim_id,
          claim.package_id,
          claim.old_package_id,
          claim.shipment_id,
          claim.customer_name,
          claim.customer_phone_number,
          claim.customer_wa_number,
          claim.customer_email,
          claim.ktp_image_url,
          claim.amount,
          claim.note,
          claim.claim_type,
          claim.claim_status_id,
          claim.actor_name,
          claim.actor_role,
          claim.created_at,
          claim.expired_at,
          claim.claim_history_created_at,
          claim.from,
          claim.claimer_status,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
        )
      })
    )
  }

  public convertClaimFromApi(result: AxiosResponse): Claim {
    const { data } = result
    return new Claim(
      data.claim_id,
      data.external_claim_id,
      data.package_id,
      data.old_package_id,
      data.shipment_id,
      data.customer_name,
      data.customer_phone_number,
      data.customer_wa_number,
      data.customer_email,
      data.ktp_image_url,
      data.amount,
      data.note,
      data.claim_type,
      undefined,
      undefined,
      undefined,
      data.created_at,
      data.expired_at,
      undefined,
      data.from,
      data.claimer_status,
      data.claim_history &&
        data.claim_history.map(
          (claimHistory: Record<string, never>) =>
            new ClaimHistory(
              claimHistory.claim_status_id,
              claimHistory.actor_name,
              claimHistory.actor_role,
              claimHistory.claim_history_created_at
            )
        ),
      new ShipmentDetail(
        new ShipmentInfo(
          data.detail_shipment.shipment.shipment_id,
          data.detail_shipment.shipment.created_at,
          data.detail_shipment.insurance_fee
        ),
        data.detail_shipment.shipment_history &&
          data.detail_shipment.shipment_history.map(
            (shipmentHistory: Record<string, never>) =>
              new TimelineHistory(
                shipmentHistory.status_id,
                shipmentHistory.status,
                shipmentHistory.long_status,
                shipmentHistory.created_at
              )
          ),
        new PackageInfo(
          data.detail_shipment.package.package_id,
          data.detail_shipment.package.product_type,
          data.detail_shipment.package.sender_name,
          data.detail_shipment.package.sender_origin,
          data.detail_shipment.package.sender_address,
          data.detail_shipment.package.recipient_name,
          data.detail_shipment.package.recipient_destination,
          data.detail_shipment.package.recipient_address,
          data.detail_shipment.package.quantity,
          data.detail_shipment.package.chargeable_weight,
          data.detail_shipment.package.total_fee,
          data.detail_shipment.package.total_delivery_fee,
          data.detail_shipment.package.total_underpay,
          data.detail_shipment.package.total_overpay
        ),
        data.detail_shipment.package_history &&
          data.detail_shipment.package_history.map(
            (packageHistory: Record<string, never>) =>
              new TimelineHistory(
                packageHistory.status_id,
                packageHistory.status,
                packageHistory.long_status,
                packageHistory.created_at
              )
          )
      ),
      data.detail_invoices &&
        data.detail_invoices.map(
          (invoice: Record<string, never>) =>
            new InvoiceDetail(
              invoice.invoice_id,
              invoice.payment_method,
              invoice.payment_status,
              invoice.finished_at
            )
        ),
      data.package_images && data.package_images.map((url: string) => url),
      data.stnk_images &&
        data.stnk_images.map((url: string) => url),
      data.invoice_image_url,
      data.bank_account_image_url
    )
  }
}
