import { injectable } from 'tsyringe'
import {
  BulkInsertPOSRequestInterface,
  UpdateStatusAndSubscriptionPOSRequestInterface,
  UpdatePOSRequestInterface,
} from '@/data/payload/contracts/POSRequest'
import { POSRepositoryInterface } from '@/data/persistences/repositories/contracts/POSRepositoryInterface'
import { CourierRepositoryInterface } from '@/data/persistences/repositories/contracts/CourierRepositoryInterface'
import { POS, POSBulkResponse, POSes, Subdistrict } from '@/domain/entities/POS'
import { Courier } from '@/domain/entities/Courier'

@injectable()
export class POSPresenter {
  private posRepository: POSRepositoryInterface
  private courierRepository: CourierRepositoryInterface

  constructor(
    posRepository: POSRepositoryInterface,
    courierRepository: CourierRepositoryInterface
  ) {
    this.posRepository = posRepository
    this.courierRepository = courierRepository
  }

  public getCourierByAgentId(agentId: string): Promise<Courier[]> {
    return this.courierRepository.getAllByAgentId(agentId)
  }

  public bulkInsert(
    payload: BulkInsertPOSRequestInterface
  ): Promise<POSBulkResponse> {
    return this.posRepository.bulkInsert(payload)
  }

  public update(
    agentId: string,
    payload: UpdatePOSRequestInterface
  ): Promise<boolean> {
    return this.posRepository.update(agentId, payload)
  }

  public get(agentId: string): Promise<POS> {
    return this.posRepository.get(agentId)
  }

  public getAll(params: Map<string, any>): Promise<POSes> {
    return this.posRepository.getAll(params)
  }

  public export(params: Map<string, any>): Promise<string> {
    return this.posRepository.export(params)
  }

  public findSubdistrict(params: Map<string, any>): Promise<Subdistrict[]> {
    return this.posRepository.findSubdistrict(params)
  }

  public updateStatusAndSubscription(
    agentId: string,
    payload: UpdateStatusAndSubscriptionPOSRequestInterface
  ): Promise<boolean> {
    return this.posRepository.updateStatusAndSubscription(agentId, payload)
  }
}
