import {AxiosResponse} from 'axios'
import {BaseResponseMapper} from '@/data/persistences/mappers/BaseMapper'
import {
  DetailUploadBulkyVoucherSKU, ResultUploadBulkyVoucherSKU,
  UploadBulkyVoucherSKU,
  VoucherSKU,
  VoucherSKUList
} from '@/domain/entities/VoucherConfigSKU'
import {
  DetailUploadBulkyInterface,
} from '@/data/responses/contracts/VoucherConfigResponseInterface'
import {Pagination} from '@/domain/entities/Pagination'

export class VoucherConfigSKUMapper extends BaseResponseMapper {
  convertVoucherSKU(result: AxiosResponse): VoucherSKU {
    const {
      data,
    } = result
    return new VoucherSKU(
      data.id,
      data.sku,
      data.name,
      data.amount,
      data.created_at,
      data.updated_at,
    );
  }

  convertVoucherSKUList(result: AxiosResponse): VoucherSKUList {
    const {
      data,
    } = result
    return new VoucherSKUList(
      new Pagination(),
      data.map((item: {
        id: number
        sku: string
        name: string
        amount: number
        created_at: string
        updated_at: string
      }) => new VoucherSKU(
        item.id,
        item.sku,
        item.name,
        item.amount,
        item.created_at,
        item.updated_at,
      )),
    );
  }

  public convertUploadBulkyFromApi(result: AxiosResponse): UploadBulkyVoucherSKU {
    const {data: metadata} = result.data
    return new UploadBulkyVoucherSKU(
      new ResultUploadBulkyVoucherSKU(
        metadata.file_name || '',
        Number(metadata.total_valid || 0),
        Number(metadata.total_invalid || 0),
      ),
      metadata.data?.map((val: DetailUploadBulkyInterface | null) => {
        return new DetailUploadBulkyVoucherSKU(
          val?.sku || '',
          val?.name || '',
          Number(val?.amount || 0),
          val?.status || '',
        )
      }),
    )
  }
}
