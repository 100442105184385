import { PhoneCodeRepositoryInterface } from "@/data/persistences/repositories/contracts/PhoneCodeRepositoryInterface";
import { PhoneCodes } from "@/domain/entities/PhoneCode";
import { injectable } from "tsyringe";

@injectable()
export class PhoneCodePresenter {
  private repository: PhoneCodeRepositoryInterface

  constructor(repository: PhoneCodeRepositoryInterface) {
    this.repository = repository
  }

  public getAll(): Promise<PhoneCodes> {
    return this.repository.getAll()
  }
}
