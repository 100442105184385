import { ManageCourier } from './ManageCourier'
import { Pagination } from './Pagination'

export class Shuttle {
  public shuttleId?: string
  public shuttleName?: string
  public shuttleAddress?: string
  public shuttleMapUrl?: string
  public shuttleRegion?: string
  public latitude?: number
  public longitude?: number
  public createdAt?: string
  public updatedAt?: string
  public shuttleCycle?: ShuttleCycle[]
  public shuttlePic?: ShuttlePIC[]

  constructor(
    shuttleId?: string,
    shuttleName?: string,
    shuttleAddress?: string,
    shuttleMapUrl?: string,
    shuttleRegion?: string,
    latitude?: number,
    longitude?: number,
    createdAt?: string,
    updatedAt?: string,
    shuttleCycle?: ShuttleCycle[],
    shuttlePic?: ShuttlePIC[]
  ) {
    this.shuttleId = shuttleId
    this.shuttleName = shuttleName
    this.shuttleAddress = shuttleAddress
    this.shuttleMapUrl = shuttleMapUrl
    this.shuttleRegion = shuttleRegion
    this.latitude = latitude
    this.longitude = longitude
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.shuttleCycle = shuttleCycle
    this.shuttlePic = shuttlePic
  }
}

export class ShuttleCycle {
  public startAt?: string
  public endAt?: string

  constructor(startAt?: string, endAt?: string) {
    this.startAt = startAt
    this.endAt = endAt
  }
}

export class ShuttlePIC {
  public picName?: string
  public picPhoneNumber?: string

  constructor(picName?: string, picPhoneNUmber?: string) {
    this.picName = picName
    this.picPhoneNumber = picPhoneNUmber
  }
}

export class Shuttles {
  public data?: Shuttle[]
  public pagination?: Pagination

  constructor(data?: Shuttle[], pagination?: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}

export class ManageCourierCaptain extends ManageCourier {
  public courierCaptainId?: number

  constructor(
    courierId?: number,
    fullName?: string,
    role?: string,
    phoneNumber?: string,
    vehicleType?: string,
    vehiclePlate?: string,
    partnerType?: string,
    partnerName?: string,
    origin?: string,
    courierType?: string,
    status?: string,
    courierTypeValid?: string,
    courierCaptainId?: number
  ) {
    super(
      courierId,
      fullName,
      role,
      phoneNumber,
      vehicleType,
      vehiclePlate,
      partnerType,
      partnerName,
      origin,
      courierType,
      status,
      courierTypeValid
    )
    this.courierCaptainId = courierCaptainId
  }
}

export class ShuttleDetail extends Shuttle {
  public shuttleCourierCaptain?: ManageCourierCaptain
  public shuttleCourier?: ManageCourier[]
  public isCaptainSkStatus?: string

  constructor(
    shuttleId?: string,
    shuttleName?: string,
    shuttleAddress?: string,
    shuttleMapUrl?: string,
    shuttleRegion?: string,
    latitude?: number,
    longitude?: number,
    createdAt?: string,
    updatedAt?: string,
    shuttleCycle?: ShuttleCycle[],
    shuttlePic?: ShuttlePIC[],
    shuttleCourierCaptain?: ManageCourierCaptain,
    shuttleCourier?: ManageCourier[],
    isCaptainSkStatus?: string
  ) {
    super(
      shuttleId,
      shuttleName,
      shuttleAddress,
      shuttleMapUrl,
      shuttleRegion,
      latitude,
      longitude,
      createdAt,
      updatedAt,
      shuttleCycle,
      shuttlePic
    )
    this.shuttleCourierCaptain = shuttleCourierCaptain
    this.shuttleCourier = shuttleCourier || []
    this.isCaptainSkStatus = isCaptainSkStatus
  }
}

export class CourierShuttle {
  public shuttleCourierId?: number
  public shuttleId?: string
  public courierId?: string
  public shuttleName?: string
  public shuttleRegion?: string
  public shuttleAddress?: string
  public longitude?: number
  public latitude?: number
  public shuttleMapUrl?: string
  public locationGeo?: string
  public createdAt?: string
  public courierCaptainId?: number
  public isCaptain?: boolean

  constructor(
    shuttleCourierId?: number,
    shuttleId?: string,
    courierId?: string,
    shuttleName?: string,
    shuttleRegion?: string,
    shuttleAddress?: string,
    longitude?: number,
    latitude?: number,
    shuttleMapUrl?: string,
    locationGeo?: string,
    createdAt?: string,
    courierCaptainId?: number,
    isCaptain?: boolean ,
  ) {
    this.shuttleCourierId = shuttleCourierId
    this.shuttleId = shuttleId
    this.courierId = courierId
    this.shuttleName = shuttleName
    this.shuttleRegion = shuttleRegion
    this.shuttleAddress = shuttleAddress
    this.longitude = longitude
    this.latitude = latitude
    this.shuttleMapUrl = shuttleMapUrl
    this.locationGeo = locationGeo
    this.createdAt = createdAt
    this.courierCaptainId = courierCaptainId
    this.isCaptain = isCaptain
  }
}

export class CourierShuttles {
  public data?: CourierShuttle[]
  public pagination?: Pagination

  constructor(data?: CourierShuttle[], pagination?: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}
