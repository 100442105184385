export class SelectedMerchant {
  selectedMerchantID?: number
  merchantName?: string
  merchantID?: number
  status?: string
  position?: number

  constructor(
    selectedMerchantID?: number,
    merchantName?: string,
    merchantID?: number,
    status?: string,
    position?: number
  ) {
    this.selectedMerchantID = selectedMerchantID
    this.merchantName = merchantName
    this.merchantID = merchantID
    this.status = status
    this.position = position
  }
}
