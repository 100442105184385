import { RouteConfig } from 'vue-router'
import BACKEND_SLUG from '@/app/ui/router/constants'

export const manualAdjustBasicFee: RouteConfig = {
  path: 'manual-adjust-basic-fee',
  name: 'ManualAdjustBasicFee',
  redirect: {
    name: 'ManualAdjustBasicFeeList',
  },
  component: {
    render: c => c('router-view'),
  },
  children: [
    {
      path: '',
      name: 'ManualAdjustBasicFeeList',
      component: () =>
        import(
          /* webpackChunkName: "ManualAdjustBasicFeeList" */ '@/app/ui/views/Payroll/ManualAdjustBasicFee/index.vue'
        ),
      meta: {
        title: 'Manual Adjust Basic Fee',
        slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_BASIC_FEE,
        accessType: 'read',
      },
    },
    {
      path: 'detail/:historyId',
      name: 'ManualAdjustBasicFeeDetail',
      component: () =>
        import(
          /* webpackChunkName: "ManualAdjustBasicFeeDetail" */ '@/app/ui/views/Payroll/ManualAdjustBasicFee/Detail/index.vue'
        ),
      meta: {
        title: 'Detail',
        slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_BASIC_FEE,
        accessType: 'read',
      },
    },
    {
      path: 'upload-bulky',
      name: 'UploadBulkyManualAdjustBasicFee',
      component: () =>
        import(
          /* webpackChunkName: "UploadBulkyManualAdjustBasicFee" */ '@/app/ui/views/Payroll/ManualAdjustBasicFee/UploadBulky/index.vue'
        ),
      meta: {
        title: 'Upload Bulky',
        slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_BASIC_FEE,
        accessType: 'write',
      },
    },
    {
      path: 'upload-bulky/list',
      name: 'UploadBulkyManualAdjustBasicFeeList',
      component: () =>
        import(
          /* webpackChunkName: "UploadBulkyManualAdjustBasicFeeList" */ '@/app/ui/views/Payroll/ManualAdjustBasicFee/UploadBulky/index.vue'
        ),
      meta: {
        title: 'Upload Bulky',
        slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_BASIC_FEE,
        accessType: 'write',
      },
    },
  ],
  meta: {
    title: 'Manual Adjust Basic Fee',
    slugFromBackend: BACKEND_SLUG.MANUAL_ADJUST_BASIC_FEE,
    accessType: 'read',
    isParent: true,
  },
}
