import {injectable} from 'tsyringe'
import {
  VoucherConfigSetLimitRepositoryInterface
} from '@/data/persistences/repositories/contracts/VoucherConfigSetLimitRepositoryInterface'
import {VoucherConfigSKUInterface} from '@/data/payload/contracts/VoucherConfigSKURequest'
import {
  UploadBulkyVoucherSetLimitSpecificCustomer,
  VoucherSetLimit,
  VoucherSetLimitList,
} from '@/domain/entities/VoucherConfigSetLimit'
import {UploadRequest} from '@/data/payload/api/UploadRequest'
import {CancelTokenSource} from 'axios'

@injectable()
export class VoucherConfigSetLimitPresenter {
  private readonly repository: VoucherConfigSetLimitRepositoryInterface

  constructor(repo: VoucherConfigSetLimitRepositoryInterface) {
    this.repository = repo
  }

  public getAll(params: Map<string, unknown>): Promise<VoucherSetLimitList> {
    return this.repository.getAll(params);
  }

  public getOne(id: number): Promise<VoucherSetLimit> {
    return this.repository.getOne(id);
  }

  public create(payload: VoucherConfigSKUInterface): Promise<boolean> {
    return this.repository.create(payload);
  }

  public update(id: number, payload: VoucherConfigSKUInterface): Promise<boolean> {
    return this.repository.update(id, payload);
  }

  public delete(id: number): Promise<boolean> {
    return this.repository.delete(id);
  }

  public uploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    return this.repository.uploadBulky(file, cancelToken)
  }

  public previewUploadBulky(file: UploadRequest, cancelToken: CancelTokenSource, params?: Map<string, string>): Promise<UploadBulkyVoucherSetLimitSpecificCustomer> {
    return this.repository.previewUploadBulky(file, cancelToken, params)
  }

}
