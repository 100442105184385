export class LoyaltyProgramGetPointRules {
  public pointRulesId?: number
  public isRegistered?: boolean
  public isUnRegistered?: boolean
  public transactionType?: string[]
  public shipmentType?: string[]
  public shipmentStatus?: string[]
  public minimumAmount?: number
  public isWithoutShipment?: boolean
  public createdAt?: string
  public createdBy?: number

  constructor (
    pointRulesId?: number,
    isRegistered?: boolean,
    isUnRegistered?: boolean,
    transactionType?: string[],
    shipmentType?: string[],
    shipmentStatus?: string[],
    minimumAmount?: number,
    isWithoutShipment?: boolean,
    createdAt?: string,
    createdBy?: number,
  ) {
    this.pointRulesId =  pointRulesId
    this.isRegistered =isRegistered
    this.isUnRegistered = isUnRegistered
    this.transactionType = transactionType
    this.shipmentType = shipmentType
    this.shipmentStatus = shipmentStatus
    this.minimumAmount = minimumAmount
    this.isWithoutShipment = isWithoutShipment
    this.createdAt = createdAt
    this.createdBy = createdBy
  }
}
