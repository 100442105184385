import { injectable } from 'tsyringe'
import { ManageDataPayrollRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageDataPayrollRepositoryInterface'
import {
  ManageDataPayrollDetails,
  ManageDataPayrollHistories,
  ManageDataPayrolls,
} from '@/domain/entities/ManageDataPayroll'
import { UpdateManageDataPayrollRequestInterface } from '@/data/payload/contracts/ManageDataPayrollRequest'

@injectable()
export class ManageDataPayrollPresenter {
  private repository: ManageDataPayrollRepositoryInterface

  constructor(repository: ManageDataPayrollRepositoryInterface) {
    this.repository = repository
  }

  public getAll(
    params: Map<string, string | number>
  ): Promise<ManageDataPayrolls> {
    return this.repository.getAll(params)
  }

  public getAllDetail(
    id: number,
    params: Map<string, string | number>
  ): Promise<ManageDataPayrollDetails> {
    return this.repository.getAllDetail(id, params)
  }

  public getAllHistory(
    id: number,
    params: Map<string, string | number>
  ): Promise<ManageDataPayrollHistories> {
    return this.repository.getAllHistory(id, params)
  }

  public update(
    id: number,
    payload: UpdateManageDataPayrollRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }
}
