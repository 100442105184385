import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'

import {
  DetailAdjustmentParcelPoin,
  HistoryUploadBulkyParcelPoin,
  ListAdjustmentParcelPoin,
  ListHistoryLogPoinUser,
  UploadBulkyParcelPoin,
} from '@/domain/entities/AdjustmentParcelPoin'
import { AdjustmentParcelPoinMapper } from '@/data/persistences/mappers/AdjustmentParcelPoinMapper'
import { AdjustmentParcelPoinRepositoryInterface } from '@/data/persistences/repositories/contracts/AdjustmentParcelPoinRepositoryInterface'
import {
  AdjustmentParcelPoinRequestPoinRequestInterface,
  AdjustmentParcelPoinApprovingPoinRequestInterface,
} from '@/data/payload/contracts/AdjustmentParcelPoinRequest'
import { UploadRequest } from '@/data/payload/api/UploadRequest'
import { CancelTokenSource } from 'axios'
import { ApproveRejectBulkyParcelPoinRequest } from '@/data/payload/api/AdjustmentParcelPoinRequest'

export class AdjustmentParcelPoinRepository
  implements AdjustmentParcelPoinRepositoryInterface {
  private service: ApiService
  private mapper: AdjustmentParcelPoinMapper

  constructor(service: ApiService, mapper: AdjustmentParcelPoinMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(
    params: Map<string, string | number>
  ): Promise<ListAdjustmentParcelPoin> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADJUSTMENT_PARCEL_POIN}/customer-list`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertResponseListAdjustmentParcelPoinFromApi(resp)
  }

  public async postRequestPoin(
    payload: AdjustmentParcelPoinRequestPoinRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.ADJUSTMENT_PARCEL_POIN}`,
      undefined,
      payload
    )

    return true
  }

  public async getHistoryLogPoinUser(
    params: Map<string, number>
  ): Promise<ListHistoryLogPoinUser> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADJUSTMENT_PARCEL_POIN}/list`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertResponseListHistoryLogPoinUserFromApi(resp)
  }

  public async getDetail(id: number): Promise<DetailAdjustmentParcelPoin> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.ADJUSTMENT_PARCEL_POIN}/${id}`,
      undefined,
      undefined
    )

    return this.mapper.convertResponseDetailAdjustmentParcelPoinFromApi(resp)
  }

  public async postApprovingPoin(
    payload: AdjustmentParcelPoinApprovingPoinRequestInterface
  ): Promise<boolean> {
    const resp = await this.service.invoke(
      'post',
      `${Endpoints.Route.ADJUSTMENT_PARCEL_POIN}/approval`,
      undefined,
      payload
    )

    return true
  }

  public async uploadBulky(
    file: UploadRequest,
    cancelToken: CancelTokenSource
  ): Promise<boolean> {
    const customService = new ApiService(Endpoints.baseUrl, 200000, cancelToken)

    await customService.invoke(
      'POST',
      `${Endpoints.Route.PARCEL_POIN}/bulk-adjustment/upload`,
      undefined,
      file
    )

    return true
  }

  public async getHistoryUploadBulky(
    params: Map<string, string>
  ): Promise<HistoryUploadBulkyParcelPoin> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PARCEL_POIN}/bulk-adjustment/history`,
      params
    )

    return this.mapper.convertHistoryUploadBulky(resp)
  }

  public async previewUploadBulky(
    params: Map<string, string>
  ): Promise<UploadBulkyParcelPoin> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PARCEL_POIN}/bulk-adjustment/preview`,
      params
    )
    return this.mapper.convertUploadBulkyFromApi(resp)
  }

  public async processBulkyRequestParcelPoin(): Promise<boolean> {
    const customService = new ApiService(Endpoints.baseUrl, 300000)

    // change end point next development
    await customService.invoke(
      'POST',
      `${Endpoints.Route.PARCEL_POIN}/bulk-adjustment/process`
    )

    return true
  }

  public async postApproveRejectBulkyParcelPoin(
    payload: ApproveRejectBulkyParcelPoinRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.PARCEL_POIN}/bulk-adjustment/update-status`,
      undefined,
      payload
    )

    return true
  }
}
