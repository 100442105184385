import {
  CreateMerchantRequestInterface,
  UpdateMerchantRequestInterface,
  UpdateMerchantAccountRequestInterface,
  UpdateMerchantStatusRequestInterface,
} from '@/data/payload/contracts/MerchantRequest'
import ApiService from '@/app/infrastructures/services/ApiService'
import { MerchantRepositoryInterface } from '@/data/persistences/repositories/contracts/MerchantRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { MerchantMapper } from '@/data/persistences/mappers/MerchantMapper'
import { MerchantDetail, Merchants } from '@/domain/entities/Merchant'

export class MerchantApiRepository implements MerchantRepositoryInterface {
  private service: ApiService
  private mapper: MerchantMapper

  constructor(service: ApiService, mapper: MerchantMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(merchantId: string): Promise<MerchantDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MERCHANT_ADMIN}/${merchantId}`
    )
    return this.mapper.convertMerchantFromApi(resp)
  }

  public async getAll(
    params: Map<string, string | number | undefined>
  ): Promise<Merchants> {
    const resp = await this.service.invoke('get', `${Endpoints.Route.MERCHANT}/data`, params)
    return this.mapper.convertMerchantsFromApi(resp)
  }

  public async create(payload: CreateMerchantRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.V2MERCHANT,
      undefined,
      payload as CreateMerchantRequestInterface
    )
    return true
  }

  public async update(
    merchantId: number,
    payload: UpdateMerchantRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.MERCHANT}/${merchantId}/profile`,
      undefined,
      payload as UpdateMerchantRequestInterface
    )
    return true
  }

  public async updateAccount(
    merchantId: number,
    payload: UpdateMerchantAccountRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.MERCHANT}/${merchantId}/account`,
      undefined,
      payload as UpdateMerchantAccountRequestInterface
    )
    return true
  }

  public async updateStatus(
    merchantId: number,
    payload: UpdateMerchantStatusRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.MERCHANT}/${merchantId}/status`,
      undefined,
      payload as UpdateMerchantStatusRequestInterface
    )
    return true
  }
}
