import { LoyaltyProgramGetPointRules } from "@/domain/entities/LoyaltyProgramGetPointRules";
import { AxiosResponse } from "axios";
import { BaseResponseMapper } from "./BaseMapper";

export class LoyaltyProgramGetPointRulesMapper extends BaseResponseMapper {
  public convertGetPointRules(dataGetPointRules: AxiosResponse): LoyaltyProgramGetPointRules {
    return new LoyaltyProgramGetPointRules(
      dataGetPointRules.data.point_rules_id,
      dataGetPointRules.data.is_registered,
      dataGetPointRules.data.is_unregistered,
      dataGetPointRules.data.transaction_type,
      dataGetPointRules.data.shipment_type,
      dataGetPointRules.data.shipment_status,
      dataGetPointRules.data.minimum_amount,
      dataGetPointRules.data.is_without_shipment,
      dataGetPointRules.data.created_at,
      dataGetPointRules.data.created_by
    )
  }
}
