import { injectable } from 'tsyringe'
import {
  DataExtraMultiplier,
  ListExtraMultiplier,
  ListMembershipFormula,
  ListMultiplierLevel,
  MembershipFormula,
} from '@/domain/entities/MembershipFormula'
import { MembershipFormulaRepositoryInterface } from '@/data/persistences/repositories/contracts/MembershipFormulaRepositoryInterface'
import { UpdateMembershipFormulaRequestInterface } from '@/data/payload/contracts/MembershipFormulaRequest'
import {
  CreateExtraMultiplierRequest,
  CreateLoyaltyProgramMembershipFormula,
  UpdateExtraMultiplierRequest,
} from '@/data/payload/api/MembershipFormulaRequest'

@injectable()
export class MembershipFormulaPresenter {
  private repository: MembershipFormulaRepositoryInterface

  constructor(repository: MembershipFormulaRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, unknown>): Promise<ListMembershipFormula> {
    return this.repository.getAll(params)
  }

  public get(params: Map<string, string | number>): Promise<MembershipFormula> {
    return this.repository.get(params)
  }

  public delete(id: number | string): Promise<boolean> {
    return this.repository.delete(id)
  }

  public update(
    id: string,
    payload: UpdateMembershipFormulaRequestInterface
  ): Promise<boolean> {
    return this.repository.update(id, payload)
  }

  public create(
    payload: CreateLoyaltyProgramMembershipFormula
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public getMultiplier(): Promise<ListMultiplierLevel> {
    return this.repository.getMultiplier()
  }

  public createExtraMultiplier(
    payload: CreateExtraMultiplierRequest
  ): Promise<boolean> {
    return this.repository.createExtraMultiplier(payload)
  }

  public getListExtraMultiplier(
    params: Map<string, string>
  ): Promise<ListExtraMultiplier> {
    return this.repository.getListExtraMultiplier(params)
  }

  public deleteExtraMultiplier(id: string): Promise<boolean> {
    return this.repository.deleteExtraMultiplier(id)
  }

  public getDetailExtraMultiplier(id: string): Promise<DataExtraMultiplier> {
    return this.repository.getDetailExtraMultiplier(id)
  }

  public updateExtraMultiplier(
    payload: UpdateExtraMultiplierRequest
  ): Promise<boolean> {
    return this.repository.updateExtraMultiplier(payload)
  }
}
