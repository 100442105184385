import { AxiosResponse } from 'axios'
import {
  HomepageBanners,
  HomepageBanner,
} from '@/domain/entities/HomepageBanner'
import { Pagination } from '@/domain/entities/Pagination'
import { Utils } from '@/data/infrastructures/misc/Utils'
import { BaseResponseMapper } from './BaseMapper'

export class HomepageBannerMapper extends BaseResponseMapper {
  public convertHomepageBannerFromApi(
    result: AxiosResponse<any>
  ): HomepageBanner {
    const { data } = result
    return new HomepageBanner(
      data.id as number,
      data.title as string,
      data.image_url as string,
      data.url_link as string,
      data.start_time as string,
      data.end_time as string,
      data.status as string,
      data.slide_text as string
    )
  }

  public convertHomepageBannersFromApi(
    result: AxiosResponse<any>
  ): HomepageBanners {
    const {
      data: { data, pagination },
    } = result
    return new HomepageBanners(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (homepageBanner: Record<string, string | number>) =>
          new HomepageBanner(
            homepageBanner.id as number,
            homepageBanner.title as string,
            homepageBanner.image_url as string,
            homepageBanner.url_link as string,
            homepageBanner.start_time as string,
            homepageBanner.end_time as string,
            homepageBanner.status as string,
            homepageBanner.slide_text as string
          )
      )
    )
  }

  public convertActiveHomepageBannersFromApi(
    result: AxiosResponse<any>
  ): HomepageBanner[] {
    const { data } = result
    return data.map(
      (homepageBanner: Record<string, string | number>) =>
        new HomepageBanner(
          homepageBanner.id as number,
          homepageBanner.title as string,
          homepageBanner.image_url as string,
          homepageBanner.url_link as string,
          homepageBanner.start_time as string,
          homepageBanner.end_time as string,
          homepageBanner.status as string,
          homepageBanner.slide_text as string
        )
    )
  }
}
