import { EnumLoyaltiProgramChannel, EnumExtraMultiplierProductType } from "@/app/infrastructures/misc/Constants/loyaltyProgram"
import { Pagination } from "./Pagination"

export class MembershipFormula {
  public membershipPointFormulaId?: number
  public channel?: string
  public nominal?: number
  public isSender?: boolean
  public isReceiver?: boolean
  public levelInfo?: string
  public startDate?: Date | string
  public endDate?: Date | string
  public status?: string

  constructor(
    membershipPointFormulaId?: number,
    channel?: string,
    nominal?: number,
    isSender?: boolean,
    isReceiver?: boolean,
    levelInfo?: string,
    startDate?: Date | string,
    endDate?: Date | string,
    status?: string
  ) {
    this.membershipPointFormulaId = membershipPointFormulaId
    this.channel = channel
    this.nominal = nominal
    this.isSender = isSender
    this.isReceiver = isReceiver
    this.levelInfo = levelInfo
    this.startDate = startDate
    this.endDate = endDate
    this.status = status
  }
}

export class ListMembershipFormula {
  public data?: Array<MembershipFormula>

  constructor(
    data?: Array<MembershipFormula>
  ) {
    this.data = data
  }
}

export class MultiplierLevel {
  public levelId?: number
  public levelName?: string
  public minimumAmountTransaction?: number
  public minimumNumberTransaction?: number

  constructor(
    level_id: number,
    level_name: string,
    minimum_amount_transaction: number,
    minimum_number_transaction: number
  ) {
    this.levelId = level_id
    this.levelName = level_name
    this.minimumAmountTransaction = minimum_amount_transaction
    this.minimumNumberTransaction = minimum_number_transaction
  }
}

export class ListMultiplierLevel {
  data?: Array<MultiplierLevel>

  constructor(data?: Array<MultiplierLevel>) {
    this.data = data
  }
}

export class DataExtraMultiplier {
  id?: number
  channel?: EnumLoyaltiProgramChannel
  productType?: EnumExtraMultiplierProductType
  extraMultiplierInfo?: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string

  constructor(
    id?: number,
    channel?: EnumLoyaltiProgramChannel,
    productType?: EnumExtraMultiplierProductType,
    extraMultiplierInfo?: string,
    createdAt?: string,
    updatedAt?: string,
    deletedAt?: string
  ) {
    this.id =  id;
    this.channel = channel;
    this.productType = productType;
    this.extraMultiplierInfo = extraMultiplierInfo;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.deletedAt = deletedAt;
  }
}

export class ListExtraMultiplier {
  pagination: Pagination
  data: Array<DataExtraMultiplier>

  constructor(
    pagination: Pagination,
    data: Array<DataExtraMultiplier>,
  ) {
    this.pagination = pagination;
    this.data = data;
  }
}
