export class Validation {
  public isMaxDonate?: boolean
  public isInvalidTime?: boolean
  public isValidated?: boolean

  constructor(
    isMaxDonate?: boolean,
    isInvalidTime?: boolean,
    isValidated?: boolean
  ) {
    this.isMaxDonate = isMaxDonate
    this.isInvalidTime = isInvalidTime
    this.isValidated = isValidated
  }
}

export class PackageType {
  public books?: boolean
  public toys?: boolean
  public clothes?: boolean
  public rice?: boolean
}
