import { WithdrawalRepositoryInterface } from '@/data/persistences/repositories/contracts/WithdrawalRepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import { WithdrawalMapper } from '@/data/persistences/mappers/WithdrawalMapper'
import { Withdrawal, Withdrawals } from '@/domain/entities/Withdrawal'
import { Endpoints } from '@/app/infrastructures/misc'
import { UpdateWithdrawalRequestInterface } from '@/data/payload/contracts/WithdrawalRequest'
import { UpdateWithdrawalRequest } from '@/data/payload/api/WithdrawalRequest'

export class WithdrawalApiRepository implements WithdrawalRepositoryInterface {
  private service: ApiService
  private mapper: WithdrawalMapper

  constructor(service: ApiService, mapper: WithdrawalMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(withdrawalId: string, params: Map<string, any>): Promise<Withdrawal> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.WITHDRAWAL}/${withdrawalId}`,
      params
    )
    return this.mapper.convertWithdrawalFromApi(resp)
  }

  public async getAll(params: Map<string, any>): Promise<Withdrawals> {
    const resp = await  this.service.invoke(
      'get',
      `${Endpoints.Route.WITHDRAWAL}`,
      params
    )
    return this.mapper.convertWithdrawalsFromApi(resp)
  }

  public async update(
    withdrawalId: string,
    payload: UpdateWithdrawalRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.WITHDRAWAL}/${withdrawalId}`,
      undefined,
      payload as UpdateWithdrawalRequest
    )
    return true;
  }
}
