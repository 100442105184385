import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { UpdateAppVersionRepositoryInterface } from '@/data/persistences/repositories/contracts/UpdateAppVersionRepositoryInterface'
import { UpdateAppVersionRequestInterface } from '@/data/payload/contracts/UpdateAppVersionRequest'
import {
  UpdateAppVersions,
  UpdateAppVersion,
} from '@/domain/entities/UpdateAppVersion'
import { UpdateAppVersionMapper } from '@/data/persistences/mappers/UpdateAppVersionMapper'

export class UpdateAppVersionApiRepository
  implements UpdateAppVersionRepositoryInterface {
  private service: ApiService
  private mapper: UpdateAppVersionMapper

  constructor(service: ApiService, mapper: UpdateAppVersionMapper) {
    this.service = service
    this.mapper = mapper
  }

  async create(payload: UpdateAppVersionRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      Endpoints.Route.UPDATE_APP_VERSION,
      undefined,
      payload
    )
    return true
  }

  async update(
    id: number,
    payload: UpdateAppVersionRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.UPDATE_APP_VERSION}/${id}`,
      undefined,
      payload
    )
    return true
  }

  async getAll(params: Map<string, unknown>): Promise<UpdateAppVersions> {
    const data = await this.service.invoke(
      'GET',
      `${Endpoints.Route.UPDATE_APP_VERSION}/list`,
      params,
      undefined
    )
    return this.mapper.convertOriginFromApiList(data)
  }

  async getOne(id: number): Promise<UpdateAppVersion> {
    const data = await this.service.invoke(
      'GET',
      `${Endpoints.Route.UPDATE_APP_VERSION}/${id}`,
      undefined,
      undefined
    )
    return this.mapper.convertOriginFromApiDetail(data)
  }

  async cancel(id: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.UPDATE_APP_VERSION}/${id}`,
      undefined,
      undefined
    )
    return true
  }
}
