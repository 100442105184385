import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import {
  DataExtraMultiplier,
  ListExtraMultiplier,
  ListMembershipFormula,
  ListMultiplierLevel,
  MembershipFormula,
} from '@/domain/entities/MembershipFormula'
import { MembershipFormulaMapper } from '@/data/persistences/mappers/MembershipFormulaMapper'
import {
  CreateLoyaltyProgramMembershipFormulaInterface,
  UpdateMembershipFormulaRequestInterface,
} from '@/data/payload/contracts/MembershipFormulaRequest'
import { MembershipFormulaRepositoryInterface } from '@/data/persistences/repositories/contracts/MembershipFormulaRepositoryInterface'
import {
  CreateExtraMultiplierRequest,
  UpdateExtraMultiplierRequest,
} from '@/data/payload/api/MembershipFormulaRequest'

export class MembershipFormulaApiRepository
  implements MembershipFormulaRepositoryInterface {
  private service: ApiService
  private mapper: MembershipFormulaMapper

  constructor(service: ApiService, mapper: MembershipFormulaMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(
    params: Map<string, unknown>
  ): Promise<ListMembershipFormula> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.MEMBERSHIP_FORMULA,
      params
    )
    return this.mapper.convertLoyaltyProgramMembershipFormulaFromApi(resp)
  }

  public async get(
    params: Map<string, string | number>
  ): Promise<MembershipFormula> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.MEMBERSHIP_FORMULA}`,
      params
    )

    return this.mapper.convertDetailMembershipFormulaFromApi(resp)
  }

  public async delete(id: number | string): Promise<boolean> {
    await this.service.invoke(
      'delete',
      `${Endpoints.Route.MEMBERSHIP_FORMULA}/${id}`
    )
    return true
  }

  public async update(
    id: string,
    payload: UpdateMembershipFormulaRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.MEMBERSHIP_FORMULA}/${id}`,
      undefined,
      <UpdateMembershipFormulaRequestInterface>payload
    )

    return true
  }

  public async create(
    payload: CreateLoyaltyProgramMembershipFormulaInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.MEMBERSHIP_FORMULA,
      undefined,
      payload
    )

    return true
  }

  public async getMultiplier(): Promise<ListMultiplierLevel> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.LOYALTY_MEMBERSHIP}/level/active`
    )
    return this.mapper.convertMultiplierLevelMembershipProgram(resp)
  }

  public async createExtraMultiplier(
    payload: CreateExtraMultiplierRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      Endpoints.Route.MEMBERSHIP_EXTRA_MULTIPLIER,
      undefined,
      payload
    )

    return true
  }

  public async getListExtraMultiplier(
    params: Map<string, string>
  ): Promise<ListExtraMultiplier> {
    const resp = await this.service.invoke(
      'GET',
      Endpoints.Route.MEMBERSHIP_EXTRA_MULTIPLIER,
      params,
      undefined
    )

    return this.mapper.convertListExtraMultiplier(resp)
  }

  public async deleteExtraMultiplier(id: string): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.MEMBERSHIP_EXTRA_MULTIPLIER}/${id}`,
      undefined,
      undefined
    )

    return true
  }

  public async getDetailExtraMultiplier(
    id: string
  ): Promise<DataExtraMultiplier> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.MEMBERSHIP_EXTRA_MULTIPLIER}/${id}`,
      undefined,
      undefined
    )

    return this.mapper.convertDetailExtraMultiplier(resp)
  }

  public async updateExtraMultiplier(
    payload: UpdateExtraMultiplierRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      Endpoints.Route.MEMBERSHIP_EXTRA_MULTIPLIER,
      undefined,
      payload
    )

    return true
  }
}
