import { Pagination } from '@/domain/entities/Pagination'

export class ApprovalAdjustSaldoList {
  public pagination?: Pagination
  public data?: ApprovalAdjustSaldo[]

  constructor(
    pagination?: Pagination,
    data?: ApprovalAdjustSaldo[]
  ) {
    this.pagination = pagination
    this.data = data
  }
}

export class ApprovalAdjustSaldo {
  public id?: number
  public filename?: string
  public createdBy?: string
  public createdAt?: string
  public updatedBy?: string
  public updatedAt?: string
  public status?: string
  public reason?: string

  constructor(
    id?: number,
    filename?: string,
    createdBy?: string,
    createdAt?: string,
    updatedBy?: string,
    updatedAt?: string,
    statusApproval?: string,
    reason?: string
  ) {
    this.id = id
    this.filename = filename
    this.createdBy = createdBy
    this.createdAt = createdAt
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt
    this.status = statusApproval
    this.reason = reason
  }
}

export class ApprovalAdjustSaldoDetail {
  public createdBy?: string
  public createdAt?: string
  public updatedBy?: string
  public updatedAt?: string
  public status?: string
  public reason?: string
  public dataUpload?: DataUploadApproval
  public pagination?: Pagination

  constructor(
    createdBy?: string,
    createdAt?: string,
    updatedBy?: string,
    updatedAt?: string,
    status?: string,
    reason?: string,
    dataUpload?: DataUploadApproval,
    pagination?: Pagination
  ) {
    this.createdBy = createdBy
    this.createdAt = createdAt
    this.updatedBy = updatedBy
    this.updatedAt = updatedAt
    this.status = status
    this.reason = reason
    this.dataUpload = dataUpload
    this.pagination = pagination
  }
}

export class DataUploadApproval {
  public filename?: string
  public filepath?: string
  public summary?: SummaryDataUpload
  public rows?: RowsApproval[]

  constructor(
    filename?: string,
    filepath?: string,
    summary?: SummaryDataUpload,
    rows?: RowsApproval[]
  ) {
    this.filename = filename
    this.filepath = filepath
    this.summary = summary
    this.rows = rows
  }
}

export class SummaryDataUpload {
  public rowValid?: number
  public rowInvalid?: number
  public courierValid?: number
  public courierInvalid?: number
  public amountValid?: AmountApproval
  public amountInvalid?: AmountApproval

  constructor(
    rowValid?: number,
    rowInvalid?: number,
    courierValid?: number,
    courierInvalid?: number,
    amountValid?: AmountApproval,
    amountInvalid?: AmountApproval
  ) {
    this.rowValid = rowValid
    this.rowInvalid = rowInvalid
    this.courierValid = courierValid
    this.courierInvalid = courierInvalid
    this.amountValid = amountValid
    this.amountInvalid = amountInvalid
  }
}

export class RowsApproval {
  public no?: number
  public courierId?: string
  public amount?: string
  public note?: string
  public status?: string
  public reason?: string

  constructor(
    no?: number,
    courierId?: string,
    amount?: string,
    note?: string,
    status?: string,
    reason?: string
  ) {
    this.no = no
    this.courierId = courierId
    this.amount = amount
    this.note = note
    this.status = status
    this.reason = reason
  }
}

export class AmountApproval {
  public addition?: number
  public reduction?: number

  constructor(
    addition?: number,
    reduction?: number
  ) {
    this.addition = addition
    this.reduction = reduction
  }
}
