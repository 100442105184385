import { PickupRadiusValidationRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupRadiusValidationRepositoryInterface'
import { PickupRadiusDetail, PickupRadiusValidations } from '@/domain/entities/PickupRadiusValidation'
import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { PickupRadiusValidationMapper } from '@/data/persistences/mappers/PickupRadiusValidationMapper'
import { UpdatePickupLogStatusRequest } from '@/data/payload/api/PickupRadiusValidationRequest'

export class PickupRadiusValidationApiRepository
  implements PickupRadiusValidationRepositoryInterface {
  private service: ApiService
  private mapper: PickupRadiusValidationMapper

  constructor(service: ApiService, mapper: PickupRadiusValidationMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getPickupRadiusValidations(
    params: Map<string, string | number>
  ): Promise<PickupRadiusValidations> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.SHIPMENT_PICKUP_LOG}`,
      params
    )
    return this.mapper.convertPickupRadiusValidationResponseFromAPI(resp)
  }

  async getPickupRadiusValidationDetail(
    pickupLogId: number,
    params: Map<string, string | number>
  ): Promise<PickupRadiusDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.SHIPMENT_PICKUP_LOG}/detail/${pickupLogId}`,
      params
    )
    return this.mapper.convertPickupRadiusValidationDetailResponseFromAPI(resp)
  }

  async updatePickupLogStatus(
    pickupLogId: number,
    payload: UpdatePickupLogStatusRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.SHIPMENT_PICKUP_LOG}/update-status/${pickupLogId}`,
      undefined,
      payload
    )
    return true
  }

  async exportPickupLog(
    params: Map<string, string | number>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.SHIPMENT_PICKUP_LOG}/export`,
      params
    )
    return this.mapper.convertPickupRadiusValidationExportPickupLogResponseFromAPI(resp)
  }
}
