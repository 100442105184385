import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { ManualAdjustmentBalanceRepositoryInterface } from '@/data/persistences/repositories/contracts/ManualAdjustmentBalanceRepositoryInterface'
import { ManualdjustmentBalanceMapper } from '@/data/persistences/mappers/ManualAdjustmentMapperMapper'
import {
  ManualAdjustmentBalances,
  ManualAdjustmentUploadHistories,
  UploadBulkData
} from '@/domain/entities/ManualAdjustmentBalance'
import {
  CreateManualAdjustmentBalanceRequestInterface,
  UploadBulkyManualAdjustmentBalanceRequestInterface,
  UploadManualAdjustmentBalanceRequestInterface }
from '@/data/payload/contracts/ManualAdjustmentBalanceRequest'
export class ManualAdjustmentBalanceApiRepository implements ManualAdjustmentBalanceRepositoryInterface {
  private service: ApiService
  private mapper: ManualdjustmentBalanceMapper

  constructor(service: ApiService, mapper: ManualdjustmentBalanceMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, number>): Promise<ManualAdjustmentBalances> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/admin-list`,
      params,
      undefined,
      new Map([['Content-Type', '']])
    )

    return this.mapper.convertManualAdjustmentBalanceFromApi(resp)
  }

  public async getUploadHistoryList(params: Map<string, number>): Promise<ManualAdjustmentUploadHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/manual-adjustment/upload-history/list`,
      params
    )

    return this.mapper.convertManualAdjustmentUploadHistoryFromApi(resp)
  }

  public async create(payload: CreateManualAdjustmentBalanceRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLL}/manual-adjustment`,
      undefined,
      payload as CreateManualAdjustmentBalanceRequestInterface
    )

    return true
  }

  public async export(params: Map<string, number>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/manual-adjustment/export`,
      params
    )
    return this.mapper.convertExportManualAdjustmentBalanceFromApi(resp)
  }

  public async uploadImage(payload: UploadManualAdjustmentBalanceRequestInterface): Promise<string> {
    const res = await this.service.invoke(
      'post',
      Endpoints.Route.UPLOAD_IMAGE,
      undefined,
      payload
    )

    return res.data.data.path_images
  }

  public async uploadBulky(payload: UploadBulkyManualAdjustmentBalanceRequestInterface): Promise<string> {
    const customService = new ApiService(Endpoints.baseUrl, 300000)

    const res = await customService.invoke(
      'POST',
      `${Endpoints.Route.PAYROLL}/manual-adjustment/upload-bulky`,
      undefined,
      payload
    )

    return res.data.data.filepath
  }

  public async downloadTemplate(): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/manual-adjustment/download-template`,
    )

    return this.mapper.convertDownloadTemplateManualAdjustmentBalanceFromApi(resp)
  }

  public async uploadBulkyNew(payload: UploadBulkyManualAdjustmentBalanceRequestInterface): Promise<UploadBulkData> {
    const customService = new ApiService(Endpoints.baseUrl, 300000)

    const resp = await customService.invoke(
      'POST',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/upload`,
      undefined,
      payload
    )

    return this.mapper.convertUploadBulkResFromApi(resp, true)
  }

  public async getUploadBulkData(params: Map<string, number>): Promise<UploadBulkData> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/upload/list`,
      params
    )

    return this.mapper.convertUploadBulkResFromApi(resp, false)
  }

  public async submitBulk(): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLLV1}/manual-adjustment/upload/submit`,
      undefined,
    )

    return true
  }
}
