import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import { WhiteListSeller, WhiteSeller } from '@/domain/entities/WhiteSeller'

export class WhiteSellerMapper extends BaseResponseMapper {
  public convertWhiteListSellerFromApi(result: AxiosResponse): WhiteListSeller {
    const {
      data: { data, pagination },
    } = result

    return new WhiteListSeller(
      new Pagination(
        pagination.page,
        pagination.limit,
        pagination.total_data,
      ),
      data.map(
        (item: Record<string, any>) =>
          new WhiteSeller(
            item.id,
            item.name,
            item.partner_pos,
            item.partner_code,
            item.phone,
            item.role,
            item.status,
          )
      )
    )
  }

  public convertDetailWhiteSellerFromApi(result: AxiosResponse): WhiteSeller {
    const {
      data: { data },
    } = result

    return new WhiteSeller(
      data.id,
      data.name,
      data.partner_pos,
      undefined,
      data.phone,
      data.role,
      data.status,
      data.partner_id,
      data.cod_handling,
      data.created_at,
      data.created_by,
      data.updated_at,
      data.updated_by
    )
  }

  public convertExportWhiteSellerFromApi(result: AxiosResponse): string {
    const {
      data: { data },
    } = result

    return data.url
  }
}
