import { injectable } from 'tsyringe'
import { SelectedMerchantRepositoryInterface } from '@/data/persistences/repositories/contracts/SelectedMerchantRepositoryInterface'
import { SelectedMerchant } from '@/domain/entities/SelectedMerchant'
import { SaveSelectedMerchantRequestInterface } from '@/data/payload/contracts/SelectedMerchantRequest'
import { MerchantDropdown } from '@/domain/entities/Merchant'

@injectable()
export class SelectedMerchantPresenter {
  private repository: SelectedMerchantRepositoryInterface

  constructor(repository: SelectedMerchantRepositoryInterface) {
    this.repository = repository
  }

  getAll(): Promise<SelectedMerchant[]> {
    return this.repository.getAll()
  }

  create(payload: SaveSelectedMerchantRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  getAllDropdown(): Promise<MerchantDropdown[]> {
    return this.repository.getAllDropdown()
  }
}
