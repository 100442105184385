import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { ScheduleOrderRepositoryInterface } from '@/data/persistences/repositories/contracts/ScheduleOrderRepositoryInterface'
import { ScheduleOrder } from '@/domain/entities/ScheduleOrder'
import { ScheduleOrderMapper } from '@/data/persistences/mappers/ScheduleOrderMapper'
import { UpdateScheduleOrderRequestInterface } from '@/data/payload/contracts/ScheduleOrderRequest'

export class ScheduleOrderApiRepository
  implements ScheduleOrderRepositoryInterface {
  private service: ApiService
  private mapper: ScheduleOrderMapper

  constructor(service: ApiService, mapper: ScheduleOrderMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(params: Map<string, number>): Promise<ScheduleOrder[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.SHIPMENT_ADMIN}/schedule`,
      params
    )

    return this.mapper.convertScheduleOrderFromApi(resp)
  }

  public async update(
    payload: UpdateScheduleOrderRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.SHIPMENT_ADMIN}/schedule/edit`,
      undefined,
      payload as UpdateScheduleOrderRequestInterface
    )

    return true
  }
}
