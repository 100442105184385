import { injectable } from 'tsyringe'

import { OrderRepositoryInterface } from '@/data/persistences/repositories/contracts/OrderRepositoryInterface'
import { Orders, OrderDetail } from '@/domain/entities/Order'
import { CancelOrderRequestInterface } from '@/data/payload/contracts/OrderRequest'

@injectable()
export class OrderPresenter {
  private repository: OrderRepositoryInterface

  constructor(repository: OrderRepositoryInterface) {
    this.repository = repository
  }

  public get(shipmentId: string): Promise<OrderDetail> {
    return this.repository.get(shipmentId)
  }

  public getAll(params: Map<string, any>): Promise<Orders> {
    return this.repository.getAll(params)
  }

  public cancelOrder(payload: CancelOrderRequestInterface): Promise<boolean> {
    return this.repository.cancelOrder(payload)
  }

  public exportOrder(params: Map<string, any>): Promise<string> {
    return this.repository.exportOrder(params)
  }
}
