import ApiService from '@/app/infrastructures/services/ApiService'
import { PNRepositoryInterface } from '@/data/persistences/repositories/contracts/PNRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { PNMapper } from '@/data/persistences/mappers/PNMapper'
import {
  PushNotification,
  PushNotificationProgramMembership,
  PushNotifications,
} from '@/domain/entities/PN'
import {
  CreatePNRequestInterface,
  UpdatePNRequestInterface,
} from '@/data/payload/contracts/PNRequest'

export class PNApiRepository implements PNRepositoryInterface {
  private service: ApiService
  private mapper: PNMapper

  constructor(service: ApiService, mapper: PNMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async create(payload: CreatePNRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.PUSH_NOTIFICATION,
      undefined,
      payload as CreatePNRequestInterface,
      new Map([
        [
          'Content-Type',
          'multipart/form-data; ------WebKitFormBoundary7MA4YWxkTrZu0gW',
        ],
      ])
    )

    return true
  }

  public async update(
    id: string,
    payload: UpdatePNRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.PUSH_NOTIFICATION}/${id}`,
      undefined,
      payload as UpdatePNRequestInterface,
      new Map([
        [
          'Content-Type',
          'multipart/form-data; ------WebKitFormBoundary7MA4YWxkTrZu0gW',
        ],
      ])
    )

    return true
  }

  public async cancel(id: string): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PUSH_NOTIFICATION}/cancel/${id}`
    )
    return true
  }

  public async getAll(params: Map<string, any>): Promise<PushNotifications> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PUSH_NOTIFICATION,
      params
    )
    return this.mapper.convertPushNotificationsFromApi(resp)
  }

  public async get(id: string): Promise<PushNotification> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PUSH_NOTIFICATION}/${id}`
    )
    return this.mapper.convertPushNotificationFromApi(resp)
  }

  public async getCatategoryOptions(): Promise<string[]> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PUSH_NOTIFICATION_CATEGORY_OPTIONS
    )

    return resp.data.data as string[]
  }

  public async getListProgramMembership(): Promise<
    PushNotificationProgramMembership[]
  > {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.LOYALTY_MEMBERSHIP}/level/active`
    )

    return this.mapper.convertProgramActiveMembershipFromApi(resp)
  }
}
