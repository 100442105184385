import { injectable } from 'tsyringe'

import { RouteRepositoryInterface } from '@/data/persistences/repositories/contracts/RouteRepository'
import { LetterCode, OriginRoute, Route } from '@/domain/entities/Route'

@injectable()
export class RoutePresenter {
  private repository: RouteRepositoryInterface

  constructor(repository: RouteRepositoryInterface) {
    this.repository = repository
  }

  public getActiveOrigin(): Promise<OriginRoute[]> {
    return this.repository.getActiveOrigin()
  }

  public getAllCity(): Promise<OriginRoute[]> {
    return this.repository.getAllCity()
  }

  public getAllDistrict(city: string): Promise<OriginRoute[]> {
    return this.repository.getAllDistrict(city)
  }

  public async getAllRoute(params: Map<string, string>): Promise<Route[]> {
    return this.repository.getAllRoute(params)
  }

  public async getLetterCodes(): Promise<LetterCode[]> {
    return this.repository.getLetterCOdes()
  }
}
