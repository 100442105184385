import { PackageRepositoryInterface } from '@/data/persistences/repositories/contracts/PackageRepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import { Package } from '@/domain/entities/Package'
import { Endpoints } from '@/app/infrastructures/misc'
import { PackageMapper } from '@/data/persistences/mappers/PackageMapper'

export class PackageApiRepository implements PackageRepositoryInterface {
  private service: ApiService
  private mapper: PackageMapper

  constructor(service: ApiService, mapper: PackageMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async get(id: string): Promise<Package> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PACKAGE}/${id}`
    )
    return this.mapper.convertPackageFromApi(resp)
  }
}
