import { Pagination } from '@/domain/entities/Pagination'

export class PushNotification {
  public id?: string
  public title?: string
  public wording?: string
  public content?: string
  public publishDate?: string
  public imageUrl?: string
  public targetUrl?: string
  public ctaName?: string
  public status?: string
  public targetUsers?: string
  public cities?: string[]
  public selectedUser?: string
  public membershipLevel?: string
  public platformOS?: string
  public platformValidation?: string
  public platformVersion?: string
  public createdAt?: string
  public gifUrl?: string
  public notificationCategory?: string
  public notificationData?: NotificationData

  constructor(
    id?: string,
    title?: string,
    wording?: string,
    content?: string,
    publishDate?: string,
    imageUrl?: string,
    targetUrl?: string,
    ctaName?: string,
    status?: string,
    targetUsers?: string,
    cities?: string[],
    selectedUser?: string,
    membershipLevel?: string,
    platformOS?: string,
    platformValidation?: string,
    platformVersion?: string,
    createdAt?: string,
    gifUrl?: string,
    notificationCategory?: string,
    notificationData?: NotificationData
  ) {
    this.id = id
    this.title = title
    this.wording = wording
    this.content = content
    this.publishDate = publishDate
    this.imageUrl = imageUrl
    this.targetUrl = targetUrl
    this.ctaName = ctaName
    this.status = status
    this.targetUsers = targetUsers
    this.cities = cities
    this.selectedUser = selectedUser
    this.membershipLevel = membershipLevel
    this.platformOS = platformOS
    this.platformValidation = platformValidation
    this.platformVersion = platformVersion
    this.createdAt = createdAt
    this.gifUrl = gifUrl
    this.notificationCategory = notificationCategory
    this.notificationData = notificationData
  }
}

export class PushNotifications {
  public pagination?: Pagination
  public data?: PushNotifications[]

  constructor(pagination?: Pagination, data?: PushNotifications[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PushNotificationProgramMembership {
  public label: string
  public value: number

  constructor(label: string, value: number) {
    this.label = label
    this.value = value
  }
}

export class NotificationOptionProgramMembership {
  public levelID: number
  public levelName: string
  public selected: boolean

  constructor(levelID: number, levelName: string, selected: boolean) {
    this.levelID = levelID
    this.levelName = levelName
    this.selected = selected
  }
}

export class NotificationData {
  public membershipLevel: NotificationOptionProgramMembership[]

  constructor(membershipLevel: NotificationOptionProgramMembership[]) {
    this.membershipLevel = membershipLevel
  }
}
