import { injectable } from 'tsyringe'

import { FAQRepositoryInterface } from '@/data/persistences/repositories/contracts/FAQRepositoryInterface'
import { FAQ, FAQs, StarQuestion } from '@/domain/entities/FAQ'
import {
  CreateFAQRequestInterface,
  ReorderStarQuestionRequestInterface,
  UpdateFAQCategoryOrderRequestInterface,
  UpdateFAQCategoryStatusRequestInterface,
  UpdateFAQDraftStatusRequestInterface,
  UpdateFAQRequestInterface,
  UploadFAQCategoryImageRequestInterface,
} from '@/data/payload/contracts/FAQRequest'

@injectable()
export class FAQPresenter {
  private repository: FAQRepositoryInterface

  constructor(repository: FAQRepositoryInterface) {
    this.repository = repository
  }

  public get(faqCategoryId: string): Promise<FAQ> {
    return this.repository.get(faqCategoryId)
  }

  public getAll(params: Map<string, any>): Promise<FAQs> {
    return this.repository.getAll(params)
  }

  public getListQuestion(): Promise<StarQuestion[]> {
    return this.repository.getListQuestion()
  }

  public create(payload: CreateFAQRequestInterface): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    faqCategoryId: string,
    payload: UpdateFAQRequestInterface
  ): Promise<boolean> {
    return this.repository.update(faqCategoryId, payload)
  }

  public updateCategoryOrder(
    faqCategoryId: string,
    payload: UpdateFAQCategoryOrderRequestInterface
  ): Promise<boolean> {
    return this.repository.updateCategoryOrder(faqCategoryId, payload)
  }

  public updateCategoryStatus(
    faqCategoryId: string,
    payload: UpdateFAQCategoryStatusRequestInterface
  ): Promise<boolean> {
    return this.repository.updateCategoryStatus(faqCategoryId, payload)
  }

  public updateFAQDraftStatus(
    faqCategoryId: string,
    payload: UpdateFAQDraftStatusRequestInterface
  ): Promise<boolean> {
    return this.repository.updateFAQDraftStatus(faqCategoryId, payload)
  }

  public deleteFAQ(
    faqCategoryId: string,
    params: Map<string, any>
  ): Promise<boolean> {
    return this.repository.deleteFAQ(faqCategoryId, params)
  }

  public deleteSubCategory(faqCategoryId: number): Promise<boolean> {
    return this.repository.deleteSubCategory(faqCategoryId)
  }

  public postImageCategory(
    payload: UploadFAQCategoryImageRequestInterface
  ): Promise<string> {
    return this.repository.postImageCategory(payload)
  }

  public starredQuestion(idQuestion: number, action: string): Promise<boolean> {
    return this.repository.starredQuestion(idQuestion, action)
  }

  public reorderStarQuestion(
    payload: ReorderStarQuestionRequestInterface
  ): Promise<boolean> {
    return this.repository.reorderStarQuestion(payload)
  }
}
