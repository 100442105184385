import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'

export const listUpdateAppVersionRouteName = 'ListUpdateAppVersionRouteName'
export const createUpdateAppVersionRouteName = 'CreateUpdateAppVersionRouteName'
export const editUpdateAppVersionRouteName = 'EditUpdateAppVersionRouteName'
export const detailUpdateAppVersionRouteName = 'DetailUpdateAppVersionRouteName'

export const updateAppVersion = {
  path: '/update-app-version',
  component: MainLayout,
  redirect: '/update-app-version',
  meta: {
    title: 'Update App Version',
    single: true,
    icon: 'MenuIconUpdateAppVersion',
  },
  children: [
    {
      path: '/update-app-version',
      name: listUpdateAppVersionRouteName,
      component: () =>
        import(
          /* webpackChunkName: "ListUpdateAppVersionPage" */ '@/app/ui/views/UpdateAppVersion/index.vue'
          ),
      meta: {
        title: 'Update App Version',
        slugFromBackend: BACKEND_SLUG.UPDATE_APP_VERSION,
        accessType: 'read',
      },
    },
    {
      path: '/update-app-version/create',
      name: createUpdateAppVersionRouteName,
      component: () =>
        import(
          /* webpackChunkName: "CreateUpdateAppVersionPage" */ '@/app/ui/views/UpdateAppVersion/Create/index.vue'
          ),
      meta: {
        title: 'Create New Update App Version Detail',
        slugFromBackend: BACKEND_SLUG.UPDATE_APP_VERSION,
        accessType: 'write',
      },
    },
    {
      path: '/update-app-version/edit/:id',
      name: editUpdateAppVersionRouteName,
      component: () =>
        import(
          /* webpackChunkName: "EditUpdateAppVersionPage" */ '@/app/ui/views/UpdateAppVersion/Edit/index.vue'
          ),
      meta: {
        title: 'Edit Update App Version Detail',
        slugFromBackend: BACKEND_SLUG.UPDATE_APP_VERSION,
        accessType: 'write',
      },
    },
    {
      path: '/update-app-version/:id',
      name: detailUpdateAppVersionRouteName,
      component: () =>
        import(
          /* webpackChunkName: "DetailUpdateAppVersionPage" */ '@/app/ui/views/UpdateAppVersion/Detail/index.vue'
          ),
      meta: {
        title: 'View Update App Version Detail',
        slugFromBackend: BACKEND_SLUG.UPDATE_APP_VERSION,
        accessType: 'read',
      },
    },
  ],
}
