import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'
export const adjustmentParcelPoinRequestPoinListRouteName =
  'AdjustmentParcelPoinRequestPoinPage'
export const adjustmentParcelPoinRequestPoinPreviewUploadBulkRouteName = 'PreviewUploadBulkAdjustmentParcelPoinRequestPoinPage'
export const adjustmentParcelPoinRequestPoinDetailRouteName =
  'DetailAdjustmentParcelPoinRequestPoinPage'
export const adjustmentParcelPoinApprovingPoinListRouteName =
  'AdjustmentParcelPoinApprovingPoinPage'
export const adjustmentParcelPoinApprovingPoinDetailRouteName =
  'DetailAdjustmentParcelPoinApprovingPoinPage'
export const adjustmentParcelPoinAllDataAdjustmentListRouteName =
  'AdjustmentParcelPoinAllDataPage'
export const adjustmentParcelPoinAllDataAdjustmentDetailRouteName =
  'DetailAdjustmentParcelPoinAllDataPage'

export const adjustmentParcelPoin: RouteConfig = {
  path: '/adjustment-parcel-poin',
  component: MainLayout,
  redirect: '/adjustment-parcel-poin/request-poin',
  meta: {
    title: 'Adjustment Parcel Poin',
    single: false,
    icon: 'MenuIconAdjustmentParcelPoint',
  },
  children: [
    {
      path: 'request-poin',
      name: adjustmentParcelPoinRequestPoinListRouteName,
      component: () =>
        import(
          /* webpackChunkName: "AdjustmentParcelPoinRequestPoinPage" */ '@/app/ui/views/Adjustment/RequestPoin/index.vue'
        ),
      meta: {
        title: 'Request Parcel Poin',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_REQUEST_POINT,
        accessType: 'read',
      },
    },
    {
      path: 'request-poin/preview-upload-bulk',
      name: adjustmentParcelPoinRequestPoinPreviewUploadBulkRouteName,
      component: () =>
        import(
          /* webpackChunkName: "PreviewUploadBulkAdjustmentParcelPoinRequestPoinPage" */ '@/app/ui/views/Adjustment/RequestPoin/PreviewUploadBulk/index.vue'
        ),
      meta: {
        title: 'Preview Upload Bulk Request Parcel Poin',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_REQUEST_POINT,
        accessType: 'read',
      },
    },
    {
      path: 'request-poin/:id',
      name: adjustmentParcelPoinRequestPoinDetailRouteName,
      component: () =>
        import(
          /* webpackChunkName: "DetailAdjustmentParcelPoinRequestPoinPage" */ '@/app/ui/views/Adjustment/RequestPoin/Detail/index.vue'
        ),
      meta: {
        title: 'Request Parcel Poin',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_REQUEST_POINT,
        accessType: 'write',
      },
    },
    {
      path: 'approving-poin',
      name: adjustmentParcelPoinApprovingPoinListRouteName,
      component: () =>
        import(
          /* webpackChunkName: "AdjustmentParcelPoinApprovingPoinPage" */ '@/app/ui/views/Adjustment/ApprovingPoin/index.vue'
        ),
      meta: {
        title: 'Approving Parcel Poin',
        isParent: true,
        slugFromBackend: BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_APPROVING_POINT,
        accessType: 'read',
      },
    },
    {
      path: 'approving-poin/:id',
      name: adjustmentParcelPoinApprovingPoinDetailRouteName,
      component: () =>
        import(
          /* webpackChunkName: "DetailAdjustmentParcelPoinApprovingPoinPage" */ '@/app/ui/views/Adjustment/ApprovingPoin/Detail/index.vue'
        ),
      meta: {
        title: 'Approving Parcel Poin',
        isHidden: true,
        slugFromBackend: BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_APPROVING_POINT,
        accessType: 'write',
      },
    },
    {
      path: 'all-data-adjustment',
      name: adjustmentParcelPoinAllDataAdjustmentListRouteName,
      component: () =>
        import(
          /* webpackChunkName: "AdjustmentParcelPoinAllDataPage" */ '@/app/ui/views/Adjustment/AllDataAdjustmentPoin/index.vue'
        ),
      meta: {
        title: 'All Data Adjustment',
        isParent: true,
        slugFromBackend:
          BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_ALL_DATA_ADJUTSTMENT,
        accessType: 'read',
      },
    },
    {
      path: 'all-data-adjustment/:id',
      name: adjustmentParcelPoinAllDataAdjustmentDetailRouteName,
      component: () =>
        import(
          /* webpackChunkName: "DetailAdjustmentParcelPoinAllDataPage" */ '@/app/ui/views/Adjustment/AllDataAdjustmentPoin/Detail/index.vue'
        ),
      meta: {
        title: 'All Data Adjustment',
        isHidden: true,
        slugFromBackend:
          BACKEND_SLUG.ADJUSTMENT_PARCEL_POINT_ALL_DATA_ADJUTSTMENT,
        accessType: 'write',
      },
    },
  ],
}
