import { ShuttleRepositoryInterface } from '@/data/persistences/repositories/contracts/ShuttleRepositoryInterface'
import { CourierShuttles, ShuttleDetail, Shuttles } from '@/domain/entities/Shuttle'
import ApiService from '../../services/ApiService'
import { ShuttleMapper } from '@/data/persistences/mappers/ShuttleMapper'
import { Endpoints } from '../../misc'
import { CreateShuttleRequest } from '@/data/payload/api/ShuttleManagementRequest'

export class ShuttleApiRepository implements ShuttleRepositoryInterface {
  private service: ApiService
  private mapper: ShuttleMapper

  constructor(service: ApiService, mapper: ShuttleMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getAll(params: Map<string, string | number>): Promise<Shuttles> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.SHUTTLE}/list`,
      params
    )

    return this.mapper.convertShuttleListFromApi(resp)
  }

  async create(payload: CreateShuttleRequest): Promise<boolean> {
    await this.service.invoke(
      'POST',
      Endpoints.Route.SHUTTLE,
      undefined,
      payload
    )

    return true
  }

  async get(shuttleId: string): Promise<ShuttleDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.SHUTTLE}/${shuttleId}`
    )

    return this.mapper.convertShuttleDetailFromApi(resp)
  }

  async update(
    payload: CreateShuttleRequest,
    shuttleId: string
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.SHUTTLE}/${shuttleId}`,
      undefined,
      payload
    )

    return true
  }

  async delete(shuttleId: string): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.SHUTTLE}/${shuttleId}`
    )

    return true
  }

  async getAllCourierShuttle(params: Map<string, string | number>): Promise<CourierShuttles> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.SHUTTLE}/list/courier`,
      params
    ) 

    return this.mapper.convertCourierShuttleListFromApi(resp)
  }
}
