import { ManageCourierRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageCourierRepositoryInterface'
import ApiService from '@/app/infrastructures/services/ApiService'
import { ManageCourierMapper } from '@/data/persistences/mappers/ManageCourierMapper'
import {
  ManageCourierDetail,
  ManageCourierHistoryLogs,
  ManageCourierHistoryUploadBulks,
  ManageCourierOnLeaves,
  ManageCouriers,
  ManageCouriersAdjustSaldo,
  Partner,
  ManageCourierUploadBulk,
  TruckVendor,
  ManageCourierLeaveRequests,
} from '@/domain/entities/ManageCourier'
import { Endpoints } from '@/app/infrastructures/misc'
import {
  AddCourierRequestInterface,
  EditCourierRequestInterface,
  UploadBulkyManageCourierLeaveRequestInterface,
} from '@/data/payload/contracts/ManageCourierRequest'
import {
  AddCourierRequest,
  CourierLeaveApproval,
  EditCourierRequest,
} from '@/data/payload/api/ManageCourierRequest'

export class ManageCourierApiRepository
  implements ManageCourierRepositoryInterface {
  private service: ApiService
  private mapper: ManageCourierMapper

  constructor(service: ApiService, mapper: ManageCourierMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(
    params: Map<string, string | number>
  ): Promise<ManageCouriers> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER}`,
      params
    )
    return this.mapper.convertManageCouriersFromApi(resp)
  }

  public async get(courierId: string): Promise<ManageCourierDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER}/${courierId}`
    )
    return this.mapper.convertCourierDetailFromApi(resp)
  }

  public async addCourier(
    payload: AddCourierRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.MANAGE_COURIER_V2,
      undefined,
      payload as AddCourierRequest
    )

    return true
  }

  public async updateCourier(
    courierId: string,
    payload: EditCourierRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.MANAGE_COURIER}/${courierId}`,
      undefined,
      payload as EditCourierRequest
    )

    return true
  }

  public async getCouriersAdjustSaldo(
    params: Map<string, string | number>
  ): Promise<ManageCouriersAdjustSaldo> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER}`,
      params
    )
    return this.mapper.convertManageCouriersAdjustSaldoFromApi(resp)
  }

  public async getAllPartner(
    params: Map<string, string | number>
  ): Promise<Partner[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PARTNER}`,
      params
    )
    return this.mapper.convertPartnersFromApi(resp)
  }

  public async export(params: Map<string, number>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.COURIER}/export-csv`,
      params
    )
    return this.mapper.convertManageCourierExportFromApi(resp)
  }

  public async getAllHistoryLog(params: Map<string, string | number>, courierId: string): Promise<ManageCourierHistoryLogs> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER}/history-data/${courierId}`,
      params
    )
    return this.mapper.convertManageCourierHistoryLogsFromApi(resp)
  }

  public async getAllCourierOnLeave(params: Map<string, string | number>): Promise<ManageCourierOnLeaves> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/list`,
      params
    )
    return this.mapper.convertManageCourierOnLeavesFromApi(resp)
  }

  public async downloadTemplate(): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/template`,
    )
    return this.mapper.convertDownloadTemplateManageCourierLeaveFromApi(resp)
  }

  public async uploadBulky(payload: UploadBulkyManageCourierLeaveRequestInterface): Promise<ManageCourierUploadBulk> {
    const customService = new ApiService(Endpoints.baseUrl, 300000)

    const resp = await customService.invoke(
      'post',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/upload`,
      undefined,
      payload
    )

    return this.mapper.convertUploadBulkResFromApi(resp, true)
  }

  public async getUploadBulkData(params: Map<string, number>): Promise<ManageCourierUploadBulk> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/upload/list`,
      params
    )

    return this.mapper.convertUploadBulkResFromApi(resp, false)
  }

  public async submitBulk(): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/upload/submit`,
      undefined
    )

    return true
  }

  public async getHistoryUploadBulky(params: Map<string, string | number>): Promise<ManageCourierHistoryUploadBulks> {
     const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/upload/history`,
      params
     )

     return this.mapper.convertManageCourierHistoryUploadBulksFromApi(resp)
   }

   public async getDetailHistoryUploadBulky(params: Map<string, string | number>): Promise<ManageCourierUploadBulk> {
     const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/upload/history/detail`,
      params
     )

     return this.mapper.convertManageCourierDetailHistoryUploadBulkFromApi(resp)
   }

   public async getTruckVendorSuggestions(params: Map<string, string | number>): Promise<TruckVendor[]> {
     const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.TRUCK_VENDOR}`,
      params
     )

     return this.mapper.convertTruckVendorsFromApi(resp)
   }
   
   public async getCourierLeaveRequest(params: Map<string, string | number>): Promise<ManageCourierLeaveRequests> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/request/list`,
      params
    )
    
    return this.mapper.convertCourierLeaveRequestsFromApi(resp)
   }

   public async getCourierLeaveTotalRequest(): Promise<number> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/request/total`,
    )

    return this.mapper.convertCourierLeaveTotalRequestFromApi(resp)
   }

   public async submitCourierLeaveApproval(payload: CourierLeaveApproval): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.MANAGE_COURIER_LEAVE_SCHEDULE}/approval`,
      undefined,
      payload
    )

    return true
       
   }
}
