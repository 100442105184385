import { PickupTimeManagementRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupTimeManagementRepositoryInterface'
import ApiService from '../../services/ApiService'
import { PickupTimeManagementMapper } from '@/data/persistences/mappers/PickupTimeManagementMapper'
import {
  PickupTimeDetail,
  PickupTimes,
} from '@/domain/entities/PickupTimeManagement'
import { Endpoints } from '../../misc'
import { CreateDynamicPickupRequest, UpdateDynamicPickupRequest } from '@/data/payload/api/PickupTimeManagementRequest'

export class PickupTimeManagementApiRepository
  implements PickupTimeManagementRepositoryInterface {
  private service: ApiService
  private mapper: PickupTimeManagementMapper

  constructor(service: ApiService, mapper: PickupTimeManagementMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getPickupTimes(
    params: Map<string, string | number>
  ): Promise<PickupTimes> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.DYNAMIC_PICKUP}/route/list`,
      params
    )

    return this.mapper.convertPickupTimesResponseFromAPI(resp)
  }

  async getDetailPickupTime(id: number): Promise<PickupTimeDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.DYNAMIC_PICKUP}/route/${id}`
    )

    return this.mapper.convertPickupTimeDetailResponseFromAPI(resp)
  }

  async createDynamicPickupTime(
    payload: CreateDynamicPickupRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.DYNAMIC_PICKUP}/route`,
      undefined,
      payload
    )

    return true
  }

  async updateDynamicPickupTime(payload: UpdateDynamicPickupRequest): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.DYNAMIC_PICKUP}/route/${payload.dynamicPickupId}`,
      undefined,
      payload
    )

    return true
  }

  async deleteDynamicPickupTime(id: number): Promise<boolean> {
    await this.service.invoke(
      'DELETE',
      `${Endpoints.Route.DYNAMIC_PICKUP}/route/${id}`
    )

    return true
  }
}
