import { injectable } from 'tsyringe'

import { BrandRepositoryInterface } from '@/data/persistences/repositories/contracts/BrandRepositoryInterface'
import { Brand, Brands } from '@/domain/entities/Brand'
import {
  CreateBrandRequestInterface,
  UpdateBrandRequestInterface,
  UpdateBrandStatusRequestInterface
} from '@/data/payload/contracts/BrandRequest'

@injectable()
export class BrandPresenter {
  private repository: BrandRepositoryInterface

  constructor(repository: BrandRepositoryInterface) {
    this.repository = repository
  }

  public get(brandId: string): Promise<Brand> {
    return this.repository.get(brandId)
  }

  public getAll(params: Map<string, any>): Promise<Brands> {
    return this.repository.getAll(params)
  }

  public create(payload: CreateBrandRequestInterface): Promise<Brand> {
    return this.repository.create(payload)
  }

  public update(
    brandId: number,
    payload: UpdateBrandRequestInterface
  ): Promise<boolean> {
    return this.repository.update(brandId, payload)
  }

  public updateStatus(
    brandId: number,
    payload: UpdateBrandStatusRequestInterface
  ): Promise<boolean> {
    return this.repository.updateStatus(brandId, payload)
  }
}
