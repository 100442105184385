export class Utils {
  public static toSnakeCase(input: string): string {
    return (
      input[0].toLowerCase() +
      input
        .slice(1, input.length)
        .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    )
  }

  public static snakeCaseToCamelCase(input: string): string {
    return input
      .split('_')
      .reduce(
        (res, word, i) =>
          i === 0
            ? word.toLowerCase()
            : `${res}${word.charAt(0).toUpperCase()}${word
                .substr(1)
                .toLowerCase()}`,
        ''
      )
  }

  public static toInstance<T>(
    obj: T,
    json: Map<string, any> | string,
    to: 'snake_case' | 'camelCase' = 'camelCase'
  ): T {
    const jsonObj = typeof json === 'string' ? JSON.parse(json) : json

    for (const propName in jsonObj) {
      const keyName =
        to === 'camelCase'
          ? this.snakeCaseToCamelCase(propName)
          : this.toSnakeCase(propName)

      obj = Object.assign({}, obj, {
        [keyName]: jsonObj[propName],
      })
    }

    return obj
  }
}
