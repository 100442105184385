import { ManageCourier } from './ManageCourier'
import { Pagination } from './Pagination'

export class CODPaymentSetting {
  public id?: number
  public status?: string
  public periodeStart?: string
  public periodeEnd?: string
  public feeCod?: number
  public feeChargedBy?: string
  public isDefault?: boolean
  public createdAt?: string
  public createdBy?: string
  public updatedAt?: string
  public updatedBy?: string

  constructor(
    id?: number,
    status?: string,
    periodeStart?: string,
    periodeEnd?: string,
    feeCod?: number,
    feeChargedBy?: string,
    isDefault?: boolean,
    createdAt?: string,
    createdBy?: string,
    updatedAt?: string,
    updatedBy?: string
  ) {
    this.id = id
    this.status = status
    this.periodeStart = periodeStart
    this.periodeEnd = periodeEnd
    this.feeCod = feeCod
    this.feeChargedBy = feeChargedBy
    this.isDefault = isDefault
    this.createdAt = createdAt
    this.createdBy = createdBy
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
  }
}

export class CODPaymentSettings {
  data?: CODPaymentSetting[]
  pagination?: Pagination

  constructor(data?: CODPaymentSetting[], pagination?: Pagination) {
    this.data = data
    this.pagination = pagination
  }
}

export class CODPayment {
  packageId?: string
  codType?: string
  podDate?: string
  codAmount?: number
  courierData?: ManageCourier
  paymentMethod?: string
  status?: number
  statusTxt?: string

  constructor(
    packageId?: string,
    codType?: string,
    podDate?: string,
    codAmount?: number,
    courierData?: ManageCourier,
    paymentMethod?: string,
    status?: number,
    statusTxt?: string
  ) {
    this.packageId = packageId
    this.codType = codType
    this.podDate = podDate
    this.codAmount = codAmount
    this.courierData = courierData
    this.paymentMethod = paymentMethod
    this.status = status
    this.statusTxt = statusTxt
  }
}

export class CODPaymentSummary {
  notValid?: number
  valid?: number
  waiting?: number

  constructor(notValid?: number, valid?: number, waiting?: number) {
    this.notValid = notValid
    this.valid = valid
    this.waiting = waiting
  }
}

export class CODPayments {
  data?: CODPayment[]
  summary?: CODPaymentSummary
  pagination?: Pagination

  constructor(
    data?: CODPayment[],
    summary?: CODPaymentSummary,
    pagination?: Pagination
  ) {
    this.data = data
    this.summary = summary
    this.pagination = pagination
  }
}

export class CODPaymentCourierInfo {
  public courierId?: number
  public courierName?: string
  public courierPhone?: string

  constructor(courierId?: number, courierName?: string, courierPhone?: string) {
    this.courierId = courierId
    this.courierName = courierName
    this.courierPhone = courierPhone
  }
}

export class CODPaymentRecipientInfo {
  public recipientName?: string
  public recipientPhone?: string

  constructor(recipientName?: string, recipientPhone?: string) {
    this.recipientName = recipientName
    this.recipientPhone = recipientPhone
  }
}

export class CODPaymentDetail {
  public sttNumber?: string
  public codType?: string
  public podDate?: string
  public codAmount?: number
  public codFee?: number
  public paymentMethod?: string
  public status?: number
  public statusText?: string
  public invalidReason?: string
  public historyId?: number
  public isValid?: boolean
  public courierInfo?: CODPaymentCourierInfo
  public recipientInfo?: CODPaymentRecipientInfo
  public photoProofs?: string[]
  public approvalBy?: string
  public approvalAt?: string

  constructor(
    sttNumber?: string,
    codType?: string,
    podDate?: string,
    codAmount?: number,
    codFee?: number,
    paymentMethod?: string,
    status?: number,
    statusText?: string,
    invalidReason?: string,
    historyId?: number,
    isValid?: boolean,
    courierInfo?: CODPaymentCourierInfo,
    recipientInfo?: CODPaymentRecipientInfo,
    photoProofs?: string[],
    approvalBy?: string,
    approvalAt?: string
  ) {
    this.sttNumber = sttNumber
    this.codType = codType
    this.podDate = podDate
    this.codAmount = codAmount
    this.codFee = codFee
    this.paymentMethod = paymentMethod
    this.status = status
    this.statusText = statusText
    this.invalidReason = invalidReason
    this.historyId = historyId
    this.isValid = isValid
    this.courierInfo = courierInfo
    this.recipientInfo = recipientInfo
    this.photoProofs = photoProofs
    this.approvalAt = approvalAt
    this.approvalBy = approvalBy
  }
}
