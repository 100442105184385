import ApiService from '@/app/infrastructures/services/ApiService'
import { PickupRepositoryInterface } from '@/data/persistences/repositories/contracts/PickupRepositoryInterface'
import { Endpoints } from '@/app/infrastructures/misc/Endpoints'
import { PickupMapper } from '@/data/persistences/mappers/PickupMapper'
import { NearbyCourier, PickupAdditionalTimes, PickupCorporates, PickupCorporateSummary, PickupCRROTWs, PickupCRRQUEs, PickupDetail, PickupDetailSnapshot, PickupForceDetail, Pickups, PickupSummary } from '@/domain/entities/Pickup'
import {
  CancelPickupRequestInterface,
  ExportPickupRequestInterface,
  ManualAssigneeRequestInterface,
  MarkAsDoneRequestInterface,
  PickupDedicatedCRROTWRequestInterface,
  PickupForceCRRCNCRequestInterface,
} from '@/data/payload/contracts/PickupRequest'
import { ForceCancelCRRQUERequest, ForceCRRSRCRequest, UpdateIsContactedCRROTWRequest, UpdateIsContactedCRRQUERequest } from '@/data/payload/api/PickupRequest'
import { AxiosResponse } from 'axios'
import { Utils } from '../../misc'

export class PickupApiRepository implements PickupRepositoryInterface {
  private service: ApiService
  private mapper: PickupMapper

  constructor(service: ApiService, mapper: PickupMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getListCRROTW(params: Map<string, string | number>): Promise<PickupCRROTWs> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP_OTW,
      params
    )
    return this.mapper.convertPickupCRROTWsFromApi(resp)
  }

  public async updateIsContactedCRROTW(taskId: number, payload: UpdateIsContactedCRROTWRequest): Promise<boolean> {
    await this.service.invoke(
      'put',
      `${Endpoints.Route.PICKUP_OTW}/${taskId}`,
      undefined,
      payload
    )
    return true
  }

  public async getAll(params: Map<string, string | number>): Promise<Pickups> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP_V2,
      params
    )
    return this.mapper.convertPickupsFromApi(resp)
  }

  public async get(shipmentId: string): Promise<PickupDetail> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PICKUP}/${shipmentId}`
    )
    return this.mapper.convertPickupFromApi(resp)
  }

  public async getBulk(params: Map<string, string | number>): Promise<PickupDetail[]> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PICKUP}/bulk`,
      params
    )

    return this.mapper.convertPickupBulkFromApi(resp)
  }

  public async getListCRRNFD(params: Map<string, string | number>): Promise<Pickups> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP_V2,
      params
    )
    return this.mapper.convertPickupsFromApi(resp)
  }

  public async getListCRRDON(params: Map<string, string | number>): Promise<Pickups> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP,
      params
    )
    return this.mapper.convertPickupsFromApi(resp)
  }

  public async getPickupDetail(params: Map<string, string | number>): Promise<PickupForceDetail> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP_FORCE_DETAIL,
      params
    )
    return this.mapper.convertPickupDetailFromApi(resp)
  }

  public async getSnapshot(shipmentId: string): Promise<PickupDetailSnapshot[]> {
    const resp = await this.service.invoke(
      'get', `${Endpoints.Route.SNAPSHOT}/${shipmentId}`
    )
    return this.mapper.convertSnapshotFromApi(resp)
  }

  public async getSummary(params: Map<string, string | undefined>): Promise<PickupSummary> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP_SUMMARY,
      params
    )
    return this.mapper.convertPickupSummaryFromApi(resp)
  }

  public async export(
    payload: ExportPickupRequestInterface
  ): Promise<string[]> {
    const resp = await this.service.invoke(
      'post',
      Endpoints.Route.PICKUP_EXPORT,
      undefined,
      payload
    )
    return resp.data.data
  }

  public async exportCRRNFD(params: Map<string, string>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.SHIPMENT_ADMIN}/pickup-crrnfd/export`,
      params,
    )

    return this.mapper.convertCRRNFDExportFromApi(resp)
  }

  public async getNearbyCourier(
    params: Map<string, number>
  ): Promise<NearbyCourier[]> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.NEARBY_COURIER,
      params
    )
    return this.mapper.convertNearbyCourierFromApi(resp)
  }

  public async manualAssignee(
    payload: ManualAssigneeRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.MANUAL_ASSIGNEE_PICKUP}/bulk`,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ])
    )

    return true
  }

  public async markAsDone(
    payload: MarkAsDoneRequestInterface,
    group: boolean
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      group
        ? Endpoints.Route.PICKUP_GROUP_MARK_AS_DONE
        : Endpoints.Route.PICKUP_MARK_AS_DONE,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ])
    )

    return true
  }

  public async cancelPickup(
    payload: CancelPickupRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.CANCEL_ORDER,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ])
    )

    return true
  }

  public async pickupDedicatedCRROTW(
    payload: PickupDedicatedCRROTWRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      Endpoints.Route.PICKUP_DEDICATED_CRROTW,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ])
    )
    return true
  }

  public async getPickupAdditionalTime(
    params: Map<string, string>
  ): Promise<PickupAdditionalTimes> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.PICKUP_ADDITIONAL_TIME,
      params
    )
    return this.mapper.convertPickupAdditionalTimeFromApi(resp)
  }

  public async exportPickupList(params: Map<string, string>): Promise<string> {
    const resp = await this.service.invoke(
      'get',
      Endpoints.Route.EXPORT_PICKUP_LIST,
      params,
    )

    return this.mapper.convertPickupListFromApi(resp)
  }
  
  public async forceCRRCNC(payload: PickupForceCRRCNCRequestInterface): Promise<boolean> {
    await this.service.invoke(
      'POST',
      Endpoints.Route.PICKUP_FORCE_CRRCNC,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ])
    )

    return true
  }

  public async exportCRROTW(params: Map<string, string>): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PICKUP_OTW}/export`,
      params
    )

    return this.mapper.convertCRROTWExportFromApi(resp)
  }
  
  public async forceCRRSRC(payload: ForceCRRSRCRequest): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.SHIPMENT_PICKUP}/corporate`,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ]) 
    )

    return true
  }

  public async getListCRRQUE(params: Map<string, string | number>): Promise<PickupCRRQUEs> {
    const resp = await this.service.invoke(
      'GET',
      Endpoints.Route.PICKUP_QUEUE,
      params
    )

    return this.mapper.convertCRRQUEFromApi(<AxiosResponse>resp)
  }

  public async forceCancelCRRQUE(payload: ForceCancelCRRQUERequest): Promise<boolean> {
    await this.service.invoke(
      'POST',
      `${Endpoints.Route.PICKUP_QUEUE}/cancel`,
      undefined,
      payload,
      new Map([
        ['metadata', Utils.convertBase64(`{"timezone": "${Utils.getIANATimeZone()}"}`)]
      ]) 
    )

    return true
  }

  public async updateIsContactedCRRQUE(shipmentId: string, payload: UpdateIsContactedCRRQUERequest): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.PICKUP_QUEUE}/${shipmentId}`,
      undefined,
      payload
    )

    return true
  }

  public async exportCRRQUE(params: Map<string, string | number>): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PICKUP_QUEUE}/download`,
      params
    ) 

    return this.mapper.convertCRRQUEExportFromApi(resp)
  }

  public async exportManualAssignLog(params: Map<string, string>): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      Endpoints.Route.MANUAL_ASSIGN_EXPORT_LOG,
      params
    )  

    return this.mapper.convertManualAssignPickupFromApi(resp)
  }

  public async getListCorporate(params: Map<string, string | number>): Promise<PickupCorporates> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PICKUP_CORPORATE}/lists`,
      params
    )

    return this.mapper.convertPickupCorporatesFromApi(resp)
  }

  public async getCorporateSummary(): Promise<PickupCorporateSummary> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.PICKUP_CORPORATE}/summary`
    )

    return this.mapper.convertPickupCorporateSummaryFromApi(resp)
  }
}
