import { injectable } from 'tsyringe'

import { CategorySectionRepositoryInterface } from '@/data/persistences/repositories/contracts/CategorySectionRepositoryInterface'
import { CategorySection } from '@/domain/entities/CategorySection'
import {
  CreateCategorySectionRequestInterface,
  RepositionCategorySectionRequestInterface,
  UpdateCategorySectionRequestInterface,
} from '@/data/payload/contracts/CategorySectionRequest'
import { ProductDropdown } from '@/domain/entities/Product'

@injectable()
export class CategorySectionPresenter {
  private repository: CategorySectionRepositoryInterface

  constructor(repository: CategorySectionRepositoryInterface) {
    this.repository = repository
  }

  public get(categorySectionId: number): Promise<CategorySection> {
    return this.repository.get(categorySectionId)
  }

  public getAll(): Promise<CategorySection[]> {
    return this.repository.getAll()
  }

  public create(
    payload: CreateCategorySectionRequestInterface
  ): Promise<boolean> {
    return this.repository.create(payload)
  }

  public update(
    categorySectionId: number,
    payload: UpdateCategorySectionRequestInterface
  ): Promise<boolean> {
    return this.repository.update(categorySectionId, payload)
  }

  public delete(categorySectionId: number): Promise<boolean> {
    return this.repository.delete(categorySectionId)
  }

  public getProducts(
    params: Map<string, string | number>
  ): Promise<ProductDropdown[]> {
    return this.repository.getProducts(params)
  }

  public reposition(
    payload: RepositionCategorySectionRequestInterface
  ): Promise<boolean> {
    return this.repository.reposition(payload)
  }
}
