import { Pagination } from './Pagination'

export class DriverAnnouncement {
  public announcementId?: number
  public announcementTitle?: string
  public announcementBody?: string
  public announcementType?: string
  public startAt?: string
  public endAt?: string
  public imageUrl?: string
  public location?: string
  public announcementCourierType?: string
  public status?: string
  public createdAt?: string
  public updatedAt?: string

  constructor(
    announcementId?: number,
    announcementTitle?: string,
    announcementBody?: string,
    announcementType?: string,
    startAt?: string,
    endAt?: string,
    imageUrl?: string,
    location?: string,
    announcementCourierType?: string,
    status?: string,
    createdAt?: string,
    updatedAt?: string
  ) {
    this.announcementId = announcementId
    this.announcementTitle = announcementTitle
    this.announcementBody = announcementBody
    this.announcementType = announcementType
    this.startAt = startAt
    this.endAt = endAt
    this.imageUrl = imageUrl
    this.location = location
    this.announcementCourierType = announcementCourierType
    this.status = status
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export class DriverAnnouncements {
  public pagination?: Pagination
  public data?: DriverAnnouncement[]

  constructor(pagination?: Pagination, data?: DriverAnnouncement[]) {
    this.pagination = pagination
    this.data = data
  }
}
