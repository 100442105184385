import { AxiosResponse } from 'axios'
import {
  DriverScoreboardConfigurations,
  DriverScoreboardConfiguration,
  Drivers,
  Driver,
  DriverScoreReport,
  DriverScoreData,
  DriverScoreReportData,
  DriverScoreReportCalculate,
  DriverScoreCalculateData,
  DriverScoreReportCalculateData,
  DetailScorePerDay,
} from '@/domain/entities/DriverScoreboard'
import { BaseResponseMapper } from './BaseMapper'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'

export class DriverScoreboardMapper extends BaseResponseMapper {
  public convertDriverScoreboardConfigFromApi(
    result: AxiosResponse<any>
  ): DriverScoreboardConfigurations {
    const {
      data: { data },
    } = result
    return new DriverScoreboardConfigurations(
      data.map(
        (config: Record<string, string | number | string[]>) =>
          new DriverScoreboardConfiguration(
            Number(config.driver_score_config_id),
            config.score_type as string,
            config.label as string,
            config.score_value as string,
            Number(config.poin),
            config.action as string,
            config.start_date as string,
            config.end_date as string,
            config.created_at as string
          )
      )
    )
  }

  public convertDriversFromApi(result: AxiosResponse<any>): Drivers {
    const {
      data: { data, pagination },
    } = result
    return new Drivers(
      Utils.toInstance(new Pagination(), pagination),
      data.map(
        (driver: Record<string, string | number | string[]>) =>
          new Driver(
            Number(driver.courier_id),
            driver.fullname as string,
            driver.phone_number as string,
            driver.courier_type as string,
            Number(driver.score)
          )
      )
    )
  }

  public convertDriverReportFromApi(
    result: AxiosResponse<any>
  ): DriverScoreReport {
    const { data } = result
    const scoreData = data.data as Record<string, any[]>

    return new DriverScoreReport(
      data.courier_id,
      data.courier_name,
      data.courier_origin,
      data.courier_phone,
      data.courier_vehicle_number,
      data.courier_type,
      Object.fromEntries(
        Object.keys(scoreData).map(dataType => {
          return [
            dataType,
            scoreData[dataType as string].map(
              score =>
                new DriverScoreReportData(
                  score.rules,
                  score.point,
                  score.total_shipment_id,
                  score.total_point
                )
            ),
          ]
        })
      ) as DriverScoreData
    )
  }

  public convertDriverReportCalculateFromApi(
    result: AxiosResponse<any>
  ): DriverScoreReportCalculate {
    const { data } = result
    const scoreData = data.data as Record<string, any[]>

    return new DriverScoreReportCalculate(
      data.courier_id,
      data.courier_name,
      data.courier_origin,
      data.courier_phone,
      data.courier_vehicle_number,
      data.courier_type,
      Object.fromEntries(
        Object.keys(scoreData).map(dataType => {
          return [
            dataType,
            scoreData[dataType as string].map(
              score =>
                new DriverScoreReportCalculateData(
                  score.rules,
                  score.point,
                  (score.detail as {
                    date: string
                    total_shipment_id: number
                    total_point: number
                  }[]).map(
                    item =>
                      new DetailScorePerDay(
                        item.date,
                        item.total_shipment_id,
                        item.total_point
                      )
                  )
                )
            ),
          ]
        })
      ) as DriverScoreCalculateData
    )
  }
}
