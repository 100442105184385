import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'
import { RouteConfig } from 'vue-router'
import { RouteInterface } from '@/app/ui/router/interface'

export const requestTopUpRouteName = 'RequestTopupPage'
export const requestTopUpDetailRouteName = 'RequestTopupPageDetail'
export const approvingTopUpRouteName = 'ApprovingTopUpSaldo'
export const approvingTopUpDetailRouteName = 'ApprovingTopUpSaldoDetailPage'
export const allDataAdjustmentRouteName = 'AllDataAdjustment'
export const allDataAdjustmentDetailRouteName = 'AllDataAdjustmentPage'
export const requestTopUpPreviewUploadBulkRouteName = 'RequestTopupPageSaldoPreviewUploadBulk'

export const requestTopUpRoute: RouteInterface = {
  path: 'request-topup',
  name: requestTopUpRouteName,
  meta: {
    title: 'Request Top-up Saldo',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_REQUEST_TOPUP,
    accessType: 'read',
  },
}

export const requestTopUpDetailRoute: RouteInterface = {
  path: 'request-topup/:id',
  name: requestTopUpDetailRouteName,
  meta: {
    title: 'Request Top-up Saldo',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_REQUEST_TOPUP,
    accessType: 'read',
  },
}

export const approvingTopUpRoute: RouteInterface = {
  path: 'approving-topup-saldo',
  name: approvingTopUpRouteName,
  meta: {
    title: 'Approving Top-up Saldo',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_APPROVING_TOPUP,
    accessType: 'read',
  },
}

export const approvingTopUpDetailRoute: RouteInterface = {
  path: 'approving-topup-saldo/:id',
  name: approvingTopUpDetailRouteName,
  meta: {
    title: 'Approving Top-up Saldo',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_APPROVING_TOPUP,
    accessType: 'read',
  },
}

export const allDataAdjustmentRoute: RouteInterface = {
  path: 'all-data-adjustment',
  name: allDataAdjustmentRouteName,
  meta: {
    title: 'All Data Adjustment',
    isParent: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_ALL_DATA,
    accessType: 'read',
  },
}

export const allDataAdjustmentDetailRoute: RouteInterface = {
  path: 'all-data-adjustment/:id',
  name: allDataAdjustmentDetailRouteName,
  meta: {
    title: 'All Data Adjustment',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_ALL_DATA,
    accessType: 'read',
  },
}

export const previewRequestTopupBulkUploadRoute: RouteInterface = {
  path: 'request-topup/preview-upload-bulk',
  name: requestTopUpPreviewUploadBulkRouteName,
  meta: {
    title: 'Preview Request Topup Upload Bulk',
    isHidden: true,
    slugFromBackend: BACKEND_SLUG.ADJUSTMENT_REQUEST_TOPUP,
    accessType: 'read'
  },
}

export const adjustmentSaldo: RouteConfig = {
  path: '/adjustment',
  component: MainLayout,
  redirect: '/adjustment/request-topup',
  meta: {
    title: 'Adjustment Saldo',
    single: false,
    icon: 'MenuIconAdjustment',
  },
  children: [
    {
      ...requestTopUpRoute,
      component: () =>
        import(
          /* webpackChunkName: "RequestTopupPage" */ '@/app/ui/views/Adjustment/RequestTopup/index.vue'
        ),
    },
    {
      ...previewRequestTopupBulkUploadRoute,
      component: () =>
        import(
          /* webpackChunkName: "PreviewRequestTopupUploadBulkPage" */ '@/app/ui/views/Adjustment/RequestTopup/PreviewUploadBulk/index.vue'
      ),
    },
    {
      ...requestTopUpDetailRoute,
      component: () =>
        import(
          /* webpackChunkName: "RequestTopupPageDetail" */ '@/app/ui/views/Adjustment/RequestTopup/Detail/index.vue'
        ),
    },
    {
      ...approvingTopUpRoute,
      component: () =>
        import(
          /* webpackChunkName: "ApprovingTopUpSaldo" */ '@/app/ui/views/Adjustment/ApprovingTopupSaldo/index.vue'
        ),
    },
    {
      ...approvingTopUpDetailRoute,
      component: () =>
        import(
          /* webpackChunkName: "ApprovingTopUpSaldoDetailPage" */ '@/app/ui/views/Adjustment/ApprovingTopupSaldo/Detail/index.vue'
        ),
    },
    {
      ...allDataAdjustmentRoute,
      component: () =>
        import(
          /* webpackChunkName: "AllAdjusmentData" */ '@/app/ui/views/Adjustment/AllDataAdjustment/index.vue'
        ),
    },
    {
      ...allDataAdjustmentDetailRoute,
      component: () =>
        import(
          /* webpackChunkName: "AllDataAdjustmentDetailPage" */ '@/app/ui/views/Adjustment/AllDataAdjustment/Detail/index.vue'
        ),
    },
  ],
}
