import { BaseResponseMapper } from '@/data/persistences/mappers/BaseMapper'
import { AxiosResponse } from 'axios'
import { Pagination } from '@/domain/entities/Pagination'
import {
  ManageDataPayroll,
  ManageDataPayrollDetail,
  ManageDataPayrollDetails,
  ManageDataPayrollHistories,
  ManageDataPayrollHistory,
  ManageDataPayrolls,
} from '@/domain/entities/ManageDataPayroll'
import {
  ManageDataPayrollDetailResponse,
  ManageDataPayrollHistoryResponse,
  ManageDataPayrollResponse,
} from '@/data/responses/contracts/ManageDataPayrollResponse'

export class ManageDataPayrollMapper extends BaseResponseMapper {
  public convertManageDataPayrollFromApi(
    result: AxiosResponse
  ): ManageDataPayrolls {
    const {
      data: { data, pagination },
    } = result

    return new ManageDataPayrolls(
      new Pagination(pagination.page, pagination.limit, pagination.total_data),
      data.map(
        (item: ManageDataPayrollResponse) =>
          new ManageDataPayroll(
            item.courier_id,
            item.courier_name,
            item.phone_number,
            item.balance,
            item.is_active
          )
      )
    )
  }

  public convertManageDataPayrollDetailFromApi(
    result: AxiosResponse
  ): ManageDataPayrollDetails {
    const {
      data: { data, pagination },
    } = result

    return new ManageDataPayrollDetails(
      new Pagination(pagination.page, pagination.limit, pagination.total_data),
      data.map(
        (item: ManageDataPayrollDetailResponse) =>
          new ManageDataPayrollDetail(
            item.mode,
            item.shipment_id,
            item.package_id,
            item.created_at,
            item.amount,
            item.has_adjustment
          )
      )
    )
  }

  public convertManageDataPayrollHistoryFromApi(
    result: AxiosResponse
  ): ManageDataPayrollHistories {
    const {
      data: { data, pagination },
    } = result

    return new ManageDataPayrollHistories(
      new Pagination(pagination.page, pagination.limit, pagination.total_data),
      data.map(
        (item: ManageDataPayrollHistoryResponse) =>
          new ManageDataPayrollHistory(
            item.withdrawal_id,
            item.created_at,
            item.amount,
            item.bank_name,
            item.admin_fee,
            item.received,
            item.status,
            item.description
          )
      )
    )
  }
}
