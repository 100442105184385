export class CategorySectionProduct {
  public productId?: number
  public productName?: string
  public merchantId?: number
  public merchantName?: string

  constructor(
    productId?: number,
    productName?: string,
    merchantId?: number,
    merchantName?: string
  ) {
    this.productId = productId
    this.productName = productName
    this.merchantId = merchantId
    this.merchantName = merchantName
  }
}
export class CategorySection {
  public id?: number
  public categoryName?: string
  public bannerHomepageImage?: string
  public bannerDetailImage?: string
  public products?: CategorySectionProduct[]

  constructor(
    id?: number,
    categoryName?: string,
    bannerHomepageImage?: string,
    bannerDetailImage?: string,
    products?: CategorySectionProduct[]
  ) {
    this.id = id
    this.categoryName = categoryName
    this.bannerHomepageImage = bannerHomepageImage
    this.bannerDetailImage = bannerDetailImage
    this.products = products
  }
}
