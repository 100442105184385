import { Pagination } from '@/domain/entities/Pagination';

export enum EnumStatusLoyaltyMembership  {
  ACTIVE = 'ACTIVE', // active
  PAUSED = 'PAUSED', // active
  SCHEDULED = 'SCHEDULED', // inactive
  STOPPED = 'STOPPED', // inactive
  EXPIRED = 'EXPIRED' // inactive
}

export class LoyaltyMembershipInfo {
  public id: number
  public startDate; endDate: string
  public prefixSTTShipments; prefixShipmentIDs; packageStatuses: Array<string>
  public status: EnumStatusLoyaltyMembership
  public isIncludeSTTNonCustomerApp: boolean
  public backwardMonthPeriod: number
  public createdAt?; updatedAt?: string
  public createdBy?: string
  public updatedBy?: null | string
  public levelsName?: Array<string>
  constructor(
    id: number,
    startDate: string,
    endDate: string,
    prefixSTTShipments: Array<string>,
    prefixShipmentIDs: Array<string>,
    packageStatuses: Array<string>,
    status: EnumStatusLoyaltyMembership,
    isIncludeSTTNonCustomerApp: boolean,
    backwardMonthPeriod: number,
    createdAt?: string,
    updatedAt?: string,
    createdBy?: string,
    updatedBy?: null | string,
    levelsName?: Array<string>
  ) {
    this.id = id
    this.startDate = startDate
    this.endDate = endDate
    this.prefixSTTShipments = prefixSTTShipments
    this.prefixShipmentIDs = prefixShipmentIDs
    this.packageStatuses = packageStatuses
    this.status = status
    this.isIncludeSTTNonCustomerApp = isIncludeSTTNonCustomerApp
    this.backwardMonthPeriod = backwardMonthPeriod
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.levelsName = levelsName
  }
}

export class LoyaltyMembershipDateSchedule {
  public id; date; month: number

  constructor(id: number, date: number, month: number) {
    this.id = id
    this.date = date
    this.month = month
  }
}

export class LoyaltyMembershipLevel {
  public id: number
  public name: string
  public minimalAmountTransaction; minimalNumberTransaction: number

  constructor(
    id: number,
    name: string,
    minimalAmountTransaction: number,
    minimalNumberTransaction: number
  ) {
    this.id = id
    this.name = name
    this.minimalAmountTransaction = minimalAmountTransaction
    this.minimalNumberTransaction = minimalNumberTransaction
  }
}

export class LoyaltyMembershipHistory {
  public id: number
  public status: string
  public remarks: string
  public createdAt: string
  public updatedAt: null | string

  constructor(
    id: number,
    status: string,
    remarks: string,
    createdAt: string,
    updatedAt: null | string
  ) {
    this.id = id
    this.status = status
    this.remarks = remarks
    this.createdAt = createdAt
    this.updatedAt = updatedAt
  }
}

export class DetailLoyaltyProgramMembership {
  public info?: LoyaltyMembershipInfo
  public levels?: Array<LoyaltyMembershipLevel>

  public dateSchedules?: Array<LoyaltyMembershipDateSchedule>
  public histories?: Array<LoyaltyMembershipHistory>
  constructor(
    info?: LoyaltyMembershipInfo,
    levels?: Array<LoyaltyMembershipLevel>,
    dateSchedules?: Array<LoyaltyMembershipDateSchedule>,
    histories?: Array<LoyaltyMembershipHistory>
  ) {
    this.info = info
    this.levels = levels
    this.dateSchedules = dateSchedules
    this.histories = histories
  }

}

export class ListLoyaltyProgramMembership {
    public pagination?: Pagination
    public data?: Array<LoyaltyMembershipInfo>

  constructor(pagination?: Pagination, data?: Array<LoyaltyMembershipInfo>) {
      this.pagination = pagination
      this.data = data
  }
}

