import { Pagination } from '@/domain/entities/Pagination'

export class PublicVoucherData {
  public expiryDay?: number
  public pointAmount?: number
  public pointPurpose?: string
  public pointExpiryDay?: number

  constructor(
    expiryDay?: number,
    pointAmount?: number,
    pointPurpose?: string,
    pointExpiryDay?: number
  ) {
    this.expiryDay = expiryDay
    this.pointAmount = pointAmount
    this.pointPurpose = pointPurpose
    this.pointExpiryDay = pointExpiryDay
  }
}

export class PublicVoucherPurposeData {
  public discountPercentage?: number
  public discountPercentageMinimumTransaction?: number
  public discountPercentageMaximumAmount?: number
  public shipmentType?: string

  constructor(
    discountPercentage?: number,
    discountPercentageMinimumTransaction?: number,
    discountPercentageMaximumAmount?: number,
    shipmentType?: string
  ) {
    this.discountPercentage = discountPercentage
    this.discountPercentageMinimumTransaction = discountPercentageMinimumTransaction
    this.discountPercentageMaximumAmount = discountPercentageMaximumAmount
    this.shipmentType = shipmentType
  }
}

export class PublicVoucher {
  public id?: number
  public name?: string
  public code?: string
  public limitPerDay?: number
  public purpose?: string
  public limitUsagePerVoucher?: number
  public limitUsagePerUser?: number
  public voucherData?: PublicVoucherData
  public status?: string
  public startDate?: string
  public endDate?: string
  public createdBy?: string
  public updatedBy?: string
  public createdAt?: string
  public updatedAt?: string
  public deletedAt?: string
  public voucherExpiredAt?: string
  public voucherPurposeData?: PublicVoucherPurposeData
  public multipleVoucherCount?: number
  public multipleVoucherPartner?: string

  constructor(
    id?: number,
    name?: string,
    code?: string,
    limitPerDay?: number,
    purpose?: string,
    limitUsagePerVoucher?: number,
    limitUsagePerUser?: number,
    voucherData?: PublicVoucherData,
    status?: string,
    startDate?: string,
    endDate?: string,
    createdBy?: string,
    updatedBy?: string,
    createdAt?: string,
    updatedAt?: string,
    deletedAt?: string,
    voucherExpiredAt?: string,
    voucherPurposeData?: PublicVoucherPurposeData,
    multipleVoucherCount?: number,
    multipleVoucherPartner?: string,
  ) {
    this.id = id
    this.name = name
    this.code = code
    this.limitPerDay = limitPerDay
    this.purpose = purpose
    this.limitUsagePerVoucher = limitUsagePerVoucher
    this.limitUsagePerUser = limitUsagePerUser
    this.voucherData = voucherData
    this.status = status
    this.startDate = startDate
    this.endDate = endDate
    this.createdBy = createdBy
    this.updatedBy = updatedBy
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.deletedAt = deletedAt
    this.voucherExpiredAt = voucherExpiredAt
    this.voucherPurposeData = voucherPurposeData
    this.multipleVoucherCount = multipleVoucherCount
    this.multipleVoucherPartner = multipleVoucherPartner
  }
}

export class PublicVouchers {
  public pagination?: Pagination
  public data?: PublicVoucher[]

  constructor(pagination?: Pagination, data?: PublicVoucher[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PublicVoucherHistory {
  public id?: number
  public name?: string
  public customerId?: number
  public customerName?: string
  public customerEmail?: string
  public city?: string
  public voucherType?: string
  public code?: string
  public amount?: number // potential amount
  public usedAmount?: number
  public createdAt?: string
  public voucherPercentage?: number // usage for discount by percentage
  public vouchedUsedAt?: string
  public voucherAmount?: number // usage for discount by percentage
  public vouchedUsed?: number // usage for discount by percentage
  public purpose?: string
  public purchaseAt?: string

  constructor(
    id?: number,
    name?: string,
    customerId?: number,
    customerName?: string,
    customerEmail?: string,
    city?: string,
    voucherType?: string,
    code?: string,
    amount?: number,
    usedAmount?: number,
    createdAt?: string,
    voucherPercentage?: number,
    vouchedUsedAt?: string,
    voucherAmount?: number,
    vouchedUsed?: number,
    purpose?: string,
    purchaseAt?: string
  ) {
    this.id = id
    this.name = name
    this.customerId = customerId
    this.customerName = customerName
    this.customerEmail = customerEmail
    this.city = city
    this.voucherType = voucherType
    this.code = code
    this.amount = amount
    this.usedAmount = usedAmount
    this.createdAt = createdAt
    this.voucherPercentage = voucherPercentage
    this.vouchedUsedAt = vouchedUsedAt
    this.voucherAmount = voucherAmount
    this.vouchedUsed = vouchedUsed
    this.purpose = purpose
    this.purchaseAt = purchaseAt
  }
}

export class PublicVoucherHistories {
  public pagination?: Pagination
  public data?: PublicVoucherHistory[]

  constructor(pagination?: Pagination, data?: PublicVoucherHistory[]) {
    this.pagination = pagination
    this.data = data
  }
}

export class PublicVoucherCodeMultipleExternalPartner {
  partnerCode: string
  partnerName: string

  constructor(partnerCode: string, partnerName: string) {
    this.partnerCode = partnerCode
    this.partnerName = partnerName
  }
}
