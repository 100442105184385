import { injectable } from "tsyringe";
import { ManageCustomerRepositoryInterface } from "@/data/persistences/repositories/contracts/ManageCustomerInterface";
import { ManageCustomer, ManageCustomers, HistoryStatusCustomer } from "@/domain/entities/ManageCustomer";
import {
  BlockedCustomerRequestInterface,
  ChangeStatusCustomerRequestInterface,
  UnblockedCustomerRequestInterface
} from "@/data/payload/contracts/ManageCustomer";

@injectable()
export class ManageCustomerPresenter {
  private repository: ManageCustomerRepositoryInterface

  constructor(repository: ManageCustomerRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number>): Promise<ManageCustomers> {
    return this.repository.getAll(params)
  }

  public get(customerId: string): Promise<ManageCustomer> {
    return this.repository.get(customerId)
  }

  public getHistoryStatus(customerId: string): Promise<HistoryStatusCustomer> {
    return this.repository.getHistoryStatus(customerId)
  }

  public blocked(payload: BlockedCustomerRequestInterface,reason: string): Promise<boolean> {
    return this.repository.blocked(payload, reason)
  }

  public unblocked(payload: UnblockedCustomerRequestInterface, reason: string): Promise<boolean> {
    return this.repository.unblocked(payload, reason)
  }

  public changeStatus(payload: ChangeStatusCustomerRequestInterface): Promise<boolean> {
    return this.repository.changeStatus(payload)
  }
}
