import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'
import {
  CourierShuttle,
  CourierShuttles,
  ManageCourierCaptain,
  Shuttle,
  ShuttleCycle,
  ShuttleDetail,
  ShuttlePIC,
  Shuttles,
} from '@/domain/entities/Shuttle'
import { Utils } from '@/app/infrastructures/misc'
import { Pagination } from '@/domain/entities/Pagination'
import {
  CourierShuttleResponse,
  ShuttleDetailResponse,
  ShuttleResponse,
} from '@/data/responses/contracts/ShuttleResponse'
import { ManageCourier } from '@/domain/entities/ManageCourier'

export class ShuttleMapper extends BaseResponseMapper {
  public convertShuttleListFromApi(result: AxiosResponse): Shuttles {
    const {
      data: { pagination, data },
    } = result

    return new Shuttles(
      data.map((shuttle: ShuttleResponse) => {
        return new Shuttle(
          <string>shuttle.shuttle_id,
          <string>shuttle.shuttle_name,
          <string>shuttle.shuttle_address,
          <string>shuttle.shuttle_map_url,
          <string>shuttle.shuttle_region,
          <number>shuttle.latitude,
          <number>shuttle.longitude,
          <string>shuttle.created_at,
          <string>shuttle.updated_at,
          <ShuttleCycle[]>shuttle.shuttle_cycle.map(cycle => {
            return new ShuttleCycle(cycle.start_at, cycle.end_at)
          }),
          <ShuttlePIC[]>shuttle.shuttle_pic.map(pic => {
            return new ShuttlePIC(pic.pic_name, pic.pic_phone_number)
          })
        )
      }),
      Utils.toInstance(new Pagination(), pagination)
    )
  }

  public convertShuttleDetailFromApi(result: AxiosResponse): ShuttleDetail {
    const { data } = result

    const shuttle: ShuttleDetailResponse = data

    return new ShuttleDetail(
      <string>data.shuttle_id,
      <string>shuttle.shuttle_name,
      <string>shuttle.shuttle_address,
      <string>shuttle.shuttle_map_url,
      <string>shuttle.shuttle_region,
      <number>shuttle.latitude,
      <number>shuttle.longitude,
      <string>shuttle.created_at,
      <string>shuttle.updated_at,
      <ShuttleCycle[]>shuttle.shuttle_cycle.map(cycle => {
        return new ShuttleCycle(cycle.start_at, cycle.end_at)
      }),
      <ShuttlePIC[]>shuttle.shuttle_pic.map(pic => {
        return new ShuttlePIC(pic.pic_name, pic.pic_phone_number)
      }),
      new ManageCourierCaptain(
        shuttle.shuttle_courier_captain?.courier_id,
        shuttle.shuttle_courier_captain?.fullname,
        shuttle.shuttle_courier_captain?.role,
        shuttle.shuttle_courier_captain?.phone_number,
        shuttle.shuttle_courier_captain?.vehicle_type,
        shuttle.shuttle_courier_captain?.vehicle_plate,
        shuttle.shuttle_courier_captain?.partner_type,
        shuttle.shuttle_courier_captain?.partner_name,
        shuttle.shuttle_courier_captain?.origin,
        shuttle.shuttle_courier_captain?.courier_type,
        shuttle.shuttle_courier_captain?.courier_status,
        shuttle.shuttle_courier_captain?.courier_type_valid,
        shuttle.shuttle_courier_captain?.courier_captain_id
      ),
      shuttle.shuttle_courier.map(courier => {
        return new ManageCourier(
          courier.courier_id,
          courier.fullname,
          courier.role,
          courier.phone_number,
          courier.vehicle_type,
          courier.vehicle_plate,
          courier.partner_type,
          courier.partner_name,
          courier.origin,
          courier.courier_type,
          courier.courier_status,
          courier.courier_type_valid
        )
      }),
      shuttle.is_captain_sk_status
    )
  }

  public convertCourierShuttleListFromApi(result: AxiosResponse): CourierShuttles {
    const {
      data: { pagination, list },
    } = result

    return new CourierShuttles(
      list.map((shuttle: CourierShuttleResponse) => {
        return new CourierShuttle(
          shuttle.shuttle_courier_id,
          shuttle.shuttle_id,
          shuttle.courier_id,
          shuttle.shuttle_name,
          shuttle.shuttle_region,
          shuttle.shuttle_address,
          shuttle.longitude,
          shuttle.latitude,
          shuttle.shuttle_map_url,
          shuttle.location_geo,
          shuttle.created_at,
          shuttle.courier_captain_id,
          shuttle.is_captain
        )
      }),
      Utils.toInstance(new Pagination(), pagination)
    )
  }
}
