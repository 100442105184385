import { AccessMenu, Account, Token } from '@/domain/entities/Account'
import { AxiosResponse } from 'axios'
import { BaseResponseMapper } from './BaseMapper'

export class AuthDataMapper extends BaseResponseMapper {
  public convertAuthFromApi(result: AxiosResponse<any>): Token {
    const { data } = result
    return new Token(data.token, Number(data.expired_at))
  }

  public convertMyProfileFromApi(result: AxiosResponse<any>): Account {
    const { data } = result
    return new Account(
      data.account_id,
      data.email,
      data.role,
      undefined,
      data.access_menu.map(
        (menu: Record<string, any>) =>
          new AccessMenu(menu.id, menu.slug, menu.name, menu.group, true)
      )
    )
  }
}
