import { RouteConfig } from 'vue-router';
import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'

export const driver: RouteConfig = {
  path: '/driver',
  component: MainLayout,
  meta: {
    title: 'Driver',
    icon: 'MenuIconDriver',
  },
  redirect: '/driver/scoreboard',
  children: [
    {
      path: 'scoreboard',
      name: 'DriverScoreboardConfiguration',
      component: () =>
        import(
          /* webpackChunkName: "DriverScoringConfig" */ '@/app/ui/views/Driver/Config/index.vue'
        ),
      meta: {
        title: 'Driver Scoreboard',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
        accessType: 'read',
      },
    },
    {
      path: 'courier',
      component: {
        render: c => c('router-view'),
      },
      meta: {
        title: '',
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          component: {
            render: c => c('router-view'),
          },
          meta: {
            title: 'List Kurir',
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
            accessType: 'read',
          },
          children: [
            {
              path: '',
              name: 'DriverScoreboardList',
              component: () =>
                import(
                  /* webpackChunkName: "DriverScoreboardList" */ '@/app/ui/views/Driver/Courier/index.vue'
                ),
              meta: {
                title: 'List Kurir',
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
                accessType: 'read',
              },
            },
            {
              path: ':courierId',
              component: {
                render: c => c('router-view'),
              },
              meta: {
                title: 'Kartu Nilai Kurir',
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
                accessType: 'read',
              },
              children: [
                {
                  path: '',
                  name: 'DriverScoreReport',
                  component: () =>
                    import(
                      /* webpackChunkName: "DriverScoreReport" */ '@/app/ui/views/Driver/Courier/Report/index.vue'
                    ),
                  meta: {
                    title: 'Kartu Nilai Kurir',
                    isHidden: false,
                    slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
                    accessType: 'read',
                  },
                },
                {
                  path: 'detail/:date',
                  name: 'DriverScoreReportDetail',
                  component: () =>
                    import(
                      /* webpackChunkName: "DriverScoreReport" */ '@/app/ui/views/Driver/Courier/Report/Detail/index.vue'
                    ),
                  meta: {
                    title: 'Detail Perhitungan',
                    isHidden: false,
                    slugFromBackend: BACKEND_SLUG.DRIVER_SCOREBOARD,
                    accessType: 'read',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'pod-tracker',
      name: 'PODTracker',
      redirect: '/driver/pod-tracker/driver-pod',
      component: () =>
        import(
          /* webpackChunkName: "PODTracker" */ '@/app/ui/views/Driver/PODTracker/index.vue'
        ),
      meta: {
        title: 'POD Tracker',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.POD_TRACKER,
        accessType: 'read',
      },
      children: [
        {
          path: 'driver-pod',
          component: {
            render: c => c('router-view'),
          },
          meta: {
            title: 'List Kurir POD',
            isHidden: true,
            isParent: true,
            slugFromBackend: BACKEND_SLUG.POD_TRACKER,
            accessType: 'read',
          },
          children: [
            {
              path: '',
              name: 'CourierPODList',
              component: () =>
                import(
                  /* webpackChunkName: "CourierPODList" */ '@/app/ui/views/Driver/PODTracker/CourierPOD/index.vue'
                ),
              meta: {
                title: 'List Kurir POD',
                isHidden: true,
                isParent: true,
                slugFromBackend: BACKEND_SLUG.POD_TRACKER,
                accessType: 'read',
              },
            },
            {
              path: ':courierId',
              component: {
                render: c => c('router-view'),
              },
              meta: {
                title: 'List STT POD',
                isHidden: true,
                slugFromBackend: BACKEND_SLUG.POD_TRACKER,
                accessType: 'read',
              },
              children: [
                {
                  path: '',
                  name: 'SttPODListByCourier',
                  component: () =>
                    import(
                      /* webpackChunkName: "SttPODList" */ '@/app/ui/views/Driver/PODTracker/SttPOD/index.vue'
                    ),
                  meta: {
                    title: 'List STT POD',
                    isHidden: true,
                    slugFromBackend: BACKEND_SLUG.POD_TRACKER,
                    accessType: 'read',
                  },
                },
                {
                  path: ':packageId',
                  name: 'SttPODDetail',
                  component: () =>
                    import(
                      /* webpackChunkName: "PODDetail" */ '@/app/ui/views/Driver/PODTracker/SttPOD/Detail/index.vue'
                    ),
                  meta: {
                    title: 'Detail POD',
                    isHidden: true,
                    slugFromBackend: BACKEND_SLUG.POD_TRACKER,
                    accessType: 'read',
                  },
                },
              ],
            },
          ],
        },
        {
          path: 'stt-pod',
          name: 'SttPODList',
          component: () =>
            import(
              /* webpackChunkName: "SttPODList" */ '@/app/ui/views/Driver/PODTracker/SttPOD/index.vue'
            ),
          meta: {
            title: 'List STT POD',
            isHidden: true,
            isParent: true,
            slugFromBackend: BACKEND_SLUG.POD_TRACKER,
            accessType: 'read',
          },
        },
      ],
    },
    //
    // Request from Product Team
    //
    // COD Tracker page is temporarily hidden for now until further notice.
    // This is because of heavy query happening on this page.
    //

    // {
    //   path: 'cod-tracker',
    //   component: {
    //     render: c => c('router-view'),
    //   },
    //   redirect: {
    //     name: 'CODTracker',
    //   },
    //   meta: {
    //     title: 'COD Tracker',
    //     isParent: true,
    //     isHidden: false,
    //     slugFromBackend: BACKEND_SLUG.COD_TRACKER,
    //     accessType: 'read',
    //   },
    //   children: [
    //     {
    //       path: 'list',
    //       name: 'CODTracker',
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "CODTracker" */ '@/app/ui/views/Driver/CODTracker/index.vue'
    //         ),
    //       meta: {
    //         title: 'List Kurir COD',
    //         isParent: true,
    //         isHidden: false,
    //         slugFromBackend: BACKEND_SLUG.COD_TRACKER,
    //         accessType: 'read',
    //       },
    //     },
    //     {
    //       path: 'detail/:packageId',
    //       name: 'CODTrackerDetail',
    //       component: () =>
    //         import(
    //           /* webpackChunkName: "CODTrackerDetail" */ '@/app/ui/views/Driver/CODTracker/Detail/index.vue'
    //         ),
    //       meta: {
    //         title: 'List Kurir COD',
    //         isHidden: true,
    //         slugFromBackend: BACKEND_SLUG.COD_TRACKER,
    //         accessType: 'read',
    //       },
    //     },
    //   ],
    // },
    {
      path: 'manual-pod-photos',
      component: {
        render: c => c('router-view'),
      },
      redirect: {
        name: 'ManualPODPhotos',
      },
      meta: {
        title: 'Manual POD Photos',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.MANUAL_POD_PHOTOS,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'ManualPODPhotos',
          component: () =>
            import(
              /* webpackChunkName: "ManualPODPhotos" */ '@/app/ui/views/Driver/ManualPODPhotos/index.vue'
            ),
          meta: {
            title: 'Manual POD Photos',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.MANUAL_POD_PHOTOS,
            accessType: 'read',
          },
        },
      ],
    },
    {
      path: 'pickup-validation-radius',
      component: {
        render: c => c('router-view'),
      },
      redirect: {
        name: 'PickupRadiusValidationList',
      },
      meta: {
        title: 'Pickup Validation Radius',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.PICKUP_VALIDATION_RADIUS,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'PickupRadiusValidation',
          component: () =>
            import(
              /* webpackChunkName: "PickupRadiusValidation" */ '@/app/ui/views/Driver/PickupValidationRadius/index.vue'
            ),
          meta: {
            title: 'Pickup Validation Radius',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.PICKUP_VALIDATION_RADIUS,
            accessType: 'read',
          },
        },
        {
          path: ':pickupLogId',
          name: 'PickupRadiusValidationDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupRadiusValidationDetail" */ '@/app/ui/views/Driver/PickupValidationRadius/Detail/index.vue'
            ),
          meta: {
            title: 'Detail',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.PICKUP_VALIDATION_RADIUS,
            accessType: 'read',
          },
        },
        {
          path: 'shipment/:shipmentId',
          name: 'PickupRadiusValidationShipmentDetail',
          component: () =>
            import(
              /* webpackChunkName: "PickupRadiusValidationShipmentDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.PICKUP_VALIDATION_RADIUS,
            accessType: 'read',
          },
        },
      ],
    },
    {
      path: 'cancel-validation',
      component: {
        render: c => c('router-view'),
      },
      name: 'CancelValidation',
      redirect: {
        name: 'CancelValidationTab'
      },
      meta: {
        title: 'Cancel Validation',
        isParent: true,
        isHidden: false,
        slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
        accessType: 'read',
      },
      children: [
        {
          path: '',
          name: 'CancelValidationTab',
          redirect: {
            name: 'CancelValidationTabCancelList',
          },
          component: () =>
            import(
              /* webpackChunkName: "CancelValidation" */ '@/app/ui/views/Driver/CancelValidation/index.vue'
            ),
          meta: {
            title: 'Cancel Validation',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
            accessType: 'read',
          },
          children: [
            {
              path: 'cancel',
              name: 'CancelValidationTabCancelList',
              component: () =>
                import(
                  /* webpackChunkName: "CancelValidationCancelList" */ '@/app/ui/views/Driver/CancelValidation/Cancel/index.vue'
                ),
              meta: {
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
                accessType: 'read',
              },
            },
            {
              path: 'courier-cancel',
              name: 'CancelValidationTabCourierCancelList',
              component: () =>
                import(
                  /* webpackChunkName: "CancelValidationCourierCancelList" */ '@/app/ui/views/Driver/CancelValidation/CourierCancel/index.vue'
                ),
              meta: {
                isHidden: false,
                slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
                accessType: 'read',
              },
            }
          ]
        },
        {
          path: 'shipment/:shipmentId',
          name: 'CancelValidationShipmentDetail',
          component: () =>
            import(
              /* webpackChunkName: "CancelValidationShipmentDetail" */ '@/app/ui/views/Pickup/Detail/index.vue'
            ),
          meta: {
            title: 'Shipment Tracking',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
            accessType: 'read',
          },
        },
        {
          path: 'shipment-cancel/:courierId',
          name: 'CancelValidationShipmentCancelList',
          component: () =>
            import(
              /* webpackChunkName: "CancelValidationShipmentCancelList" */ '@/app/ui/views/Driver/CancelValidation/CourierShipmentCancel/index.vue'
            ), 
          meta: {
            title: 'List Shipment Cancel',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
            accessType: 'read',
          }
        },
        {
          path: 'detail/:historyId',
          name: 'CancelValidationDetail',
          component: () =>
            import(
              /* webpackChunkName: "CancelValidationDetail" */ '@/app/ui/views/Driver/CancelValidation/Detail/index.vue'
            ), 
          meta: {
            title: 'Detail',
            isParent: true,
            isHidden: false,
            slugFromBackend: BACKEND_SLUG.CANCEL_VALIDATION,
            accessType: 'read',
          }
        }
      ],
    }
  ],
}
