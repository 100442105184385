import {
  MAX_LATITUDE,
  MAX_LONGITUDE,
  MIN_LATITUDE,
  MIN_LONGITUDE,
} from './Constants/validation'
import { LocalStorage } from './LocalStorage'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import {
  REGEX_SPECIAL_CHAR_WITH_EMOJIS,
  REGEX_SCRIPT_TAG,
  REGEX_SPECIAL_CHARACTERS,
  REGEX_HTML_TAG,
  REGEX_INDONESIA_VEHICLE_PLATE,
} from './Constants/regex'

export class Utils {
  /**
   * Return only ASCII standard string from input
   *
   * @static
   * @param {string} input
   * @returns {string}
   * @memberof Utils
   */
  public static takeASCII(input: string): string {
    // eslint-disable-next-line no-control-regex
    return input.replace(/[^\x00-\xFF]/g, '')
  }

  /**
   * stripTags
   * strip all html tags from string
   *
   * @static
   * @param {string} input
   * @returns {string}
   * @memberof Utils
   */
  public static stripTags(input: string): string {
    const div = document.createElement('div')
    div.innerHTML = input
    const text = div.textContent || div.innerText || ''
    return text
  }

  /**
   * isSuperAdmin to check if current login user role is guest or not
   *
   * @static
   * @returns {boolean}
   * @memberof Utils
   */
  public static isSuperAdmin(): boolean {
    return LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true) ===
    'CMS_ADMIN' ||
    LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true) === 'ADMIN'
      ? true
      : false
  }

  /**
   * isGuest to check if current login user role is guest or not
   *
   * @static
   * @returns {boolean}
   * @memberof Utils
   */
  public static isGuest(): boolean {
    return LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true) ===
    'CMS_GUEST'
      ? true
      : false
  }

  /**
   * isFinance to check if current login user role either is Finance
   *
   * @static
   * @returns {boolean}
   * @memberof Utils
   */
  public static isFinance(): boolean {
    return (
      LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true) === 'CMS_FINANCE'
    )
  }

  /**
   * isCNM to check if current login user role either is CNM
   *
   * @static
   * @returns {boolean}
   * @memberof Utils
   */
  public static isCNM(): boolean {
    return (
      LocalStorage.getLocalStorage(LocalStorage.MY_ROLE, true) === 'CMS_CNM'
    )
  }

  /**
   * To sanitize payload of access menu
   * When payload role is other than CMS_ADMIN
   * Access Menu for User Management (id = 1) menu must not be included
   *
   * @private
   * @param {string} role
   * @param {number[]} accessMenus
   * @returns {number[]}
   * @memberof UserManagementController
   */
  public static sanitizeMenu(role: string, accessMenus: number[]): number[] {
    let _menus = accessMenus
    if (role !== 'CMS_ADMIN') {
      _menus = _menus.filter(item => item !== 1)
    }

    return _menus
  }

  /**
   * Get humanized name of role
   *
   * @static
   * @param {string} role
   * @returns {string}
   * @memberof Utils
   */
  public static roleName(role: string): string {
    switch (role) {
      case 'CMS_ADMIN':
        return 'Super Admin'
      case 'CMS_USER':
        return 'User'
      case 'CMS_GUEST':
        return 'Guest'
      case 'CMS_FINANCE':
        return 'Finance'
      case 'CMS_CNM':
        return 'CNM'
      default:
        return role
    }
  }

  /**
   * set value to alwaysNumber
   *
   * @static
   * @param {string} value
   * @returns {number}
   * @memberof Utils
   */
  public static alwaysNumber(value: string): number {
    return isNaN(Number(value)) ? 0 : Number(value)
  }

  /**
   * set value to currency digit format
   *
   * @static
   * @param {number} input
   * @param {number | string} withDefault
   * @returns {(string | number)}
   * @memberof Utils
   */
  public static currencyDigit(
    input: number,
    withDefault: number | string = 0
  ): string | number {
    if (isNaN(input) || input === 0) {
      return withDefault
    }
    return input.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, '$1.')
  }

  /**
   * convert value to snake_cased
   *
   * @static
   * @param {string} input
   * @returns {string}
   * @memberof Utils
   */
  public static toSnakeCase(input: string): string {
    return (
      input[0].toLowerCase() +
      input
      .slice(1, input.length)
      .replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    )
  }

  /**
   * convert value from snake_case to camelCased
   *
   * @static
   * @param {string} input
   * @returns {string}
   * @memberof Utils
   */
  public static snakeCaseToCamelCase(input: string): string {
    return input
    .split('_')
    .reduce(
      (res, word, i) =>
        i === 0
          ? word.toLowerCase()
          : `${res}${word.charAt(0).toUpperCase()}${word
          .substr(1)
          .toLowerCase()}`,
      ''
    )
  }

  /**
   * Convert phone number to Indonesia country code format phone number
   *
   * @static
   * @param {string} input
   * @param {boolean} isReverse
   * @returns
   * @memberof Utils
   */
  public static countryIndonesiaPhoneNumber(
    input: string,
    isReverse = false
  ): string {
    if (isReverse) {
      return input.replace(/(^\+?62)|^(?!0)/, '0')
    } else {
      if (input.startsWith('+62')) {
        return input
      } else {
        if (input.startsWith('62')) {
          return `+${input}`
        } else if (input.startsWith('0')) {
          return `+62${input.substr(1)}`
        } else {
          return `+62${input}`
        }
      }
    }
  }

  /**
   * Convert defined variable to be typed as destined value
   * Example: I have json that should be converted to ErrorMessage class type
   *
   * @static
   * @template T
   * @param {T} obj
   * @param {(Map<string, any> | string)} json
   * @param {('snake_case' | 'camelCase')} [to='camelCase']
   * @returns {T}
   * @memberof Utils
   */
  public static toInstance<T>(
    obj: T,
    json: Map<string, unknown> | string,
    to: 'snake_case' | 'camelCase' = 'camelCase'
  ): T {
    const jsonObj = typeof json === 'string' ? JSON.parse(json) : json

    for (const propName in jsonObj) {
      const keyName =
        to === 'camelCase'
          ? this.snakeCaseToCamelCase(propName)
          : this.toSnakeCase(propName)

      obj = Object.assign({}, obj, {
        [keyName]: jsonObj[propName],
      })
    }

    return obj
  }

  /**
   * Debounce
   *
   * @param {Function} func
   * @param {number} timeout
   */
  public static debounce<Params extends unknown[]>(
    func: (...args: Params) => unknown,
    timeout: number
  ): (...args: Params) => void {
    let timer: number
    return (...args: Params) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        func(...args)
      }, timeout)
    }
  }

  /**
   * Validation reference: https://latitudelongitude.org/id/
   *
   * @static
   * @param {number} [latitude=0]
   * @param {number} [longitude=0]
   * @returns {[boolean, boolean]}
   * @memberof Utils
   */
  public static validateIndonesiaCoordinate(
    latitude = 0,
    longitude = 0
  ): [boolean, boolean] {
    let validLatitude = true
    let validLongitude = true

    if (latitude > MAX_LATITUDE && latitude < MIN_LATITUDE) {
      validLatitude = false
    }
    if (longitude > MAX_LONGITUDE && longitude < MIN_LONGITUDE) {
      validLongitude = false
    }

    return [validLatitude, validLongitude]
  }

  /**
   * Escape HTML
   *
   * @static
   * @param {string} html
   * @returns {string}
   * @memberof Utils
   */
  public static escapeHTML(html: string): string {
    const map: Record<string, string> = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
    }

    return html.replace(/[&<>"']/g, function(m) {
      return map[m]
    })
  }

  public static isIncludeEmoticon(value: string): boolean {
    const regexEmot = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g
    return regexEmot.test(value)
  }

  public static numberOnly(value: string): string {
    return value.replace(/[^0-9]+/g, '')
  }

  public static formatPhoneNumber(phoneNumber: string): string {
    if (phoneNumber) {
      let phone = phoneNumber
      if (phoneNumber[0] === '0') {
        phone = phoneNumber.replace('0', '')
      } else if (phoneNumber.substring(0, 2) === '62') {
        phone = phoneNumber.replace('62', '')
      } else if (phoneNumber.substring(0, 3) === '+62') {
        phone = phoneNumber.replace('+62', '')
      }
      return phone
    } else {
      return ''
    }
  }

  public static toCapitalize(text: string): string {
    return text?.toLowerCase().replace(/(^.|\s+.)/g, m => m.toUpperCase())
  }

  public static toRupiah(val: number): string {
    return `Rp${this.currencyDigit(val)}`
  }

  public static getOS(): string {
    const navigator = window.navigator
    const userAgent = navigator.userAgent
    const platform =
      navigator.userAgentData?.platform || window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']

    if (macosPlatforms.indexOf(platform) !== -1) {
      return 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      return 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      return 'Windows'
    } else if (/Android/.test(userAgent)) {
      return 'Android'
    } else if (/Linux/.test(platform)) {
      return 'Linux'
    } else {
      return ''
    }
  }

  /**
   * Check if string include number
   *
   * @static
   * @param {string} str
   * @returns {boolean}
   * @memberof Utils
   */
  public static isStringIncludeNumber(str: string): boolean {
    return !/\d/.test(str)
  }

  public static formatDate(
    date: string | dayjs.Dayjs,
    format = 'DD MMMM YYYY, HH:mm Z',
    returnEmpty = '-'
  ): string {
    return date ? dayjs(date).format(format) : returnEmpty
  }

  public static formatDateWithIDLocale(
    date: string | dayjs.Dayjs,
    format = 'DD MMMM YYYY, HH:mm Z',
    returnEmpty = '-'
  ): string {
    return date
      ? dayjs(date)
      .locale(dayjsID)
      .format(format)
      : returnEmpty
  }

  public static formatTimeZone(dateTime: string): string {
    if (dateTime.includes('+07:00')) return dateTime.replace('+07:00', 'WIB')
    if (dateTime.includes('+08:00')) return dateTime.replace('+08:00', 'WITA')
    if (dateTime.includes('+09:00')) return dateTime.replace('+09:00', 'WIT')
    return dateTime
  }

  public static setFormatDateTime(
    date: Date,
    hours: number,
    minutes: number,
    seconds = 0
  ): string {
    const parsedDate = new Date(date.toISOString())
    parsedDate.setHours(hours)
    parsedDate.setMinutes(minutes)
    parsedDate.setSeconds(seconds)
    return parsedDate.toISOString().slice(0, 19) + 'Z'
  }

  public static setListToIgnoreEmpty(
    list: null | string[] | undefined
  ): string[] {
    if (list === null || list === undefined) {
      return []
    }
    return list.filter(v => v.trim() !== '')
  }

  public static phoneFormat(value: string): string {
    return value.replace(/[^+0-9]+/g, '')
  }

  public static onParamsChanged(
    key: string,
    val: string | boolean | undefined | number
  ): void {
    const url = new URL(location.href)
    url.searchParams.set(key, String(typeof val === 'undefined' ? '' : val))
    history.pushState(null, '', url.toString())
  }


  public static setRange(start: number, end: number, step = 1): Array<number> {
    const arrayLength = (end - start) / step

    const result = [
      ...new Array(Math.ceil(arrayLength)).fill('').reduce(
        (result, item, index) => {
          if (result[index] + step <= end) {
            result.push(result[index] + step)
          }

          return result
        },
        [start]
      ),
    ]

    return result
  }

  /**
   * Remove script tags from string
   * @param value
   * @returns
   * @memberof Utils
   * @example
   * removeScriptTags('<script>alert("Hello World!")</script>') // alert("Hello World!")
   */
  public static removeScriptTags(value: string): string {
    return value.replace(REGEX_SCRIPT_TAG, '')
  }

  /**
   * Remove special characters from string except emojis
   * @param value
   * @returns
   * @memberof Utils
   * @example
   * removeSpecialCharWithEmojis('Hello World!') // Hello World
   */
  public static removeSpecialCharWithEmojis(value: string): string {
    return value.replace(REGEX_SPECIAL_CHAR_WITH_EMOJIS, '')
  }

  /**
   * Remove special characters from string
   * @param value
   * @returns
   * @memberof Utils
   * @example
   * removeSpecialCharacters('Hello World!') // Hello World
   * removeSpecialCharacters('Hello World!@#$%^&*()_+-=') // Hello World
   * removeSpecialCharacters('Hello World!@#$%^&*()_+-=👍') // Hello World👍
   * removeSpecialCharacters('Hello World!@#$%^&*()_+-=👍👍') // Hello World👍👍
   *  */
  public static removeSpecialCharacters(value: string): string {
    return value.replace(REGEX_SPECIAL_CHARACTERS, '')
  }

  /**
   * Remove HTML tags from string
   * @param value
   * @returns
   * @memberof Utils
   * @example
   * removeHTMLTags('<p>Hello World!</p>') // Hello World!
   *
   * */
  public static removeHTMLTags(value: string): string {
    return value.replace(REGEX_HTML_TAG, '')
  }

  /**
   * Force TimeZone to Asia/Jakarta
   * @returns
   * @memberof Utils
   * @example
   * '2023-11-22 17:00'+forceTimeZoneAsiaJakarta() // 2023-11-22 17:00-14:00
   * */ 
  public static forceTimeZoneAsiaJakarta(): string {
    const tzAsiaJakarta = this.formatDateWithIDLocale(
      new Date().toISOString(),
      'Z'
    )
    const tzPlusMinusSign = tzAsiaJakarta.split(':')[0][0]
    const tzAsiaJakartaHours =
      tzPlusMinusSign === '+'
        ? parseInt(
            tzAsiaJakarta.split(':')[0][1] + tzAsiaJakarta.split(':')[0][2]
          ) - 7
        : parseInt(
            tzAsiaJakarta.split(':')[0][1] + tzAsiaJakarta.split(':')[0][2]
          ) + 7
    const tzAsiaJakartaMinutes = tzAsiaJakarta.split(':')[1]

    let finalTzAsiaJakartaMinutes = `${60 - parseInt(tzAsiaJakartaMinutes)}`

    finalTzAsiaJakartaMinutes =
      finalTzAsiaJakartaMinutes === '60' ? '00' : finalTzAsiaJakartaMinutes

    const finalTzAsiaJakartaHours =
      parseInt(tzAsiaJakartaMinutes) > 0
        ? Math.abs(tzAsiaJakartaHours) - 1
        : tzAsiaJakartaHours

    const isNegative =
      tzPlusMinusSign === '+' ? (tzAsiaJakartaHours < 0 ? '-' : '+') : '-'

    const isHourDoubleDigit = finalTzAsiaJakartaHours < 9 ? '0' : ''
    return `${isNegative}${isHourDoubleDigit}${Math.abs(
      finalTzAsiaJakartaHours
    )}:${finalTzAsiaJakartaMinutes}`
  }

  /**
   * Convert File to Base64
   * @param file File
   * @memberof Utils
   * */ 
  public static toBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(<string>reader.result);
      reader.onerror = reject;
    })
  }

  /**
   * Check Check Indonesia Vehicle Plate Number with Reguler expression
   * @param plateNo String
   * @memberof Utils
   * */ 
  public static checkIndonesiaVehiclePlate(plateNo: string): boolean {
    return REGEX_INDONESIA_VEHICLE_PLATE.test(plateNo);
  }

  /**
   * Convert String to Base64
   * @param str string
   * @memberof Utils
   * */ 
  public static convertBase64(str: string): string {
    return btoa(str)
  }

  /**
   * Get IANA TimeZone
   * @memberof Utils
   * */ 
  public static getIANATimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }
}
