import ApiService from '@/app/infrastructures/services/ApiService'
import { Endpoints } from '@/app/infrastructures/misc'
import { ManageDataPayrollRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageDataPayrollRepositoryInterface'
import {
  ManageDataPayrollDetails,
  ManageDataPayrollHistories,
  ManageDataPayrolls,
} from '@/domain/entities/ManageDataPayroll'
import { ManageDataPayrollMapper } from '@/data/persistences/mappers/ManageDataPayrollMapper'
import { UpdateManageDataPayrollRequestInterface } from '@/data/payload/contracts/ManageDataPayrollRequest'

export class ManageDataPayrollApiRepository
  implements ManageDataPayrollRepositoryInterface {
  private service: ApiService
  private mapper: ManageDataPayrollMapper

  constructor(service: ApiService, mapper: ManageDataPayrollMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(
    params: Map<string, number>
  ): Promise<ManageDataPayrolls> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/courier/list`,
      params
    )

    return this.mapper.convertManageDataPayrollFromApi(resp)
  }

  public async getAllDetail(
    id: number,
    params: Map<string, number>
  ): Promise<ManageDataPayrollDetails> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/courier/detail/${id}`,
      params
    )

    return this.mapper.convertManageDataPayrollDetailFromApi(resp)
  }

  public async getAllHistory(
    id: number,
    params: Map<string, number>
  ): Promise<ManageDataPayrollHistories> {
    const resp = await this.service.invoke(
      'get',
      `${Endpoints.Route.PAYROLL}/courier/withdrawal/${id}`,
      params
    )

    return this.mapper.convertManageDataPayrollHistoryFromApi(resp)
  }

  public async update(
    id: number,
    payload: UpdateManageDataPayrollRequestInterface
  ): Promise<boolean> {
    await this.service.invoke(
      'post',
      `${Endpoints.Route.PAYROLL}/courier/activation/${id}`,
      undefined,
      payload as UpdateManageDataPayrollRequestInterface
    )

    return true
  }
}
