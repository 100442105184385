import { injectable } from 'tsyringe'
import { ManageCourierRepositoryInterface } from '@/data/persistences/repositories/contracts/ManageCourierRepositoryInterface'
import {
  ManageCourierDetail,
  ManageCourierOnLeaves,
  ManageCouriers,
  Partner,
  ManageCourierHistoryLogs,
  ManageCourierUploadBulk,
  ManageCourierHistoryUploadBulks,
  TruckVendor,
  ManageCourierLeaveRequests,
} from '@/domain/entities/ManageCourier'
import {
  AddCourierRequestInterface,
  EditCourierRequestInterface,
  UploadBulkyManageCourierLeaveRequestInterface
} from '@/data/payload/contracts/ManageCourierRequest'
import { CourierLeaveApproval } from '@/data/payload/api/ManageCourierRequest'

@injectable()
export class ManageCourierPresenter {
  private repository: ManageCourierRepositoryInterface

  constructor(repository: ManageCourierRepositoryInterface) {
    this.repository = repository
  }

  public getAll(params: Map<string, string | number>): Promise<ManageCouriers> {
    return this.repository.getAll(params)
  }

  public getDetail(courierId: string): Promise<ManageCourierDetail> {
    return this.repository.get(courierId)
  }

  public addCourier(payload: AddCourierRequestInterface): Promise<boolean> {
    return this.repository.addCourier(payload)
  }

  public editCourier(
    courierId: string,
    payload: EditCourierRequestInterface
  ): Promise<boolean> {
    return this.repository.updateCourier(courierId, payload)
  }

  public getCouriersAdjustSaldo(
    params: Map<string, string | number>
  ): Promise<ManageCouriers> {
    return this.repository.getCouriersAdjustSaldo(params)
  }

  public getAllPartner(
    params: Map<string, string | number>
  ): Promise<Partner[]> {
    return this.repository.getAllPartner(params)
  }

  public export(params: Map<string, string | number>): Promise<string> {
    return this.repository.export(params)
  }

  public getAllHistoryLog(params: Map<string, string | number>, courierId: string): Promise<ManageCourierHistoryLogs> {
    return this.repository.getAllHistoryLog(params, courierId)
  }

  public getAllCourierOnLeave(params: Map<string, string | number>): Promise<ManageCourierOnLeaves> {
    return this.repository.getAllCourierOnLeave(params)
  }
  
  public dowloadTemplate(): Promise<string> {
    return this.repository.downloadTemplate()
  }

  public uploadBulky(
    payload: UploadBulkyManageCourierLeaveRequestInterface
  ): Promise<ManageCourierUploadBulk> {
    return this.repository.uploadBulky(payload)
  }

  public getUploadBulkData(
    params: Map<string, string | number>
  ): Promise<ManageCourierUploadBulk> {
    return this.repository.getUploadBulkData(params)
  }

  public submitBulk(): Promise<boolean> {
    return this.repository.submitBulk()
  }

  public getHistoryUploadBulky(params: Map<string, string | number>): Promise<ManageCourierHistoryUploadBulks> {
    return this.repository.getHistoryUploadBulky(params)
  }

  public getDetailHistoryUploadBulky(params: Map<string, string | number>): Promise<ManageCourierUploadBulk> {
    return this.repository.getDetailHistoryUploadBulky(params)
  }

  public getTruckVendorSuggestions(params: Map<string, string | number>): Promise<TruckVendor[]> {
    return this.repository.getTruckVendorSuggestions(params)
  }

  public getCourierLeaveRequest(params: Map<string, string | number>): Promise<ManageCourierLeaveRequests> {
    return this.repository.getCourierLeaveRequest(params)
  }

  public getCourierLeaveTotalRequest(): Promise<number> {
    return this.repository.getCourierLeaveTotalRequest()
  }

  public submitCourierLeaveApproval(payload: CourierLeaveApproval): Promise<boolean> {
    return this.repository.submitCourierLeaveApproval(payload)
  }
}
