import { RouteConfig } from 'vue-router'
import MainLayout from '@/app/ui/layouts/Main/index.vue'
import BACKEND_SLUG from '@/app/ui/router/constants'

export const shuttleManagement: RouteConfig = {
  path: '/shuttle-management',
  component: MainLayout,
  meta: {
    title: 'Shuttle Management',
    single: true,
    icon: 'MenuIconShuttleManagement',
  },
  children: [
    {
      path: '',
      name: 'ShuttleManagement',
      component: {
        render: c => c('router-view'),
      },
      redirect: {
        name: 'ShuttleManagementList',
      },
      meta: {
        slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
        accessType: 'read',
        isParent: true,
      },
      children: [
        {
          path: '',
          name: 'ShuttleManagementList',
          component: () =>
            import(
              /* WebpackChunkName: "ShuttleMangementList" */ '@/app/ui/views/ShuttleManagement/index.vue'
            ),
          meta: {
            title: 'Shuttle Management',
            slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
            accessType: 'read',
          },
        },
        {
          path: 'create',
          name: 'ShuttleManagementCreate',
          component: {
            render: c => c('router-view'),
          },
          redirect: {
            name: 'ShuttleManagementCreateForm'
          },
          meta: {
            title: 'Tambah Shuttle',
            slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
            accessType: 'read',
            isParent: true,
          },
          children: [
            {
              path: '',
              name: 'ShuttleManagementCreateForm',
              component: () =>
                import(
                  /* WebpackChunkName: "ShuttleManagementCreate" */ '@/app/ui/views/ShuttleManagement/ShuttleForm/index.vue'
                ),
              meta: {
                title: 'Tambah Shuttle',
                slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
                accessType: 'read',
              },
            },
            {
              path: 'add-courier',
              name: 'ShuttleManagementCreateAddCourier',
              component: () =>
                import(
                  /* WebpackChunkName: "ShuttleManagementCreateAddCourier" */ '@/app/ui/views/ShuttleManagement/ShuttleForm/AddCourier/index.vue'
                ),
              meta: {
                title: 'Tambah Kurir',
                slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
                accessType: 'read',
              },
            },
          ],
        },
        {
          path: 'edit/:shuttleId',
          name: 'ShuttleManagementEdit',
          component: {
            render: c => c('router-view'),
          },
          redirect: {
            name: 'ShuttleManagementEditForm'
          },
          meta: {
            title: 'Edit Shuttle',
            slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
            accessType: 'read',
            isParent: true,
          },
          children: [
            {
              path: '',
              name: 'ShuttleManagementEditForm',
              component: () =>
                import(
                  /* WebpackChunkName: "ShuttleManagementEdit" */ '@/app/ui/views/ShuttleManagement/ShuttleForm/index.vue'
                ),
              meta: {
                title: 'Edit Shuttle',
                slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
                accessType: 'read',
              },
            },
            {
              path: 'add-courier',
              name: 'ShuttleManagementEditAddCourier',
              component: () =>
                import(
                  /* WebpackChunkName: "ShuttleManagementEditAddCourier" */ '@/app/ui/views/ShuttleManagement/ShuttleForm/AddCourier/index.vue'
                ),
              meta: {
                title: 'Tambah Kurir',
                slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
                accessType: 'read',
              },
            },
          ],
        },
        {
          path: 'detail/:shuttleId',
          name: 'ShuttleManagementDetail',
          component: () =>
            import(
              /* WebpackChunkName: "ShuttleManagementDetail" */ '@/app/ui/views/ShuttleManagement/Detail/index.vue'
            ),
          meta: {
            title: 'Detail Shuttle',
            slugFromBackend: BACKEND_SLUG.SHUTTLE_MANAGEMENT,
            accessType: 'read'
          }
        }
      ],
    },
  ],
}
