import { PhoneCodeMapper } from "@/data/persistences/mappers/PhoneCodeMapper"
import { PhoneCodeRepositoryInterface } from "@/data/persistences/repositories/contracts/PhoneCodeRepositoryInterface"
import { PhoneCodes } from "@/domain/entities/PhoneCode"
import { Endpoints } from "../../misc"
import ApiService from "../../services/ApiService"

export class PhoneCodeApiRepository implements PhoneCodeRepositoryInterface {
  private service: ApiService
  private mapper: PhoneCodeMapper

  constructor(service: ApiService, mapper: PhoneCodeMapper) {
    this.service = service
    this.mapper = mapper
  }

  public async getAll(): Promise<PhoneCodes> {
    const resp = await this.service.invoke(
      'GET',
      Endpoints.Route.PHONE_CODE,
    )

    return this.mapper.convertResponseListPhoneCodeFromApi(resp)
  }
}

