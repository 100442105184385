import { Pagination } from '@/domain/entities/Pagination'

export class Brand {
  public id?: number
  public name?: string
  public imageUrl?: string
  public active?: boolean

  constructor(
    id?: number,
    name?: string,
    imageUrl?: string,
    active?: boolean
  ) {
    this.id = id
    this.name = name
    this.imageUrl = imageUrl
    this.active = active
  }
}

export class Brands {
  public pagination?: Pagination
  public data?: Brand[]

  constructor(pagination?: Pagination, data?: Brand[]) {
    this.pagination = pagination
    this.data = data
  }
}
