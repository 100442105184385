interface OptionMenu {
  label: string
  group?: string
  hide?: boolean
}

export interface Menu {
  label: string
  group?: string
  slug: string
  hide: boolean
}

export const menu = (option: OptionMenu) => {
  return function<T, K extends keyof T>(target: T, key: K): void {
    target[key] = {
      label: option.label as string,
      group: option.group || 'General',
      slug: (target[key] as unknown) as string,
      hide: option.hide || false
    } as T[K] & Menu
  }
}
