import { CancelValidationMapper } from '@/data/persistences/mappers/CancelValidationMapper'
import { CancelValidationRepositoryInterface } from '@/data/persistences/repositories/contracts/CancelValidationRepositoryInterface'
import {
  CancelDetail,
  Cancels,
  CourierShipmentCancels,
} from '@/domain/entities/CancelValidation'
import { Endpoints } from '../../misc'
import ApiService from '../../services/ApiService'
import { UpdateStatusValidationRequest } from '@/data/payload/api/CancelValidationRequest'

export class CancelValidationApiRepository
  implements CancelValidationRepositoryInterface {
  private service: ApiService
  private mapper: CancelValidationMapper

  constructor(service: ApiService, mapper: CancelValidationMapper) {
    this.service = service
    this.mapper = mapper
  }

  async getCancelList(params: Map<string, string | number>): Promise<Cancels> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CANCEL_VALIDATION}/list`,
      params
    )

    return this.mapper.convertCancelListFromApi(resp)
  }

  async getCourierShipmentCancelList(
    courierId: string,
    params: Map<string, string | number>
  ): Promise<CourierShipmentCancels> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CANCEL_VALIDATION}/courier/${courierId}`,
      params
    )

    return this.mapper.convertCourierShipmentCancelListFromApi(resp)
  }

  async getCancelDetail(
    historyId: string,
    params: Map<string, string | number>
  ): Promise<CancelDetail> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CANCEL_VALIDATION}/detail/${historyId}`,
      params
    )

    return this.mapper.convertCourierDetailFromApi(resp)
  }

  async updateStatusValidation(
    historyId: string,
    payload: UpdateStatusValidationRequest
  ): Promise<boolean> {
    await this.service.invoke(
      'PUT',
      `${Endpoints.Route.CANCEL_VALIDATION}/validation/${historyId}`,
      undefined,
      payload
    )

    return true
  }

  async exportCancelList(
    params: Map<string, string | number>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CANCEL_VALIDATION}/export`,
      params
    )

    return this.mapper.convertExportCancelListFromApi(resp)
  }

  async exportCourierShipmentCancelList(
    courierId: number,
    params: Map<string, string | number>
  ): Promise<string> {
    const resp = await this.service.invoke(
      'GET',
      `${Endpoints.Route.CANCEL_VALIDATION}/courier/export/${courierId}`,
      params
    )

    return this.mapper.convertExportCancelListFromApi(resp)
  }
}
